import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { toast } from 'react-toastify';
import { LayoutDto, LayoutWithTemplate } from '../../types/layout';
import TextInputWithLabel from './TextInputWithLabel';
import Button from '../buttons/Button';
import UploadField from '../misc/UploadField';
import usePermissions from '../../hooks/selectors/usePermissions';
import { Permissions } from '../../types/misc';

type LayoutFormProps = {
  onSubmit: (layout: LayoutDto) => void;
  initialState?: LayoutWithTemplate;
  formType?: 'create' | 'update';
}

type LayoutFormState = {
  name: string,
  template: Object,
}

export default function LayoutForm({ onSubmit, initialState, formType = 'create' }: LayoutFormProps): JSX.Element {
  if (!usePermissions([Permissions.ManageConfigurations])) {
    return (<></>);
  }
  const navigate = useNavigate();
  const { t } = useTranslation("layouts");
  const [layout, setLayout] = React.useState<LayoutFormState>({
    name: initialState?.name || '',
    template: initialState?.template || {},
  });
  const [isFileUploaded, setIsFileUploaded] = React.useState<boolean>(!!initialState?.template);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {
    setLayout((prev): LayoutFormState => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const handleFileUpload = ((file: File): void => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const fileContent = event.target?.result as string;

      try {
        const parsedObject = JSON.parse(fileContent);
        setLayout((prev) => ({
          ...prev,
          template: parsedObject,
        }));
        setIsFileUploaded(true);
      } catch (error) {
        toast.error(`${t("read_error_json")}${error}`);
        setIsFileUploaded(false);
      }
    };

    reader.onerror = (error) => {
      toast.error(`${t("read_error_file")}${error}`);
    };

    reader.readAsText(file);
  })

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    onSubmit({
      name: layout.name,
      template: layout.template,
    });
  }

  const handleFileSelection = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const file = event.target.files?.[0];
    if (file) {
      handleFileUpload(file);
    }
  };

  return (
    <form className="max-w-3xl mx-auto w-full" onSubmit={handleSubmit}>
      <h2
        className="text-2xl font-semibold mb-4 text-center">{formType === 'create' ? t("create_title") : t("edit_title")}</h2>
      <TextInputWithLabel label={t("name")} id="name" value={layout.name} onChange={handleChange} name="name"
                          placeholder={t("name_placeholder")} className="bg-white" required />
      <UploadField isFileUploaded={isFileUploaded} handleFileSelection={handleFileSelection} translation="layouts" fileTypes={['json']} />
      <div className="flex justify-end gap-2 my-4">
        <Button type="button" className="bg-gray-500" onClick={(): void => navigate('/layouts')}>
          {t("cancel_button")}
        </Button>
        <Button type="submit">{formType === 'create' ? t("create_button") : t("save_button")}</Button>
      </div>
    </form>
  )
}
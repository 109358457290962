import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import { getAppropriateSubscriptionTypeForSubscriptionCreation } from '../../services/subscriptionTypeService';
import SubscriptionForm from '../../components/forms/SubscriptionForm';
import { createSubscription } from '../../services/subscriptionService';
import { SubscriptionCreateDto, SubscriptionType } from '../../types/subscriptions';

export default function SubscriptionCreatePage(): JSX.Element | null {
  const [subscriptionTypes, setSubscriptionTypes] = useState<SubscriptionType[]>([]);
  const navigate = useNavigate();
  const { t } = useTranslation('subscription_form');

  useEffect((): void => {
    getAppropriateSubscriptionTypeForSubscriptionCreation()
      .then((data): void => {
        setSubscriptionTypes(data);
      })
      .catch((error): void => {
        toast.error(error.message);
      });
  }, []);

  const onSubmit = (subscriptionType: SubscriptionCreateDto): void => {
    createSubscription(subscriptionType).then((): void => {
      toast.success(t("created"));
      navigate('/payments');
    }).catch((error): void => {
      if (error instanceof AxiosError && error.response?.data.errors) {
        toast.error(
          error.response?.data.errors
            .flatMap((e: { constraints: { [k: string]: string }[] }): {
              [k: string]: string
            }[] => Object.values(e.constraints))
            .map((x: string): string => x.charAt(0).toUpperCase() + x.slice(1))
            .join('. '),
        );
      } else {
        toast.error(error.message);
      }
    });
  };

  return (
    <>
        <SubscriptionForm subscriptionTypes={subscriptionTypes} onSubmit={onSubmit} />
    </>
  );
}

import { StatusCodes } from 'http-status-codes';
import { max } from 'lodash';
import { setAuthenticated } from '../redux/slices/applicationSlice';
import { store } from '../redux/store';
import { AuthTokens } from '../types/auth';
import { jwtUtil } from '../utils';
import httpService from './httpService';
import { UsernamePasswordDto } from './models/auth.model';
import { getTokenMillisecondsExp } from '../utils/jwtUtil';

const REFRESH_TOKEN_LOCK_ID = 'refresh_token_lock';
const REFRESH_OFFSET_MILLISECONDS = 20 * 1000; // 20 seconds before expiration

export function login(dto: UsernamePasswordDto): Promise<void> {
  return httpService.post<AuthTokens>('/api/auth/login', dto)
    .then(({ data }) => {
      jwtUtil.setAccessToken(data.accessToken);
      jwtUtil.setRefreshToken(data.refreshToken);
      scheduleTokenRefresh();
      store.dispatch(setAuthenticated(true));
    });
}

export function refreshTokens(): Promise<void> {
  return httpService.post<AuthTokens>('/api/auth/refresh', { refreshToken: localStorage.getItem('refresh_token') })
    .then(({ status, data }) => {
      if (status === StatusCodes.UNAUTHORIZED) {
        store.dispatch(setAuthenticated(false));
      } else if (status === StatusCodes.OK) {
        jwtUtil.setAccessToken(data.accessToken);
        jwtUtil.setRefreshToken(data.refreshToken);
        scheduleTokenRefresh();
      }
    });
}

export function register(dto: UsernamePasswordDto): Promise<void> {
  return httpService.post('/api/auth', dto).then(() => {});
}

export function logout(): void {
  store.dispatch(setAuthenticated(false));
  jwtUtil.wipeToken();
}

export function scheduleTokenRefresh(): void {
  const accessToken = jwtUtil.getAccessToken();
  if (!accessToken) return;
  
  const exp = getTokenMillisecondsExp(accessToken);
  const timeToExp = (exp - REFRESH_OFFSET_MILLISECONDS) - Date.now();
  setTimeout(async () => {
    await refreshTokenInner()
  }, max([timeToExp, 0]));
}
  
async function refreshTokenInner(): Promise<void> {
  const options: LockOptions = { ifAvailable: true, mode: 'exclusive' };
  await navigator.locks.request(REFRESH_TOKEN_LOCK_ID, options, async () => {
    const accessToken = jwtUtil.getAccessToken();
    const shouldBeRefreshed = !accessToken || Date.now() >= (getTokenMillisecondsExp(accessToken) - REFRESH_OFFSET_MILLISECONDS);
    if (!shouldBeRefreshed) return;
    
    const refreshToken = jwtUtil.getRefreshToken();
    if (!refreshToken) return;
    
    const refreshExp = getTokenMillisecondsExp(refreshToken);
    if (Date.now() >= refreshExp) return;
    
    await refreshTokens();
  });
}

export async function requestPasswordReset(email: string): Promise<void> {
  await httpService.put(`/api/auth/reset?email=${email}&origin=backoffice` );
}

export async function resetPassword(token: string, password: string): Promise<void> {
  await httpService.post('/api/auth/reset', { token, newPassword: password });
}

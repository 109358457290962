import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import AccountForm from '../../components/forms/AccountForm';
import { AccountDto, LocationAndRole } from '../../types/account';
import { createAccount, updateLocationRole } from '../../services/accountService';
import ProgressBar from '../../components/misc/ProgressBar';

export default function CreateAccountPage(): JSX.Element {
  const navigate = useNavigate();
  const { t } = useTranslation('accounts_form');
  const [progress, setProgress] = React.useState<{ progress: number, statusText: string; }>();


  const onSubmit = (account: AccountDto, locationRolePairs: LocationAndRole[]): void => {
    setProgress({ progress: 0.15, statusText: t('progress_create_account') });

    createAccount(account)
      .then((newAccount) => {
        setProgress({ progress: 0.5, statusText: t('progress_location_roles') });

        Promise.all(
          locationRolePairs.map((locationRolePair) => updateLocationRole(newAccount.id, locationRolePair.locationId, locationRolePair.roleId)),
        )
          .then(() => {
            setProgress({ progress: 1, statusText: t('progress_done') });
            setTimeout(() => navigate('/accounts'), 500); // to give the user a chance to see the progress bar
            toast.success(t('account_created'));
          })
          .catch(() => {
            toast.error(t('error_assigning_roles'));
            setProgress(undefined);
          });
      })
      .catch(() => {
        toast.error(t('error_creating_account'));
        setProgress(undefined);
      });
  };

  return (
    <>
      {progress && (
        <div className="py-2 flex items-center gap-2">
          <ProgressBar progress={progress.progress} />
          <p className="text-green-500">{progress.statusText}</p>
        </div>
      )}
      <AccountForm onSubmit={onSubmit} />
    </>
  );
}

import { Scanner, ScannerDTO } from '../types/tickets';
import httpService from './httpService';
import { store } from '../redux/store';

export async function getScannersForCurrentLocation(offset: number, limit: number): Promise<Scanner[]> {
  const locationId = store.getState().locations.selectedLocation;

  return httpService
    .get<Scanner[]>(`/api/locations/${locationId}/scanners?offset=${offset}&limit=${limit}`)
    .then((response): Scanner[] => response.data);
}

export async function createScanner(data: ScannerDTO): Promise<Scanner> {
    const locationId = store.getState().locations.selectedLocation;
  
    return httpService
      .post<Scanner>(`/api/locations/${locationId}/scanners`, data)
      .then((response): Scanner => response.data);
}

export async function deleteScanner(scannerId: number): Promise<Scanner> {
    const locationId = store.getState().locations.selectedLocation;
  
    if (!locationId) {
      throw new Error('No location selected');
    }
  
    return httpService
      .delete<Scanner>(`/api/locations/${locationId}/scanners/${scannerId}`)
      .then((response): Scanner => response.data);
}
  
export async function updateScanner(scannerId: number, data: Partial<ScannerDTO>): Promise<Scanner> {
    const locationId = store.getState().locations.selectedLocation;

    return httpService
        .put<Scanner>(`/api/locations/${locationId}/scanners/${scannerId}`, data)
        .then((response): Scanner => response.data);
}
  
export async function getScanner(scannerId: number): Promise<Scanner> {
    const locationId = store.getState().locations.selectedLocation;
  
    return httpService
      .get<Scanner>(`/api/locations/${locationId}/scanners/${scannerId}`)
      .then((response): Scanner => {
        return response.data;
      });
}
  

export interface EmailTemplate {
  /**
   * The id of the email template
   */
  id: number,
  /**
   * The name of the email template
   */
  name: string,
}

export interface EmailTemplateWithData {
  /**
   * The id of the email template
   */
  id: number,
  /**
   * The name of the email template
   */
  name: string,
  /**
   * The subject of the email template
   */
  subject: string,
  /**
   * The data of the email template
   */
  data: string
}

export interface EmailTemplateDto {
  /**
   * The name of the email template
   */
  name: string,
  /**
   * The subject of the email template
   */
  subject: string,
  /**
   * The data of the email template
   */
  data: string
}

export enum EmailTemplateType {
  PURCHASE_CONFIRMATION = 'PURCHASE_CONFIRMATION',
  PURCHASE_REFUND = 'PURCHASE_REFUND',
  PURCHASE_CANCELLATION = 'PURCHASE_CANCELLATION',
  BOOKING_CONFIRMATION = 'BOOKING_CONFIRMATION'
}

export interface ShopEmailTemplate {
  type: EmailTemplateType,
  emailTemplateId?: number,
  emailTemplate?: EmailTemplate,
}
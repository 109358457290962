import React, { ChangeEvent, Dispatch, SetStateAction } from 'react';
import { ExtendedSlot } from '../types/slots';
import { isSlotInAnActiveColumnOrRow } from '../utils/dayGroupUtils';
import { DayGroupFilters } from '../types/filtering';

type TableSelectionUtils = {
  cellSelectionState: Set<number>,
  setCellSelectionState: Dispatch<SetStateAction<Set<number>>>,
  toggleAll: (ev: ChangeEvent<HTMLInputElement>) => void,
  toggleCell: (ev: ChangeEvent<HTMLInputElement>, slot: ExtendedSlot) => void,
  toggleColumn: (ev: ChangeEvent<HTMLInputElement>, slotId: string) => void,
  toggleRow: (ev: ChangeEvent<HTMLInputElement>, dateId: string) => void,
};

export default function useTableSelection(extendedSlots: ExtendedSlot[], filters: DayGroupFilters): TableSelectionUtils {
  const [cellSelectionState, setCellSelectionState] = React.useState(new Set<number>());

  const toggleSelection = (add: boolean, ids: number[]): void => {
    if (add) {
      setCellSelectionState((prev): Set<number> => {
        const newSet = new Set(prev);
        ids.forEach((id): Set<number> => newSet.add(id));
        return newSet;
      });
    } else {
      setCellSelectionState((prev): Set<number> => {
        const newSet = new Set(prev);
        ids.forEach((id): boolean => newSet.delete(id));
        return newSet;
      });
    }
  };

  const toggleCell = (ev: ChangeEvent<HTMLInputElement>, slot: ExtendedSlot): void => {
    if (ev.target.checked) {
      setCellSelectionState(new Set(cellSelectionState).add(slot.id));
    } else {
      const newSet = new Set(cellSelectionState);
      newSet.delete(slot.id);
      setCellSelectionState(newSet);
    }
  };

  const toggleRow = (ev: ChangeEvent<HTMLInputElement>, dateId: string): void => {
    const ids = extendedSlots
      .filter((s): boolean => s.dateId === dateId && isSlotInAnActiveColumnOrRow(s, filters))
      .map((s): number => s.id);

    toggleSelection(ev.target.checked, ids);
  };

  const toggleColumn = (ev: ChangeEvent<HTMLInputElement>, slotId: string): void => {
    const ids = extendedSlots
      .filter((s): boolean => s.slotId === slotId && isSlotInAnActiveColumnOrRow(s, filters))
      .map((s): number => s.id);

    toggleSelection(ev.target.checked, ids);
  };

  const toggleAll = (ev: ChangeEvent<HTMLInputElement>): void => {
    if (ev.target.checked) {
      setCellSelectionState(new Set(extendedSlots.filter((s): boolean => isSlotInAnActiveColumnOrRow(s, filters)).map((s): number => s.id)));
    } else {
      setCellSelectionState(new Set());
    }
  };

  return {
    cellSelectionState,
    setCellSelectionState,
    toggleAll,
    toggleCell,
    toggleColumn,
    toggleRow,
  };
}

import { useEffect, MutableRefObject, EffectCallback } from 'react';

export default function useOutsideEffect(
  callback: EffectCallback,
  ref: MutableRefObject<any>,
): void {
  useEffect(() => {
    function handleClickOutside(event: MouseEvent): void {
      if (ref.current && !ref.current.contains(event.target)) {
        callback();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [ref]);
}

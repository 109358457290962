import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import useAuth from '../hooks/selectors/useAuth';

export default function AuthenticatedRoute(): JSX.Element {
  const auth = useAuth();

  if (!auth) return <Navigate to="/auth" />;
  return <Outlet />;
}

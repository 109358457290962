import React, { useEffect, useState } from 'react';
import { DndContext, closestCenter, DragEndEvent, useSensors, MouseSensor, TouchSensor, KeyboardSensor, useSensor } from '@dnd-kit/core';
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable';
import DraggableItem from './DraggableItem';

interface DraggableListProps<T> {
  items: T[];
  onItemsChange: (updatedItems: T[]) => void;
}

export default function DraggableList<T extends { id: number, name: string, checked: boolean }>({ items, onItemsChange }: DraggableListProps<T>): JSX.Element {
  const [internalItems, setInternalItems] = useState<T[]>([]);

  useEffect(() => {
    setInternalItems(items);
  }, [internalItems])

  const sensors = useSensors(
    useSensor(MouseSensor, {
      activationConstraint: {
        distance: 8,
      },
    }),
    useSensor(TouchSensor, {
      activationConstraint: {
        delay: 200,
        tolerance: 6,
      },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragEnd = (event: DragEndEvent): void => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      const oldIndex = internalItems.findIndex(item => item.id.toString() === active.id);
      const newIndex = internalItems.findIndex(item => item.id.toString() === over?.id);
      const updatedItems = arrayMove(internalItems, oldIndex, newIndex);

      setInternalItems(updatedItems);
      onItemsChange(updatedItems);
    }
  };

  const handleCheckboxChange = (id: string, checked: boolean): void => {
    const updatedItems = internalItems.map(item =>
        item.id.toString() === id ? { ...item, checked } : item
    );

    setInternalItems(updatedItems);
    onItemsChange(updatedItems);
  };

  return (
    <DndContext collisionDetection={closestCenter} onDragEnd={handleDragEnd} sensors={sensors}>
      <SortableContext items={internalItems.map(item => item.id.toString())} strategy={verticalListSortingStrategy}>
        <div className="mx-[auto] my-[0]">
          {internalItems.map(item => (
            <DraggableItem
              key={item.id}
              id={item.id.toString()}
              name={item.name}
              checked={item.checked}
              handleCheckboxChange={handleCheckboxChange}
            />
          ))}
        </div>
      </SortableContext>
    </DndContext>
  );
};
import { Reservation } from "../types/reservation";
import httpService from "./httpService";

export async function downloadReservations(reservations: string[]): Promise<Buffer> {
  return httpService
    .post<Buffer>('/api/reservations/download', {uuids: reservations}, {headers: {'Content-Type': 'application/json'}, responseType: 'arraybuffer'})
    .then(({ data }) => data);
}

export function getReservationsByIds(reservationIds: number[]): Promise<Reservation[]> {
    return httpService
    .post<Reservation[]>(`/api/reservations/all`, { reservationIds })
      .then((response): Reservation[] => response.data);
  }
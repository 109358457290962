import { DayGroup, DayGroupCreateOrUpdate, DayGroupWithTicketTypePricingPolicies } from '../types/dayGroups';
import { store } from '../redux/store';
import httpService from './httpService';

export async function deleteDayGroup(capacityGroupId: number, dayGroupId: number): Promise<DayGroup> {
  const locationId = store.getState().locations.selectedLocation;

  return httpService
    .delete<DayGroup>(`/api/locations/${locationId}/capacity-groups/${capacityGroupId}/day-groups/${dayGroupId}`)
    .then((response): DayGroup => response.data);
}

export async function createDayGroup(capacityGroupId: number, data: DayGroupCreateOrUpdate): Promise<DayGroup> {
    const locationId = store.getState().locations.selectedLocation;
  
    return httpService
      .post<DayGroup>(`/api/locations/${locationId}/capacity-groups/${capacityGroupId}/day-groups`, data)
      .then((response): DayGroup => response.data);
}

export async function updateDayGroup(capacityGroupId: number, dayGroupId: number, data: DayGroupCreateOrUpdate): Promise<DayGroup> {
  const locationId = store.getState().locations.selectedLocation;
  console.log(data);
  return httpService
    .put<DayGroup>(`/api/locations/${locationId}/capacity-groups/${capacityGroupId}/day-groups/${dayGroupId}`, data)
    .then((response): DayGroup => response.data);
}

export async function getDayGroup(capacityGroupId: number, dayGroupId: number): Promise<DayGroupWithTicketTypePricingPolicies> {
  const locationId = store.getState().locations.selectedLocation;

  return httpService
    .get(`/api/locations/${locationId}/capacity-groups/${capacityGroupId}/day-groups/${dayGroupId}`)
    .then(response => {
      console.log(response.data);
      return response.data
    });
}
  
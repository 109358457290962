import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FaRegTrashAlt } from 'react-icons/fa';
import TextInputWithLabel from './TextInputWithLabel';
import Button from '../buttons/Button';
import { CreateTicketReservationsAdminDto } from '../../types/tickets';
import CreateTicketReservationTable from '../tables/CreateTicketReservationTable';
import IconButton from '../buttons/IconButton';
import usePermissions from '../../hooks/selectors/usePermissions';
import { Permissions } from '../../types/misc';

type TicketFormProps = {
    onSubmit: (createTicketReservationDto: CreateTicketReservationsAdminDto) => void;
}

export type CreateTicketReservationsFormState = {
    reservations: TicketReservationsFormState[];
  }
  
  export type TicketReservationsFormState = {
    userDetails: UserDetailsForTicketReservationFormState;
    tickets: TicketReservationFormState[];
  }
  
  export type UserDetailsForTicketReservationFormState = {
    email: string;
    firstName: string;
    lastName: string;
  }
  
  export type TicketReservationFormState = {
    ticketTypeId: string;
    amount: string;
  }

function TicketForm({
    onSubmit,
}: TicketFormProps): JSX.Element {
    if (!usePermissions([Permissions.ManageTickets])) {
        return (<></>);
    }
    
    const navigate = useNavigate();
    const { t } = useTranslation("ticket_reservation_form");

    const [tickets, setTickets] = useState<CreateTicketReservationsFormState>({
        reservations: [
            {
                userDetails: {
                    email: '',
                    firstName: '',
                    lastName: '',
                },
                tickets: [
                    {
                        ticketTypeId: '',
                        amount: '',
                    },
                ],
            },
        ],
    });

    const handleUserDetailsChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const { name, value, id } = event.target;

        const rId = Number(id.split('-').slice(-1));
        setTickets((prev) => ({
            reservations: prev.reservations.map((reservation, reservationIndex) => {
                if (rId === reservationIndex) {
                    return {
                        ...reservation,
                        userDetails: {
                            ...reservation.userDetails,
                            [name]: value,
                        },
                    };
                }
                return reservation;
            }),
        }))
    }

    const handleTicketReservationChange = (reservationId: number, newTicketReservations: TicketReservationFormState[]): void => {
        setTickets((prev) => ({
            reservations: prev.reservations.map((reservation, reservationIndex) => {
                if (reservationId === reservationIndex) {
                    return {
                        ...reservation,
                        tickets: newTicketReservations,
                    };
                }
                return reservation;
            }),
        }));
    }

    const addTicketReservation = (): void => {
        setTickets((prev) => ({
            reservations: [
                ...prev.reservations,
                {
                    userDetails: {
                        email: '',
                        firstName: '',
                        lastName: '',
                    },
                    tickets: [
                        {
                            ticketTypeId: '',
                            amount: '',
                        },
                    ],
                },
            ],
        }));
    }

    const removeTicketReservation = (rId: number): void => {
        setTickets((prev) => ({
            reservations: prev.reservations.filter((_reservation, reservationIndex) => reservationIndex !== rId),
        }));
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        onSubmit({
            reservations: tickets.reservations.map((reservation) => ({
                userDetails: reservation.userDetails,
                tickets: reservation.tickets.map((ticket) => ({
                    ticketTypeId: Number(ticket.ticketTypeId),
                    amount: Number(ticket.amount),
                })),
            })),
        });
      };

    return (
    <>
        <h2 className="font-semibold text-xl text-center">{t("create_ticket_header")}</h2>
        <form className="flex flex-col gap-4 w-full max-w-3xl m-auto" onSubmit={handleSubmit}>
            {tickets.reservations.length > 0 && <p className="my-1 font-semibold text-lg">{t("ticket_reservations")}</p>}
            {tickets.reservations.map((reservation, reservationIndex) => (
                <div className="p-4 bg-white rounded-md flex flex-col gap-2" key={reservationIndex}>
                    <div className="flex flex-row justify-between">
                        <h2 className="mb-1 font-semibold">{t("user")} {reservationIndex + 1}</h2>
                        <IconButton icon={FaRegTrashAlt} label={t('remove_user')} variant='danger' className='px-3 py-3 mb ml-4' onClick={() => removeTicketReservation(reservationIndex)} />
                    </div>
                    <TextInputWithLabel label={t("email")} id={`email-${reservationIndex}`} placeholder={t("email_placeholder")} name="email" onChange={handleUserDetailsChange}
                            value={tickets.reservations[reservationIndex].userDetails.email} className="bg-white" required />
                    <TextInputWithLabel label={t("first_name")} id={`first-name-${reservationIndex}`} placeholder={t("first_name_placeholder")} name="firstName" onChange={handleUserDetailsChange}
                                    value={tickets.reservations[reservationIndex].userDetails.firstName} className="bg-white" required />
                    <TextInputWithLabel label={t("last_name")} id={`last-name-${reservationIndex}`} placeholder={t("last_name_placeholder")} name="lastName" onChange={handleUserDetailsChange}
                                    value={tickets.reservations[reservationIndex].userDetails.lastName} className="bg-white" required />
                    <CreateTicketReservationTable reservationId={reservationIndex} items={reservation.tickets} onChangeTicketReservations={handleTicketReservationChange}/>
                </div>
            ))}
            <Button className='mt-4' type='button' onClick={addTicketReservation}>{t("add_user")}</Button>
            <div className="flex justify-end gap-2 my-4">
                <Button type="button" className="bg-gray-500" onClick={(): void => navigate('/payments')}>{t("cancel_button")}</Button>
                <Button type="submit">{t("create_ticket_reservations")}</Button>
            </div>
        </form>
    </>
    );
}

export default TicketForm;

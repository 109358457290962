export enum Language {
  EN = 'en',
  NL = 'nl',
}

export interface Auth {
  email: string;
}

/**
 * Flags enum for user permissions
 */
export enum Permissions {
  /**
   * No permissions needed
   */
  None = 0,
  /**
   * Manage locations
   */
  ManageLocations = 1,

  ManageRoles = 2,

  ViewConfigurations = 4,

  ManageConfigurations = 12,

  ManageUsers = 16,

  ManageTickets = 32,

  ManageSubscriptions = 64,

  ManagePayments = 128,
  /**
   * Manage privileged/Duosport subscriptions
   */
  ManagePrivilegedSubscription = 256,

  ManageDiscounts = 512,
}

export interface CheckboxOption {
  label: string;
  value: string;
  description: string;
}

export type FetchData<T> = {
  data: T;
  loading: boolean;
  error: Error | null;
}

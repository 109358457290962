import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CapacityGroupUpdate, CapacityGroupWithDayGroups } from '../../types/capacityGroups';
import { getCapacityGroup, updateCapacityGroup } from '../../services/capacityGroupService';
import CapacityGroupForm from '../../components/forms/CapacityGroupForm';

export default function EditCapacityGroupPage(): JSX.Element {
    const navigate = useNavigate();
    const [capacityGroup, setCapacityGroup] = React.useState<CapacityGroupWithDayGroups>();
    const { id } = useParams();

  useEffect(() => {
    if (!id) {
      navigate('/capacity-groups');
    }

    getCapacityGroup(Number(id))
      .then((oldCapacityGroup): void => {
        setCapacityGroup(oldCapacityGroup);
      })
      .catch((error): void => {
        toast.error(error.message);
        navigate(`/capacity-groups`);
      })
  }, []);

  const onSubmit = (capacityGroupDto: CapacityGroupUpdate): void => {
    updateCapacityGroup(capacityGroupDto, Number(id))
      .then((): void => {
        toast.success('Capacity group updated');
        navigate(`/capacity-groups`);
      })
      .catch((error): void => {
        toast.error(error);
      });
  }

  if (!capacityGroup) {
    return <></>;
  }
  
    return (
      <CapacityGroupForm onSubmitCreate={() => {}} onSubmitUpdate={onSubmit} initialState={capacityGroup} formType="update" capacityGroupId={capacityGroup.id} />
    );
  }
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { toast } from 'react-toastify';
import { LayoutDto } from '../../types/layout';
import LayoutForm from '../../components/forms/LayoutForm';
import { createLayoutForLocation } from '../../services/layoutService';

export default function LayoutsCreatePage(): JSX.Element {
  const navigate = useNavigate();
  const { t } = useTranslation("layouts");
  const onSubmit = (layout: LayoutDto): void => {
    createLayoutForLocation(layout)
      .then(() => {
        toast.success(t("created"));
        navigate('/layouts');
      })
      .catch((error): void => {
        toast.error(`${t("failed_create")}: ${error}`);
      })
  }
  
  return (
    <LayoutForm onSubmit={onSubmit} />
  )
}

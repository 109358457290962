import React, { HTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';
import { PaymentStatus } from '../../types/filter';

type PaymentStatusProps = {
  label: string,
  paymentStatus?: PaymentStatus,
} & HTMLAttributes<HTMLDivElement>;

const paymentStatusClasses: { [key in PaymentStatus]: string } = {
  paid: 'bg-green-500',
  open: 'bg-orange-400',
  failed: 'bg-red-500',
  expired: 'bg-red-500',
  canceled: 'bg-red-500',
  pending: 'bg-orange-400',
  authorized: 'bg-green-500',
  processing: 'bg-orange-400',
  refunded: 'bg-green-500',
  notissued: 'bg-slate-500',
  all: 'bg-green-500',
}

export default function PaymentStatusColorBar({ label, paymentStatus, ...props }: PaymentStatusProps): JSX.Element {
  return <div className={twMerge('rounded-lg p-1 text-center text-white w-32', paymentStatus ? paymentStatusClasses[paymentStatus] : '')} {...props}>
      <span>{label.toUpperCase()}</span>
    </div>
}

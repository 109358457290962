import { createSelector } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { LocationsState, RootState } from '../../types/redux';
import { Location } from '../../types/location';

const selectLocations = (state: RootState): LocationsState => state.locations;
const selectSelectedLocation = (state: RootState): number | undefined => state.locations.selectedLocation;

const selectLocation = createSelector(
  [selectLocations, selectSelectedLocation],
  (locations, selectedLocation) => locations.locations.find((l): boolean => l.id === selectedLocation),
)

export default function useSelectedLocation(): Location | undefined {
  return useSelector(selectLocation);
}

import React, { Dispatch, FormEvent, SetStateAction, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TbPlus, TbX } from 'react-icons/tb';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import TextInputWithLabel from './TextInputWithLabel';
import SelectWithData from './SelectWithData';
import { AccountDto, AccountWithUserAndIdentities, LocationAndRole } from '../../types/account';
import Button from '../buttons/Button';
import IconButton from '../buttons/IconButton';
import { getAllRoles } from '../../services/accountService';
import { store } from '../../redux/store';
import { setRoles } from '../../redux/slices/rolesSlice';
import useRoles from '../../hooks/selectors/useRoles';
import useLocations from '../../hooks/selectors/useLocations';
import usePermissions from '../../hooks/selectors/usePermissions';
import { Permissions } from '../../types/misc';

type CreateAccountState = {
  email?: string;
  roleId?: string;
}

type LocationAndRoleStateEntry = {
  index: number;
  locationId?: number;
  roleId?: number;
}

type LocationAndRoleState = LocationAndRoleStateEntry[];

type AccountFormProps = {
  onSubmit: (account: AccountDto, locationRolePairs: LocationAndRole[]) => void;
  initialAccount?: AccountWithUserAndIdentities;
  formType?: 'create' | 'edit';
}

export default function AccountForm({ onSubmit, initialAccount, formType = 'create' }: AccountFormProps): JSX.Element {
  if (!usePermissions([Permissions.ManageUsers])) {
    return (<></>);
  }
  const { t } = useTranslation('accounts_form');
  const navigate = useNavigate();

  const [account, setAccount] = useState<CreateAccountState>({
    email: initialAccount?.email,
    roleId: initialAccount?.roleId?.toString() ?? undefined,
  });

  const [locationRoles, setLocationRoles] = React.useState<LocationAndRoleState>([
    ...initialAccount?.locationAccounts.map((la) => ({
      // when the index is negative, it means it is a pre-existing location role
      index: -la.locationId,
      locationId: la.locationId,
      roleId: la.roleId,
    })) ?? [],
  ]);

  const allRoles = useRoles();

  const handleAccountChange = (ev: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {
    setAccount((prev) => ({
      ...prev,
      [ev.target.name]: ev.target.value,
    }));
  };

  const handleSubmit = (ev: FormEvent<HTMLFormElement>): void => {
    ev.preventDefault();
    if (account.email && account.roleId && locationRoles.every((lr) => lr.locationId !== undefined && lr.roleId !== undefined)) {
      onSubmit(
        { email: account.email, roleId: +account.roleId },
        locationRoles.map(lr => ({ locationId: (lr.locationId as number), roleId: (lr.roleId as number) })),
      );
      return;
    }

    toast.error(t('missing_account_fields_error'));
  };

  useEffect(() => {
    getAllRoles()
      .then(roles => store.dispatch(setRoles(roles)))
      .catch(() => console.error(t('error_fetching_roles')));
  }, []);

  return (
    <>
      <h2
        className="text-2xl font-bold text-center mb-4">{formType === 'create' ? t('create_title') : t('edit_title')}</h2>
      <form onSubmit={handleSubmit} className="flex flex-col gap-4 w-full max-w-3xl m-auto">
        <TextInputWithLabel type="email" className="bg-white" name="email" value={account.email}
                            onChange={handleAccountChange} required
                            label={t('email')} id="email" placeholder={t('email_placeholder')} />
        <SelectWithData label={t('general_role')} placeholder={t('general_role_placeholder')}
                        data={allRoles} name="roleId" id="role-id" containerClassName="flex-1"
                        value={account.roleId} onChange={handleAccountChange} required />

        <LocationRolesEditor locationRoles={locationRoles} setLocationRoles={setLocationRoles} />

        <div className="flex justify-end gap-2 my-4">
          <Button onClick={() => navigate('/accounts')} type="button" className="bg-gray-500">
            {t('cancel_button')}
          </Button>
          <Button type="submit">{formType === 'create' ? t('create_button') : t('edit_button')}</Button>
        </div>
      </form>
    </>
  );
}

type LocationRolesEditorProps = {
  locationRoles: LocationAndRoleState;
  setLocationRoles: Dispatch<SetStateAction<LocationAndRoleState>>
}

function LocationRolesEditor({ locationRoles, setLocationRoles }: LocationRolesEditorProps): JSX.Element {
  const { t } = useTranslation('accounts_form');
  const allLocations = useLocations();
  const [counter, setCounter] = useState(0);

  const addLocationAndRole = (): void => {
    if (locationRoles.some((locationRole) => locationRole.locationId === undefined || locationRole.roleId === undefined)) {
      toast.warn(t('missing_fields_error'));
      return;
    }

    setLocationRoles((prev) => [...prev, { index: counter, locationId: undefined, roleId: undefined }]);
    setCounter((prev) => prev + 1);
  };

  const onRowDelete = (index: number): void => {
    setLocationRoles((prev) => prev.filter((locationRole) => locationRole.index !== index));
  };

  return (
    <div>
      <div className="flex justify-between items-center mb-4">
        <div>
          <h3 className="font-semibold text-lg">{t('location_specific_rules_title')}</h3>
          <p className="text-sm text-gray-600">{t('location_specific_rules_helper')}</p>
        </div>
        <IconButton type="button" onClick={addLocationAndRole} variant="success" className="rounded-full" icon={TbPlus}
                    label={t('add_rule')} disabled={locationRoles.length === allLocations.length} />
      </div>
      <div className="flex flex-col gap-2">
        {locationRoles.map((locationRole) => (
          <LocationRoleRow
            key={`rule_${locationRole.index}`} locationRole={locationRole}
            onDelete={() => onRowDelete(locationRole.index)}
            onLocationChange={(locationId) => setLocationRoles((prev) => prev.map((lr) => lr.index === locationRole.index ? {
              ...lr,
              locationId,
            } : lr))}
            onRoleChange={(roleId) => setLocationRoles((prev) => prev.map((lr) => lr.index === locationRole.index ? {
              ...lr,
              roleId,
            } : lr))}
          />
        ))}
        {locationRoles.length === 0 ?
          <p className="text-center font-light font-sm">{t('no_location_specific_rules')}</p> : null}
      </div>
    </div>
  );
}

type LocationRoleRowProps = {
  locationRole: LocationAndRoleStateEntry;
  onDelete: () => void;
  onLocationChange: (locationId: number) => void;
  onRoleChange: (roleId: number) => void;
}

function LocationRoleRow
({
   locationRole,
   onDelete,
   onLocationChange,
   onRoleChange,
 }: LocationRoleRowProps): JSX.Element {
  const { t } = useTranslation('accounts_form');
  const allRoles = useRoles();
  const allLocations = useLocations();

  console.log(allRoles);

  return (
    <div className="flex justify-between items-center gap-2">
      <p>{t('for_location')}</p>
      <SelectWithData label="" placeholder={t('location_placeholder')}
                      data={allLocations} id={`location-id-${locationRole.index}`}
                      containerClassName="flex-1" disabled={locationRole.index < 0}
                      value={locationRole.locationId} onChange={(ev) => onLocationChange(+ev.target.value)} required />
      <p>{t('user_has_role')}</p>
      <SelectWithData label="" placeholder={t('role_placeholder')}
                      data={allRoles} id={`role-id-${locationRole.index}`}
                      containerClassName="flex-1"
                      value={locationRole.roleId} onChange={(ev) => onRoleChange(+ev.target.value)} required />
      <IconButton type="button" onClick={onDelete} variant="danger" className="aspect-square p-2" icon={TbX}
                  aria-label={t('remove_row_label')} label="" disabled={locationRole.index < 0} />
    </div>
  );
}

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { TicketCategory, TicketCategoryDto } from '../../types/tickets';
import TextInputWithLabel from './TextInputWithLabel';
import Button from '../buttons/Button';
import usePermissions from '../../hooks/selectors/usePermissions';
import { Permissions } from '../../types/misc';

type TicketCategoryFormProps = {
  onSubmit: (ticketCategory: TicketCategoryDto) => void;
  initialState?: TicketCategory;
  formType?: 'create' | 'update';
}

type TicketCategoryFormState = {
  name: string;
}

export default function TicketCategoryForm({ onSubmit, initialState, formType = 'create' }: TicketCategoryFormProps): JSX.Element {
  if (!usePermissions([Permissions.ManageConfigurations])) {
    return (<></>);
  }
  const navigate = useNavigate();
  const { t } = useTranslation("ticket_category");
  const [ticketCategory, setTicketCategory] = React.useState<TicketCategoryFormState>({
    name: initialState?.name || '',
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {
    setTicketCategory((prev): TicketCategoryFormState => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    onSubmit({
      name: ticketCategory.name,
    });
  }

  return (
    <form className="max-w-3xl mx-auto w-full" onSubmit={handleSubmit}>
      <h2
        className="text-2xl font-semibold mb-4 text-center">{formType === 'create' ? t("create_title") : t("edit_title")}</h2>
      <TextInputWithLabel label={t("name")} id="name" value={ticketCategory.name} onChange={handleChange} name="name"
                          placeholder={t("name_placeholder")} className="bg-white" required />
      <div className="flex justify-end gap-2 my-4">
        <Button type="button" className="bg-gray-500" onClick={(): void => navigate('/ticket-categories')}>
          {t("cancel_button")}
        </Button>
        <Button type="submit">{formType === 'create' ? t("create_button") : t("save_button")}</Button>
      </div>
    </form>
  )
}

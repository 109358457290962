import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PricingPolicyDTO } from '../../types/pricingPolicies';
import { createPricingPolicy } from '../../services/pricingPoliciesService';
import PricingPolicyForm from '../../components/forms/PricingPolicyForm';

export default function PricingPoliciesCreatePage(): JSX.Element {
  const navigate = useNavigate();
  const { t } = useTranslation("pricing_policy");

  const onSubmit = (pricingPolicy: PricingPolicyDTO): void => {
    createPricingPolicy(pricingPolicy)
      .then((): void => {
        toast.success(t("created_pricing_policy"));
        navigate('/pricing-policies');
      })
      .catch((error): void => {
        if (error.response?.data?.message) {
          toast.error(`${t("failed_create_pricing_policy")}: ${error.response.data.message}`);
        }
        else {
          toast.error(`${t("failed_create_pricing_policy")}: ${error}`);
        }
      });
  };

  return (
    <PricingPolicyForm onSubmit={onSubmit} />
  );
}

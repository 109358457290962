import { TicketCategory, TicketCategoryDto } from '../types/tickets';
import httpService from './httpService';
import { store } from '../redux/store';

export function getTicketCategoriesForCurrentLocation(offset: number = 0, limit: number = Number.MAX_SAFE_INTEGER): Promise<TicketCategory[]> {
  const locationId = store.getState().locations.selectedLocation;

  return httpService
    .get<TicketCategory[]>(`/api/locations/${locationId}/ticket-categories?offset=${offset}&limit=${limit}`)
    .then((response): TicketCategory[] => response.data);
}

export function getTicketCategoryForCurrentLocation(ticketCategoryId: number): Promise<TicketCategory> {
  const locationId = store.getState().locations.selectedLocation;

  return httpService
    .get<TicketCategory>(`/api/locations/${locationId}/ticket-categories/${ticketCategoryId}`)
    .then((response): TicketCategory => response.data);
}

export function createTicketCategory(data: TicketCategoryDto): Promise<TicketCategory> {
  const locationId = store.getState().locations.selectedLocation;

  return httpService
    .post<TicketCategory>(`/api/locations/${locationId}/ticket-categories`, data)
    .then((response): TicketCategory => response.data);
}

export function editTicketCategory(ticketCategoryId: number, data: TicketCategoryDto): Promise<TicketCategory> {
  const locationId = store.getState().locations.selectedLocation;

  return httpService
    .put<TicketCategory>(`/api/locations/${locationId}/ticket-categories/${ticketCategoryId}`, data)
    .then((response): TicketCategory => response.data);
}

export function deleteTicketCategory(ticketCategoryId: number): Promise<TicketCategory> {
  const locationId = store.getState().locations.selectedLocation;

  return httpService
    .delete<TicketCategory>(`/api/locations/${locationId}/ticket-categories/${ticketCategoryId}`)
    .then((response): TicketCategory => response.data);
}

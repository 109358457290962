import httpService from './httpService';
import { store } from '../redux/store';
import { SubscriptionShop, SubscriptionShopDto } from '../types/subscriptions';

export async function getSubscriptionShopsForCurrentLocation(offset: number, limit: number): Promise<SubscriptionShop[]> {
  const locationId = store.getState().locations.selectedLocation;

  return httpService
    .get<SubscriptionShop[]>(`/api/locations/${locationId}/subscription-shops?offset=${offset}&limit=${limit}`)
    .then(({ data }): SubscriptionShop[] => data);
}

export async function deleteSubscriptionShop(subscriptionShopId: number): Promise<SubscriptionShop> {
  const locationId = store.getState().locations.selectedLocation;

  return httpService
    .delete<SubscriptionShop>(`/api/locations/${locationId}/subscription-shops/${subscriptionShopId}`)
    .then(({ data }): SubscriptionShop => data);
}

export async function createSubscriptionShop(subscriptionShop: SubscriptionShopDto): Promise<SubscriptionShop> {
  const locationId = store.getState().locations.selectedLocation;

  return httpService
    .post<SubscriptionShop>(`/api/locations/${locationId}/subscription-shops`, subscriptionShop)
    .then(({ data }): SubscriptionShop => data);
}

export async function updateSubscriptionShop(subscriptionShopId: number, subscriptionShop: Partial<SubscriptionShopDto>): Promise<SubscriptionShop> {
  const locationId = store.getState().locations.selectedLocation;

  return httpService
    .patch<SubscriptionShop>(`/api/locations/${locationId}/subscription-shops/${subscriptionShopId}`, subscriptionShop)
    .then(({ data }): SubscriptionShop => data);
}

export async function getSubscriptionShopForCurrentLocation(subscriptionShopId: number): Promise<SubscriptionShop | undefined> {
  const locationId = store.getState().locations.selectedLocation;

  return httpService
    .get<SubscriptionShop[]>(`/api/locations/${locationId}/subscription-shops?filter=${encodeURI(JSON.stringify({ id: { equals: subscriptionShopId } }))}`)
    .then(({ data }): SubscriptionShop => data[0]);
}

import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { createColumnHelper, Row } from '@tanstack/react-table';
import { TbCirclePlus, TbEdit, TbTrash } from 'react-icons/tb';
import { BsFiletypeHtml } from 'react-icons/bs';
import useEmailTemplates from '../../hooks/selectors/useEmailTemplates';
import useLanguage from '../../hooks/selectors/useLanguage';
import {
  deleteEmailTemplateForLocation, getEmailTemplateForLocation,
  getEmailTemplatesForCurrentLocation,
} from '../../services/emailTemplatesService';
import { store } from '../../redux/store';
import { setEmailTemplates } from '../../redux/slices/emailTemplatesSlice';
import { EmailTemplate } from '../../types/emailTemplates';
import IconButton from '../../components/buttons/IconButton';
import SelectionTable from '../../components/tables/SelectionTable';
import DeleteModal from '../../components/modals/DeleteModal';
import usePermissions from '../../hooks/selectors/usePermissions';
import { Permissions } from '../../types/misc';

function RenderActionsCell({ row }: { row: Row<EmailTemplate> }, resetPagination: () => void): JSX.Element {
  const [deleteModalOpen, setDeleteModalOpen] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const { t } = useTranslation("email_templates");

  const onDelete = (): void => {
    deleteEmailTemplateForLocation(row.original.id)
      .then((): void => {
        toast.success(t("delete_success"));
        resetPagination();
        setDeleteModalOpen(false);
      })
      .catch((error): void => {
        toast.error(error.message);
      });
  };

  const onDownloadHtml = (): void => {
    getEmailTemplateForLocation(row.original.id)
      .then(({ data }): void => {
        const url = window.URL.createObjectURL(new Blob([data], { type: 'text/html' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'email_template.html');
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      })
  }

  return (
    <>
      <div className="flex justify-end items-center gap-1">
        <IconButton
          onClick={onDownloadHtml}
          icon={BsFiletypeHtml} label="" variant="download"
          className="py-1 px-1 text-lg"
        />
        <IconButton
          onClick={(): void => navigate(`/templates/edit/${row.original.id}`)}
          icon={TbEdit} label="" variant="accent"
          className="py-1 px-1 text-lg"
          disabled={!usePermissions([Permissions.ManageConfigurations])}
        />
        <IconButton
          onClick={(): void => setDeleteModalOpen(true)}
          icon={TbTrash} label="" variant="danger"
          className="py-1 px-1 text-lg"
          disabled={!usePermissions([Permissions.ManageConfigurations])}
        />
      </div>
      <DeleteModal open={deleteModalOpen} setOpen={setDeleteModalOpen} onActionButtonClicked={onDelete}
                   message={`${t("delete_confirmation")} "${row.original.name}" ?`}
      />
    </>
  )
}

export default function EmailTemplatesListPage(): JSX.Element {
  if (!usePermissions([Permissions.ViewConfigurations])) {
    return (<></>);
  }
  const [pagination, setPagination] = React.useState({ pageSize: 25, pageIndex: 0 });
  const emailTemplates = useEmailTemplates();
  const language = useLanguage();
  const navigate = useNavigate();
  const { t } = useTranslation("email_templates");

  const resetPagination = (): void => {
    setPagination(prev => ({ pageSize: prev.pageSize, pageIndex: prev.pageIndex }));
  }

  useEffect(() => {
    getEmailTemplatesForCurrentLocation(pagination.pageIndex * pagination.pageSize, pagination.pageSize)
      .then((data): void => {
        store.dispatch(setEmailTemplates(data));
      })
      .catch((error): void => {
        toast.error(error.message);
      })
  }, [pagination]);

  const columns = useMemo((): any[] => {
    const columnHelper = createColumnHelper<EmailTemplate>();
    return [
      columnHelper.accessor((row) => row.id, {
        id: 'id',
        header: t("id"),
      }),
      columnHelper.accessor((row) => row.name, {
        id: 'name',
        header: t("name")
      }),
      {
        id: 'actions',
        header: t("actions"),
        cell: (row: { row: Row<EmailTemplate> }) => RenderActionsCell(row, resetPagination),
        meta: {
          align: 'text-right'
        },
      },
    ]
  }, [language]);

  return (
    <>
      <div className="flex justify-between items-center mb-2">
        <h2 className="font-bold text-2xl">{t("title")}</h2>
        <div className="flex gap-1">
          {
            usePermissions([Permissions.ManageConfigurations]) &&
            <IconButton onClick={(): void => navigate('/templates/create')} icon={TbCirclePlus} label={t("new")}
                        className="bg-gradient-to-r from-[#E700F9] hover:shadow-purple-300 hover:shadow-md
                      to-pink-500 duration-300 bg-[position:_0%_0%] hover:bg-[position:_100%_100%]
                      bg-[size:_200%] transition-all font-bold text-white rounded-full cursor-pointer" />
          }
        </div>
      </div>
      <SelectionTable
        columns={columns}
        data={emailTemplates}
        pagination={pagination}
        setPagination={setPagination}
        enableRowSelection={false}
      />
    </>
  )
}
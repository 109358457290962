import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { SubscriptionCreateDto, SubscriptionType } from '../../types/subscriptions';
import TextInputWithLabel from './TextInputWithLabel';
import SelectWithData from './SelectWithData';
import Button from '../buttons/Button';
import UploadAndDisplayImage from './Image';
import { uploadFile } from '../../services/pictureService';
import useDebounce from '../../hooks/useDebounce';
import * as accountService from '../../services/accountService';
import { AccountWithUser } from '../../types/account';
import usePermissions from '../../hooks/selectors/usePermissions';
import { Permissions } from '../../types/misc';

type SubscriptionFormProps = {
    onSubmit: (subscription: SubscriptionCreateDto) => void;
    subscriptionTypes?: SubscriptionType[];
}

type SubscriptionFormState = {
        userDetails: UserDetailsFormState;
        cardholders: SubscriptionCardholderIdentityFormState[];
}

type UserDetailsFormState = {
    salutation?: string;
    firstName: string;
    lastName: string;
    birthdate?: string;
    email: string;
    zipCode: string;
    city: string;
}

type SubscriptionCardholderIdentityFormState = {
    firstName: string;
    lastName: string;
    birthdate: string;
    pictureId?: string;
}

const debouncedAccountAutocomplete = useDebounce(accountService.getAccountByEmail, 500);

function SubscriptionForm({
    onSubmit,
    subscriptionTypes = [] as SubscriptionType[],
}: SubscriptionFormProps): JSX.Element {
    if (!usePermissions([Permissions.ManageSubscriptions])) {
        return <></>;
    }

    const navigate = useNavigate();
    const { t } = useTranslation("subscription_form");

    const [chosenSubscriptionType, setChosenSubscriptionType] = useState<SubscriptionType>();
    const [subscription, setSubscription] = useState<SubscriptionFormState>({
        userDetails: {
            salutation: '',
            firstName: '',
            lastName: '',
            birthdate: '',
            email: '',
            zipCode: '',
            city: '',
        },
        cardholders: [...Array(chosenSubscriptionType?.cardholdersCount ?? 0).keys()].map(() => ({
            firstName: '',
            lastName: '',
            birthdate: '',
            pictureId: '',
        })),
    });

    useEffect(() => {
        setSubscription((prev) => ({
            ...prev,
            cardholders: [...Array(chosenSubscriptionType?.cardholdersCount ?? 0).keys()].map(() => ({
                firstName: '',
                lastName: '',
                birthdate: '',
                pictureId: '',
            })),
        }));
    }, [chosenSubscriptionType]);

    useEffect(() => {
        if (subscription.userDetails.email) {
            debouncedAccountAutocomplete(subscription.userDetails.email).then((account: AccountWithUser | null) => {
                if (!account) {
                    setSubscription((prev) => ({
                        ...prev,
                        userDetails: {
                            ...prev.userDetails,
                            firstName: '',
                            lastName: '',
                            zipCode: '',
                            city: '',
                        },
                    }));

                    return;
                }

                setSubscription((prev) => ({
                    ...prev,
                    userDetails: {
                        ...prev.userDetails,
                        firstName: account.user.firstName,
                        lastName: account.user.lastName,
                        zipCode: account.user.zipCode,
                        city: account.user.city,
                    },
                }));
            });
        }
    }, [subscription.userDetails.email]);

    const handleSubscriptionTypeChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {
        const subscriptionType = subscriptionTypes.find((st) => st.id === Number(event.target.value))!;
        setChosenSubscriptionType((_prev): SubscriptionType => ({
        ...subscriptionType
        }));
    }

    const handleUserDetailsChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {
        setSubscription((prev) => ({
            ...prev,
            userDetails: {
                ...prev.userDetails,
                [event.target.name]: event.target.value,
            },
        }));
    };

    const handleCardholderChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {
        const cardholderIndex = Number(event.target.id.slice(-1));
        setSubscription((prev) => ({
            ...prev,
            cardholders: prev.cardholders.map((cardholder, index) => {
                if (index === cardholderIndex) {
                    return {
                        ...cardholder,
                        [event.target.name]: event.target.value,
                    };
                }
                return cardholder;
            }),
        }));
    }

    const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const file = event.target.files![0];

        uploadFile(file).then((response) => {
            const imageId = response.id;

            setSubscription((prev) => ({
                ...prev,
                cardholders: prev.cardholders.map((cardholder, index) => {
                    if (index === Number(event.target.id)) {
                        return {
                            ...cardholder,
                            pictureId: imageId.toString(),
                        };
                    }
                    return cardholder;
                }),
            }));
        });
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
        event.preventDefault();

        if (!chosenSubscriptionType) {
            return;
        }

        onSubmit({
            userDetails: {
                ...(subscription.userDetails.salutation && { salutation: subscription.userDetails.salutation }),
                firstName: subscription.userDetails.firstName,
                lastName: subscription.userDetails.lastName,
                ...(subscription.userDetails.birthdate && { birthdate: new Date(subscription.userDetails.birthdate) }),
                email: subscription.userDetails.email,
                zipCode: subscription.userDetails.zipCode,
                city: subscription.userDetails.city,
            },
            subscriptionTypeId: chosenSubscriptionType?.id,
            cardholders: subscription.cardholders.map((cardholder) => ({
                firstName: cardholder.firstName,
                lastName: cardholder.lastName,
                birthdate: new Date(cardholder.birthdate),
                ...(cardholder.pictureId && { pictureId: Number(cardholder.pictureId) }),
            })),
        });
      };

    return (
    <>
    <h2 className="font-semibold text-xl text-center">{t("create_subscription")}</h2>
    <form className="flex flex-col gap-4 w-full max-w-3xl m-auto" onSubmit={handleSubmit}>
        <TextInputWithLabel label={t("email")} id="email" placeholder={t("email_placeholder")} name="email" onChange={handleUserDetailsChange}
                        value={subscription.userDetails.email} className="bg-white" required />
        <TextInputWithLabel label={t("first_name")} id="first_name" placeholder={t("first_name_placeholder")} name="firstName" onChange={handleUserDetailsChange}
                        value={subscription.userDetails.firstName} className="bg-white" required />
        <TextInputWithLabel label={t("last_name")} id="last_name" placeholder={t("last_name_placeholder")} name="lastName" onChange={handleUserDetailsChange}
                        value={subscription.userDetails.lastName} className="bg-white" required />
        <TextInputWithLabel label={t("zip_code")} id="zip_code" placeholder={t("zip_code_placeholder")} name="zipCode" onChange={handleUserDetailsChange}
                        value={subscription.userDetails.zipCode} className="bg-white" required />
        <TextInputWithLabel label={t("city")} id="city" placeholder={t("city_placeholder")} name="city" onChange={handleUserDetailsChange}
                        value={subscription.userDetails.city} className="bg-white" required />
        <SelectWithData label={t("subscription_type")} placeholder={t("subscription_type_placeholder")} data={subscriptionTypes.map((st) => ({ id: st.id, name: st.name }))}
            name="subscriptionType" id="subscriptionType" containerClassName="flex-1"
            value={chosenSubscriptionType?.id ?? ''}
            onChange={handleSubscriptionTypeChange} required />
        {subscription.cardholders.length > 0 && <p className="my-1 font-semibold text-lg">Subscription cardholders:</p>}
        {subscription.cardholders.map((cardholder, index) => (
            <div className="p-4 bg-white rounded-md flex flex-col gap-2" key={index}>
                <h2 className="mb-1 font-semibold">{t("cardholder")} {index + 1}</h2>
                <TextInputWithLabel
                    label={t("first_name")}
                    placeholder={t("first_name_placeholder")}
                    className="bg-gray-100"
                    name="firstName"
                    id={`first-name-${index}`}
                    value={cardholder.firstName}
                    onChange={handleCardholderChange}
                    required
                />
                <TextInputWithLabel
                    label={t("last_name")}
                    placeholder={t("last_name_placeholder")}
                    className="bg-gray-100"
                    name="lastName"
                    id={`last-name-${index}`}
                    value={cardholder.lastName}
                    onChange={handleCardholderChange}
                    required
                />
                <TextInputWithLabel
                    label={t("birthdate")}
                    placeholder={t("birthdate_placeholder")}
                    className="bg-gray-100"
                    name="birthdate"
                    id={`birthdate-${index}`}
                    value={cardholder.birthdate}
                    type="date"
                    onChange={handleCardholderChange}
                    required
                />
                <UploadAndDisplayImage label={t('image')} onChange={handleFileUpload} id={index.toString()}/>
            </div>
        ))}
        <div className="flex justify-end gap-2 my-4">
            <Button type="button" className="bg-gray-500" onClick={(): void => navigate('/payments')}>{t("cancel_button")}</Button>
            <Button type="submit">{t("create_button")}</Button>
        </div>
    </form>
    </>
    );
}

export default SubscriptionForm;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { TbPlus, TbTrash } from 'react-icons/tb';
import TextInputWithLabel from './TextInputWithLabel';
import Button from '../buttons/Button';
import SelectWithData, { OptionItem } from './SelectWithData';
import CheckboxWithLabel from './CheckboxWithLabel';
import { parseToYYYYMMDD, setTimezoneToStringDate } from '../../utils/dateUtil';
import useSelectedLocation from '../../hooks/selectors/useSelectedLocation';
import { DiscountApplicabilityType, DiscountOfferUpdateDto, DiscountType, ExclusionPeriodDto, ExtendedDiscountOffer } from '../../types/discounts';
import { getSubscriptionTypesForCurrentLocation } from '../../services/subscriptionTypeService';
import { getTicketTypesForCurrentLocation } from '../../services/ticketTypeService';
import IconButton from '../buttons/IconButton';
import usePermissions from '../../hooks/selectors/usePermissions';
import { Permissions } from '../../types/misc';

type DiscountOfferFormProps = DiscountOfferCreateFormProps | DiscountOfferUpdateFormProps;

type DiscountOfferCreateFormProps = {
  onSubmit: (discountOffer:  DiscountOfferUpdateDto) => void;
  formType?: 'create';
  initialState?: undefined;
}

type DiscountOfferUpdateFormProps = {
  onSubmit: (discountOffer:  DiscountOfferUpdateDto) => void;
  formType?: 'update';
  initialState?: ExtendedDiscountOffer;
}

type DiscountOfferFormState = {
  name: string
  discount: string
  discountType: string
  applicableTo: string
  applicableToType?: number
  stackable: boolean
  repeatable?: boolean
  appliedSeparately?: boolean
  freeBookingFee: boolean
  min: number | null
  max: number | null
  prefix: string
  suffixLength: string
  exclusionPeriods: ExclusionPeriodDto[]
}

function DiscountOfferForm({
                          onSubmit,
                          formType = 'create',
                          initialState = {} as ExtendedDiscountOffer,
                        }: DiscountOfferFormProps): JSX.Element {
  if (!usePermissions([Permissions.ManageDiscounts])) {
    return (<></>);
  }
  const [discountOffer, setDiscountOffer] = useState<DiscountOfferFormState>({
    name: initialState.name || '',
    discount: String(initialState.discount ?? 0),
    discountType: initialState.discountType || DiscountType.AMOUNT,
    applicableTo: initialState.applicableTo ||  DiscountApplicabilityType.ORDER,
    applicableToType: initialState.applicableToType || undefined,
    stackable: initialState.stackable || false,
    repeatable: initialState.repeatable || false,
    appliedSeparately: initialState.appliedSeparately || false,
    freeBookingFee: initialState.freeBookingFee || false,
    min: initialState.min,
    max: initialState.max,
    prefix: initialState.prefix || '',
    suffixLength: String(initialState.suffixLength ?? 4),
    exclusionPeriods: initialState.exclusionPeriods?.map((period) => ({ from: period.from.split('T')[0], to: period.to.split('T')[0] })) || [],
  });

  const [subscriptionTypes, setSubscriptionTypes] = useState<OptionItem[] | undefined>(undefined);
  const [ticketTypes, setTicketTypes] = useState<OptionItem[] | undefined>(undefined);

  const location = useSelectedLocation();
  const navigate = useNavigate();
  const { t } = useTranslation("discount_offer_form");

  useEffect(() => {
    Promise.all([
        getSubscriptionTypesForCurrentLocation(0, Number.MAX_SAFE_INTEGER),
        getTicketTypesForCurrentLocation(0, Number.MAX_SAFE_INTEGER),
    ]).then(([subTypes, tiTypes]) => {
        setSubscriptionTypes(subTypes.map((subscriptionType) => ({ id: subscriptionType.id, name: subscriptionType.name })));
        setTicketTypes(tiTypes.map((ticketType) => ({ id: ticketType.id, name: ticketType.name })));
    });
  }, [])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {
    setDiscountOffer((prev): DiscountOfferFormState => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const handlePrefixChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {
    setDiscountOffer((prev): DiscountOfferFormState => ({
      ...prev,
      prefix: event.target.value.toUpperCase().replace(/[^A-Z0-9]/g, ''),
    }));
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setDiscountOffer((prev): DiscountOfferFormState => ({
      ...prev,
      [event.target.name]: event.target.checked,
    }));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    onSubmit({
      name: discountOffer.name,
      discount: Number(discountOffer.discount),
      discountType: discountOffer.discountType as DiscountType,
      applicableTo: discountOffer.applicableTo as DiscountApplicabilityType,
      ...(discountOffer.applicableTo !== DiscountApplicabilityType.ORDER && { applicableToType: Number(discountOffer.applicableToType) }),
      stackable: discountOffer.stackable,
      ...(discountOffer.applicableTo !== DiscountApplicabilityType.ORDER && { repeatable: discountOffer.repeatable }),
      ...(discountOffer.applicableTo !== DiscountApplicabilityType.ORDER && { appliedSeparately: discountOffer.appliedSeparately }),
      freeBookingFee: discountOffer.freeBookingFee,
      min: Number(discountOffer.min) ?? 0,
      ...(discountOffer.applicableTo !== DiscountApplicabilityType.ORDER && { max: Number(discountOffer.max) ?? 0, }),
      prefix: discountOffer.prefix,
      suffixLength: Number(discountOffer.suffixLength),
      exclusionPeriods: discountOffer.exclusionPeriods.map((period) => ({
        from: setTimezoneToStringDate(period.from, location?.timezone ?? 'UTC')!,
        to: setTimezoneToStringDate(period.to, location?.timezone ?? 'UTC')!,
      })),
    });
  };

  const formatDiscount = (): void => {
    setDiscountOffer((prev) => ({
      ...prev,
      discount: Number(prev.discount).toFixed(2),
    }));
  };

  const handleDateChange = (index: number, field: keyof ExclusionPeriodDto, value: string): void => {
    const updatedPeriods = [...discountOffer.exclusionPeriods];
    updatedPeriods[index][field] = value
    setDiscountOffer((prev) => ({
      ...prev,
      exclusionPeriods: updatedPeriods,
    }));
  };

  const addExclusionPeriod = (): void => {
    setDiscountOffer((prev) => ({
      ...prev,
      exclusionPeriods: [
        ...prev.exclusionPeriods, 
        { 
          from: parseToYYYYMMDD(new Date(), location?.timezone ?? 'UTC'), 
          to: parseToYYYYMMDD(new Date(), location?.timezone ?? 'UTC')
        }],
    }));
  };

  const removeExclusionPeriod = (index: number): void => {
    const updatedPeriods = discountOffer.exclusionPeriods.filter((_, i) => i !== index);
    setDiscountOffer((prev) => ({
      ...prev,
      exclusionPeriods: updatedPeriods,
    }));
  };

  if (subscriptionTypes === undefined || ticketTypes === undefined) return <h2 className="text-2xl font-bold text-center mb-4">{t("loading")}</h2>;

  return (
    <>
      <h2 className="text-2xl font-bold text-center mb-4">{formType === 'create' ? t("create_title") : t("edit_title")}</h2>
      <form className="flex flex-col gap-4 max-w-3xl m-auto" onSubmit={handleSubmit}>
        <TextInputWithLabel label={t("name")} id="name" placeholder={t("name_placeholder")} name="name" onChange={handleChange}
                            value={discountOffer.name} className="bg-white" required  disabled={initialState.archived} />
        <div className="flex gap-2 w-full">
          <SelectWithData label={t("discount_type")} placeholder={t("discount_type_placeholder")} 
                          data={Object.values(DiscountType).map((discountType) => ({ id: discountType, name: t(`discount_type_${discountType}`) }))}
                          name="discountType" id="discountType" containerClassName="flex-1"
                          value={discountOffer.discountType} disabled={initialState.archived}
                          selectedValueId={initialState?.discountType} onChange={handleChange} required />
          <TextInputWithLabel label={t("discount")} id="discount" placeholder={t("discount_placeholder")} name="discount" type="number" min={0}
                          onChange={handleChange} value={discountOffer.discount} className="bg-white" 
                          disabled={initialState.archived} onBlur={formatDiscount} max={discountOffer.discountType === DiscountType.PERCENTAGE ? 100 : Number.MAX_SAFE_INTEGER} required />
        </div>

        <div className="flex gap-2 w-full">
          <SelectWithData label={t("applicable_to")} placeholder={t("applicable_to_placeholder")} 
                          data={Object.values(DiscountApplicabilityType).map((applicabilityType) => ({ id: applicabilityType, name: t(`applicable_to_${applicabilityType}`) }))}
                          name="applicableTo" id="applicableTo" containerClassName="flex-1"
                          value={discountOffer.applicableTo} disabled={initialState.archived}
                          selectedValueId={initialState?.discountType} onChange={handleChange} required />
          <SelectWithData label={t("applicable_to_type")} placeholder={t("applicable_to_type_placeholder")} 
                          data={discountOffer.applicableTo === DiscountApplicabilityType.TICKET ? ticketTypes : subscriptionTypes}
                          name="applicableToType" id="applicableToType" containerClassName={`flex-1 ${ discountOffer.applicableTo === DiscountApplicabilityType.ORDER ? 'hidden' : '' }`}
                          value={discountOffer.applicableToType} selectedValueId={initialState?.applicableToType} disabled={initialState.archived}
                          onChange={handleChange} required={discountOffer.applicableTo !== DiscountApplicabilityType.ORDER} /> 
        </div>

        <CheckboxWithLabel containerClassName="flex" label={t("stackable")} id="stackable" name="stackable"
                           onChange={handleCheckboxChange} checked={discountOffer.stackable}
                           className="w-4 h-4 border-2 rounded-sm bg-white mt-1 shrink-0
                          checked:bg-[#3C50E0] checked:border-0 cursor-pointer"
                           labelClassName="cursor-pointer pl-2" disabled={initialState.archived} />
        <CheckboxWithLabel containerClassName={`flex ${ discountOffer.applicableTo === DiscountApplicabilityType.ORDER ? 'hidden' : '' }`}
                           label={t("repeatable")} id="repeatable" name="repeatable"
                           onChange={handleCheckboxChange} checked={discountOffer.repeatable}
                           className="w-4 h-4 border-2 rounded-sm bg-white mt-1 shrink-0
                          checked:bg-[#3C50E0] checked:border-0 cursor-pointer"
                           labelClassName="cursor-pointer pl-2" disabled={initialState.archived} />
        <CheckboxWithLabel containerClassName={`flex ${ discountOffer.applicableTo === DiscountApplicabilityType.ORDER ? 'hidden' : '' }`}
                           label={t("applied_separately")} id="appliedSeparately" name="appliedSeparately"
                           onChange={handleCheckboxChange} checked={discountOffer.appliedSeparately}
                           className="w-4 h-4 border-2 rounded-sm bg-white mt-1 shrink-0
                          checked:bg-[#3C50E0] checked:border-0 cursor-pointer"
                           labelClassName="cursor-pointer pl-2" disabled={initialState.archived} />
        <CheckboxWithLabel containerClassName="flex" label={t("free_booking_fee")} id="freeBookingFee" name="freeBookingFee"
                           onChange={handleCheckboxChange} checked={discountOffer.freeBookingFee}
                           className="w-4 h-4 border-2 rounded-sm bg-white mt-1 shrink-0
                          checked:bg-[#3C50E0] checked:border-0 cursor-pointer"
                           labelClassName="cursor-pointer pl-2" disabled={initialState.archived} />

        <div className="flex gap-2 w-full">
          <TextInputWithLabel label={discountOffer.applicableTo === DiscountApplicabilityType.ORDER ? t("min_order") : t("min")} id="min" placeholder={t("min_placeholder")} name="min" type="number" 
                              onChange={handleChange} value={discountOffer.min ?? 0} className="bg-white" containerClassName='flex-1' required disabled={initialState.archived}/>    
          <TextInputWithLabel label={t("max")} id="max" placeholder={t("max_placeholder")} name="max" type="number" 
                              onChange={handleChange} value={discountOffer.max ?? 1} className="bg-white" 
                              containerClassName={`flex-1 ${ discountOffer.applicableTo === DiscountApplicabilityType.ORDER ? 'hidden' : '' }`}
                              required={discountOffer.applicableTo !== DiscountApplicabilityType.ORDER} disabled={initialState.archived} />     
        </div>

        <div className="flex gap-2 w-full">
          <TextInputWithLabel label={t("prefix")} id="prefix" placeholder={t("prefix_placeholder")} name="prefix" minLength={4} maxLength={12} pattern='^[A-Z0-9]*'
                              onChange={handlePrefixChange} value={discountOffer.prefix} className="bg-white" required disabled={initialState.archived} />    
          <TextInputWithLabel label={t("suffix_length")} id="max" placeholder={t("suffix_length_placeholder")} name="suffixLength" type="number"  containerClassName='flex-1'
                              onChange={handleChange} value={discountOffer.suffixLength} className="bg-white" min={4} max={36} required disabled={initialState.archived} />     
        </div>

        <div className="flex items-center mb-4 mt-4">
          <h1 className="text-xl font-bold">{t("exclusion_periods")}</h1>
          <IconButton
            onClick={addExclusionPeriod}
            icon={TbPlus}
            label=""
            disabled={initialState.archived}
            variant="accent"
            className="w-8 h-8 p-0 flex items-center justify-center mx-2"
          />
        </div>
        <div className="flex flex-col gap-2">
          {discountOffer.exclusionPeriods.map((period, index) => (
            <div key={index} className="flex gap-2 w-full items-end">
              <TextInputWithLabel
                label={t('from')}
                id={`from-${index}`}
                name={`from-${index}`}
                type="date"
                value={period.from}
                onChange={(e) => handleDateChange(index, 'from', e.target.value)}
                className="bg-white flex-1"
                min={parseToYYYYMMDD(new Date(), location?.timezone ?? 'UTC')}
                disabled={initialState.archived}
                required
              />
              <TextInputWithLabel
                label={t('to')}
                id={`to-${index}`}
                name={`to-${index}`}
                type="date"
                value={period.to}
                onChange={(e) => handleDateChange(index, 'to', e.target.value)}
                className="bg-white flex-1"
                min={parseToYYYYMMDD(new Date(new Date(period.from).getTime() + 24 * 60 * 60 * 1000), location?.timezone ?? 'UTC')}
                disabled={initialState.archived}
                required
              />
               <IconButton
                  onClick={(): void => removeExclusionPeriod(index)}
                  icon={TbTrash} label="" variant="danger"
                  className="py-1 px-1 text-lg w-11 h-11 my-1"
                  disabled={initialState.archived}
                />
            </div>
          ))}
        </div>

        <div className="flex justify-end gap-2 my-4">
          <Button type="button" className="bg-gray-500" onClick={(): void => navigate('/discount-offers')}>
            {t("cancel_button")}
          </Button>
          <Button type="submit" disabled={initialState.archived}>{formType === 'create' ? t("create_button") : t("save_button")}</Button>
        </div>
      </form>
    </>
  );
}

export default DiscountOfferForm;

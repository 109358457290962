import { Status } from './filter';

export type DiscountOffer = {
  id: number
  name: string
  discount: number
  discountType: DiscountType
  applicableTo: DiscountApplicabilityType
  applicableToType: number | null
  stackable: boolean
  repeatable: boolean | null
  appliedSeparately: boolean | null
  freeBookingFee: boolean
  min: number | null
  max: number | null
  prefix: string
  suffixLength: number
  archived: boolean
};

export enum DiscountType {
  AMOUNT = 'AMOUNT',
  PERCENTAGE = 'PERCENTAGE'
};

export enum DiscountTypeSelection {
  ALL = 'ALL',
  AMOUNT = 'AMOUNT',
  PERCENTAGE = 'PERCENTAGE'
};

export enum DiscountApplicabilityType {
  ORDER = 'ORDER',
  TICKET = 'TICKET',
  SUBSCRIPTION = 'SUBSCRIPTION'
};

export enum DiscountApplicabilityTypeSelection {
    ALL = 'ALL',
    ORDER = 'ORDER',
    TICKET = 'TICKET',
    SUBSCRIPTION = 'SUBSCRIPTION'
}

export type ExtendedDiscountOffer = DiscountOffer & {
  exclusionPeriods: ExclusionPeriod[];
}

export type ExclusionPeriod = {
  id: number
  from: string
  to: string
}

export type DiscountOfferFilter = {
  applicabilityType: DiscountApplicabilityTypeSelection
  discountType: DiscountTypeSelection
  status: Status
}

export type DiscountOfferUpdateDto = {
  name: string
  discount: number
  discountType: DiscountType
  applicableTo: DiscountApplicabilityType
  applicableToType?: number | null
  stackable: boolean
  repeatable?: boolean
  appliedSeparately?: boolean
  freeBookingFee: boolean
  min?: number
  max?: number
  prefix: string
  suffixLength: number
  exclusionPeriods: ExclusionPeriodDto[]
}

export type ExclusionPeriodDto = {
  from: string
  to: string
}

export type DiscountCode = {
  code: string;
  usageCap: number;
  uses: number;
  from: string;
  to: string;
  frozen: boolean;
  discountOfferId: number;
}

export type DiscountCodeFilter = {
  block: BlockStatus
}

export enum BlockStatus {
  ALL = 'ALL',
  BLOCKED = 'BLOCKED',
  NOT_BLOCKED = 'NOT_BLOCKED'
}

export type DiscountCodeDto = {
  usageCap: number;
  from: string;
  to: string;
}
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import React from 'react';
import { TicketCategoryDto } from '../../types/tickets';
import { createTicketCategory } from '../../services/ticketCategoryService';
import TicketCategoryForm from '../../components/forms/TicketCategoryForm';

export default function TicketCategoryCreatePage(): JSX.Element {
  const navigate = useNavigate();
  const { t } = useTranslation("ticket_category");
  const onSubmit = (ticketCategory: TicketCategoryDto): void => {
    createTicketCategory(ticketCategory)
      .then(() => {
        toast.success(t("created"));
        navigate('/ticket-categories');
      })
      .catch((error): void => {
        toast.error(`${t("failed_create")}: ${error}`);
      })
  }

  return (
    <TicketCategoryForm onSubmit={onSubmit} />
  )
}

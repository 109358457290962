import { Field, Label, Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react';
import { TbCheck, TbChevronDown } from 'react-icons/tb';
import { useTranslation } from 'react-i18next';
import React from 'react';

type MultipleDropdownProps<T> = {
  label: string;
  placeholder: string;
  values: T[];
  onChange: (newEntries: T[]) => void;
  allValues: T[];
}

export default function MultipleDropdown<T extends { id: any; name: string; }>({ values, onChange, allValues, label, placeholder }: MultipleDropdownProps<T>): JSX.Element {
  const { t } = useTranslation("multiple_dropdown");
  return (
    <Field className="w-full mx-auto">
      <Label>{label}</Label>
      <Listbox value={values} onChange={onChange} multiple>
        <ListboxButton className="relative text-left w-full rounded-lg border-[1.5px] border-[#E2E8F0] bg-white py-3 px-5 text-black outline-none transition focus:border-[#3C50E0] active:border-[#3C50E0] disabled:cursor-default disabled:bg-[#F5F7FD]">
          {values.length > 0
            ? <p>{t("selected")} {values.map(v => v.name).join(', ')}</p>
            : <p className="text-gray-600">{placeholder}</p>
          }
          <TbChevronDown size={24} className="group pointer-events-none absolute top-3.5 right-3" />
        </ListboxButton>
        <ListboxOptions anchor="bottom">
          {allValues.map((v) => (
            <ListboxOption key={v.id} value={v} className="cursor-pointer w-[48rem] border-[1.5px] border-[#E2E8F0] bg-white py-1 px-2 text-black transition group flex items-center gap-2">
              {values.find(d => d.id === v.id) &&
                <TbCheck size={18} />}<div>{v.name}</div>
            </ListboxOption>
          ))}
        </ListboxOptions>
      </Listbox>
    </Field>
  );
}

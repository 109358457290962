import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { TbCurrencyEuro } from 'react-icons/tb';
import TextInputWithLabel from './TextInputWithLabel';
import Button from '../buttons/Button';
import SelectWithData from './SelectWithData';
import MultipleDropdown from './MultipleDropdown';
import CheckboxWithLabel from './CheckboxWithLabel';
import { parseToYYYYMMDD, setTimezoneToStringDate } from '../../utils/dateUtil';
import useSelectedLocation from '../../hooks/selectors/useSelectedLocation';
import { ExtendedSubscriptionType, SubscriptionShop, SubscriptionTypeCreateDto, SubscriptionTypeUpdateDto } from '../../types/subscriptions';
import useSubscriptionTypeFormData from '../../hooks/selectors/useSubscriptionTypeFormData';
import PrefixedTextInputWithLabel from './PrefixedTextInputWithLabel';
import usePermissions from '../../hooks/selectors/usePermissions';
import { Permissions } from '../../types/misc';

type SubscriptionTypeFormProps = SubscriptionTypeCreateFormProps | SubscriptionTypeUpdateFormProps;

type SubscriptionTypeCreateFormProps = {
  onSubmit: (subscriptionType:  SubscriptionTypeCreateDto) => void;
  formType?: 'create';
  initialState?: undefined;
}

type SubscriptionTypeUpdateFormProps = {
  onSubmit: (subscriptionType:  SubscriptionTypeUpdateDto) => void;
  formType?: 'update';
  initialState?: ExtendedSubscriptionType;
}

type SubscriptionTypeFormState = {
    name: string;
    price: string;
    description: string;
    defaultRidesCount: string;
    cardholdersCount: string;
    discountable: boolean;
    unlimited: boolean;
    alwaysAvailable: boolean;
    privileged: boolean;
    vatPercentage?: string;
    sellingFrom: string;
    sellingTo: string;
    validFrom?: string;
    validTo?: string;
    capacityGroupId?: string;
    scanDefinitionId?: string;
    layoutId?: string;
    subscriptionShops?: SubscriptionShop[];
    orderPriority: string,
}

function SubscriptionTypeForm({
                          onSubmit,
                          formType = 'create',
                          initialState = {} as ExtendedSubscriptionType,
                        }: SubscriptionTypeFormProps): JSX.Element {
  if (!usePermissions([Permissions.ManageConfigurations])) {
    return (<></>);
  }
  const location = useSelectedLocation();

  const [subscriptionType, setSubscriptionType] = useState<SubscriptionTypeFormState>({
    name: initialState.name || '',
    price: String(initialState?.price?.toFixed(2) || ''),
    description: initialState.description || '',
    defaultRidesCount: String(initialState.defaultRidesCount || 1),
    cardholdersCount: String(initialState.cardholdersCount || 1),
    unlimited: initialState.defaultRidesCount === 0,
    discountable: initialState.discountable || false,
    alwaysAvailable: initialState.alwaysAvailable || false,
    privileged: initialState.privileged || false,
    vatPercentage: String(initialState.vatPercentage || 9),
    sellingFrom: initialState.sellingFrom && parseToYYYYMMDD(new Date(initialState.sellingFrom), location?.timezone ?? 'UTC'),
    sellingTo: initialState.sellingTo && parseToYYYYMMDD(new Date(initialState.sellingTo), location?.timezone ?? 'UTC'),
    validFrom: initialState.validFrom && parseToYYYYMMDD(new Date(initialState.validFrom), location?.timezone ?? 'UTC'),
    validTo: initialState.validTo && parseToYYYYMMDD(new Date(initialState.validTo), location?.timezone ?? 'UTC'),
    capacityGroupId: String(initialState.capacityGroupId || ''),
    scanDefinitionId: String(initialState.scanDefinitionId || ''),
    layoutId: String(initialState?.layoutId || ''),
    subscriptionShops: [],
    orderPriority: String(initialState.orderPriority || ''),
  });

  const navigate = useNavigate();
  const { t } = useTranslation("subscription_type_form");
  const { loading, subscriptionShops, capacityGroups, scanDefinitions, layouts } = useSubscriptionTypeFormData();

  useEffect(() => {
    if (!loading && formType === 'update') {
      setSubscriptionType((prev): SubscriptionTypeFormState => ({
        ...prev,
        subscriptionShops: initialState?.subscriptionShopsAvailableAt
          ?.map(d => subscriptionShops.find(shop => shop.id === d.id))
          .filter(x => x) as SubscriptionShop[],
      }));
    }
  }, [loading]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {
    setSubscriptionType((prev): SubscriptionTypeFormState => ({
      ...prev,
      [event.target.name]: event.target.value,
      unlimited: event.target.name === 'defaultRidesCount' ? Number(event.target.value) === 0 : prev.unlimited, 
    }));
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSubscriptionType((prev): SubscriptionTypeFormState => ({
      ...prev,
      [event.target.name]: event.target.checked,
      defaultRidesCount: event.target.name === 'unlimited' && !event.target.checked ? '1' : prev.defaultRidesCount,
    }));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    onSubmit({
      name: subscriptionType.name,
      description: subscriptionType.description,
      price: Number(subscriptionType.price),
      discountable: subscriptionType.discountable,
      alwaysAvailable: subscriptionType.alwaysAvailable,
      vatPercentage: Number(subscriptionType.vatPercentage),
      defaultRidesCount: subscriptionType.unlimited ? 0 : Number(subscriptionType.defaultRidesCount),
      cardholdersCount: Number(subscriptionType.cardholdersCount),
      sellingFrom: setTimezoneToStringDate(subscriptionType.sellingFrom, location?.timezone ?? 'UTC')!,
      sellingTo: setTimezoneToStringDate(subscriptionType.sellingTo, location?.timezone ?? 'UTC')!,
      privileged: subscriptionType.privileged,
      ...(subscriptionType.alwaysAvailable && subscriptionType.validFrom && { validFrom: setTimezoneToStringDate(subscriptionType.validFrom, location?.timezone ?? 'UTC') }),
      ...(subscriptionType.alwaysAvailable && subscriptionType.validTo && { validTo: setTimezoneToStringDate(subscriptionType.validTo, location?.timezone ?? 'UTC') }),
      ...(!subscriptionType.alwaysAvailable && subscriptionType.capacityGroupId && { capacityGroupId: Number(subscriptionType.capacityGroupId) }),
      ...(subscriptionType.scanDefinitionId && { scanDefinitionId: Number(subscriptionType.scanDefinitionId) }),
      ...(subscriptionType.layoutId && { layoutId: Number(subscriptionType.layoutId) }),
      ...(subscriptionType.subscriptionShops && !subscriptionType.privileged && {
        subscriptionShopsIds: subscriptionType.subscriptionShops?.map(d => d.id),
      }),
      orderPriority: Number(subscriptionType.orderPriority),
    });
  };

  const formatPrice = (): void => {
    setSubscriptionType((prev): SubscriptionTypeFormState => ({
      ...prev,
      price: Number(prev.price).toFixed(2),
    }));
  };

  if (loading) return <h2 className="text-2xl font-bold text-center mb-4">{t("loading")}</h2>;

  return (
    <>
      <h2 className="text-2xl font-bold text-center mb-4">{formType === 'create' ? t("create_title") : t("edit_title")}</h2>
      <form className="flex flex-col gap-4 max-w-3xl m-auto" onSubmit={handleSubmit}>
        <TextInputWithLabel label={t("name")} id="name" placeholder={t("name_placeholder")} name="name" onChange={handleChange}
                            value={subscriptionType.name} className="bg-white" required />
        <TextInputWithLabel label={t("description")} id="description" placeholder={t("description_placeholder")} name="description"
                            onChange={handleChange} value={subscriptionType.description} className="bg-white" required />
        <TextInputWithLabel label={t("order_priority")} id="orderPriority" placeholder={t("order_priority_placeholder")} name="orderPriority"
                            onChange={handleChange} value={subscriptionType.orderPriority} className="bg-white" required type="number" />
        <PrefixedTextInputWithLabel label={t("price")}
                            prefix={<TbCurrencyEuro size={20} strokeWidth={2.25} color="#303030" />}
                            id="price" name="price" value={subscriptionType.price} type="number"
                            onChange={handleChange} placeholder={t("price_placeholder")}
                            className="bg-white" required onBlur={formatPrice} />
        <SelectWithData label={t("vat_percentage")} placeholder={t("vat_percentage_placeholder")} data={[{ id: 9, name: '9%' }, { id: 21, name: '21%' }]}
                            name="vatPercentage" id="vatPercentage" containerClassName="flex-1"
                            value={subscriptionType.vatPercentage}
                            selectedValueId={initialState?.vatPercentage} onChange={handleChange} required />
        <CheckboxWithLabel containerClassName="flex" label={t("unlimited")} id="unlimited" name="unlimited"
                            onChange={handleCheckboxChange} checked={subscriptionType.unlimited}
                            className="w-4 h-4 border-2 rounded-sm bg-white mt-1 shrink-0
                          checked:bg-[#3C50E0] checked:border-0 cursor-pointer"
                            labelClassName="cursor-pointer pl-2" />
        <div className={`flex gap-2 w-full ${ subscriptionType.unlimited ? 'hidden' : '' }`}>
          <TextInputWithLabel label={t("default_rides_count")}
                              id="defaultRidesCount" name="defaultRidesCount" value={subscriptionType.defaultRidesCount} type="number"
                              onChange={handleChange} placeholder={t("default_rides_count_placeholder")}
                              className='bg-white' min={0} required={!subscriptionType.unlimited} />
        </div>
    
        <TextInputWithLabel label={t("cardholders_count")}
                            id="cardholdersCount" name="cardholdersCount" value={subscriptionType.cardholdersCount} type="number"
                            onChange={handleChange} placeholder={t("cardholders_count_placeholder")}
                            className="bg-white" min={1} required />

        <div className="flex gap-2 w-full">
          <TextInputWithLabel label={t("selling_from")} id="sellingFrom" placeholder={t("selling_from_placeholder")} name="sellingFrom" type="date" onChange={handleChange}
                            value={subscriptionType.sellingFrom} className="bg-white" required />
          <TextInputWithLabel label={t("selling_to")} id="sellingTo" placeholder={t("selling_to_placeholder")} name="sellingTo" type="date" onChange={handleChange}
                            value={subscriptionType.sellingTo} className="bg-white" min={subscriptionType.sellingFrom ?? parseToYYYYMMDD(new Date(), location?.timezone ?? 'UTC')} required />
        </div>
        <div className="flex gap-2 w-full">
          <SelectWithData label={t("capacity_group")} placeholder={t("capacity_group_placeholder")} data={capacityGroups}
                          name="capacityGroupId" id="capacityGroupId" containerClassName={`flex-1 ${ subscriptionType.alwaysAvailable ? 'hidden' : '' }`}
                          value={subscriptionType.capacityGroupId}
                          selectedValueId={initialState?.capacityGroupId} onChange={handleChange} />
        </div>
        <div className="flex gap-2 w-full">
          <SelectWithData label={t("scan_definition")} placeholder={t("scan_definition_placeholder")} data={scanDefinitions}
                          name="scanDefinitionId" id="scanDefinitionId" containerClassName="flex-1"
                          value={subscriptionType.scanDefinitionId}
                          selectedValueId={initialState?.scanDefinitionId} onChange={handleChange} />
          <SelectWithData label={t("layouts")} placeholder={t("layouts_placeholder")} data={layouts}
                          name="layoutId" id="layoutId" containerClassName="flex-1"
                          value={subscriptionType.layoutId}
                          selectedValueId={initialState?.layoutId} onChange={handleChange} />
        </div>
        <div className={`flex-1 ${ subscriptionType.privileged ? 'hidden' : '' }`}>
          <MultipleDropdown placeholder={t("shop_availability_placeholder")} label={t("shop_availability")}
                            values={subscriptionType.subscriptionShops || []} onChange={(newSubscriptionShops) => {
            setSubscriptionType((prev): SubscriptionTypeFormState => {
              return {
                ...prev,
                subscriptionShops: newSubscriptionShops,
              };
            });
          }} allValues={subscriptionShops} />
        </div>

        <CheckboxWithLabel containerClassName="flex" label={t("discountable")} id="discountable" name="discountable"
                           onChange={handleCheckboxChange} checked={subscriptionType.discountable}
                           className="w-4 h-4 border-2 rounded-sm bg-white mt-1 shrink-0
                          checked:bg-[#3C50E0] checked:border-0 cursor-pointer"
                           labelClassName="cursor-pointer pl-2" />

        <CheckboxWithLabel containerClassName={`flex ${ formType !== 'create' ? 'invisible' : '' }`} label={t("privileged")} id="privileged" name="privileged"
                           onChange={handleCheckboxChange} checked={subscriptionType.privileged}
                           className="w-4 h-4 border-2 rounded-sm bg-white mt-1 shrink-0
                          checked:bg-[#3C50E0] checked:border-0 cursor-pointer"
                           labelClassName="cursor-pointer pl-2" />

        <CheckboxWithLabel containerClassName={`flex ${ formType !== 'create' ? 'invisible' : '' }`} label={t("always_available")} id="alwaysAvailable" name="alwaysAvailable"
                           onChange={handleCheckboxChange} checked={subscriptionType.alwaysAvailable}
                           className="w-4 h-4 border-2 rounded-sm bg-white mt-1 shrink-0
                          checked:bg-[#3C50E0] checked:border-0 cursor-pointer"
                           labelClassName="cursor-pointer pl-2" />

        <div className={`flex gap-2 w-full ${ !subscriptionType.alwaysAvailable ? 'hidden' : '' }`}>
          <TextInputWithLabel label={t("valid_from")} id="validFrom" placeholder={t("valid_from_placeholder")} name="validFrom" type="date" onChange={handleChange}
                            value={subscriptionType.validFrom} className="bg-white" required={subscriptionType.alwaysAvailable} />
          <TextInputWithLabel label={t("valid_to")} id="validTo" placeholder={t("valid_to_placeholder")} name="validTo" type="date" onChange={handleChange}
                            value={subscriptionType.validTo} className="bg-white" min={subscriptionType.validFrom ?? parseToYYYYMMDD(new Date(), location?.timezone ?? 'UTC')} required={subscriptionType.alwaysAvailable} />
        </div>

        <div className="flex justify-end gap-2 my-4">
          <Button type="button" className="bg-gray-500" onClick={(): void => navigate('/subscription-types')}>
            {t("cancel_button")}
          </Button>
          <Button type="submit">{formType === 'create' ? t("create_button") : t("save_button")}</Button>
        </div>
      </form>
    </>
  );
}

export default SubscriptionTypeForm;

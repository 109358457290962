import React from 'react';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import ModalLayout from '../layouts/ModalLayout';
import { locationService } from '../../services';
import LocationForm from '../forms/LocationForm';
import { LocationCreate, LocationUpdate } from '../../types/location';
import useSelectedLocation from '../../hooks/selectors/useSelectedLocation';

interface EditLocationModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function EditLocationModal({
  open,
  setOpen,
}: EditLocationModalProps): JSX.Element {
  const { t } = useTranslation("location_button");
  const selectedLocation = useSelectedLocation();

  if (!selectedLocation) {
    setOpen(false);
  }

  const onSubmit = (location: LocationCreate | LocationUpdate): void => {
      locationService.updateLocation(location as LocationUpdate).then((_): void => {
        toast.success(t("location_updated"));
        setOpen(false);
      }).catch((error): void => {
        if (error instanceof AxiosError && error.response?.data.errors) {
          toast.error(
            error.response?.data.errors
              .flatMap((e: { constraints: { [k: string]: string }[] }): {
                [k: string]: string
              }[] => Object.values(e.constraints))
              .map((x: string): string => x.charAt(0).toUpperCase() + x.slice(1))
              .join('. '),
          );
        } else {
          toast.error(error.message);
        }
      });
  };

  return (
    <ModalLayout
      open={open}
      setOpen={setOpen}
      closeButton
      className="bg-white max-w-3xl rounded-3xl p-16">
      <LocationForm onSubmit={onSubmit} onCancel={() => setOpen(false)} formType="update" initialState={selectedLocation} />
    </ModalLayout>
  );
}

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextInputWithLabel from './TextInputWithLabel';
import Button from '../buttons/Button';
import SelectWithData from './SelectWithData';
import { Location, LocationCreate, LocationUpdate } from '../../types/location';
import usePermissions from '../../hooks/selectors/usePermissions';
import { Permissions } from '../../types/misc';

type TicketTypeFormProps = {
  onSubmit: (ticketType: LocationCreate | LocationUpdate) => void;
  onCancel: () => void;
  formType: 'create' | 'update';
  initialState?: Location;
}

type LocationFormState = {
  name: string,
  timezone: string,
}

function LocationForm({
                        onSubmit,
                        onCancel,
                        formType = 'create',
                        initialState = {} as Location,
                      }: TicketTypeFormProps): JSX.Element {
  if (!usePermissions([Permissions.ManageLocations])) {
    return (<></>);
  }
  const [location, setLocation] = useState<LocationFormState>({
    name: initialState.name || '',
    timezone: initialState.timezone || 'Europe/Amsterdam',
  });

  const { t } = useTranslation("location_form");
  
  const timezones = Intl.supportedValuesOf('timeZone').map((timezone) => ({ id: timezone, name: timezone }));

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {
    setLocation((prev): LocationFormState => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    onSubmit({
      name: location.name,
      timezone: location.timezone,
    });
  };

  return (
    <>
      <h2 className="text-2xl font-bold text-center mb-4">{formType === 'create' ? t("create_title") : t("edit_title", { name: initialState.name })}</h2>
      <form className="flex flex-col gap-4 max-w-3xl m-auto" onSubmit={handleSubmit}>
        <TextInputWithLabel label={t("name")} id="name" placeholder={t("name_placeholder")} name="name" onChange={handleChange}
                            value={location.name} className="bg-white" required />
          <SelectWithData label={t("timezone")} placeholder={t("timezone_placeholder")} data={timezones}
                          name="timezone" id="timezone" containerClassName="flex-1"
                          value={location.timezone}
                          selectedValueId={initialState?.timezone} onChange={handleChange} required={formType === 'create'} disabled={formType === 'update'} />

        <div className="flex justify-end gap-2 my-4">
          <Button type="button" className="bg-gray-500" onClick={onCancel}>
            {t("cancel_button")}
          </Button>
          <Button type="submit">{formType === 'create' ? t("create_button") : t("save_button")}</Button>
        </div>
      </form>
    </>
  );
}

export default LocationForm;

import React, { useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { createColumnHelper, Row } from '@tanstack/react-table';
import { TbCirclePlus, TbEdit, TbTrash } from 'react-icons/tb';
import { useTranslation } from 'react-i18next';
import { deleteAccount, getAccounts, getAllRoles } from '../../services/accountService';
import { setAccounts } from '../../redux/slices/accountsSlice';
import { store } from '../../redux/store';
import { Account, AccountFilter, Role } from '../../types/account';
import IconButton from '../../components/buttons/IconButton';
import DeleteModal from '../../components/modals/DeleteModal';
import SelectionTable from '../../components/tables/SelectionTable';
import CheckboxDropdown from '../../components/forms/CheckboxDropdown';
import TextInput from '../../components/forms/TextField';
import useAccounts from '../../hooks/selectors/useAccounts';
import useLanguage from '../../hooks/selectors/useLanguage';
import usePermissions from '../../hooks/selectors/usePermissions';
import { Permissions } from '../../types/misc';

function RenderActionsCell({ row }: { row: Row<Account> }): JSX.Element {
  const [deleteModalOpen, setDeleteModalOpen] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const { t } = useTranslation("accounts");
  
  const onDelete = (): void => {
    deleteAccount(row.original.id)
      .then((): void => {
        toast.success(t("deleted"));
        store.dispatch(setAccounts(store
          .getState().accounts.accounts
          .filter((account): boolean => account.id !== row.original.id),
        ));
        setDeleteModalOpen(false);
      })
      .catch((error): void => {
        toast.error(error.message);
      });  
  };
  return (
    <>
      <div className="flex justify-end items-center gap-1">
        <IconButton
          onClick={(): void => navigate(`/accounts/edit/${row.original.id}`)}
          icon={TbEdit} label="" variant="accent"
          className="py-1 px-1 text-lg"
        />
        <IconButton
          onClick={(): void => setDeleteModalOpen(true)}
          icon={TbTrash} label="" variant="danger"
          className="py-1 px-1 text-lg"
        />
      </div>
      <DeleteModal open={deleteModalOpen} setOpen={setDeleteModalOpen} onActionButtonClicked={onDelete}
                   message={`${t("delete_confirmation")}"${row.original.email}" ?`}
      />
    </>
  );
}

export default function AccountsPage(): JSX.Element {
  if (!usePermissions([Permissions.ManageUsers])) {
    return (<></>);
  }
  const accounts = useAccounts();
  const language = useLanguage();
  const [pagination, setPagination] = React.useState({ pageSize: 25, pageIndex: 0 });
  const [roles, setRoles] = React.useState<Role[]>([]);
  const [filter, setFilter] = React.useState<AccountFilter>({ roles: [] });
  const [search, setSearch] = React.useState<string>('')
  const navigate = useNavigate();
  const { t } = useTranslation("accounts");
  const hasPermissionViewConfigurations = usePermissions([Permissions.ViewConfigurations]);
  useEffect(() => {
    if (hasPermissionViewConfigurations) {
      getAllRoles()
        .then((data) => setRoles(data))
    }
  }, [language]);

  useEffect(() => {
    getAccounts(pagination.pageIndex * pagination.pageSize, pagination.pageSize, filter, search)
      .then((data): void => {
        store.dispatch(setAccounts(data));
      })
      .catch((error): void => {
        toast.error(error.message);
      });
  }, [pagination, search, filter, language]);

  const onChangeRoles = (selected: Role[]) : void => {
    setFilter({ roles: selected.map(role => role.id) });
  }

  const searchEmails = (event: React.ChangeEvent<HTMLInputElement>) : void => {
    setSearch(event.target.value);
  }

  const columns = useMemo((): any[] => {
    const columnHelper = createColumnHelper<Account>();
    return [
      columnHelper.accessor((row) => row.id, {
        id: 'id',
        header: t("id")
      }),
      columnHelper.accessor((row) => row.email, {
        id: 'email',
        header: t("email"),
      }),
      columnHelper.accessor((row) => row.role.name, {
        id: 'role',
        header: t("role")
      }),
      {
        id: 'actions',
        header: t("actions"),
        cell: RenderActionsCell,
        meta: {
          align: 'text-right'
        }
      }
    ]
  }, []);

  return (
    <>
      <div className="flex justify-between items-center mb-2">
        <h2 className="font-bold text-2xl font-gintobold">{t("accounts")}</h2>
        <div className="flex gap-1">
          {
            usePermissions([Permissions.ManageUsers]) &&
            <IconButton onClick={(): void => navigate('/accounts/create')} icon={TbCirclePlus} label={t("new_account")}
                        className="bg-gradient-to-r from-[#E700F9] hover:shadow-purple-300 hover:shadow-md
                      to-pink-500 duration-300 bg-[position:_0%_0%] hover:bg-[position:_100%_100%]
                      bg-[size:_200%] transition-all font-bold text-white rounded-full cursor-pointer" />
          }
        </div>
      </div>
      <TextInput onChange={searchEmails} placeholder={t('search')} className='bg-white'/>
      {
        usePermissions([Permissions.ViewConfigurations]) &&
        <div className="flex gap-1 items-center py-2">
          <p className="font-semibold text-md ml-4">{t("filters")}</p>
          <CheckboxDropdown label='Role' values={roles} onChange={onChangeRoles} type="checkbox" />
        </div>
      }
      <SelectionTable
        columns={columns}
        data={accounts}
        pagination={pagination}
        setPagination={setPagination}
        enableRowSelection={false}
      />
    </>)
}

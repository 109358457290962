import React, { useEffect, useMemo } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CellContext, createColumnHelper, PaginationState, Row, RowSelectionState } from '@tanstack/react-table';
import { TbCirclePlus } from 'react-icons/tb';
import { Purchase } from '../../types/payment';
import SelectionTable from '../../components/tables/SelectionTable';
import { getSelectedLocation } from '../../services/locationService';
import useLanguage from '../../hooks/selectors/useLanguage';
import { Reservation } from '../../types/reservation';
import { formatCurrency } from '../../utils/currencyUtil';
import BooleanIcon from '../../components/icons/BooleanIcon';
import IconButton from '../../components/buttons/IconButton';
import Button from '../../components/buttons/Button';
import { TicketType } from '../../types/tickets';

function canBeRebooked(purchase: Purchase, purchases: Purchase[], selection: RowSelectionState, reservations: Reservation[], ticketTypes: TicketType[]): boolean {
  const reservation = reservations.find(d => d.id === purchase.extendedPurchaseData.reservation?.id);

  const firstSelectedId = Number(Object.keys(selection)[0]);
  const selectionTicketTypeId = purchases.find(p => p.id === firstSelectedId)?.extendedPurchaseData.reservation?.ticketTypeOnSlot.ticketType.id;

  return !purchase.extendedPurchaseData.rotterdampas
    && reservation !== undefined && reservation !== null
    && reservation.ticketTypeOnSlot !== null
    && new Date(reservation.ticketTypeOnSlot.slot.endTime).getTime() > new Date().getTime()
    && !ticketTypes.find(t => t.id === reservation.ticketTypeOnSlot.ticketType.id)?.alwaysAvailable
    && reservation.status === 'CONFIRMED'
    && ((Object.entries(selection).length > 0
          && selectionTicketTypeId === reservation.ticketTypeOnSlot.ticketType.id)
      || Object.entries(selection).length === 0);
}

const columnHelper = createColumnHelper<Purchase>();

function renderDate(date: Date) : string {
  const timeZone: string = getSelectedLocation()?.timezone ?? 'UTC';
  return new Date(date).toLocaleDateString('nl-NL', {day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', timeZone});
}

function RenderProductString({ row }: CellContext<Purchase, Purchase>, reservations: Reservation[]): JSX.Element {
  if (!row.original.extendedPurchaseData.reservation) return <></>;
  if (!row.original.extendedPurchaseData.reservation.qrCode) return <></>;

  const { reservation } = row.original.extendedPurchaseData;
  // eslint-disable-next-line react/destructuring-assignment
  const slot = reservations.find(r => r.id === reservation.id)?.ticketTypeOnSlot.slot;

  return (
    <div>{`${reservation.ticketTypeOnSlot.ticketType.name}`} {(slot && slot.startTime) ? `(${renderDate(slot.startTime)})` : null} <br/>
    <span className='text-xs text-gray-500'>{reservation.qrCode.code}</span><br/></div>
  );
}

function RenderStatucCell({ row }: { row: Row<Purchase> }, reservations: Reservation[]): JSX.Element {
  const { t } = useTranslation("payments_rebook")

  // eslint-disable-next-line react/destructuring-assignment
  const reservation = reservations.find(d => d.id === row.original.extendedPurchaseData.reservation?.id);
  if (!reservation) return <></>;

  const reservationScannedSuccesful = (reservation.scanHistories?.filter(d => d.succesful).length ?? 0) > 0;
  return reservation?.status === "CONFIRMED" 
    ? <BooleanIcon value={reservationScannedSuccesful} label={reservationScannedSuccesful ? t("used") : t("active")}/> 
    : <BooleanIcon value label={t("inactive")} />
}

interface PaymentsRebookSelectTicketsStepProps {
  paymentId: number,
  purchases: Purchase[],
  reservations: Reservation[],
  ticketTypes: TicketType[],
}

export default function PaymentsRebookSelectTicketsStep({ paymentId, purchases, reservations, ticketTypes }: PaymentsRebookSelectTicketsStepProps): JSX.Element | null {
  const language = useLanguage();
  const navigate = useNavigate();
  const [queryParams, setQueryParams] = useSearchParams();

  const [rowSelection, setRowSelection] = React.useState<RowSelectionState>(
    queryParams.has('selected') 
      ? queryParams.get('selected')!
        .split(',')
        .filter(s => s !== '')
        .map(String)
        .reduce((acc, id) => ({ ...acc, [id]: true }), {}) 
      : {}
  );

  const [pagination, setPagination] = React.useState<PaginationState>({ pageIndex: 0, pageSize: 25 });

  const { t } = useTranslation("payments_rebook");

  useEffect((): void => {
    setQueryParams({ selected: Object.keys(rowSelection).join(',') });
  }, [rowSelection]);

  const columns = useMemo((): any[] => [
    columnHelper.accessor((row) => row, {
      id: 'product',
      header: t("product"),
      cell: (row) => RenderProductString(row, reservations),
    }),
    columnHelper.accessor((row) => row.type, {
      id: 'type',
      header: t("type"),
      cell: ({ row: { original: { type } } }) => t(`type_${type}`)
    }),
    columnHelper.accessor((row) => row.price, {
      id: 'price',
      header: t("price"),
      cell : ({ row: { original: { price } } }) => formatCurrency(price)
    }),
    columnHelper.accessor((row) => row.extendedPurchaseData.reservation, {
      id: 'rotterdampass',
      header: t("rotterdampass"),
      cell : ({ row: { original: { extendedPurchaseData: { rotterdampas } } }}) => !rotterdampas ? t("no") : t("yes"),
    }),
    columnHelper.accessor((row) => row.extendedPurchaseData.reservation, {
      id: 'status',
      header: t("status"),
      cell: (row) => RenderStatucCell(row, reservations),
    }),
  ], [language, reservations]);

  return (
    <>
      <div className="flex justify-between items-center mb-4">
        <h2 className="font-gintobold font-bold text-2xl">{t("title_select_tickets", { paymentId })}</h2>
        <div className="flex gap-1">
          <Button type="button" className="bg-gray-500" onClick={(): void => navigate(`/payments/${paymentId}/details`)}>
                  {t("back")}
          </Button>
          <IconButton onClick={(): void => setQueryParams(current => ({ products: current.get('selected') ?? '' }))} icon={TbCirclePlus} label={t("next")} disabled={Object.entries(rowSelection).length === 0}
                      className="bg-gradient-to-r from-[#E700F9] hover:shadow-purple-300 hover:shadow-md
                    to-pink-500 duration-300 bg-[position:_0%_0%] hover:bg-[position:_100%_100%]
                    bg-[size:_200%] transition-all font-bold text-white rounded-full cursor-pointer" />
        </div>
      </div>
      <div>
        <SelectionTable
          columns={columns}
          data={purchases}
          pagination={pagination}
          setPagination={setPagination}
          enableRowSelection
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
          isRowDisabled={(purchase) => !canBeRebooked(purchase, purchases, rowSelection, reservations, ticketTypes)}
        />
      </div>
    </>
  );
}
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ScanGroupDTO } from '../../types/tickets';
import { createScanGroup } from '../../services/scanGroupService';
import ScanGroupForm from '../../components/forms/ScanGroupForm';

export default function ScanGroupsCreatePage(): JSX.Element {
  const navigate = useNavigate();
  const { t } = useTranslation("scan_groups");

  const onSubmit = (scanGroup: ScanGroupDTO): void => {
    createScanGroup(scanGroup)
      .then((): void => {
        toast.success(t("created"));
        navigate('/scan-groups');
      })
      .catch((error): void => {
        toast.error(`${t("failed_create")}: ${error}`);
      });
  };

  return (
    <ScanGroupForm onSubmit={onSubmit} />
  );
}

import React from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import { TicketTypeDTO } from '../../types/tickets';
import { createTicketType } from '../../services/ticketTypeService';
import TicketTypeForm from '../../components/forms/TicketTypeForm';

export default function CreateTicketTypePage(): JSX.Element {
  const navigate = useNavigate();
  const { t } = useTranslation("ticket_type");

  const onSubmit = (ticketType: TicketTypeDTO): void => {
    createTicketType(ticketType).then((): void => {
      toast.success(t("created"));
      navigate('/ticket-types');
    }).catch((error): void => {
      if (error instanceof AxiosError && error.response?.data.errors) {
        toast.error(
          error.response?.data.errors
            .flatMap((e: { constraints: { [k: string]: string }[] }): {
              [k: string]: string
            }[] => Object.values(e.constraints))
            .map((x: string): string => x.charAt(0).toUpperCase() + x.slice(1))
            .join('. '),
        );
      } else {
        toast.error(error.message);
      }
    });
  };

  return (
    <TicketTypeForm onSubmit={onSubmit} formType="create" />
  );
}

import { Field } from '@headlessui/react';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { TbX } from 'react-icons/tb';
import { useTranslation } from 'react-i18next';
import FileInputWithLabel from './FileInputWithLabel';
import IconButton from '../buttons/IconButton';

type ImageProps = {
  label: string;
  id: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export default function UploadAndDisplayImage({ label, onChange, id }: ImageProps,
): JSX.Element {
  const [selectedImage, setSelectedImage] = useState<File>();
  const [inputKey, setInputKey] = useState(new Date());
  const { t } = useTranslation('image_upload');

  const handleReset = (): void => {
    setSelectedImage(undefined);
    setInputKey(new Date());
  };

  const handleUpload = (event: React.ChangeEvent<HTMLInputElement>): void => {
    if (!event.target.files || event.target.files.length !== 1) {
      toast.error('Upload a single image!'); // TODO: i18n
      return;
    }

    setSelectedImage(event.target.files[0]);
    onChange(event);
  };

  return (
    <div>
      <Field>
        <div>
          <FileInputWithLabel
            id={id} label={label} name="image" accept="image/*" key={inputKey.toString()}
            onChange={handleUpload} currentFileName={selectedImage?.name}
          />
        </div>
        {selectedImage && (
          <div className="mt-2">
            <label>{t('selected_image')}</label>
            <div className="flex gap-2 items-center">
              <img
                alt="not found"
                className="w-32"
                src={URL.createObjectURL(selectedImage)}
              />
              <IconButton className="h-fit" icon={TbX} label={t('remove_image_with_name', {name: selectedImage.name})} variant="danger" onClick={handleReset} />
            </div>
          </div>
        )}
      </Field>
    </div>
  );
}

import { store } from '../redux/store';
import { PricingPolicy, PricingPolicyDTO } from '../types/pricingPolicies';
import httpService from './httpService';

export async function getPricingPoliciesForCurrentLocation(offset: number, limit: number): Promise<PricingPolicy[]> {
  const locationId = store.getState().locations.selectedLocation;

  return httpService
    .get<PricingPolicy[]>(`/api/locations/${locationId}/pricing-policies?offset=${offset}&limit=${limit}`)
    .then((response): PricingPolicy[] => {
      return response.data
    });
}

export async function createPricingPolicy(data: PricingPolicyDTO): Promise<PricingPolicy> {
  const locationId = store.getState().locations.selectedLocation;

  return httpService
    .post<PricingPolicy>(`/api/locations/${locationId}/pricing-policies`, data)
    .then((response): PricingPolicy => response.data);
}

export async function updatePricingPolicy(scanGroupId: number, data: Partial<PricingPolicyDTO>): Promise<PricingPolicy> {
  const locationId = store.getState().locations.selectedLocation;

  return httpService
      .put<PricingPolicy>(`/api/locations/${locationId}/pricing-policies/${scanGroupId}`, data)
      .then((response): PricingPolicy => response.data);
}

export async function getPricingPolicy(pricingPolicyId: number): Promise<PricingPolicy> {
  const locationId = store.getState().locations.selectedLocation;

  return httpService
    .get<PricingPolicy>(`/api/locations/${locationId}/pricing-policies/${pricingPolicyId}`)
    .then((response): PricingPolicy => {
      return response.data;
    });
}


import React, { FormEvent } from 'react';
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { validateUtil } from "../../utils";
import { authService } from "../../services";
import Button from "../../components/buttons/Button";
import TextInputWithLabel from '../../components/forms/TextInputWithLabel';
import AnonymousUserForm from '../../components/forms/AnonymousUserForm';
import ProgressBar from '../../components/misc/ProgressBar';
import useCurrentUser from '../../hooks/selectors/useCurrentUser';

export default function ForgottenPasswordPage(): JSX.Element {
    const user = useCurrentUser();
    const {t} = useTranslation('auth');
    const [email, setEmail] = React.useState<string>( user?.email || '');
    const [progress, setProgress] = React.useState<{ progress: number, statusText: string; }>();


    const handleSubmit = (ev: FormEvent<HTMLFormElement>): void => {
      ev.preventDefault();
      setProgress({ progress: 0.15, statusText: '' });
      if(!validateUtil.validateEmail(email)) {
            toast.error("invalid_email");
            return;
        };
        authService.requestPasswordReset(email)
        .then(() => {
            toast.success(t("password_reset_email_sent"));
        })
        .catch((error) => {
          if(error.response?.status === 400) {
            toast.error(t("password_reset_email_already_sent"));
            return;
          } if(error.response?.status === 404) {
            toast.error(t("password_reset_email_not_found"));
            return;
          }

            toast.error(t("unknown_error"));
        })
        .finally(() => {
          setProgress(undefined);
        });
    };
  return (
    <>
    <AnonymousUserForm onSubmit={handleSubmit}>

      <h1 className="font-semibold text-lg">{user ? t('password_forgotten_auth') : t('password_forgotten')}</h1>
      <TextInputWithLabel className="text-white focus:border-purple-300 active:border-purple-300" id="email"
                          label={t(user ? "password_forgotten_email_auth" : "password_forgotten_email_anonymous")} value={email} onChange={(e) => setEmail(e.target.value)} />
      <Button type='submit' className="self-end bg-purple-300 text-[#1C2434]">
        {t("submit_button")}
      </Button>
      {progress && (
        <div className="py-2 flex items-center gap-2">
          <ProgressBar progress={progress.progress} />
          <p className="text-green-500">{progress.statusText}</p>
        </div>
      )}
    </AnonymousUserForm>
    </>
  );
}

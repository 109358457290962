import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaRegTrashAlt } from 'react-icons/fa';
import { toast } from 'react-toastify';
import TextInput from '../forms/TextField';
import Button from '../buttons/Button';
import IconButton from '../buttons/IconButton';
import { getBookableTicketTypesForCurrentLocation } from '../../services/ticketTypeService';
import SelectWithData from '../forms/SelectWithData';
import { TicketType } from '../../types/tickets';

interface TicketReservationTableProps<T> {
	reservationId: number;
  items: T[];
  onChangeTicketReservations: (reservationId: number, updatedItems: T[]) => void;
}

export type TicketReservationFormState = {
  ticketTypeId: string;
  amount: string;
};

export default function CreateTicketReservationTable<T extends TicketReservationFormState>({
	reservationId,
  items,
  onChangeTicketReservations: onChangeTicketReservation,
}: TicketReservationTableProps<T>): JSX.Element {
  const [ticketTypes, setTicketTypes] = useState<TicketType[]>([]);

  React.useEffect((): void => {
    getBookableTicketTypesForCurrentLocation()
      .then((data: TicketType[]) => {
        	setTicketTypes(data);
      })
      .catch((error): void => {
        toast.error(error.message);
      });
  }, []);

  const [rows, setRows] = useState<T[]>([]);
  const { t } = useTranslation('ticket_reservation_form');

  useEffect(() => {
    setRows(
      items.map((item, index) => {
        return {
          ...item,
          id: index,
        };
      }),
    );
  }, [items]);

  const handleInputChange = (index: number, column: keyof T, value: number): void => {
    const newRows = rows.map((row, i) => (i === index ? { ...row, [column]: value } : row));
    setRows(newRows);
    onChangeTicketReservation(reservationId, newRows);
  };

  const handleDelete = (index: number): void => {
    const updatedRows = rows.filter((_d, i) => i !== index);
    setRows(updatedRows);
    onChangeTicketReservation(reservationId, updatedRows);
  };

  const addRow = (): void => {
    const addRowItem: T = {
      ticketTypeId: '',
      amount: '',
    } as T;

    setRows((prevRows) => [...prevRows, addRowItem]);
  };

  return (
    <div>
      <table className="min-w-full border-collapse mt-2">
				{
				rows.length > 0
					&& 
						<thead>
							<tr>
								<td className="p-1 text-left">{t('ticket_type')}</td>
								<td className="p-1 text-left">{t('amount')}</td>
							</tr>
						</thead>
				}
        <tbody>
          {rows.map((row, index) => (
            <tr key={index}>
              <td>
                <SelectWithData
									label=''
                  placeholder={t('ticket_type_placeholder')}
                  data={ticketTypes.map((tt) => ({ id: tt.id, name: tt.name }))}
                  name="ticketTypeId"
                  containerClassName="flex-1"
                  value={row.ticketTypeId}
                  onChange={(e) => handleInputChange(index, 'ticketTypeId', Number(e.target.value))}
                  required
                />
              </td>
              <td>
                <TextInput
                  type="number"
									placeholder={t('amount_placeholder')}
									min={1}
                  step="1"
                  defaultValue={row.amount}
                  onChange={(e) => handleInputChange(index, 'amount', Number(e.target.value))}
                  className="bg-white mb"
                />
              </td>
							<td>
								<IconButton icon={FaRegTrashAlt} label='' variant='danger' className='px-3 py-3 mb ml-4' onClick={() => handleDelete(index)} />
							</td>
						</tr>
          ))}
        </tbody>
      </table>
      <Button className="mt-4" type="button" onClick={addRow}>
        {t('add_ticket')}
      </Button>
    </div>
  );
}

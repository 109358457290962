import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextInputWithLabel from './TextInputWithLabel';
import Button from '../buttons/Button';
import { parseToYYYYMMDD, setTimezoneToStringDate } from '../../utils/dateUtil';
import useSelectedLocation from '../../hooks/selectors/useSelectedLocation';
import { generateDiscountCodesForDiscountOffer } from '../../services/discountService';
import usePermissions from '../../hooks/selectors/usePermissions';
import { Permissions } from '../../types/misc';

type GenerateDiscountCodesFormProps = {
  onCancel: () => void;
  onSuccess: () => void;
  discountOfferId: number;
}

type GenerateDiscountCodesFormState = {
  count: string,
  from: string,
  to: string,
  usageCap: string,
}

function GenerateDiscountCodesForm({
                        onCancel,
                        onSuccess,
                        discountOfferId,
                      }: GenerateDiscountCodesFormProps): JSX.Element {
  if (!usePermissions([Permissions.ManageConfigurations])) {
    return (<></>);
  }
  const location = useSelectedLocation();

  const [codesGeneration, setCodesGeneration] = useState<GenerateDiscountCodesFormState>({
    count: String(1),
    from: parseToYYYYMMDD(new Date(), location?.timezone ?? 'UTC'),
    to: parseToYYYYMMDD(new Date(new Date().getTime() + 24 * 60 * 60 * 1000), location?.timezone ?? 'UTC'),
    usageCap: String(1),
  });

  const [isWaiting, setIsWaiting] = useState<boolean>(false);

  const { t } = useTranslation("generate_discount_codes_form");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {
    setCodesGeneration((prev): GenerateDiscountCodesFormState => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    setIsWaiting(true);
    generateDiscountCodesForDiscountOffer(discountOfferId, {
      from: setTimezoneToStringDate(codesGeneration.from, location?.timezone ?? 'UTC')!,
      to: setTimezoneToStringDate(codesGeneration.to, location?.timezone ?? 'UTC')!,
      usageCap: Number(codesGeneration.usageCap),
    }, Number(codesGeneration.count)).then(() => {
      setIsWaiting(false);
      onSuccess();
    });
  };

  return (
    <>
      <h2 className="text-2xl font-bold text-center mb-4">{t("title")}</h2>
      <form className="flex flex-col gap-4 max-w-3xl m-auto" onSubmit={handleSubmit}>
        
      <div className="flex gap-2 w-full">
        <TextInputWithLabel label={t("count")} id="count" placeholder={t("count_placeholder")} name="count" type="number" min={1} max={10000}
                            onChange={handleChange} value={codesGeneration.count} className="bg-white" required />     
        <TextInputWithLabel label={t("usage_cap")} id="usageCap" placeholder={t("usage_cap_placeholder")} name="usageCap" type="number" min={1}
                            onChange={handleChange} value={codesGeneration.usageCap} className="bg-white" required />     
        </div>

      <div className="flex gap-2 w-full items-end">
          <TextInputWithLabel
            label={t('from')}
            id="from"
            name="from"
            type="date"
            value={codesGeneration.from}
            onChange={handleChange}
            className="bg-white flex-1"
            min={parseToYYYYMMDD(new Date(), location?.timezone ?? 'UTC')}
            required
          />
          <TextInputWithLabel
            label={t('to')}
            id="to"
            name="to"  
            type="date"
            value={codesGeneration.to}
            onChange={handleChange}
            className="bg-white flex-1"
            min={parseToYYYYMMDD(new Date(new Date(codesGeneration.from).getTime() + 24 * 60 * 60 * 1000), location?.timezone ?? 'UTC')}
            required
          />
        </div>

        <div className="flex justify-end gap-2 my-4">
          <Button type="button" className="bg-gray-500" onClick={onCancel}>
            {t("cancel_button")}
          </Button>
          <Button type="submit" disabled={isWaiting}>{t("generate")}</Button>
        </div>
      </form>
    </>
  );
}

export default GenerateDiscountCodesForm;

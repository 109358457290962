import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { createColumnHelper, PaginationState, Row } from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';
import {
  TbArchive,
  TbCirclePlus,
  TbEdit,
  TbTrash,
} from 'react-icons/tb';
import { MdDiscount } from 'react-icons/md';
import SelectionTable from '../../components/tables/SelectionTable';
import IconButton from '../../components/buttons/IconButton';
import { getTicketTypesForCurrentLocation } from '../../services/ticketTypeService';
import DeleteModal from '../../components/modals/DeleteModal';
import { setTicketTypes } from '../../redux/slices/ticketTypesSlice';
import { store } from '../../redux/store';
import CheckboxDropdown from '../../components/forms/CheckboxDropdown';
import useLanguage from '../../hooks/selectors/useLanguage';
import useDiscountOffers from '../../hooks/selectors/useDiscountOffers';
import { getSubscriptionTypesForCurrentLocation } from '../../services/subscriptionTypeService';
import { setSubscriptionTypes } from '../../redux/slices/subscriptionTypesSlice';
import { deleteDiscountOffer, getDiscountOffersForCurrentLocation } from '../../services/discountService';
import { setDiscountOffers } from '../../redux/slices/discountOffersSlice';
import { DiscountApplicabilityType, DiscountApplicabilityTypeSelection, DiscountOffer, DiscountOfferFilter, DiscountTypeSelection } from '../../types/discounts';
import useSubscriptionTypes from '../../hooks/selectors/useSubscriptionTypes';
import useTicketTypes from '../../hooks/selectors/useTicketTypes';
import { Status } from '../../types/filter';
import { OptionItem } from '../../components/forms/SelectWithData';
import usePermissions from '../../hooks/selectors/usePermissions';
import { Permissions } from '../../types/misc';

const columnHelper = createColumnHelper<DiscountOffer>();

function RenderActionsCell({ row }: { row: Row<DiscountOffer> }, resetPagination: () => void): JSX.Element {
  const [deleteModalOpen, setDeleteModalOpen] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const { t } = useTranslation("discount_offer");

  const onDelete = (): void => {
    deleteDiscountOffer(row.original.id)
      .then((): void => {
        toast.success(t("deleted"));
        resetPagination();
        setDeleteModalOpen(false);
      })
      .catch((error): void => {
        toast.error(error.message);
      });
  };

  return (
    <>
      <div className="flex justify-end items-center gap-1">
        <IconButton
          onClick={(): void => navigate(`/discount-offers/${row.original.id}/codes`)}
          icon={MdDiscount} label="" variant="download"
          className="py-1 px-1 text-lg"
          disabled={!usePermissions([Permissions.ManageDiscounts])}
        />
        <IconButton
          onClick={(): void => navigate(`/discount-offers/edit/${row.original.id}`)}
          icon={TbEdit} label="" variant="accent"
          className="py-1 px-1 text-lg"
          disabled={!usePermissions([Permissions.ManageDiscounts])}
        />
        <IconButton
          onClick={(): void => setDeleteModalOpen(true)}
          icon={TbTrash} label="" variant="danger"
          className="py-1 px-1 text-lg"
          disabled={!usePermissions([Permissions.ManageDiscounts])}
        />
      </div>
      <DeleteModal open={deleteModalOpen} setOpen={setDeleteModalOpen} onActionButtonClicked={onDelete}
                   message={`${t("delete_confirmation")} "${row.original.name}"?`}
      />
    </>
  );
}

function RenderName({ row: { original: { name, archived } } }: {
  row: Row<DiscountOffer>
}): JSX.Element {
  if (!archived) return <>{name}</>;

  return (
    <div className="flex items-center gap-1 text-gray-600"><TbArchive size={16} />{name}</div>
  );
}

export default function DiscountOffersListPage(): JSX.Element {
  if (!usePermissions([Permissions.ManageDiscounts | Permissions.ViewConfigurations])) {
    return (<></>);
  }
  const [pagination, setPagination] = React.useState<PaginationState>({ pageIndex: 0, pageSize: 25 });
  const [filter, setFilter] = React.useState<DiscountOfferFilter>({ 
    applicabilityType: DiscountApplicabilityTypeSelection.ALL, 
    discountType: DiscountTypeSelection.ALL,
    status: Status.ACTIVE,
  });
  const discountOffers = useDiscountOffers();
  const ticketTypes = useTicketTypes();
  const subscriptionTypes = useSubscriptionTypes();
  const language = useLanguage();
  const navigate = useNavigate();
  const { t } = useTranslation("discount_offer");

  const resetPagination = (): void => {
    setPagination(prev => ({ pageSize: prev.pageSize, pageIndex: prev.pageIndex }));
  }

  React.useEffect((): void => {
    getTicketTypesForCurrentLocation(0, Number.MAX_SAFE_INTEGER)
      .then((data): void => {
        store.dispatch(setTicketTypes(data));
        resetPagination();
      })
      .catch((error): void => {
        toast.error(error.message);
      });

    getSubscriptionTypesForCurrentLocation(0, Number.MAX_SAFE_INTEGER)
      .then((data): void => {
        store.dispatch(setSubscriptionTypes(data));
        resetPagination();
      })
      .catch((error): void => {
        toast.error(error.message);
      });
  }, []);

  React.useEffect((): void => {
    getDiscountOffersForCurrentLocation(pagination.pageIndex * pagination.pageSize, pagination.pageSize, filter)
      .then((data): void => {
        store.dispatch(setDiscountOffers(data));
      })
      .catch((error): void => {
        toast.error(error.message);
      });
  }, [pagination, filter]);

  const columns = useMemo((): any[] => [
    columnHelper.accessor((row) => row.id, {
      id: 'id',
      header: t("id"),
    }),
    columnHelper.accessor((row) => row.name, {
      id: 'name',
      header: t("name"),
      cell: RenderName,
    }),
    columnHelper.accessor((row) => row.applicableTo, {
      id: 'applicableTo',
      header: t("applicableTo"),
      cell: ({ row: { original: { applicableTo, applicableToType } } }): string => 
        t(`applicable_to_${applicableTo}`) + (applicableToType 
          ? ` (${applicableTo === DiscountApplicabilityType.TICKET 
            ? ticketTypes.find(tt => tt.id === applicableToType)?.name 
            : (applicableTo === DiscountApplicabilityType.SUBSCRIPTION 
              ? subscriptionTypes.find(st => st.id === applicableToType)?.name 
              : '')})` 
          : ''),
    }),
    columnHelper.accessor((row) => row.discountType, {
        id: 'discountType',
        header: t("discountType"),
        cell: ({ row: { original: { discountType } } }): string => t(`discount_type_${  discountType}`),
      }),
    columnHelper.accessor((row) => row.discount, {
      id: 'discount',
      header: t("discount"),
      cell: ({ row: { original: { discount } } }): string => `${discount.toFixed(2)}`,
    }),
    columnHelper.accessor((row) => row.freeBookingFee, {
      id: 'freeBookingFee',
      header: t("freeBookingFee"),
      cell: ({ row: { original: { freeBookingFee } } }): string => t(freeBookingFee.toString()),
    }),
    {
      id: 'actions',
      header: t("actions"),
      cell: (row: { row: Row<DiscountOffer> }) => RenderActionsCell(row, resetPagination),
      meta: {
        align: 'text-right',
      },
    },
  ], [language]);

  const applicabilityTypeSelection: Record<DiscountApplicabilityTypeSelection, OptionItem> = {
    [DiscountApplicabilityTypeSelection.ALL]: { id: DiscountApplicabilityTypeSelection.ALL, name: t("all") },
    [DiscountApplicabilityTypeSelection.ORDER]: { id: DiscountApplicabilityTypeSelection.ORDER, name: t("order") },
    [DiscountApplicabilityTypeSelection.TICKET]: { id: DiscountApplicabilityTypeSelection.TICKET, name: t("ticket") },
    [DiscountApplicabilityTypeSelection.SUBSCRIPTION]: { id: DiscountApplicabilityTypeSelection.SUBSCRIPTION, name: t("subscription") },
  };
  
  const onChangeApplicabilityType = (selected: { id: DiscountApplicabilityTypeSelection }[]): void => {
    if (selected.length === 0) return;
    setFilter(prevFilter => ({ ...prevFilter, applicabilityType: selected[0].id }));
  }

  const discountTypeSelection: Record<DiscountTypeSelection, OptionItem> = {
    [DiscountTypeSelection.ALL]: { id: DiscountTypeSelection.ALL, name: t("all") },
    [DiscountTypeSelection.AMOUNT]: { id: DiscountTypeSelection.AMOUNT, name: t("amount") },
    [DiscountTypeSelection.PERCENTAGE]: { id: DiscountTypeSelection.PERCENTAGE, name: t("percentage") },
  };

  const onChangeDiscountType = (selected: { id: DiscountTypeSelection }[]): void => {
    if (selected.length === 0) return;
    setFilter(prevFilter => ({ ...prevFilter, discountType: selected[0].id }));
  }

  const discountOfferStatus: Record<Status, OptionItem> = {
    [Status.ALL]: { id: Status.ALL, name: t("all") },
    [Status.ACTIVE]: { id: Status.ACTIVE, name: t("active") },
    [Status.ARCHIVED]: { id: Status.ARCHIVED, name: t("archived") },
  };

  const onChangeStatus = (selected: { id: Status, name: string }[]): void => {
    if (selected.length === 0) return;
    setFilter(prevFilter => ({ ...prevFilter, status: selected[0].id }));
  }

  return (
    <>
      <div className="flex justify-between items-center">
        <h2 className="font-gintobold font-bold text-2xl">{t("title")}</h2>
        <div className="flex gap-1">
          {
            usePermissions([Permissions.ManageDiscounts]) &&
            <IconButton onClick={(): void => navigate('/discount-offers/create')} icon={TbCirclePlus} label={t("new")}
                        className="bg-gradient-to-r from-[#E700F9] hover:shadow-purple-300 hover:shadow-md
                      to-pink-500 duration-300 bg-[position:_0%_0%] hover:bg-[position:_100%_100%]
                      bg-[size:_200%] transition-all font-bold text-white rounded-full cursor-pointer" />
          }
        </div>
      </div>
      <div className="flex gap-1 items-center py-2">
        <p className="font-semibold text-md">{t("filters")}: </p>
        <CheckboxDropdown label={t("status")} values={Object.values(discountOfferStatus)} onChange={onChangeStatus} type="radio" initialValues={[discountOfferStatus[filter.status]]}/>
        <CheckboxDropdown label={t("applicability_type")} values={Object.values(applicabilityTypeSelection)} onChange={onChangeApplicabilityType} type="radio" initialValues={[applicabilityTypeSelection[filter.applicabilityType]]}/>
        <CheckboxDropdown label={t("discount_type")} values={Object.values(discountTypeSelection)} onChange={onChangeDiscountType} type="radio" initialValues={[discountTypeSelection[filter.discountType]]}/>
      </div>
      <div>
        <SelectionTable
          columns={columns}
          data={discountOffers}
          pagination={pagination}
          setPagination={setPagination}
          enableRowSelection={false}
        />
      </div>
    </>
  );
}
import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

interface DraggableItemProps {
  id: string;
  name: string;
  checked: boolean;
  handleCheckboxChange: (id: string, checked: boolean) => void;
}

export default function DraggableItem({ id, name, checked, handleCheckboxChange }: DraggableItemProps): JSX.Element {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners} className="p-[10px] mx-[0] my-[5px] border-[1px] border-[solid] border-[#ccc] bg-[#fff] cursor-move text-center flex items-center justify-start
 rounded-lg focus:border-[#3C50E0] active:border-[#3C50E0]">
        <input type='checkbox' defaultChecked={checked} onChange={(e) => handleCheckboxChange(id, e.target.checked)} className='h-[20px] w-[20px]'/>
        <span className='w-full p-[5px] border-[none] bg-transparent text-center'>{name}</span>
    </div>
  );
};
import React from 'react';
import { useTranslation } from 'react-i18next';
import ModalLayout from '../layouts/ModalLayout';
import Button from '../buttons/Button';

interface DeleteModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  message: string;
  onActionButtonClicked: () => void;
}

export default function DeleteModal(
  {
    open,
    setOpen,
    message,
    onActionButtonClicked,
  }: DeleteModalProps,
): JSX.Element | null {
  if (!open) return null;
  const { t } = useTranslation("modals");

  return (
    <ModalLayout
      open={open}
      setOpen={setOpen}
      closeButton
      className="bg-white max-w-3xl rounded-3xl p-16">
      <div className="flex flex-col">
        <h3 className="text-xl font-bold my-2">{t("alert")}</h3>
        <p>{message}</p>
        <div className="flex self-end gap-2 mt-8">
          <Button className="bg-gray-600" onClick={(): void => setOpen(false)}>{t("cancel")}</Button>
          <Button className="bg-red-500" onClick={onActionButtonClicked}>{t("delete")}</Button>
        </div>
      </div>
    </ModalLayout>
  );
}

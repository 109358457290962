import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Price from "../misc/Price";
import BooleanIcon from "../icons/BooleanIcon";
import { Purchase } from "../../types/payment";
import * as subscriptionService from "../../services/subscriptionService";
import { Subscription } from "../../types/subscriptions";
import { parseToDDMMYYYY, parseToYYYYMMDDHHmm } from "../../utils/dateUtil";
import { getSelectedLocation } from "../../services/locationService";
import Button from "../buttons/Button";
import { API_URL } from "../../constants";

type PurchaseSubscriptionTableProps = {
    purchases: Purchase[];
    bookingFee: number;
};

type CalculateAmountComponentProps = {
    purchasesToCalculate: Purchase[], 
    bookingFeeToCalculate: number
}

function PurchaseSubscriptionTable({ purchases, bookingFee }: PurchaseSubscriptionTableProps) : JSX.Element {
    const { t } = useTranslation("payments");
    const [subscriptions, setSubscriptions] = useState<Subscription[]>();

    const getSubscriptionData = () : void => {
        const subscriptionIds = purchases
            .map(p => p.extendedPurchaseData.subscription?.id)
            .filter((id): id is number => id !== undefined);
    
        subscriptionService.getSubscriptionsByIds(subscriptionIds)
            .then((response): void => {
                setSubscriptions(response);
            })
            .catch((error): void => {
                toast.error(error.message);
            });
    }

    useEffect(() => {
        getSubscriptionData();
    }, [purchases]);

    const downloadCardholder = async (cardholdersUuid: string): Promise<void> => {
      const response = await subscriptionService.downloadQrCodesCardholders([cardholdersUuid]);
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'subscription.pdf');
      document.body.appendChild(link);
      link.click();
  
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    };

    const blockCardholder = async (subscriptionId: number, cardholderId: number, disabled: boolean): Promise<void> => {
        subscriptionService.updateCardholder(subscriptionId, cardholderId, { disabled })
        .then((_response): void => {
            toast.success(disabled ? t("cardholder_blocked") : t("cardholder_unblocked"));
            getSubscriptionData();
        })
        .catch((error): void => {
            toast.error(error.message);
        });
    };

    const renderPurchases = purchases.map((purchase) => {
        const subscription = subscriptions?.find(d => d.id === purchase.extendedPurchaseData.subscription?.id);

        return (
            <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                { purchase.extendedPurchaseData.reservation && <td className="px-1 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    {`${subscription?.subscriptionType.name}`}
                </td> }
                { purchase.extendedPurchaseData.subscription && <td className="px-1 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                    {`${purchase.extendedPurchaseData.subscription.subscriptionType.name}`}
                </td> }
                <td className="px-1 py-1">
                    <BooleanIcon value={subscription?.status !== "ACTIVE"} label={t(subscription?.status ?? '')} />
                </td>
                <td className="px-1 py-1">
                    {t(purchase.type)}
                </td>
                <td className="px-1 py-1">
                    <Price price={purchase.price}/>
                </td>
                <td className="px-1 py-1">
                    { purchase.refundPaymentId &&
                        <Link to={`/payments/${purchase.refundPaymentId}/details`} className="text-blue-600 underline hover:text-blue-800">{`${t('refund_link')} #${purchase.refundPaymentId}`}</Link>
                    }
                </td>
            </tr>
        )
    });
  
    return (
        <>
            <div className="border-2 rounded-3xl p-4 mt-2 mb-4">
                <table className="w-full text-sm text-left rtl:text-right text-black-500 dark:text-black-400 mb-8">
                    <thead className="text-xs text-black-700 uppercase dark:text-black-400 border-b dark:border-gray-700">
                        <tr>
                            <th scope="col" className="py-3">
                                {t("product")}
                            </th>
                            <th scope="col" className="py-3">
                                {t("status")}
                            </th>
                            <th scope="col" className="py-3">
                                {t("type")}
                            </th>
                            <th scope="col" className="py-3">
                                {t("price")}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {renderPurchases}
                        <tr className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                            <td className='px-1 py-1 font-medium text-gray-900 whitespace-nowrap dark:text-white'>{t("booking_fee")}</td>
                            <td className="px-1 py-1" />
                            <td className="px-1 py-1" />
                            <td className="px-1 py-1"><Price price={bookingFee}/></td>
                        </tr>
                        <tr>
                            <td className='px-1 py-1 text-gray-900 whitespace-nowrap dark:text-white font-gintobold font-bold'>{t("total")}</td>
                            <td />
                            <td />
                            <td className='px-1 py-1 font-gintobold font-bold'>{calculateAmountComponent({ purchasesToCalculate: purchases, bookingFeeToCalculate: bookingFee})}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <h3 className='font-gintobold font-bold text-l mb-4'>{t("subscriptions")}</h3>

            { subscriptions?.map(subscription => (
                <div className="border-2 rounded-3xl p-4">
                    <div className="flex justify-between items-start">
                        <div>
                            <h4 className="font-gintobold">{subscription.subscriptionType.name}</h4>
                            {t("subscriptionid")}: {subscription.id} <br/>
                            {t("status")}: {t(subscription.status)} <br/>
                            {t("startdate")}: {parseToYYYYMMDDHHmm(subscription.createdAt, getSelectedLocation()?.timezone ?? 'UTC')}<br/>
                            {t("enddate")}: {parseToYYYYMMDDHHmm(subscription.expiry, getSelectedLocation()?.timezone ?? 'UTC')}<br/>
                        </div>
                        <div className="border-2 p-4 rounded-3xl">
                            <div>{subscription.subscriptionType.defaultRidesCount !== 0 ? `${subscription.rides} ${t("of")} ${subscription.subscriptionType.defaultRidesCount} ${t("ridesleft")}` : t("unlimited")}</div>
                        </div>
                    </div>
                    <div className="mt-2 mb-2">
                        { subscription.cardholders.map(cardholder => (
                            <div className="border p-4 rounded-3xl mb-2 bg-sbr-lightblue">
                                <div className="grid grid-cols-3 gap-4">
                                    <div className="col-span-1">
                                        <img className="rounded-xl" width={100} src={cardholder.identity.picture?.path ? `${API_URL}/${cardholder.identity.picture.path}` : `${process.env.PUBLIC_URL}/assets/subscriptions/user.jpeg`} alt="user" />
                                    </div>
                                    <div className="col-span-2">
                                        {t("name")}: {`${cardholder.identity.firstName} ${cardholder.identity.lastName}`} <br/>
                                        {t("birthdate")}: {parseToDDMMYYYY(cardholder.identity.birthdate, getSelectedLocation()?.timezone ?? 'UTC')} <br/>
                                        {t("qrcode")}: {cardholder.qrCodeValue} <br/>
                                        <BooleanIcon value={cardholder.disabled} label={!cardholder.disabled ? t("active") : t("blocked")} />
                                        <div className="flex justify-end">
                                            <Button type="button" className="bg-sb-pink mb-4 mt-5 mr-2 text-sm" onClick={(): void => {}}>
                                                TODO: {t("scan_cardholder")}
                                            </Button>
                                            <Button type="button" className="bg-sb-pink mb-4 mt-5 mr-2 text-sm" onClick={(): Promise<void> => blockCardholder(subscription.id, cardholder.id, !cardholder.disabled)}>
                                                { !cardholder.disabled ? t("block_cardholder") : t("unblock_cardholder")}
                                            </Button>
                                            <Button type="button" className="bg-sb-pink mb-4 mt-5 text-sm" onClick={(): Promise<void> => downloadCardholder(cardholder.qrCodeValue)}>
                                                {t("download_cardholder")}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </div>
                   
                </div>
                ))
            }

        </>
    );
  };

function calculateAmountComponent({ purchasesToCalculate, bookingFeeToCalculate } : CalculateAmountComponentProps ) : JSX.Element {
    const totalAmount = purchasesToCalculate.reduce((total, currentValue) => total + currentValue.price, 0) + bookingFeeToCalculate;
    return <Price price={totalAmount} />;
}

export default PurchaseSubscriptionTable;
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { createColumnHelper, Row } from '@tanstack/react-table';
import { TbCirclePlus, TbEye } from 'react-icons/tb';
import { useTranslation } from 'react-i18next';
import { Payment, PaymentFilter } from '../../types/payment';
import { PaymentStatus, PurchaseType } from '../../types/filter';
import { getPayments } from '../../services/paymentService';
import usePayments from '../../hooks/selectors/usePayments';
import IconButton from '../../components/buttons/IconButton';
import { setPayments } from '../../redux/slices/paymentsSlice';
import { store } from '../../redux/store';
import CheckboxDropdown from '../../components/forms/CheckboxDropdown';
import TextInput from '../../components/forms/TextField';
import SelectionTable from '../../components/tables/SelectionTable';
import useLanguage from '../../hooks/selectors/useLanguage';
import PaymentStatusColorBar from '../../components/misc/PaymentStatusColorBar';
import { OptionItem } from '../../components/forms/SelectWithData';
import usePermissions from '../../hooks/selectors/usePermissions';
import { Permissions } from '../../types/misc';

function RenderActionsCell({ row }: { row: Row<Payment> }): JSX.Element {
  const navigate = useNavigate();

  return (
    <>
      <div className="flex justify-end items-center gap-1">
        <IconButton
          onClick={(): void => navigate(`/payments/${row.original.id}/details`)}
          icon={TbEye} label="" variant="accent"
          className="py-1 px-1 text-lg"
        />
      </div>
    </>
  );
}

function RenderStatus({ row: { original: { status } } }: {
  row: Row<Payment>
}): JSX.Element | null {
  const { t } = useTranslation("payments");
  return <PaymentStatusColorBar paymentStatus={status} label={t(status)} />;
}

function RenderUser({ row: { original: { extendedPaymentData } } }: {
  row: Row<Payment>
}): JSX.Element {
  return (
    <>
      <span>{`${extendedPaymentData.user.firstName} ${extendedPaymentData.user.lastName}`}</span><br/>
      <a className='font-medium text-blue-600 dark:text-blue-500 hover:underline' href={`mailto:${extendedPaymentData.account.email}`}>{`${extendedPaymentData.account.email}`}</a>
    </>
  );
}

type GroupedData = {
  [key: string]: string[];
};

function RenderPurchases({ row: { original: { purchases } } }: {
  row: Row<Payment>
}): JSX.Element {

  const groupedPurchases : GroupedData = purchases.reduce<GroupedData>((acc, item) => {
    const key = item.extendedPurchaseData.reservation?.ticketTypeOnSlot.ticketType.name ?? item.extendedPurchaseData.subscription?.subscriptionType.name ?? "";

    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(key);
    return acc;
  }, {});

  return (
    <>
     {Object.keys(groupedPurchases).map(groupedPurchase => (
      <span key={groupedPurchase}>{groupedPurchases[groupedPurchase].length}x {groupedPurchase}<br/></span>
     ))}
    </>
  );
}


export default function PaymentsListPage(): JSX.Element {
  if (!usePermissions([Permissions.ViewConfigurations]) && !usePermissions([Permissions.ManagePayments])) {
    return (<></>);
  }
  const payments = usePayments();
  const [pagination, setPagination] = React.useState({ pageSize: 25, pageIndex: 0 });
  const [filter, setFilter] = React.useState<PaymentFilter>({ status: PaymentStatus.ALL, type: PurchaseType.ALL });
  const [search, setSearch] = React.useState<string>('');
  const language = useLanguage();
  const navigate = useNavigate();
  const { t } = useTranslation("payments");

  const paymentStatuses: Record<PaymentStatus, OptionItem> = {
    [PaymentStatus.ALL]: { id: PaymentStatus.ALL, name: t("all") },
    [PaymentStatus.PAID]: { id: PaymentStatus.PAID, name: t("paid") },
    [PaymentStatus.OPEN]: { id: PaymentStatus.OPEN, name: t("open") },
    [PaymentStatus.EXPIRED]: { id: PaymentStatus.EXPIRED, name: t("expired") },
    [PaymentStatus.FAILED]: { id: PaymentStatus.FAILED, name: t("failed") },
    [PaymentStatus.CANCELED]: { id: PaymentStatus.CANCELED, name: t("canceled") },
    [PaymentStatus.PENDING]: { id: PaymentStatus.PENDING, name: t("pending") },
    [PaymentStatus.AUTHORIZED]: { id: PaymentStatus.AUTHORIZED, name: t("authorized") },
    [PaymentStatus.REFUNDED]: { id: PaymentStatus.REFUNDED, name: t("refunded") },
    [PaymentStatus.PROCESSING]: { id: PaymentStatus.PROCESSING, name: t("processing") },
    [PaymentStatus.NOTISSUED]: { id: PaymentStatus.NOTISSUED, name: t("notissued") },
  };
  

  const purchaseTypes: Record<PurchaseType, OptionItem> = {
    [PurchaseType.ALL]: { id: PurchaseType.ALL, name: t("all") },
    [PurchaseType.TICKET]: { id: PurchaseType.TICKET, name: t("ticket") },
    [PurchaseType.SUBSCRIPTION]: { id: PurchaseType.SUBSCRIPTION, name: t("subscription") },
    [PurchaseType.REFUND]: { id: PurchaseType.REFUND, name: t("refund") },
  };
  
  React.useEffect((): void => {
    getPayments(pagination.pageIndex * pagination.pageSize, pagination.pageSize, search, filter)
      .then((data): void => {
        store.dispatch(setPayments(data));
      })
      .catch((error): void => {
        toast.error(error.message);
      });
  }, [pagination, filter, search]);


  const onChangeStatus = (selected: { id: PaymentStatus, name: string }[]): void => {
    if (selected.length === 0) return;
    setFilter(prevFilter => ({ ...prevFilter, status: selected[0].id }));
  }

  const onChangeType = (selected: { id: PurchaseType, name: string }[]): void => {
    if (selected.length === 0) return;
    setFilter(prevFilter => ({ ...prevFilter, type: selected[0].id }));
  }

  const searchRecords = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setSearch(event.target.value);
  }
  
  const columns = useMemo((): any[] => {
    const columnHelper = createColumnHelper<Payment>();
    return [
      columnHelper.accessor((row) => row.id, {
        id: 'id',
        header: t("id")
      }),
      columnHelper.accessor((row) => row.extendedPaymentData, {
        id: 'user',
        header: t("user"),
        cell: RenderUser
      }),
      columnHelper.accessor((row) => row.purchases, {
        id: 'purchases',
        header: t("purchases"),
        cell: RenderPurchases
      }),
      columnHelper.accessor((row) => row.status, {
        id: 'status',
        header: t("status"),
        cell: RenderStatus
      }),
      {
        id: 'actions',
        header: t("actions"),
        cell: RenderActionsCell,
        meta: {
          align: 'text-right'
        }
      }
    ]
  }, [language]);

  return (
    <>
      <div className="flex gap-1">
        <IconButton onClick={(): void => navigate('/tickets/create')} icon={TbCirclePlus} label={t("new_ticket")}
                    className="bg-gradient-to-r from-[#E700F9] hover:shadow-purple-300 hover:shadow-md
                    to-pink-500 duration-300 bg-[position:_0%_0%] hover:bg-[position:_100%_100%]
                    bg-[size:_200%] transition-all font-bold text-white rounded-full cursor-pointer" 
                    disabled={!usePermissions([Permissions.ManageTickets])}
                    />
        <IconButton onClick={(): void => navigate('/subscriptions/create')} icon={TbCirclePlus} label={t("new_subscription")}
                    className="bg-gradient-to-r from-[#E700F9] hover:shadow-purple-300 hover:shadow-md
                    to-pink-500 duration-300 bg-[position:_0%_0%] hover:bg-[position:_100%_100%]
                    bg-[size:_200%] transition-all font-bold text-white rounded-full cursor-pointer"
                    disabled={!usePermissions([Permissions.ManageSubscriptions])}/>
      </div>
      <div className="flex justify-between items-center mb-2">
        <h2 className="font-gintobold font-bold text-2xl">{t("title")}</h2>
      </div>
      <TextInput onChange={searchRecords} placeholder={t('search')} className='bg-white'/>
      <div className="flex gap-1 items-center py-2">
        <p className="font-semibold text-md ml-4">{t("filters")}: </p>
        <CheckboxDropdown label='Status' values={Object.values(paymentStatuses)} onChange={onChangeStatus} type="radio" initialValues={[paymentStatuses[filter.status]]}/>
        <CheckboxDropdown label='Types' values={Object.values(purchaseTypes)} onChange={onChangeType} type="radio" initialValues={[purchaseTypes[filter.type]]}/>
      </div>
      <SelectionTable
        columns={columns}
        data={payments}
        pagination={pagination}
        setPagination={setPagination}
        enableRowSelection={false}
      />
    </>)
}

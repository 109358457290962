import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RolesState } from '../../types/redux';
import { Role } from '../../types/account';

const initialState: RolesState = {
  roles: [],
};

const rolesSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    setRoles(state, action: PayloadAction<Role[]>): void {
      state.roles = action.payload;
    },
  },
});

const rolesReducer = rolesSlice.reducer;

export const { setRoles } = rolesSlice.actions;
export default rolesReducer;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import TextInputWithLabel from './TextInputWithLabel';
import Button from '../buttons/Button';
import { CapacityBasedPolicy, PricingPolicy, PricingPolicyDTO, TimeBasedPolicy } from '../../types/pricingPolicies';
import CapacityBasedPricingPolicyTable from '../tables/CapacityBasedPricingPolicyTable';
import TimeBasedPricingPolicyTable from '../tables/TimeBasedPricingPolicyTable';
import { secondsToHHMM } from '../../utils/dateUtil';
import usePermissions from '../../hooks/selectors/usePermissions';
import { Permissions } from '../../types/misc';

type PricingPolicyFormProps = {
  onSubmit: (scanDefinition: PricingPolicyDTO) => void;
  initialState?: PricingPolicy;
  formType?: 'create' | 'update';
}

type PricingPolicyFormState = {
  name: string;
  capacityBasedPolicies: CapacityBasedPolicy[],
  timeBasedPolicies: TimeBasedPolicy[],
}

export default function PricingPolicyForm({ initialState, onSubmit, formType = 'create' }: PricingPolicyFormProps): JSX.Element {
  if (!usePermissions([Permissions.ManageConfigurations])) {
    return (<></>);
  }
  const navigate = useNavigate();
  const { t } = useTranslation("pricing_policy_form");
  const [pricingPolicy, setPricingPolicy] = React.useState<PricingPolicyFormState>({
    name: initialState?.name || '',
    capacityBasedPolicies: initialState?.capacityBasedPricingPolicyDetails || [ { from: 0, to: 0, increment: 0} ],
    timeBasedPolicies: initialState?.timeBasedPricingPolicyDetails?.map((timeBasedPolicy) => {
      return {
        ...timeBasedPolicy,
        from: secondsToHHMM(timeBasedPolicy.from),
        to: secondsToHHMM(timeBasedPolicy.to),
      }
    }) || [ { from: '', to: '', increment: 0} ],
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {
    setPricingPolicy((prev): PricingPolicyFormState => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const onCapacityBasedItemsChange = (items : CapacityBasedPolicy[]) : void => {
    setPricingPolicy((prev) => {
      return {
        ...prev,
        capacityBasedPolicies: items
      }
    })
  }

  const onTimeBasedItemsChange = (items : TimeBasedPolicy[]) : void => {
    setPricingPolicy((prev) => {
      return {
        ...prev,
        timeBasedPolicies: items
      }
    })
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    onSubmit({
      name: pricingPolicy.name,
      archived: false,
      capacityBasedPolicies: pricingPolicy.capacityBasedPolicies.map((item) => { return { from: item.from, to: item.to, increment: item.increment }}),
      timeBasedPolicies: pricingPolicy.timeBasedPolicies.map((item) => { return { from: item.from, to: item.to, increment: item.increment }}),
    });
  };

  return (
    <form className="max-w-3xl mx-auto w-full" onSubmit={handleSubmit}>
      <h2
        className="text-2xl font-semibold mb-4 text-center">{formType === 'create' ? t("create_title") : t("edit_title")}</h2>
      <TextInputWithLabel label={t("pricing_policy_name")} id="name" name="name" value={pricingPolicy.name} onChange={handleChange}
                          placeholder={t("pricing_policy_name_placeholder")} className="bg-white mb-4" required />
    
      <h4>{t("capacity_based_pricing")}</h4>
      <CapacityBasedPricingPolicyTable items={pricingPolicy.capacityBasedPolicies} onChangeCapacityItems={onCapacityBasedItemsChange} />

      <h4 className='mt-4'>{t("time_based_pricing")}</h4>
      <TimeBasedPricingPolicyTable items={pricingPolicy.timeBasedPolicies} onChangeTimeItems={onTimeBasedItemsChange} />

      <div className="flex justify-end gap-2 my-4">
        <Button type="button" className="bg-gray-500" onClick={(): void => navigate('/pricing-policies')}>
          {t("cancel_button")}
        </Button>
        <Button type="submit">{formType === 'create' ? t("create_button") : t("save_button")}</Button>
      </div>
    </form>
  );
}

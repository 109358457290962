import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ScannerState } from '../../types/redux';
import { Scanner } from '../../types/tickets';

const initialState: ScannerState = {
  scanners: [],
};

const scannersSlice = createSlice({
  name: 'scanGroups',
  initialState,
  reducers: {
    setScanners(state, action: PayloadAction<Scanner[]>): void {
      state.scanners = action.payload;
    },
  },
});

const scannersReducer = scannersSlice.reducer;

export const { setScanners } = scannersSlice.actions;
export default scannersReducer;

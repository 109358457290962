import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { createColumnHelper, PaginationState, Row } from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';
import { TbArchive, TbCirclePlus, TbEdit, TbTrash } from 'react-icons/tb';
import SelectionTable from '../../components/tables/SelectionTable';
import IconButton from '../../components/buttons/IconButton';
import DeleteModal from '../../components/modals/DeleteModal';
import { store } from '../../redux/store';
import { PrivilegedStatus, SubscriptionType, SubscriptionTypeFilter } from '../../types/subscriptions';
import { deleteSubscriptionType, getSubscriptionTypesForCurrentLocation } from '../../services/subscriptionTypeService';
import { setSubscriptionTypes } from '../../redux/slices/subscriptionTypesSlice';
import useSubscriptionTypes from '../../hooks/selectors/useSubscriptionTypes';
import CheckboxDropdown from '../../components/forms/CheckboxDropdown';
import { Status } from '../../types/filter';
import useLanguage from '../../hooks/selectors/useLanguage';
import { OptionItem } from '../../components/forms/SelectWithData';
import usePermissions from '../../hooks/selectors/usePermissions';
import { Permissions } from '../../types/misc';

const columnHelper = createColumnHelper<SubscriptionType>();

function RenderActionsCell({ row }: { row: Row<SubscriptionType> }, resetPagination: () => void): JSX.Element {
  const [deleteModalOpen, setDeleteModalOpen] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const { t } = useTranslation("subscription_type");

  const onDelete = (): void => {
    deleteSubscriptionType(row.original.id)
      .then((): void => {
        toast.success(t("deleted"));
        resetPagination();
        setDeleteModalOpen(false);
      })
      .catch((error): void => {
        toast.error(error.message);
      });
  };

  return (
    <>
      <div className="flex justify-end items-center gap-1">
        <IconButton
          onClick={(): void => navigate(`/subscription-types/edit/${row.original.id}`)}
          icon={TbEdit} label="" variant="accent"
          className="py-1 px-1 text-lg"
          disabled={!usePermissions([Permissions.ManageConfigurations])}

        />
        <IconButton
          onClick={(): void => setDeleteModalOpen(true)}
          icon={TbTrash} label="" variant="danger"
          className="py-1 px-1 text-lg"
          disabled={!usePermissions([Permissions.ManageConfigurations])}
        />
      </div>
      <DeleteModal open={deleteModalOpen} setOpen={setDeleteModalOpen} onActionButtonClicked={onDelete}
                   message={`${t("delete_confirmation")} "${row.original.name}" (€${row.original.price.toFixed(2)})?`}
      />
    </>
  );
}

function RenderName({ row: { original: { name, archived } } }: {
  row: Row<SubscriptionType>
}): JSX.Element {
  if (!archived) return <>{name}</>;

  return (
    <div className="flex items-center gap-1 text-gray-600"><TbArchive size={16} />{name}</div>
  );
}

export default function ListSubscriptionTypePage(): JSX.Element {
  if (!usePermissions([Permissions.ViewConfigurations])) {
    return (<></>);
  }
  const [pagination, setPagination] = React.useState<PaginationState>({ pageIndex: 0, pageSize: 25 });
  const [filter, setFilter] = React.useState<SubscriptionTypeFilter>({ status: Status.ACTIVE, privileged: PrivilegedStatus.NONPRIVILEGED });
  const subscriptionTypes = useSubscriptionTypes();
  const language = useLanguage();
  const navigate = useNavigate();
  const { t } = useTranslation("subscription_type");

  const resetPagination = (): void => {
    setPagination(prev => ({ pageSize: prev.pageSize, pageIndex: prev.pageIndex }));
  }

  React.useEffect((): void => {
    getSubscriptionTypesForCurrentLocation(pagination.pageIndex * pagination.pageSize, pagination.pageSize, filter)
      .then((data): void => {
        store.dispatch(setSubscriptionTypes(data));
      })
      .catch((error): void => {
        toast.error(error.message);
      });
  }, [pagination, filter]);

  const columns = useMemo((): any[] => [
    columnHelper.accessor((row) => row.id, {
      id: 'id',
      header: t("id"),
    }),
    columnHelper.accessor((row) => row.name, {
      id: 'name',
      header: t("name"),
      cell: RenderName,
    }),
    columnHelper.accessor((row) => row.price, {
      id: 'price',
      header: t("price"),
      cell: ({ row: { original: { price } } }): string => `${price < 0 ? '-' : ''}€${Math.abs(price).toFixed(2)}`,
    }),    
    columnHelper.accessor((row) => row.defaultRidesCount === 0 ? t('type_subscription') : t('type_ridescard'), {
      id: 'type',
      header: t("type"),
    }),   
    columnHelper.accessor((row): string => row?.capacityGroup?.name || t('not_specified'), {
      id: 'capacityGroup',
      header: t("capacity_group"),
    }),
    columnHelper.accessor((row): string => row?.scanDefinition?.name || t('not_specified'), {
      id: 'scanDefinition',
      header: t("scan_definition"),
    }),
    {
      id: 'actions',
      header: t("actions"),
      cell: (row: { row: Row<SubscriptionType> }) => RenderActionsCell(row, resetPagination),
      meta: {
        align: 'text-right',
      },
    },
  ], [language]);

  const subscriptionTypeStatus: Record<Status, OptionItem> = {
    [Status.ALL]: { id: Status.ALL, name: t("all") },
    [Status.ACTIVE]: { id: Status.ACTIVE, name: t("active") },
    [Status.ARCHIVED]: { id: Status.ARCHIVED, name: t("archived") },
  };
  

  const subscriptionTypePrivileged: Record<PrivilegedStatus, OptionItem> = {
    [PrivilegedStatus.NONPRIVILEGED]: { id: PrivilegedStatus.NONPRIVILEGED, name: t("not_privileged") },
    [PrivilegedStatus.PRIVILEGED]: { id: PrivilegedStatus.PRIVILEGED, name: t("privileged") },
  };
  
  
  const onChangeStatus = (selected: { id: Status, name: string }[]): void => {
    if (selected.length === 0) return;
    setFilter(prevFilter => ({ ...prevFilter, status: selected[0].id }));
  }

  const onChangePrivilegedStatus = (selected: { id: PrivilegedStatus, name: string }[]): void => {
    if (selected.length === 0) return;
    setFilter(prevFilter => ({ ...prevFilter, privileged: selected[0].id }));
  }

  return (
    <>
      <div className="flex justify-between items-center">
        <h2 className="font-gintobold font-bold text-2xl">{t("title")}</h2>
        <div className="flex gap-1">
          {
            usePermissions([Permissions.ManageConfigurations]) &&
            <IconButton onClick={(): void => navigate('/subscription-types/create')} icon={TbCirclePlus} label={t("new")}
                        className="bg-gradient-to-r from-[#E700F9] hover:shadow-purple-300 hover:shadow-md
                      to-pink-500 duration-300 bg-[position:_0%_0%] hover:bg-[position:_100%_100%]
                      bg-[size:_200%] transition-all font-bold text-white rounded-full cursor-pointer" />
          }
        </div>
      </div>
      <div className="flex gap-1 items-center py-2">
        <p className="font-semibold text-md">{t("filters")}: </p>
        <CheckboxDropdown label={t("status")} values={Object.values(subscriptionTypeStatus)} onChange={onChangeStatus} type="radio" initialValues={[subscriptionTypeStatus[filter.status]]}/>
        <CheckboxDropdown label={t("privileged_status")} values={Object.values(subscriptionTypePrivileged)} onChange={onChangePrivilegedStatus} type="radio" initialValues={[subscriptionTypePrivileged[filter.privileged]]}/>
      </div>
      <div>
        <SelectionTable
          columns={columns}
          data={subscriptionTypes}
          pagination={pagination}
          setPagination={setPagination}
          enableRowSelection={false}
        />
      </div>
    </>
  );
}

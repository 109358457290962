import axios, { AxiosInstance, InternalAxiosRequestConfig } from 'axios';
import { StatusCodes } from 'http-status-codes';
import { API_URL } from '../constants';
import { setAuthenticated } from '../redux/slices/applicationSlice';
import { store } from '../redux/store';

const httpService: AxiosInstance = axios.create({
  baseURL: API_URL,
});

httpService.interceptors.response.use((resp) => resp, (err) => {
  if (err.response?.status === StatusCodes.UNAUTHORIZED) {
    store.dispatch(setAuthenticated(false));
  }
  
  throw err;
});

export const attachToken = (request: InternalAxiosRequestConfig): InternalAxiosRequestConfig => {
  const token = localStorage.getItem('access_token');
  request.headers.Authorization = token ? `Bearer ${token}` : '';
  return request;
};

httpService.interceptors.request.use(attachToken);

export default httpService;

export default function useDebounce (func: any, delay: number): any {
    let timeout: any = null;

    return(...args: any[]) => {
        return new Promise( ( resolve, reject ) => {
            clearTimeout(timeout);

            timeout = setTimeout( () => {
                try {
                    const output = func(...args);
                    resolve( output );
                } catch ( err ) {
                    reject( err );
                }
            }, delay);
        })
    }
}
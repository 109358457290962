import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { createDayGroup } from '../../services/dayGroupService';
import { DayGroupCreateOrUpdate } from '../../types/dayGroups';
import DayGroupForm from '../../components/forms/DayGroupForm';

export default function CreateDayGroupPage(): JSX.Element {
    const navigate = useNavigate();
    const { capacityGroupId } = useParams();
    const { t } = useTranslation("day_group");
  
    useEffect((): void => {
      if (!capacityGroupId) {
        navigate('/capacity-groups');
      }
    }, []);

    const onSubmit = (dayGroup: DayGroupCreateOrUpdate): void => {
      createDayGroup(Number(capacityGroupId), dayGroup).then((): void => {
        toast.success(t("created"));
        navigate(`/capacity-groups/edit/${capacityGroupId}`);
      }).catch((error): void => {
        if (error instanceof AxiosError && error.response?.data.errors) {
          toast.error(
            error.response?.data.errors
              .flatMap((e: { constraints: { [k: string]: string }[] }): {
                [k: string]: string
              }[] => Object.values(e.constraints))
              .map((x: string): string => x.charAt(0).toUpperCase() + x.slice(1))
              .join('. '),
          );
        } else {
          toast.error(error.message);
        }
      });
    };
  
    return (
      <>
        <DayGroupForm onSubmit={onSubmit} formType="create" />
      </>
    );
  }
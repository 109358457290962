import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { TicketShop } from '../../types/tickets';
import { TicketShopsState } from '../../types/redux';

const initialState: TicketShopsState = {
  ticketShops: [],
};

const ticketShopsSlice = createSlice({
  name: 'ticketShops',
  initialState,
  reducers: {
    setTicketShops(state, action: PayloadAction<TicketShop[]>): void {
      state.ticketShops = action.payload;
    },
    updateTicketShop(state, action: PayloadAction<TicketShop>): void {
      state.ticketShops = state.ticketShops.map((s) =>
        s.id === action.payload.id ? action.payload : s
      );
    }
  },
});

const ticketShopsReducer = ticketShopsSlice.reducer;

export const { setTicketShops, updateTicketShop } = ticketShopsSlice.actions;
export default ticketShopsReducer;

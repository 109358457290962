import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { CapacityGroup, ScanDefinition, TicketShop } from '../../types/tickets';
import { getCapacityGroupsForCurrentLocation } from '../../services/capacityGroupService';
import { getScanDefinitionsCurrentLocation } from '../../services/scanDefinitionService';
import { Layout } from '../../types/layout';
import { getLayoutsForCurrentLocation } from '../../services/layoutService';
import { SubscriptionShop } from '../../types/subscriptions';
import { getSubscriptionShopsForCurrentLocation } from '../../services/subscriptionShopService';

type SubscriptionTypeFormData = {
  loading: boolean;
  subscriptionShops: SubscriptionShop[];
  capacityGroups: CapacityGroup[];
  scanDefinitions: ScanDefinition[];
  layouts: Layout[];
};

export default function useSubscriptionTypeFormData(): SubscriptionTypeFormData {
  const [capacityGroups, setCapacityGroups] = useState<CapacityGroup[]>([]);
  const [scanDefinitions, setScanDefinitions] = useState<ScanDefinition[]>([]);
  const [subscriptionShops, setSubscriptionShops] = useState<TicketShop[]>([]);
  const [layouts, setLayouts] = useState<Layout[]>([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { t } = useTranslation("service_errors");

  useEffect((): void => {
    Promise.all([
      getCapacityGroupsForCurrentLocation(),
      getScanDefinitionsCurrentLocation(0, Number.MAX_SAFE_INTEGER),
      getSubscriptionShopsForCurrentLocation(0, Number.MAX_SAFE_INTEGER),
      getLayoutsForCurrentLocation(0, Number.MAX_SAFE_INTEGER),
    ])
      .then(([cg, sd, ss, ls]): void => {
        setCapacityGroups(cg);
        setScanDefinitions(sd);
        setSubscriptionShops(ss);
        setLayouts(ls);
      })
      .catch((): void => {
        toast.error(t("failed_fetch_information_subscription_types"));
        navigate('/subscription-types');
      })
      .finally((): void => setLoading(false));
  }, []);

  return { loading, subscriptionShops, capacityGroups, scanDefinitions, layouts };
}

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DayGroupsState } from '../../types/redux';
import { Day } from '../../types/slots';

const initialState: DayGroupsState = {
  dayGroups: [],
};

const dayGroupsSlice = createSlice({
  name: 'dayGroups',
  initialState,
  reducers: {
    setDayGroups(state, action: PayloadAction<Day[]>): void {
      state.dayGroups = action.payload;
    },
  },
});

const dayGroupsReducer = dayGroupsSlice.reducer;

export const { setDayGroups } = dayGroupsSlice.actions;
export default dayGroupsReducer;

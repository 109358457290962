import validator from 'email-validator';

export function validateEmail(email: string | undefined): boolean {
  if (!email) return false;
  return validator.validate(email);
}

export function validatePassword(password: string | undefined): boolean {
  if (!password) return false;
  if (password.length < 8) return false;
  if (password.length > 36) return false;
  if (!/[A-Z]/.test(password)) return false;
  if (!/[a-z]/.test(password)) return false;
  if (!/[0-9]/.test(password)) return false;
  if (!/[!@#$%^&*(),.?":{}|<>]/.test(password)) return false;
  return true;
}

export function validateNonEmpty(str: string | undefined): boolean {
  if (!str) return false;
  if (str.length < 1) return false;
  return true;
}

export const passwordValidators = {
  minLength: (passwordToCheck: string, errorMessages: string[]): boolean => {
      if (passwordToCheck.length < 8) {
          errorMessages.push("password_8_characters");
          return false;
      }
      return true;
  },
  maxLength: (passwordToCheck: string, errorMessages: string[]): boolean => {
      if (passwordToCheck.length > 36) {
          errorMessages.push("password_36_characters");
          return false;
      }
      return true;
  },
  hasUppercase: (passwordToCheck: string, errorMessages: string[]): boolean => {
      if (!/[A-Z]/.test(passwordToCheck)) {
          errorMessages.push("password_uppercase");
          return false;
      }
      return true;
  },
  hasLowercase: (passwordToCheck: string, errorMessages: string[]): boolean => {
      if (!/[a-z]/.test(passwordToCheck)) {
          errorMessages.push("password_lowercase");
          return false;
      }
      return true;
  },
  hasNumber: (passwordToCheck: string, errorMessages: string[]): boolean => {
      if (!/[0-9]/.test(passwordToCheck)) {
          errorMessages.push("password_digit");
          return false;
      }
      return true;
  },
  hasSpecialCharacter: (passwordToCheck: string, errorMessages: string[]): boolean => {
      if (!/[!@#$%^&*(),.?":{}|<>]/.test(passwordToCheck)) {
          errorMessages.push("password_special");
          return false;
      }
      return true;
  },
}
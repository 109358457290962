import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LayoutsState } from '../../types/redux';
import { Layout } from '../../types/layout';

const initialState: LayoutsState = {
  layouts: []
};

const layoutsSlice = createSlice({
  name: "layouts",
  initialState,
  reducers: {
    setLayouts(state, action: PayloadAction<Layout[]>): void {
      state.layouts = action.payload;
    },
  },
});

const layoutsReducer = layoutsSlice.reducer;

export const { setLayouts } = layoutsSlice.actions;
export default layoutsReducer
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ScanGroup, Scanner, ScannerDTO } from '../../types/tickets';
import TextInputWithLabel from './TextInputWithLabel';
import Button from '../buttons/Button';
import useScanGroupsFormData from '../../hooks/selectors/useScannerFormData';
import DraggableList from '../sortable/DraggableList';
import usePermissions from '../../hooks/selectors/usePermissions';
import { Permissions } from '../../types/misc';

type ScannerFormProps = {
  onSubmit: (scanGroup: ScannerDTO) => void;
  initialState?: Scanner;
  formType?: 'create' | 'update';
}

type ScannerFormState = {
  name: string;
  scanGroups: ScanGroup[],
}

export default function ScannerForm({ initialState, onSubmit, formType = 'create' }: ScannerFormProps): JSX.Element {
  if (!usePermissions([Permissions.ManageConfigurations])) {
    return (<></>);
  }
  const navigate = useNavigate();
  const { t } = useTranslation("scanner_form");
  const { scanGroups, loading } = useScanGroupsFormData();
  const [scanner, setScanner] = React.useState<ScannerFormState>({
    name: initialState?.name || '',
    scanGroups: [] as ScanGroup[]
  });

  useEffect(() => {
    if (!loading) {
      setScanner((prev): ScannerFormState => ({
        ...prev,
        scanGroups: scanGroups.map((scanGroup) => {
          return {
            ...scanGroup,
            order: initialState?.scanConfigurations?.find(d => d.scanGroupId === scanGroup.id)?.order ?? 1000,
            checked: initialState?.scanConfigurations?.some((value) => {
              return value.scanGroupId === scanGroup.id;
            }) ?? false,
          }
        }).sort((a, b) => (a.order - b.order))
      }))
    }
  }, [loading]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {
    setScanner((prev): ScannerFormState => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const onItemsChange = (items : ScanGroup[]): void => {
    setScanner((prev) => {
      return {
        ...prev,
        scanGroups: items
      }
    })
    console.log(scanner);
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    onSubmit({
      name: scanner.name,
      scanConfigurations: scanner.scanGroups.filter(d => d.checked).map((d, i) => {
        return {
          scanGroupId: d.id,
          order: i,
        }
      }),
    });
  };

  if (loading) {
    return <div>{t("loading")}</div>;
  }

  return (
    <form className="max-w-3xl mx-auto w-full" onSubmit={handleSubmit}>
      <h2
        className="text-2xl font-semibold mb-4 text-center">{formType === 'create' ? t("create_title") : t("edit_title")}</h2>
      <TextInputWithLabel label={t("scanner_name")} id="name" name="name" value={scanner.name} onChange={handleChange}
                          placeholder={t("scanner_placeholder")} className="bg-white mb-4" required />
    
      <h4>{t("scangroups")}</h4>
      <DraggableList items={scanner.scanGroups} onItemsChange={onItemsChange} />

      <div className="flex justify-end gap-2 my-4">
        <Button type="button" className="bg-gray-500" onClick={(): void => navigate('/scanners')}>
          {t("cancel_button")}
        </Button>
        <Button type="submit">{formType === 'create' ? t("create_button") : t("save_button")}</Button>
      </div>
    </form>
  );
}

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { SubscriptionTypesState } from '../../types/redux';
import { SubscriptionType } from '../../types/subscriptions';

const initialState: SubscriptionTypesState = {
  subscriptionTypes: [],
};

const subscriptionTypeSlice = createSlice({
  name: 'subscriptionType',
  initialState,
  reducers: {
    setSubscriptionTypes(state, action: PayloadAction<SubscriptionType[]>): void {
      state.subscriptionTypes = action.payload;
    },
  },
});

const subscriptionTypesReducer = subscriptionTypeSlice.reducer;

export const { setSubscriptionTypes } = subscriptionTypeSlice.actions;
export default subscriptionTypesReducer;

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PaymentsState } from '../../types/redux';
import { Payment } from '../../types/payment';

const initialState: PaymentsState = {
  payments: [],
}

const paymentsSlice = createSlice({
  name: 'payments',
  initialState,
  reducers: {
    setPayments(state, action: PayloadAction<Payment[]>): void {
      state.payments = action.payload;
    }
  }
})

const paymentsReducer = paymentsSlice.reducer;

export const { setPayments } = paymentsSlice.actions;
export default paymentsReducer;

import { PaymentStatus, PurchaseType } from "./filter";

export type Payment = {
    id: number;
    date: Date;
    userId: number | null;
    locationId: number;
    extendedPaymentData: ExtendedPaymentData;
    paymentReference: string;
    status: PaymentStatus;
    method: string;
    bookingFee: number;
    purchases: Purchase[];
    discounts: Discount[];
}

export type Discount = {
    code: string
}

export type Purchase = {
    id: number,
    price: number,
    originalPrice: number,
    type: string,
    refundPaymentId: number | null,
    extendedPurchaseData: ExtendedPurchaseData,
}

export type Rotterdampas = {
    id: number,
    voucherId: string,
    cardNumber: string,
    cardStatus: string,
    voucherName: string,
    transactionId: string,
}

export type PaymentFilter = {
    status: PaymentStatus,
    type: PurchaseType,
}

export type ExtendedPaymentData = {
    user: ExtendedPaymentUserData,
    account: ExtendedPaymentAccountData,
    location: ExtendedPaymentLocationData,
    shop: ExtendedPaymentShopData
}

export type ExtendedPaymentShopData = {
    type: ShopType 
    name?: string,
}

export type ExtendedPaymentLocationData = {
    name: string,
}

export type ExtendedPaymentUserData = {
    firstName: string,
    lastName: string,
}

export type ExtendedPaymentAccountData = {
    email: string,
}

export type ExtendedPurchaseData = {
    reservation?: ExtendedPurchaseReservation,
    subscription?: ExtendedPurchaseSubscriptionData,
    rotterdampas?: Rotterdampas
}

export type ExtendedPurchaseSubscriptionData = {
    id: number,
    subscriptionType: ExtendedPurchaseSubscriptionTypeData,
    cardholders: ExtendedPurchaseCardholderData[]
}

export type ExtendedPurchaseCardholderData = {
    qrCodeValue: string
}

export type ExtendedPurchaseSubscriptionTypeData = {
    name: string,
}

export type ExtendedPurchaseReservation = {
    id: number,
    status: ReservationStatus,
    ticketTypeOnSlot: ExtendedPurchaseTicketTypeOnSlotData,
    qrCode: ExtendedPurchaseDataQrCode
}

export enum ReservationStatus {
    CONFIRMED = "CONFIRMED",
    PENDING = "PENDING",
    USED = "USED",
    CANCELLED = "CANCELLED"
}

export enum RotterdampasCardStatus {
    CLAIMED = "CLAIMED",
    REDEEM_FAILED = 'REDEEM_FAILED',
    CANCELED = 'CANCELED',
    CANCEL_PENDING = 'CANCEL_PENDING',
    CANCEL_FAILED = 'CANCEL_FAILED'
}

export enum ShopType {
    TICKET = "TICKET",
    SUBSCRIPTION = "SUBSCRIPTION",
}

export type ExtendedPurchaseDataQrCode = {
    code: string,
}

export type ExtendedPurchaseTicketTypeOnSlotData = {
    ticketType: ExtendedPurchaseTicketTypeData,
}

export type ExtendedPurchaseTicketTypeData = {
    id: number,
    name: string,
}

export type ConfirmationEmail = {
    email?: string,
}

export type RefundPaymentData = {
    purchases: number[],
    cancellationFee: number
}
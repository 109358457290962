import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ScanGroupDTO } from '../../types/tickets';
import TextInputWithLabel from './TextInputWithLabel';
import Button from '../buttons/Button';
import usePermissions from '../../hooks/selectors/usePermissions';
import { Permissions } from '../../types/misc';

type ScanGroupFormProps = {
  onSubmit: (scanGroup: ScanGroupDTO) => void;
  initialState?: ScanGroupFormState;
  type?: 'create' | 'update';
}

type ScanGroupFormState = {
  name: string;
}

export default function TicketShopForm({ initialState, onSubmit, type = 'create' }: ScanGroupFormProps): JSX.Element {
  if (!usePermissions([Permissions.ManageConfigurations])) {
    return (<></>);
  }
  const navigate = useNavigate();
  const { t } = useTranslation("scan_group_form");
  const [scanGroup, setScanGroup] = React.useState<ScanGroupFormState>({
    name: initialState?.name || '',
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {
    setScanGroup((prev): ScanGroupFormState => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    onSubmit({
      name: scanGroup.name,
    });
  };

  return (
    <form className="max-w-3xl mx-auto w-full" onSubmit={handleSubmit}>
      <h2
        className="text-2xl font-semibold mb-4 text-center">{type === 'create' ? t("create_title") : t("edit_title")}</h2>
      <TextInputWithLabel label={t("scan_group_name")} id="name" name="name" value={scanGroup.name} onChange={handleChange}
                          placeholder={t("scan_group_name_placeholder")} className="bg-white" required />
      <div className="flex justify-end gap-2 my-4">
        <Button type="button" className="bg-gray-500" onClick={(): void => navigate('/scan-groups')}>
          {t("cancel_button")}
        </Button>
        <Button type="submit">{type === 'create' ? t("create_button") : t("save_button")}</Button>
      </div>
    </form>
  );
}

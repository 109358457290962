import React from 'react';

export default function SidebarIcon(props: React.SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 24 20"
      {...props}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 3.50374C0 1.58917 1.55206 0.0371094 3.46663 0.0371094H20.5334C22.4479 0.0371094 24 1.58917 24 3.50374V15.7196C24 17.6341 22.4479 19.1862 20.5334 19.1862H3.46663C1.55206 19.1862 0 17.6341 0 15.7196V3.50374ZM1.89031 3.70991C1.89031 2.94409 2.51114 2.32326 3.27697 2.32326H8.9909V17.0044H3.27697C2.51114 17.0044 1.89031 16.3836 1.89031 15.6178V3.70991ZM20.4094 2.32326H10.9505V17.0047H20.4094C21.1752 17.0047 21.796 16.3838 21.796 15.618V3.70991C21.796 2.94408 21.1752 2.32326 20.4094 2.32326Z"
        fill="#5B5B66"
      />
      <path
        d="M2.90821 4.49261C2.90821 4.06183 3.25743 3.71262 3.68821 3.71262H7.19301C7.62379 3.71262 7.973 4.06183 7.973 4.49261C7.973 4.92339 7.62379 5.27261 7.19301 5.27261H3.68821C3.25743 5.27261 2.90821 4.92339 2.90821 4.49261Z"
        fill="#5B5B66"
      />
      <path
        d="M2.90821 7.78591C2.90821 7.35514 3.25743 7.00592 3.68821 7.00592H7.19301C7.62379 7.00592 7.973 7.35514 7.973 7.78591C7.973 8.21669 7.62379 8.56591 7.19301 8.56591H3.68821C3.25743 8.56591 2.90821 8.21669 2.90821 7.78591Z"
        fill="#5B5B66"
      />
      <path
        d="M2.90821 11.0792C2.90821 10.6484 3.25743 10.2992 3.68821 10.2992H7.19301C7.62379 10.2992 7.973 10.6484 7.973 11.0792C7.973 11.51 7.62379 11.8592 7.19301 11.8592H3.68821C3.25743 11.8592 2.90821 11.51 2.90821 11.0792Z"
        fill="#5B5B66"
      />
    </svg>
  );
}

import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Payment, ShopType } from '../../types/payment';
import { getPayment } from '../../services/paymentService';
import PaymentsRebookSelectTicketsStep from './PaymentsRebookSelectTicketsStep';
import { Reservation } from '../../types/reservation';
import { getReservationsByIds } from '../../services/reservationService';
import PaymentsRebookSelectDateStep from './PaymentsRebookSelectDateStep';
import PaymentsRebookSelectSlotStep from './PaymentsRebookSelectSlotStep';
import { getTicketTypesForCurrentLocation } from '../../services/ticketTypeService';
import { TicketType } from '../../types/tickets';

export default function PaymentsRebookPage(): JSX.Element | null {
  const navigate = useNavigate();
  const { id } = useParams();

  const [payment, setPayment] = useState<Payment>();
  const [reservations, setReservations] = useState<Reservation[]>([]);
  const [ticketTypes, setTicketTypes] = useState<TicketType[]>([]);

  const { t } = useTranslation("payments_rebook");

  const [queryParams, _] = useSearchParams();

  const isProductsDefined = queryParams.has('products');
  const isDateDefined = queryParams.has('date');

  useEffect((): void => {
    if (!id) {
      toast.error(t('no_payment_id'));
      navigate('/payments');
      return;
    }

    getPayment(Number(id))
      .then((p): void => {
        if (p.extendedPaymentData.shop.type !== ShopType.TICKET) {
          toast.error(t('not_a_ticket'));
          navigate('/payments');
          return;
        }
  
        setPayment(p);
      })
      .catch((error): void => {
        toast.error(error.message);
        navigate('/payments');
      });
  }, [id]);

  useEffect(() => {
    if (!payment) {
      return;
    }

    const reservationIds = payment.purchases
      .map(purchase => purchase.extendedPurchaseData.reservation?.id)
      .filter((reservationId): reservationId is number => reservationId !== undefined);

    if (!reservationIds || reservationIds.length === 0) return;

    getReservationsByIds(reservationIds)
      .then((response): void => {
        setReservations(response);
      })
      .catch((error): void => {
        toast.error(error.message);
      });

    getTicketTypesForCurrentLocation(0, Number.MAX_SAFE_INTEGER)
      .then((response): void => {
        setTicketTypes(response);
      })
      .catch((error): void => {
        toast.error(error.message);
      });
  }, [payment]);

  if (!payment) return null;

  return (
    <>
      {!isProductsDefined && !isDateDefined && <><PaymentsRebookSelectTicketsStep purchases={payment.purchases} reservations={reservations} paymentId={Number(id)} ticketTypes={ticketTypes} /></>}
      {isProductsDefined && !isDateDefined && <><PaymentsRebookSelectDateStep purchases={payment.purchases} reservations={reservations} paymentId={Number(id)} /></>}
      {isProductsDefined && isDateDefined && <><PaymentsRebookSelectSlotStep purchases={payment.purchases} reservations={reservations} paymentId={Number(id)} /></>}
    </>
  );
}

import React from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';
import { CreateTicketReservationsAdminDto } from '../../types/tickets';
import { createTicket } from '../../services/ticketService';
import TicketForm from '../../components/forms/TicketForm';

export default function TicketCreatePage(): JSX.Element | null {
  const navigate = useNavigate();
  const { t } = useTranslation('ticket_reservation_form');

  const onSubmit = (createTicketReservationDto: CreateTicketReservationsAdminDto): void => {
    createTicket(createTicketReservationDto).then((): void => {
      toast.success(t("created"));
      navigate('/payments');
    }).catch((error): void => {
      if (error instanceof AxiosError && error.response?.data.errors) {
        toast.error(
          error.response?.data.errors
            .flatMap((e: { constraints: { [k: string]: string }[] }): {
              [k: string]: string
            }[] => Object.values(e.constraints))
            .map((x: string): string => x.charAt(0).toUpperCase() + x.slice(1))
            .join('. '),
        );
      } else {
        toast.error(error.message);
      }
    });
  };

  return (
    <>
        <TicketForm onSubmit={onSubmit} />
    </>
  );
}

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ScannerDTO } from '../../types/tickets';
import { createScanner } from '../../services/scannerService';
import ScannerForm from '../../components/forms/ScannerForm';

export default function ScannerCreatePage(): JSX.Element {
  const navigate = useNavigate();
  const { t } = useTranslation("scanners");

  const onSubmit = (scanner: ScannerDTO): void => {
    createScanner(scanner)
      .then((): void => {
        toast.success(t("created"));
        navigate('/scanners');
      })
      .catch((error): void => {
        toast.error(`${t("failed_create")}: ${error}`);
      });
  };

  return (
    <ScannerForm onSubmit={onSubmit} />
  );
}

import React, { ButtonHTMLAttributes } from 'react';
import { IconType } from 'react-icons';
import { twMerge } from 'tailwind-merge';
import Icon from '../icons/Icon';

type Variant = 'danger' | 'accent' | 'archive' | 'duplicate' | 'success' | 'view' | 'download';

type IconButtonProps = {
  icon: IconType,
  label: string,
  variant?: Variant,
} & ButtonHTMLAttributes<HTMLButtonElement>;

const variantClasses: { [key in Variant]: string } = {
  success: 'bg-green-500 text-white hover:bg-green-600 disabled:bg-opacity-50',
  danger: 'bg-red-500 text-white hover:bg-red-600 disabled:bg-opacity-50',
  accent: 'bg-[#113EFF] hover:bg-[#0024C4] text-white disabled:hover:bg-opacity-25' +
    'disabled:hover:bg-[#113EFF] disabled:bg-opacity-25',
  archive: 'bg-[#CC9966]text-white hover:bg-red-600',
  view: 'bg-violet-500 text-white hover:bg-opacity-75 disabled:hover:bg-opacity-50 disabled:bg-opacity-50',
  duplicate: 'bg-[#FFC34D] text-white hover:bg-red-600 disabled:bg-opacity-25',
  download: 'bg-[#CC9966]text-white hover:bg-gray-500',
}

export default function IconButton({ icon, label, className, variant, ...props }: IconButtonProps): JSX.Element {
  return <button
    type="button" // button type if not specified
    {...props}
    className={twMerge(
      'px-4 py-2 rounded-[4px] flex items-center justify-center gap-1 disabled:cursor-not-allowed',
      variant ? variantClasses[variant] : '',
      className,
      )}
  >
    <Icon icon={icon} />{label && <span className="text-sm">{label}</span>}
  </button>;
}

export type Filter<T> = {
  [K in keyof T]?: T[K] extends number ? IntFilter
      : T[K] extends boolean ? BoolFilter
          : T[K] extends Date ? DateFilter
              : T[K] extends string ? StringFilter
                  : T[K] extends Array<infer Something> ? ArrayFilter<Something> : never;
} & {
  AND?: Filter<T>[]
  OR?: Filter<T>[]
};

export interface BoolFilter {
  equals?: boolean
}

export interface IntFilter {
  equals?: number
  lt?: number
  lte?: number
  gt?: number
  gte?: number
  in?: number[]
}

export interface StringFilter {
  in?: string[]
}

export interface DateFilter {
  equals?: Date
  lt?: Date
  lte?: Date
  gt?: Date
  gte?: Date
}

export interface ArrayFilter<T> {
  some?: Filter<T>
  none?: Filter<T>
}

export enum Validity {
  ALL = 'ALL',
  EXPIRED = 'EXPIRED',
  CURRENT = 'CURRENT',
}

export enum Status {
  ALL = 'ALL',
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
}

export enum PurchaseType {
  ALL = 'ALL',
  TICKET = 'TICKET',
  SUBSCRIPTION = 'SUBSCRIPTION',
  REFUND = 'REFUND'
}

export enum PaymentStatus {
  ALL = 'all',
  PAID = 'paid',
  OPEN = 'open',
  FAILED = 'failed',
  EXPIRED = 'expired',
  CANCELED = 'canceled',
  PENDING = 'pending',
  AUTHORIZED = 'authorized',
  PROCESSING = 'processing',
  REFUNDED = 'refunded',
  NOTISSUED = 'notissued'
}

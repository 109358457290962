import React from 'react';
import ModalLayout from '../layouts/ModalLayout';
import EmailConfirmationForm from '../forms/EmailConfirmationForm';
import { ConfirmationEmail } from '../../types/payment';

interface EmailConfirmationModalProps {
  open: boolean;
  email: string;
  setOpen: (open: boolean) => void;
  onSubmit: (confirmationEmail: ConfirmationEmail) => void;
}

export default function EmailConfirmationModal({
  open,
  setOpen,
  email,
  onSubmit
}: EmailConfirmationModalProps): JSX.Element {
  return (
    <ModalLayout
      open={open}
      setOpen={setOpen}
      closeButton
      className="bg-white max-w-3xl rounded-3xl p-16">
      <EmailConfirmationForm email={email} onSubmit={onSubmit} onCancel={() => setOpen(false)} />
    </ModalLayout>
  );
}

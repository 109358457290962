import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ExtendedSubscriptionType, SubscriptionTypeUpdateDto } from '../../types/subscriptions';
import SubscriptionTypeForm from '../../components/forms/SubscriptionTypeForm';
import { getSubscriptionType, updateSubscriptionType } from '../../services/subscriptionTypeService';

export default function EditSubscriptionTypePage(): JSX.Element | null {
  const navigate = useNavigate();
  const [subscriptionType, setSubscriptionType] = useState<ExtendedSubscriptionType>();
  const { id } = useParams();
  const { t } = useTranslation("subscription_type");

  if (!id) {
    navigate('/subscription-types');
    return null;
  }

  useEffect((): void => {
    getSubscriptionType(Number(id))
      .then((oldSubscriptionType): void => {
        setSubscriptionType(oldSubscriptionType);
      })
      .catch((error): void => {
        toast.error(error.message);
        navigate('/subscription-types');
      });
  }, []);

  const onSubmit = (updateModel: SubscriptionTypeUpdateDto): void => {
    updateSubscriptionType(Number(id), updateModel).then((): void => {
      toast.success(t("updated"));
      navigate('/subscription-types');
    }).catch((error): void => {
      toast.error(error.message);
    });
  };

  if (!subscriptionType) return null;

  return (
    <SubscriptionTypeForm initialState={subscriptionType} onSubmit={onSubmit} formType="update" />
  );
}

import { Day, ExtendedSlot, Slot } from '../types/slots';
import { availableCapacityFilters, dayFilterOptions, DayGroupFilters } from '../types/filtering';

export function daysToSlots(days: Day[]): Slot[] {
  return days.flatMap((day): Slot[] => day.slots);
}

export function daysToSlotIds(days: Day[]): number[] {
  return days.flatMap((day): number[] => day.slots.map((s): number => s.id));
}

export function daysToSortedExtendedSlots(days: Day[]): ExtendedSlot[] {
  return days.flatMap((d): Slot[] => d.slots)
    .sort((a, b): number => new Date(a.startTime).getTime() - new Date(b.startTime).getTime())
    .map((s): ExtendedSlot => {
      const currentDate = new Date(s.startTime);
      return {
        ...s,
        dateId: currentDate.toLocaleString(undefined, { dateStyle: 'short' }),
        slotId: `${currentDate.toLocaleString(undefined, { timeStyle: 'short' })}-${new Date(s.endTime).toLocaleString(undefined, { timeStyle: 'short' })}`,
        visible: true,
        weekday: dayFilterOptions[currentDate.getDay() === 0 ? 6 : currentDate.getDay() - 1],
      };
    });
}

export function uniqueMap<T, K>(array: T[], uniqueSelector: (x: T) => K): K[] {
  return array.map(uniqueSelector)
    .filter((e, i, arr): boolean => arr.indexOf(e) === i);
}

export function isSlotInAnActiveColumnOrRow(s: ExtendedSlot, filters: DayGroupFilters): boolean {
  return (filters.dayFilter.length === 0 || filters.dayFilter.includes(s.weekday))
    && (filters.timeSlotIds.length === 0 || filters.timeSlotIds.includes(s.slotId))
    && (filters.totalCapacityFilter.length === 0 || filters.totalCapacityFilter.includes(s.capacity))
    && (filters.availableCapacityFilter.length === 0 || filters.availableCapacityFilter.some((cf): boolean =>
      availableCapacityFilters[cf].low <= s.availableCapacity && s.availableCapacity <= availableCapacityFilters[cf].high));
}

export function isColumnEmpty(extendedSlots: ExtendedSlot[], slot: string, filters: DayGroupFilters): boolean {
  return extendedSlots
    .filter((s): boolean => s.slotId === slot && isSlotInAnActiveColumnOrRow(s, filters)).length === 0;
}

export function getCommonCapacity(extendedSlots: ExtendedSlot[], cellSelectionState: Set<number>): number | undefined {
  const selectedSlots = extendedSlots.filter((s): boolean => cellSelectionState.has(s.id));
  const capacities = new Set(selectedSlots.map((s): number => s.capacity));

  if (capacities.size === 1) {
    return Array.from(capacities)[0];
  }

  return undefined;
}

import React from 'react';
import { useTranslation } from 'react-i18next';
import { toUpper } from 'lodash';

type UploadFieldProps = {
  isFileUploaded: boolean;
  handleFileSelection: (event: React.ChangeEvent<HTMLInputElement>) => void;
  translation: string;
  fileTypes: string[]
}

export default function UploadField({ isFileUploaded, handleFileSelection, translation, fileTypes }: UploadFieldProps): JSX.Element {
  const { t } = useTranslation(translation);
  return <div
    className={`mt-10 flex items-center justify-center w-full ${isFileUploaded ? 'bg-green-100 dark:bg-green-700' : ''}`}>
    <label
      htmlFor="dropzone-file"
      className={`flex flex-col items-center justify-center w-full h-40 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-gray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 ${
        isFileUploaded ? 'border-green-500' : ''
      }`}
    >
      <div className="flex flex-col items-center justify-center pt-5 pb-6 ">
        <svg
          className={`w-8 h-8 mb-4 ${isFileUploaded ? 'text-green-500' : 'text-gray-500 dark:text-gray-400'}`}
          aria-hidden="true"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 20 16"
        >
          <path
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
          />
        </svg>
        <p className={`mb-2 text-sm ${isFileUploaded ? 'text-green-500' : 'text-gray-500 dark:text-gray-400'}`}>
          {isFileUploaded ? t("uploaded") : t("upload")}
        </p>
        <p className={`text-xs ${isFileUploaded ? 'text-green-500' : 'text-gray-500 dark:text-gray-400'}`}>
          {fileTypes.map(toUpper).join(',')}
        </p>
      </div>
      <input id="dropzone-file" type="file" className="hidden" onChange={handleFileSelection} accept={fileTypes.map(fileType => `.${fileType}`).join('|')} />
    </label>
  </div>
}

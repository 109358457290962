import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TbCurrencyEuro } from 'react-icons/tb';
import PrefixedTextInputWithLabel from './PrefixedTextInputWithLabel';
import Button from '../buttons/Button';
import { Purchase, RefundPaymentData, ReservationStatus } from '../../types/payment';
import { DEFAULT_CANCELLATION_FEE } from '../../constants';
import { CheckboxOption, Permissions } from '../../types/misc';
import CheckboxList from './CheckboxList';
import usePermissions from '../../hooks/selectors/usePermissions';
import { formatCurrency } from '../../utils/currencyUtil';

type RefundPaymentProps = {
  onSubmit: (refundPaymentData: RefundPaymentData) => void;
  onCancel: () => void;
  purchases: Purchase[],
}

type RefundPaymentFormState = {
  selectedPurchases: string[],
  cancellationFee: string
}

function RefundPaymentForm({
  onSubmit,
  onCancel,
  purchases,
}: RefundPaymentProps): JSX.Element {
  if (!usePermissions([Permissions.ManagePayments])) {
    return (<></>);
  }
  const { t } = useTranslation("refund_payment_form");
  const [refundDisabled, setRefundDisabled] = useState<boolean>(true);
  const [refundPayment, setRefundPayment] = useState<RefundPaymentFormState>({
    selectedPurchases: [],
    cancellationFee: DEFAULT_CANCELLATION_FEE.toFixed(2)
  });

  const filteredPurchases = purchases.filter(p => p.refundPaymentId === null);
  const checkboxOptions: CheckboxOption[] = filteredPurchases.map(p => {
    const status = p.extendedPurchaseData.reservation?.status === ReservationStatus.USED ? `(${t("ticket_used")})` : "";
    const label = `${formatCurrency(p.price)} | ${p.extendedPurchaseData.reservation?.ticketTypeOnSlot.ticketType.name ?? p.extendedPurchaseData.subscription?.subscriptionType.name} ${status}`
    return ({ 
      label,
      description: p.extendedPurchaseData.reservation?.qrCode.code ?? "",
      value: p.id.toString() 
    })
  });

  const handleCheckboxChange = (value: string) : void => {
    setRefundPayment((prevData) => {
      if (prevData.selectedPurchases.includes(value)) {
        const updatedPurchases = prevData.selectedPurchases.includes(value)
        ? prevData.selectedPurchases.filter(option => option !== value)
        : [...prevData.selectedPurchases, value];

        return {
          ...prevData,
          selectedPurchases: updatedPurchases
        };
      }
      return {
        ...prevData,
        selectedPurchases: [...prevData.selectedPurchases, value],
      };
    });
  };

  useEffect(() => {
    setRefundDisabled(refundPayment.selectedPurchases.length === 0);
  }, [refundPayment.selectedPurchases]);

  const handleSelectAll = () : void => {
    const allOptions = checkboxOptions.map(option => option.value);
    setRefundPayment((prevData) => ({
      ...prevData,
      selectedPurchases: allOptions,
    }));
  };

  const handleUnselectAll = () : void => {
    setRefundPayment((prevData) => ({
      ...prevData,
      selectedPurchases: [],
    }));
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {
    setRefundPayment((prev): RefundPaymentFormState => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    onSubmit({
      cancellationFee: parseFloat(refundPayment.cancellationFee),
      purchases: refundPayment.selectedPurchases.map(d => Number(d)),
    });
  };

  const formatNumber = (): void => setRefundPayment((prevState) => ({
    ...prevState,
    cancellationFee: parseFloat(prevState.cancellationFee).toFixed(2),
  }));

  return (
    <>
      <h2 className="text-2xl font-bold text-center mb-4">{t("title")}</h2>
      <form className="flex flex-col gap-4 max-w-3xl m-auto" onSubmit={handleSubmit}>
        <h3>{t("choose_purchases")}</h3>
        <CheckboxList 
          options={checkboxOptions} 
          selectedOptions={refundPayment.selectedPurchases} 
          onChange={handleCheckboxChange}
          onSelectAll={handleSelectAll}
          onUnselectAll={handleUnselectAll}
         />

        <PrefixedTextInputWithLabel label={t("cancellation_fee")}
                                  prefix={<TbCurrencyEuro size={20} strokeWidth={2.25} color="#303030" />}
                                  id="cancellationFee" name="cancellationFee" value={refundPayment.cancellationFee} type="number"
                                  onChange={handleChange} placeholder={t("cancellation_fee_placeholder")} className="bg-white"
                                  required onBlur={formatNumber} min={0} step={0.01} />

        <p className='text-sm text-red-500'>*{t("attention_duplicate")}</p>

        <div className="flex justify-end gap-2 my-4">
          <Button type="button" className="bg-gray-500" onClick={onCancel}>
            {t("cancel_button")}
          </Button>
          <Button type="submit" disabled={refundDisabled}>{t("send_button")}</Button>
        </div>
      </form>
    </>
  );
}

export default RefundPaymentForm;

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import TicketShopForm from '../../components/forms/TicketShopForm';
import { TicketShopDTO } from '../../types/tickets';
import { createTicketShop } from '../../services/ticketShopService';

export default function TicketShopsCreatePage(): JSX.Element {
  const navigate = useNavigate();
  const { t } = useTranslation("ticket_shop");

  const onSubmit = (ticketShop: TicketShopDTO): void => {
    createTicketShop(ticketShop)
      .then((): void => {
        toast.success(t("created"));
        navigate('/ticket-shops');
      })
      .catch((error): void => {
        toast.error(`${t("failed_create")}: ${error}`);
      });
  };

  return (
    <TicketShopForm onSubmit={onSubmit} />
  );
}

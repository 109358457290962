import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { TbDownload, TbEditCircle } from 'react-icons/tb';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import {
  DateIdentifier,
  Day,
  ExtendedSlot,
  Slot,
  SlotsUpdateDto,
  TicketTypeWithSlotsAndPricing,
} from '../../types/slots';
import HeroButton from '../buttons/HeroButton';
import EditSlotsModal from '../modals/EditSlotsModal';
import { downloadExcelReportForSlots, updateSlots, updateTicketTypesOnSlots } from '../../services/slotService';
import useDayGroups from '../../hooks/selectors/useDayGroups';
import { store } from '../../redux/store';
import { setDayGroups } from '../../redux/slices/dayGroupSlice';
import CapacityGroupEditorFilters from '../misc/CapacityGroupEditorFilters';
import { DayGroupFilters, initialDayGroupFilters } from '../../types/filtering';
import {
  daysToSlotIds,
  daysToSortedExtendedSlots,
  getCommonCapacity,
  isColumnEmpty,
  isSlotInAnActiveColumnOrRow,
  uniqueMap,
} from '../../utils/dayGroupUtils';
import useTableSelection from '../../hooks/useTableSelection';

export default function CapacityGroupEditor(): JSX.Element {
  const [isOpen, setOpen] = React.useState(false);
  const [filters, setFilters] = React.useState<DayGroupFilters>({ ...initialDayGroupFilters });
  const [extendedSlots, setExtendedSlots] = useState<ExtendedSlot[]>([]);
  const days = useDayGroups();
  const { id: capacityGroupId } = useParams();
  const {
    cellSelectionState,
    setCellSelectionState,
    toggleCell,
    toggleRow,
    toggleAll,
    toggleColumn,
  } = useTableSelection(extendedSlots, filters);
  const { t } = useTranslation('capacity_group_editor');

  useEffect((): void => {
    // remove filtered out content from the selection
    setCellSelectionState((prevState): Set<number> => {
      return new Set(Array.from(prevState)
        .filter((x): boolean => {
          const extSlot = extendedSlots.find((s): boolean => s.id === x);
          return daysToSlotIds(days).includes(x) && (extSlot ? isSlotInAnActiveColumnOrRow(extSlot, filters) : false);
        }));
    });
  }, [days, filters]);

  useEffect((): void => {
    setExtendedSlots(daysToSortedExtendedSlots(days));
  }, [days]);

  const slotsByDate = useMemo((): Record<string, ExtendedSlot> =>
    extendedSlots.reduce((prev, curr): Record<string, ExtendedSlot> => ({
      ...prev,
      [`${curr.dateId}_${curr.slotId}`]: curr,
    }), {}), [extendedSlots, filters.dayFilter]);

  const uniqueSlots = useMemo((): string[] => {
    const uniqueSlotIds = uniqueMap(extendedSlots.sort((a, b) => {
      const dateA = new Date(a.startTime);
      const dateB = new Date(b.startTime);

      return (dateA.getHours() * 60 + dateA.getMinutes()) - (dateB.getHours() * 60 + dateB.getMinutes());
    }), (s): string => s.slotId);

    if (filters.timeSlotIds.length === 0) {
      return uniqueSlotIds;
    }

    return uniqueSlotIds.filter((slotId): boolean => filters.timeSlotIds.includes(slotId));
  }, [extendedSlots, filters.timeSlotIds]);

  const uniqueDates = useMemo((): DateIdentifier[] => {
    const dateTuples = extendedSlots
      .map(({ dateId, weekday, startTime }): DateIdentifier => ({
        dateId,
        weekday,
        startTime,
      }));

    const uniqueDateTuples = Array.from(
      new Map(dateTuples.map((d): [string, DateIdentifier] => [d.dateId, d])).values(),
    ).sort((a, b): number =>
      new Date(a.startTime).getTime() - new Date(b.startTime).getTime());

    if (filters.dayFilter.length === 0) {
      return uniqueDateTuples;
    }

    return uniqueDateTuples.filter((id): boolean => filters.dayFilter.includes(id.weekday));
  }, [extendedSlots, filters.dayFilter]);

  const totalCapacities = useMemo((): number[] => {
    return uniqueMap(extendedSlots, (slot): number => slot.capacity);
  }, [extendedSlots]);

  const onSubmit = (updateDto: SlotsUpdateDto, pricingData?: TicketTypeWithSlotsAndPricing[]): void => {
    if (!capacityGroupId) {
      toast.error(t('no_id_present'));
      setOpen(false);
      return;
    }

    Promise.all([updateSlots(+capacityGroupId, updateDto), pricingData ? updateTicketTypesOnSlots(+capacityGroupId, pricingData) : undefined])
      .then(([newSlots]): void => {
        toast.success(t('updated'));
        store.dispatch(setDayGroups(days.map((d): Day => ({
          ...d,
          slots: d.slots.map((s): Slot => {
            const newSlot = newSlots.find((ns): boolean => ns.id === s.id);
            return newSlot ? { ...s, ...newSlot } : s;
          }),
        }))));

        setCellSelectionState(new Set());
      })
      .catch((_): void => {
        toast.error(t('update_error'));
      })
      .finally((): void => setOpen(false));
  };

  const downloadExcel = (): void => {
    downloadExcelReportForSlots(Array.from(cellSelectionState))
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'report.xlsx');
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      });
  }

  return (
    <main className="w-full">
      <div className="flex justify-between items-center my-2">
        <h1 className="text-2xl font-bold">{t('title')}</h1>
        <div className="flex justify-between items-center my-2">
          <HeroButton
            onClick={(): void => setOpen(true)}
            className="text-lg disabled:opacity-50 disabled:transition-none disabled:bg-none disabled:bg-gray-400 disabled:hover:shadow-none"
            disabled={cellSelectionState.size === 0}
            label={cellSelectionState.size > 0 ? t('edit_items', { size: cellSelectionState.size }) : t('select_slots_for_update')}
            icon={TbEditCircle} />
          <HeroButton
            onClick={(): void => downloadExcel()}
            className="text-lg disabled:opacity-50 disabled:transition-none disabled:bg-none disabled:bg-gray-400 disabled:hover:shadow-none ml-1"
            disabled={cellSelectionState.size === 0}
            label={cellSelectionState.size > 0 ? t('download_items', { size: cellSelectionState.size }) : t('select_slots_for_download')}
            icon={TbDownload} />
        </div>
      </div>
      <div className="flex gap-1">
        <CapacityGroupEditorFilters totalCapacities={totalCapacities} filters={filters} setFilters={setFilters}
                                    slotIds={uniqueMap(extendedSlots, (s): string => s.slotId)} />
        <div className="overflow-x-auto flex-[3] bg-gray-200 h-fit">
          <table className="table-auto w-full text-sm">
            <thead
              className="bg-[#1C2434] text-white"
            >
            <tr>
              <th>
                <p>{t('date_slots')}</p>
                {extendedSlots.filter((s): boolean => isSlotInAnActiveColumnOrRow(s, filters)).length > 0
                  ? (
                    <input type="checkbox"
                           checked={extendedSlots.filter((s): boolean => isSlotInAnActiveColumnOrRow(s, filters)).every((extSlot): boolean => cellSelectionState.has(extSlot.id))}
                           onChange={(ev): void => toggleAll(ev)} />
                  )
                  : <p className="font-normal">{t('no_results')}</p>}
              </th>
              {uniqueSlots.map((slot): JSX.Element => {
                const validSlots = extendedSlots
                  .filter((s): boolean => s.slotId === slot && isSlotInAnActiveColumnOrRow(s, filters));

                if (validSlots.length === 0) {
                  return <></>;
                }

                return (
                  <th className="p-2" key={slot}>
                    <p>{slot}</p>
                    <input type="checkbox"
                           checked={validSlots
                             .every((extSlot): boolean => cellSelectionState.has(extSlot.id))}
                           onChange={(ev): void => toggleColumn(ev, slot)} />
                  </th>
                );
              })}
            </tr>
            </thead>
            <tbody>
            {uniqueDates.map((date): JSX.Element => (
              <DateRow key={date.dateId} date={date} filters={filters} extendedSlots={extendedSlots}
                       toggleRow={toggleRow} toggleCell={toggleCell} cellSelectionState={cellSelectionState}
                       uniqueSlots={uniqueSlots} slotsByDate={slotsByDate} />
            ))}
            </tbody>
          </table>
        </div>
      </div>
      <EditSlotsModal isOpen={isOpen} setOpen={setOpen} onSubmit={onSubmit} slotIds={Array.from(cellSelectionState)}
                      initialCapacity={getCommonCapacity(extendedSlots, cellSelectionState)} />
    </main>
  );
}

type DateRowProps = {
  date: DateIdentifier;
  extendedSlots: ExtendedSlot[];
  filters: DayGroupFilters;
  cellSelectionState: Set<number>;
  uniqueSlots: string[];
  toggleRow: (ev: ChangeEvent<HTMLInputElement>, dateId: string) => void;
  toggleCell: (ev: ChangeEvent<HTMLInputElement>, cell: ExtendedSlot) => void;
  slotsByDate: Record<string, ExtendedSlot>
}

function DateRow(
  {
    date,
    extendedSlots,
    filters,
    cellSelectionState,
    toggleCell,
    toggleRow,
    uniqueSlots,
    slotsByDate,
  }: DateRowProps): JSX.Element {
  const filteredSlots = extendedSlots
    .filter((extSlot): boolean => extSlot.dateId === date.dateId && isSlotInAnActiveColumnOrRow(extSlot, filters));

  const { t } = useTranslation('capacity_group_editor');

  if (filteredSlots.length === 0) {
    return <></>;
  }

  return (
    <tr
      className="hover:bg-gray-300 duration-300 transition-all"
      key={date.dateId}
    >
      <td className="text-center">
        <p>{date.dateId} ({t(date.weekday)})</p>
        <input type="checkbox"
               checked={filteredSlots
                 .every((extSlot): boolean => cellSelectionState.has(extSlot.id))}
               onChange={(ev): void => toggleRow(ev, date.dateId)}
        />
      </td>
      {uniqueSlots.map((slotId): JSX.Element|null => {
        const slotCell: ExtendedSlot | undefined = slotsByDate[`${date.dateId}_${slotId}`];

        if (isColumnEmpty(extendedSlots, slotId, filters)) {
          return null;
        }

        // there is some other entry in the column, therefore we may need to render an empty cell
        if (!slotCell || !isSlotInAnActiveColumnOrRow(slotCell, filters)) {
          return <td key={`${date.dateId}_${slotId}`} />;
        }

        return (
          <td key={`${date.dateId}_${slotId}`} className="p-2 text-center">
            <div>
              <p>
                <span className={`mr-1 ${slotCell.active ? 'text-green-500' : 'text-red-500'}`}>•</span>
                {slotCell.availableCapacity} / {slotCell.capacity}
              </p>
              <input checked={cellSelectionState.has(slotCell.id)} type="checkbox"
                     onChange={(ev): void => toggleCell(ev, slotCell)} />
            </div>
          </td>
        );
      })}
    </tr>
  );
}

import httpService from './httpService';
import { store } from '../redux/store';
import { Layout, LayoutDto, LayoutWithTemplate } from '../types/layout';

export async function getLayoutsForCurrentLocation(offset: number, limit: number): Promise<Layout[]> {
  const locationId = store.getState().locations.selectedLocation;

  return httpService
    .get<Layout[]>(`/api/locations/${locationId}/layouts?offset=${offset}&limit=${limit}`)
    .then((response): Layout[] => {
      return response.data;
    })
}

export async function deleteLayoutForLocation(layoutId: number): Promise<Layout> {
  const locationId = store.getState().locations.selectedLocation;

  return httpService
    .delete<Layout>(`/api/locations/${locationId}/layouts/${layoutId}`)
    .then((response): Layout => response.data);
}

export async function getDummyPdfForLayout(layoutId: number): Promise<Buffer> {
  const locationId = store.getState().locations.selectedLocation;

  return httpService
    .get<Buffer>(`/api/locations/${locationId}/layouts/${layoutId}/pdf`, {headers: {'Content-Type': 'application/json'}, responseType: 'arraybuffer'})
    .then((response): Buffer => response.data);
}

export async function createLayoutForLocation(data: LayoutDto): Promise<Layout> {
  const locationId = store.getState().locations.selectedLocation;

  return httpService
    .post<Layout>(`/api/locations/${locationId}/layouts`, data)
    .then((response): Layout => response.data);
}

export async function editLayoutForLocation(data: LayoutDto, layoutId: number): Promise<Layout> {
  const locationId = store.getState().locations.selectedLocation;

  return httpService
    .put<Layout>(`/api/locations/${locationId}/layouts/${layoutId}`, data)
    .then((response): Layout => response.data)
}

export async function getLayoutForLocation(layoutId: number): Promise<LayoutWithTemplate> {
  const locationId = store.getState().locations.selectedLocation;

  return httpService
    .get<LayoutWithTemplate>(`/api/locations/${locationId}/layouts/${layoutId}`)
    .then((response): LayoutWithTemplate => response.data);
}
import React from 'react';
import { Menu, MenuButton, MenuItem, MenuItems } from '@headlessui/react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TbChevronDown, TbCircleKey, TbLogout, TbUser, TbUserX } from 'react-icons/tb';
import { logout } from '../../services/authService';
import useCurrentUser from '../../hooks/selectors/useCurrentUser';
import Button from '../buttons/Button';

export default function UserMenu(): JSX.Element {
  const { t } = useTranslation('default_layout');
  const user = useCurrentUser();

  return (
    <Menu>
      <MenuButton className="flex items-center min-w-60 hover:bg-gray-200 rounded-lg p-3 gap-1">
        <p>{user?.email || <TbUserX />}</p>
        <TbChevronDown />
      </MenuButton>
      <MenuItems anchor="bottom" className="bg-gray-200 p-4 flex flex-col gap-2 z-[51] mt-2 min-w-64 origin-top-right rounded-xl transition duration-100 ease-out [--anchor-gap:var(--spacing-1)] focus:outline-none data-[closed]:scale-95 data-[closed]:opacity-0">
        <MenuItem>
          <Link to="/account"><Button type="button" className="w-full p-1.5 bg-sb-dark-blue items-center gap-1"><TbUser />{t('account')}</Button></Link>
        </MenuItem>
        <MenuItem>
          <Link to="/forgotten-password"><Button type="button" className="w-full p-1.5 bg-sb-dark-blue items-center gap-1"><TbCircleKey />{t('reset_password')}</Button></Link>
        </MenuItem>
        <hr className="bg-sb-pink border border-gray-300 my-0.5" />
        <MenuItem>
          <Button type="button" onClick={logout} className="w-full p-1.5 bg-sb-pink flex items-center gap-1"><TbLogout />{t('logout')}</Button>
        </MenuItem>
      </MenuItems>
    </Menu>
  );
}

import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { RadioGroup } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { zip } from 'lodash';
import ModalLayout from '../layouts/ModalLayout';
import Button from '../buttons/Button';
import TextInputWithLabel from '../forms/TextInputWithLabel';
import { SlotsUpdateDto, TicketTypeWithSlotsAndPricing } from '../../types/slots';
import SelectWithData from '../forms/SelectWithData';
import useTicketTypesOnSlots from '../../hooks/data-fetching/useTicketTypesOnSlots';
import usePricingPolicies from '../../hooks/selectors/usePricingPolicies';
import { store } from '../../redux/store';
import { getPricingPoliciesForCurrentLocation } from '../../services/pricingPoliciesService';
import { setPricingPolicies } from '../../redux/slices/pricingPoliciesSlice';
import { PricingPolicy } from '../../types/pricingPolicies';
import RadioCard from '../forms/RadioCard';
import RadioTab from '../forms/RadioTab';

type EditSlotsModalProps = {
  isOpen: boolean;
  setOpen: (open: boolean) => void;
  onSubmit: (updateDto: SlotsUpdateDto, pricingData?: TicketTypeWithSlotsAndPricing[]) => void;
  slotIds: number[];
  initialCapacity?: number;
}

export default function EditSlotsModal
({
   isOpen,
   setOpen,
   slotIds,
   onSubmit,
   initialCapacity,
 }: EditSlotsModalProps): JSX.Element | null {
  if (!isOpen) return null;

  const [active, setActive] = React.useState<boolean | null>(null);
  const [capacity, setCapacity] = React.useState<number | undefined>(initialCapacity);
  const [noChangesInPricingPolicies, setNoChangesInPricingPolicies] = useState<boolean>(true);
  const { t } = useTranslation('capacity_group_editor');
  const [editData, setEditData] = React.useState<TicketTypeWithSlotsAndPricing[]>([]);

  const handleSubmit = (): void => {
    const updateDto = { slotIds, update: { ...(active !== null ? { active } : { active: undefined }), capacity } };
    onSubmit(updateDto, noChangesInPricingPolicies ? undefined : editData);
  };

  // update capacity and isActive
  return (
    <ModalLayout
      open={isOpen}
      setOpen={setOpen}
      closeButton
      className="bg-white max-w-3xl rounded-3xl p-12">
      <div className="flex flex-col">
        <h3 className="text-xl font-bold">{t('update')}</h3>
        <p className="text-gray-600">{t('update_helper_message', { size: slotIds.length })}</p>
        <form className="mt-4"
              onSubmit={(ev) => {
                ev.preventDefault();
                handleSubmit();
              }}>
          <TextInputWithLabel label={t('capacity_label')} id="capacity" min={0} value={capacity}
                              onChange={(ev) => setCapacity(parseInt(ev.target.value, 10) || undefined)}
                              type="number"
                              placeholder={initialCapacity !== undefined ? t('capacity_placeholder') : t('capacity_placeholder_multiple_values')}
          />
          <div className="mt-4">
            <p className="text-base">{t('status_label')}</p>
            <RadioGroup className="flex flex-col gap-2" value={active} onChange={(value) => setActive(value)}>
              <RadioTab label={t('no_change_status')} value={null} />
              {/* eslint-disable-next-line react/jsx-boolean-value */}
              <RadioTab label={t('all_active')} value={true} />
              <RadioTab label={t('all_inactive')} value={false} />
            </RadioGroup>
          </div>
          <PricingPoliciesEditor slotIds={slotIds} editData={editData} setEditData={setEditData}
                                 onNumberOfChangesChange={(newChanges) => setNoChangesInPricingPolicies(newChanges === 0)} />
          <div className="flex justify-end gap-2 mt-8">
            <Button className="bg-gray-600" onClick={(): void => setOpen(false)}>{t('cancel')}</Button>
            <Button
              disabled={active === null && (capacity === undefined || capacity === initialCapacity) && noChangesInPricingPolicies}
              className="bg-purple-600"
              type="submit">{t('confirm_update')}</Button>
          </div>
        </form>
      </div>
    </ModalLayout>
  );
}

type PricingPoliciesEditorProps = {
  slotIds: number[];
  editData: TicketTypeWithSlotsAndPricing[];
  setEditData: Dispatch<SetStateAction<TicketTypeWithSlotsAndPricing[]>>;
  onNumberOfChangesChange?: (changes: number) => void;
}

function PricingPoliciesEditor({ slotIds, editData, setEditData, onNumberOfChangesChange }: PricingPoliciesEditorProps): JSX.Element {
  const { id: capacityGroupId } = useParams();
  const { data: ticketTypesOnSlots } = useTicketTypesOnSlots(Number(capacityGroupId), slotIds);
  const { t } = useTranslation('capacity_group_editor');

  useEffect(() => {
    getPricingPoliciesForCurrentLocation(0, Number.MAX_SAFE_INTEGER)
      .then((pps) => store.dispatch(setPricingPolicies(pps)))
      .catch(() => toast.error(t('error_loading_pricing_policies')));
  }, []);

  useEffect(() => {
    setEditData(ticketTypesOnSlots);
  }, [ticketTypesOnSlots]);

  const onChange = (updateDto: TicketTypeWithSlotsAndPricing): void => {
    setEditData((prev) => {
      return prev.map((tt) => tt.ticketTypeId === updateDto.ticketTypeId ? updateDto : tt);
    });
  };

  const changes = zip(ticketTypesOnSlots, editData)
    .map(([ticketType, edit]): number => (ticketType?.pricingPolicyType === edit?.pricingPolicyType && ticketType?.pricingPolicyId === edit?.pricingPolicyId) ? 0 : 1)
    .reduce((acc, curr) => acc + curr, 0);

  useEffect(() => {
    onNumberOfChangesChange?.(changes);
  }, [changes]);

  return (
    <div>
      <h3 className="mt-4 text-lg font-semibold">{t('pricing_policies')}</h3>
      <h4 className="text-purple-600 text-sm mb-2">{t('unsaved_changes', { changes })}</h4>
      <div className="flex flex-col gap-2">
        {ticketTypesOnSlots.map((ticketType) => (
          <PricingPolicyRow key={ticketType.ticketTypeId} ticketType={ticketType} onChange={onChange} />
        ))}
      </div>
    </div>
  );
}

type PricingPolicyRowProps = {
  ticketType: TicketTypeWithSlotsAndPricing;
  onChange: (updateDto: TicketTypeWithSlotsAndPricing) => void;
}

function PricingPolicyRow({ ticketType, onChange }: PricingPolicyRowProps): JSX.Element {
  const [policyDto, setPolicyDto] = React.useState<TicketTypeWithSlotsAndPricing>({
    ticketTypeId: ticketType.ticketTypeId,
    ticketTypeName: ticketType.ticketTypeName,
    slotIds: ticketType.slotIds,
    pricingPolicyType: !ticketType.pricingPolicyType ? 'STANDARD' : ticketType.pricingPolicyType,
    pricingPolicyId: ticketType.pricingPolicyId || undefined,
  });

  const { t } = useTranslation('capacity_group_editor');
  const pricingPolicies: PricingPolicy[] = usePricingPolicies();

  useEffect(() => {
    onChange(policyDto);
  }, [policyDto]);

  return (
    <div className="flex items-center gap-4">
      <p className="flex-1">{ticketType.ticketTypeName}</p>
      <RadioGroup value={policyDto.pricingPolicyType} onChange={(pricingPolicyType) => setPolicyDto(prev => {
        return { ...prev, pricingPolicyType };
      })} className="flex gap-2">
        <RadioCard label={t('standard').toUpperCase()} value="STANDARD" />
        <RadioCard disabled={pricingPolicies.length === 0} label={t('dynamic').toUpperCase()} value="DYNAMIC" />
      </RadioGroup>
      <SelectWithData
        containerClassName={`flex-1 ${policyDto.pricingPolicyType !== 'DYNAMIC' ? 'invisible' : ''}`}
        className="focus:border-purple-500 active:border-purple-500 py-1"
        value={policyDto.pricingPolicyId || ''}
        onChange={(ev) => setPolicyDto({
          ...policyDto,
          pricingPolicyId: parseInt(ev.target.value, 10),
        })}
        data={pricingPolicies.map((pp) => ({ name: pp.name, id: pp.id }))}
        label="" placeholder={t('pricing_policy_placeholder')} required={policyDto.pricingPolicyType === 'DYNAMIC'}
      />
    </div>
  );
}

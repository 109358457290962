import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { RotterdampasTable } from '../../components/tables/RotterdampasTable';
import { ConfirmationEmail, Payment, RefundPaymentData } from '../../types/payment';
import { postEmailConfirmation, postPaymentRefund } from '../../services/paymentService';
import PaymentStatusColorBar from '../../components/misc/PaymentStatusColorBar';
import { getSelectedLocation } from '../../services/locationService';
import Button from '../../components/buttons/Button';
import { downloadReservations } from '../../services/reservationService';
import { PaymentStatus } from '../../types/filter';
import EmailConfirmationModal from '../../components/modals/EmailConfirmationModal';
import RefundPaymentModal from '../../components/modals/RefundModal';
import { parseToYYYYMMDDHHmm } from '../../utils/dateUtil';
import usePermissions from '../../hooks/selectors/usePermissions';
import { Permissions } from '../../types/misc';
import PurchaseTable from '../../components/tables/PurchaseTable';

interface PaymentTicketsDetailsPageProps {
  payment: Payment,
  fetchPaymentData: (paymentId: number) => void
}

export default function PaymentsTicketDetailsPage({ payment, fetchPaymentData }: PaymentTicketsDetailsPageProps): JSX.Element | null {
  if (!usePermissions([Permissions.ViewConfigurations]) && !usePermissions([Permissions.ManagePayments])) {
    return (<></>);
  }
  const [emailConfirmationModalOpen, setEmailConfirmationModalOpen] = React.useState<boolean>(false);
  const [refundPaymentModalOpen, setRefundPaymentModalOpen] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const { t } = useTranslation("payments");

  const handleEmailConfirmation = (emailConfirmation : ConfirmationEmail): void => {
    setEmailConfirmationModalOpen(false);
    postEmailConfirmation(payment.id, emailConfirmation)
      .then((): void => {
        toast.success(t("email_send"));
      })
      .catch((error): void => {
        toast.error(error.message);
      });
  };

  const handleRefundPayment = (refundPaymentData: RefundPaymentData): void => {
    setRefundPaymentModalOpen(false);
    postPaymentRefund(payment.id, refundPaymentData)
      .then((): void => {
        toast.success(t("payment_refunded"));
        fetchPaymentData(payment.id);
      })
      .catch((error): void => {
        toast.error(error.message);
      });
  };

  const reservationsUuids = payment.purchases.map(d => d.extendedPurchaseData.reservation?.qrCode.code).filter((code): code is string => code !== undefined);;
  const downloadTickets = async (): Promise<void> => {
    if (!reservationsUuids.length) {
      return;
    }
    const response = await downloadReservations(reservationsUuids);
    const url = window.URL.createObjectURL(new Blob([response]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', 'tickets.pdf');
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  return (
    <>
        <div className="flex justify-between items-center mb-4">
            <h2 className="font-gintobold font-bold text-2xl">{t("payment")} #{payment.id}</h2>
            <PaymentStatusColorBar label={t(payment.status)} paymentStatus={payment.status} />
        </div>
        <div className='grid grid-cols-1 md:grid-cols-3 gap-4'>
            <div className='col-span-1 bg-white rounded-lg p-5'>
                <h3 className='font-gintobold font-bold text-l mb-4'>{t("information")}</h3>
                <p>
                    <div className='mb-4'>{t("id")}: <br/> {payment.id}<br/> </div>
                    <div className='mb-4'>{t("location")}: <br/> {payment.extendedPaymentData.location.name}<br/> </div>
                    <div className='mb-4'>{t("date")}: <br/> {parseToYYYYMMDDHHmm(payment.date, getSelectedLocation()?.timezone ?? 'UTC')}</div>
                    <div className='mb-4'>{t("payment_reference")}: <br/> {payment.paymentReference}</div>
                    <div className='mb-4'>{t("payment_method")}: <br/> {payment.method}</div>
                    <div className='mb-4'>{t("user")}: <br/> {`${payment.extendedPaymentData.user.firstName} ${payment.extendedPaymentData.user.lastName}`} <br/> <a className='ont-medium text-blue-600 dark:text-blue-500 hover:underline' href={`mailto:${payment.extendedPaymentData.account.email}`}>{payment.extendedPaymentData.account.email}</a></div>
                    { payment.discounts.length > 0 && <div className='mb-4'>{t("discountcodes")}: <br/> 
                        {
                            payment.discounts.map((discount) => {
                                return <>{discount.code} <br/></>;
                            })
                        }
                    </div> }
                </p>
            </div>
            <div className='col-span-2 bg-white rounded-lg p-5'>
                <h3 className='font-gintobold font-bold text-l'>{t("purchases")}</h3>
                <div className="relative overflow-x-auto">
                    <PurchaseTable purchases={payment.purchases} bookingFee={payment.bookingFee} />
                    <RotterdampasTable purchases={payment.purchases}/>
                </div>
            </div>
            <div className='col-span-1 bg-white rounded-lg p-5'>
                <h3 className='font-gintobold font-bold text-l mb-4'>{t("actions")}</h3>
                { payment.status === PaymentStatus.PAID && <Button type="button" className="bg-sb-pink mb-4" onClick={(): void => setEmailConfirmationModalOpen(true)}>
                    {t("resend_button")}
                </Button> }
                { payment.status === PaymentStatus.PAID && <Button type="button" className="bg-sb-pink mb-4" onClick={downloadTickets}>
                    {t("download_button")}
                </Button> }
                { payment.status === PaymentStatus.PAID && <Button type="button" className="bg-sb-pink mb-4" onClick={(): void => navigate(`/payments/${payment.id}/rebook`)}>
                    {t("rebook_tickets")}
                </Button> }
                { payment.status === PaymentStatus.PAID && <Button type="button" className="bg-gray-500 mb-4" onClick={(): void => {}}>
                    TODO: Download PDF invoice
                </Button> }
                { payment.status === PaymentStatus.PAID && <Button type="button" className="bg-red-500 mb-4" onClick={(): void => setRefundPaymentModalOpen(true)}>
                    {t("refund_button")}
                </Button> }
                <EmailConfirmationModal email={payment.extendedPaymentData.account.email} open={emailConfirmationModalOpen} setOpen={setEmailConfirmationModalOpen} onSubmit={handleEmailConfirmation}/>
                <RefundPaymentModal purchases={payment.purchases} open={refundPaymentModalOpen} setOpen={setRefundPaymentModalOpen} onSubmit={handleRefundPayment}/>
            </div>
            <div className='col-span-2 bg-white rounded-lg p-5'>
                <h3 className='font-gintobold font-bold text-l'>{t("Geschiedenis")}</h3>
                <div className="relative overflow-x-auto">
                    TODO: Tabpanel for action log and scan history
                </div>
            </div>
        </div>
        <div className="flex justify-end gap-2 my-4">
            <Button type="button" className="bg-gray-500" onClick={(): void => navigate("/payments")}>
                {t("back_button")}
            </Button>
        </div>
    </>
  );
}

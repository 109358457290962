import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import DefaultLayout from '../components/layouts/DefaultLayout';
import DashboardPage from '../pages/DashboardPage';
import LoginPage from '../pages/auth/LoginPage';
import useAuth from '../hooks/selectors/useAuth';
import AuthenticatedRoute from './AuthenticatedRoute';
import RegisterPage from '../pages/auth/RegisterPage';
import CreateTicketTypePage from '../pages/ticket-types/CreateTicketTypePage';
import EditTicketTypePage from '../pages/ticket-types/EditTicketTypePage';
import TicketShopsListPage from '../pages/ticket-shops/TicketShopsListPage';
import CapacityGroupPage from '../pages/CapacityGroupPage';
import CreateCapacityGroupPage from '../pages/capacity-groups/CreateCapacityGroupPage';
import EditCapacityGroupPage from '../pages/capacity-groups/EditCapacityGroupPage';
import CreateDayGroupPage from '../pages/day-groups/CreateDayGroupPage';
import TicketShopsCreatePage from '../pages/ticket-shops/TicketShopsCreatePage';
import TicketShopsEditPage from '../pages/ticket-shops/TicketShopsEditPage';
import ScanGroupsListPage from '../pages/scan-groups/ScanGroupsListPage';
import ScanGroupsEditPage from '../pages/scan-groups/ScanGroupsEditPage';
import ScanGroupsCreatePage from '../pages/scan-groups/ScanGroupsCreatePage';
import ScannersListPage from '../pages/scanners/ScannersListPage';
import ScannerCreatePage from '../pages/scanners/ScannerCreatePage';
import ScannerEditPage from '../pages/scanners/ScannerEditPage';
import ScannerRegisterPage from '../pages/scanners/ScannerRegisterPage';
import CapacityGroupEditorPage from '../pages/capacity-groups/CapacityGroupEditorPage';
import EditDayGroupPage from '../pages/day-groups/EditDayGroupPage';
import ScanDefinitionListPage from '../pages/scan-definitions/ScanDefinitionListPage';
import ScanDefinitionCreatePage from '../pages/scan-definitions/ScanDefinitionCreatePage';
import ScanDefinitionEditPage from '../pages/scan-definitions/ScanDefinitionEditPage';
import ListTicketTypePage from '../pages/ticket-types/ListTicketTypePage';
import PricingPoliciesListPage from '../pages/pricing-policies/PricingPoliciesListPage';
import PricingPoliciesCreatePage from '../pages/pricing-policies/PricingPoliciesCreatePage';
import PricingPoliciesEditPage from '../pages/pricing-policies/PricingPoliciesEditPage';
import SubscriptionShopsListPage from '../pages/subscription-shops/SubscriptionShopsListPage';
import SubscriptionShopsCreatePage from '../pages/subscription-shops/SubscriptionShopsCreatePage';
import SubscriptionShopsEditPage from '../pages/subscription-shops/SubscriptionShopsEditPage';
import ListSubscriptionTypePage from '../pages/subscription-types/ListSubscriptionTypePage';
import PaymentsListPage from '../pages/payments/PaymentsListPage';
import AccountsPage from '../pages/accounts/AccountsPage';
import LayoutsListPage from '../pages/layouts/LayoutsListPage';
import LayoutsCreatePage from '../pages/layouts/LayoutsCreatePage';
import LayoutsEditPage from '../pages/layouts/LayoutsEditPage';
import EmailTemplatesListPage from '../pages/email-templates/EmailTemplatesListPage';
import CreateSubscriptionTypePage from '../pages/subscription-types/CreateSubscriptionTypePage';
import EditSubscriptionTypePage from '../pages/subscription-types/EditSubscriptionTypePage';
import EmailTemplatesCreatePage from '../pages/email-templates/EmailTemplatesCreatePage';
import EmailTemplatesEditPage from '../pages/email-templates/EmailTemplatesEditPage';
import PaymentsDetailsPage from '../pages/payments/PaymentsDetailsPage';
import CreateAccountPage from '../pages/accounts/CreateAccountPage';
import EditAccountPage from '../pages/accounts/EditAccountPage';
import DiscountOffersListPage from '../pages/discount-offers/DiscountOffersListPage';
import CreateDiscountOfferPage from '../pages/discount-offers/CreateDiscountOfferPage';
import EditDiscountOfferPage from '../pages/discount-offers/EditDiscountOfferPage';
import DiscountCodesListPage from '../pages/discount-offers/DiscountCodesListPage';
import TicketCategoryListPage from '../pages/ticket-categories/TicketCategoryListPage';
import TicketCategoryCreatePage from '../pages/ticket-categories/TicketCategoryCreatePage';
import TicketCategoryEditPage from '../pages/ticket-categories/TicketCategoryEditPage';
import ResetPasswordPage from '../pages/auth/ResetPasswordPage';
import ForgottenPasswordPage from '../pages/auth/ForgottenPasswordPage';
import SubscriptionCreatePage from '../pages/subscriptions/SubscriptionCreatePage';
import TicketCreatePage from '../pages/tickets/TicketCreatePage';
import PaymentsRebookPage from '../pages/payments/PaymentsRebookPage';

export default function Router(): JSX.Element {
  const auth = useAuth();
  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/forgotten-password" element={<ForgottenPasswordPage />} />
      <Route path="/register" element={<RegisterPage />} />
      <Route path="/account/set-password" element={<ResetPasswordPage />} />
      <Route path="/" element={<AuthenticatedRoute />}>
        <Route path="/" element={<DefaultLayout />}>
          <Route path="/dashboard" element={<DashboardPage />} />
          <Route path="/payments" element={<PaymentsListPage />} />
          <Route path="/payments/:id/details" element={<PaymentsDetailsPage />} />
          <Route path="/payments/:id/rebook" element={<PaymentsRebookPage />} />
          <Route path="/capacity-groups" element={<CapacityGroupPage />} />
          <Route path="/capacity-groups/create" element={<CreateCapacityGroupPage />} />
          <Route path="/capacity-groups/edit/:id" element={<EditCapacityGroupPage />} />
          <Route path="/capacity-groups/edit/:capacityGroupId/create-day-group" element={<CreateDayGroupPage />} />
          <Route path="/capacity-groups/edit/:capacityGroupId/edit-day-group/:dayGroupId" element={<EditDayGroupPage />} />
          <Route path="/capacity-groups/editor/:id" element={<CapacityGroupEditorPage />} />
          <Route path="/ticket-types" element={<ListTicketTypePage />} />
          <Route path="/ticket-types/create" element={<CreateTicketTypePage />} />
          <Route path="/ticket-types/edit/:id" element={<EditTicketTypePage />} />
          <Route path="/subscription-types" element={<ListSubscriptionTypePage />} />
          <Route path="/subscription-types/create" element={<CreateSubscriptionTypePage />} />
          <Route path="/subscription-types/edit/:id" element={<EditSubscriptionTypePage />} />
          <Route path="/subscription-shops" element={<SubscriptionShopsListPage />} />
          <Route path="/subscription-shops/create" element={<SubscriptionShopsCreatePage />} />
          <Route path="/subscription-shops/edit/:id" element={<SubscriptionShopsEditPage />} />
          <Route path="/ticket-shops" element={<TicketShopsListPage />} />
          <Route path="/ticket-shops/create" element={<TicketShopsCreatePage />} />
          <Route path="/ticket-shops/edit/:id" element={<TicketShopsEditPage />} />
          <Route path="/ticket-categories" element={<TicketCategoryListPage />} />
          <Route path="/ticket-categories/create" element={<TicketCategoryCreatePage />} />
          <Route path="/ticket-categories/edit/:id" element={<TicketCategoryEditPage />} />
          <Route path="/scan-groups" element={<ScanGroupsListPage />} />
          <Route path="/scan-groups/create" element={<ScanGroupsCreatePage />} />
          <Route path="/scan-groups/edit/:id" element={<ScanGroupsEditPage />} />
          <Route path="/scanners" element={<ScannersListPage />} />
          <Route path="/scanners/create" element={<ScannerCreatePage />} />
          <Route path="/scanners/register/:id" element={<ScannerRegisterPage />} />
          <Route path="/scanners/edit/:id" element={<ScannerEditPage />} />
          <Route path="/scan-definitions" element={<ScanDefinitionListPage />} />
          <Route path="/scan-definitions/create" element={<ScanDefinitionCreatePage />} />
          <Route path="/scan-definitions/edit/:id" element={<ScanDefinitionEditPage />} />
          <Route path="/pricing-policies" element={<PricingPoliciesListPage />} />
          <Route path="/pricing-policies/create" element={<PricingPoliciesCreatePage />} />
          <Route path="/pricing-policies/edit/:id" element={<PricingPoliciesEditPage />} />
          <Route path="/tickets/create" element={<TicketCreatePage />} />
          <Route path="/subscriptions/create" element={<SubscriptionCreatePage />} />
          <Route path="/accounts" element={<AccountsPage />} />
          <Route path="/accounts/create" element={<CreateAccountPage />} />
          <Route path="/accounts/edit/:id" element={<EditAccountPage />} />
          <Route path="/layouts" element={<LayoutsListPage />} />
          <Route path="/layouts/create" element={<LayoutsCreatePage />} />
          <Route path="/layouts/edit/:id" element={<LayoutsEditPage />} />
          <Route path="/templates" element={<EmailTemplatesListPage />} />
          <Route path="/templates/create" element={<EmailTemplatesCreatePage />} />
          <Route path="/templates/edit/:id" element={<EmailTemplatesEditPage />} />
          <Route path="/discount-offers" element={<DiscountOffersListPage />} />
          <Route path="/discount-offers/create" element={<CreateDiscountOfferPage />} />
          <Route path="/discount-offers/edit/:id" element={<EditDiscountOfferPage />} />
          <Route path="/discount-offers/:id/codes" element={<DiscountCodesListPage />} />
          <Route path="/" element={<Navigate to="/dashboard" />} />
        </Route>
      </Route>
      <Route path="*" element={<Navigate to={auth ? '/dashboard' : '/login'} />} />
    </Routes>
  );
}

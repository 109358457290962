import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Button from '../../components/buttons/Button';
import TextInput from '../../components/forms/TextField';
import { UsernamePasswordDto } from '../../services/models/auth.model';
import useAuth from '../../hooks/selectors/useAuth';
import { validateUtil } from '../../utils';
import { authService } from '../../services';
import { handleRegisterError } from '../../services/errors/auth.error';
import CreatePasswordInput from '../../components/forms/CreatePasswordInput';

export default function RegisterPage(): JSX.Element {
  const [data, setData] = useState<Partial<UsernamePasswordDto>>({});
  const [isPasswordValid, setIsPasswordValid] = useState<boolean>(false);
  const auth = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation("auth");

  useEffect(() => {
    if (auth) navigate('/dashboard');
  }, [auth]);

  const handleSubmit = (): void => {
    if (!validateUtil.validateEmail(data.email)) {
      toast.error(t("invalid_email"));
      return;
    }
    if (!isPasswordValid) {
      toast.error(t("password_required"));
      return;
    }
    authService
      .register(data as UsernamePasswordDto)
      .then(() => {
        navigate('/login');
        toast.success(t("account_created"));
      })
      .catch(handleRegisterError(t));
  };

  return (
    <div className="w-full h-full flex items-center justify-center">
      <div className="rounded-xl bg-white px-6 py-4 w-full max-w-xl h-fit flex flex-col gap-4">
        <div className="flex justify-between items-center pb-4 border-[#EFF4FB] border-b">
          <h1 className="text-3xl">{t("register_title")}</h1>
        </div>
        <div className="flex flex-col gap-4">
          <div className="w-full flex flex-col gap-1">
            <p className="text-md">{t("email")}</p>
            <TextInput onChange={(e) => setData({ ...data, email: e.target.value })} />
          </div>
          <div className="w-full flex flex-col gap-1">
            <p className="text-md">{t("password")}</p>
            <CreatePasswordInput
              password={data.password || ""}
              changePassword={(password) => setData({ ...data, password})}
              isValid={isPasswordValid}
              setIsValid={setIsPasswordValid}
            />
          </div>
          <Button className="w-full" onClick={handleSubmit}>
            {t("register_button")}
          </Button>
          <Button className="w-full" onClick={() => navigate('/login')}>
            {t("login_page")}
          </Button>
        </div>
      </div>
    </div>
  );
}

import React from 'react';
import TextField from './TextField';

type TextInputWithLabelProps = {
  label: string;
  containerClassName?: string;
  id: string;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'id'>;

export default function TextInputWithLabel({ label, containerClassName, id, ...props }: TextInputWithLabelProps): JSX.Element {
  return (
    <div className={containerClassName}>
      <label htmlFor={id}>{label}</label>
      <TextField id={id} {...props} />
    </div>
  );
}

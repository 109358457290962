import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ScanDefinitionDTO } from '../../types/tickets';
import { createScanDefinition } from '../../services/scanDefinitionService';
import ScanDefinitionForm from '../../components/forms/ScanDefinitionForm';

export default function ScanDefinitionCreatePage(): JSX.Element {
  const navigate = useNavigate();
  const { t } = useTranslation("scan_definition");

  const onSubmit = (scanDefinition: ScanDefinitionDTO): void => {
    createScanDefinition(scanDefinition)
      .then((): void => {
        toast.success(t("created_scan_definition"));
        navigate('/scan-definitions');
      })
      .catch((error): void => {
        toast.error(`${t("failed_create_scan_definition")}: ${error}`);
      });
  };

  return (
    <ScanDefinitionForm onSubmit={onSubmit} />
  );
}

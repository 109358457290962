import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { LocationsState } from '../../types/redux';
import { Location } from '../../types/location';

const initialState: LocationsState = {
  locations: [],
  selectedLocation: undefined
};

const locationsSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    setLocations(state, action: PayloadAction<Location[]>): void {
      state.locations = action.payload;
    },
    setSelected(state, action: PayloadAction<number>): void {
      state.selectedLocation = action.payload;
    },
    addLocation(state, action: PayloadAction<Location>): void {
      state.locations.push(action.payload);
    },
    updateLocation(state, action: PayloadAction<Location>): void {
      state.locations = state.locations.map((loc) =>
        loc.id === action.payload.id ? action.payload : loc
      );
    }
  },
});

const locationsReducer = locationsSlice.reducer;

export const { setLocations, setSelected, addLocation, updateLocation } = locationsSlice.actions;
export default locationsReducer;

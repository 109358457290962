import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { PricingPoliciesState } from '../../types/redux';
import { PricingPolicy } from '../../types/pricingPolicies';

const initialState: PricingPoliciesState = {
  pricingPolicies: [],
};

const pricingPoliciesSlice = createSlice({
  name: 'pricingPolicies',
  initialState,
  reducers: {
    setPricingPolicies(state, action: PayloadAction<PricingPolicy[]>): void {
      state.pricingPolicies = action.payload;
    },
  },
});

const pricingPoliciesReducer = pricingPoliciesSlice.reducer;

export const { setPricingPolicies } = pricingPoliciesSlice.actions;
export default pricingPoliciesReducer;

import React from 'react';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CapacityGroupCreate } from '../../types/capacityGroups';
import { createCapacityGroup } from '../../services/capacityGroupService';
import CapacityGroupForm from '../../components/forms/CapacityGroupForm';

export default function CreateCapacityGroupPage(): JSX.Element {
    const navigate = useNavigate();
  
    const onSubmit = (capacityGroup: CapacityGroupCreate): void => {
      createCapacityGroup(capacityGroup).then((): void => {
        toast.success('Capacity Group created');
        navigate('/capacity-groups');
      }).catch((error): void => {
        if (error instanceof AxiosError && error.response?.data.errors) {
          toast.error(
            error.response?.data.errors
              .flatMap((e: { constraints: { [k: string]: string }[] }): {
                [k: string]: string
              }[] => Object.values(e.constraints))
              .map((x: string): string => x.charAt(0).toUpperCase() + x.slice(1))
              .join('. '),
          );
        } else {
          toast.error(error.message);
        }
      });
    };
  
    return (
      <CapacityGroupForm onSubmitCreate={onSubmit} onSubmitUpdate={() => {}} formType="create" capacityGroupId={undefined} />
    );
  }
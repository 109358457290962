import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import QRCode from 'react-qr-code';
import { Scanner } from '../../types/tickets';
import { getScanner } from '../../services/scannerService';
import Button from '../../components/buttons/Button';
import { API_URL } from '../../constants';
import useSelectedLocationId from '../../hooks/selectors/useSelectedLocationId';
import usePermissions from '../../hooks/selectors/usePermissions';
import { Permissions } from '../../types/misc';

export default function ScannerRegisterPage(): JSX.Element | null {
  if (!usePermissions([Permissions.ManageConfigurations])) {
    return (<></>);
  }
  const navigate = useNavigate();
  const { t } = useTranslation("scanners");
  const { id } = useParams();
  const [scanner, setScanner] = useState<Scanner>();
  const selectedLocationId = useSelectedLocationId();

  useEffect((): void => {
    if (!id) {
      return;
    }

    getScanner(Number(id))
      .then((oldScanner): void => {
        setScanner(oldScanner);
      })
      .catch((error): void => {
        toast.error(error.message);
        navigate('/scanners');
      });
  }, []);

  useEffect(() => {
    function getScannerFunction(): void {
      getScanner(Number(id))
      .then((newScanner): void => {
        if (newScanner.status === 'ACTIVE') {
          toast.success(t("registered"));
          navigate("/scanners");
        }
      })
      .catch((error): void => {
        toast.error(error.message);
        navigate('/scanners');
      });
    }
    getScannerFunction()
    const interval = setInterval(getScannerFunction, 2000)
    return () => clearInterval(interval);
  })

  if (!id) {
    toast.error('No scanner ID provided');
    navigate('/scanners');
  }

  if (scanner?.status === 'ACTIVE') {
    toast.error(t("scanner_already_registered"));
    navigate('/scanners');
  }

  if (!scanner) return null;

  const registerQrCode = `${API_URL}/api/locations/${selectedLocationId}/scanners/${scanner.token}/register`;
  
  return (
    <div>
      <h2 className="text-2xl font-semibold mb-4 text-center">{t("title_register_scanner")}</h2>
      <div style={{ height: "auto", margin: "0 auto", maxWidth: 200, width: "100%" }}>
        <QRCode
          size={512}
          style={{ height: "auto", maxWidth: "100%", width: "100%" }}
          value={registerQrCode}
          viewBox="0 0 256 256"
        />
      </div>
      <div className="flex justify-center gap-2 my-4">
        <Button type="button" className="bg-gray-500" onClick={(): void => navigate('/scanners')}>
          {t("go_back_button")}
        </Button>
      </div>
    </div>
  );
}

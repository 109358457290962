import { JSXElementConstructor, ReactNode, Children, isValidElement, ReactElement } from 'react';

export function findReactElement<P = any>(
  children: ReactNode,
  type: JSXElementConstructor<any>,
): ReactElement<P> | undefined {
  return Children.toArray(children).find(
    (child) => isValidElement(child) && child.type === type,
  ) as ReactElement<P> | undefined;
}

import { Radio, RadioProps } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';
import { TbCircle, TbCircleCheck } from 'react-icons/tb';
import React from 'react';

type RadioTabProps = {
  label: string;
  value: any;
  className?: string;
} & Omit<RadioProps, 'className' | 'value'>;

export default function RadioTab({ label, className, ...props }: RadioTabProps): JSX.Element {
  return (
    <Radio
      className={twMerge(
        'group data-[checked]:bg-green-50 border p-2 rounded-lg border-gray-500 flex items-center gap-2 cursor-pointer hover:bg-gray-100',
        className,
      )}
      {...props}
    >
      {({ checked }) => (
        <>
          {checked
            ? <TbCircleCheck size={20} className="text-green-600" />
            : <TbCircle size={20} className="text-gray-400" />
          }
          {label}
        </>
      )}
    </Radio>
  );
}

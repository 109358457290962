import { store } from '../redux/store';
import { SubscriptionCreateDto, Cardholder, CardholderDto, Subscription } from '../types/subscriptions';
import httpService from './httpService';

export async function createSubscription(subscription: SubscriptionCreateDto): Promise<void> {
    const locationId = store.getState().locations.selectedLocation;
  
    return httpService
      .post(`/api/locations/${locationId}/subscriptions`, subscription)
      .then(({ data }): void => data);
  }

export async function getSubscriptionsByIds(subscriptionIds: number[]): Promise<Subscription[]> {
  const locationId = store.getState().locations.selectedLocation;

  return httpService
    .post<Subscription[]>(`/api/locations/${locationId}/subscriptions/all`, { subscriptionIds })
    .then(({ data }): Subscription[] => data);
}

export async function downloadQrCodesCardholders(cardholdersQrCodes: string[]): Promise<Buffer> {
    const locationId = store.getState().locations.selectedLocation;

    return httpService
      .post<Buffer>(`/api/locations/${locationId}/subscriptions/download`, {uuids: cardholdersQrCodes}, {headers: {'Content-Type': 'application/json'}, responseType: 'arraybuffer'})
      .then(({ data }) => data);
}

export async function updateCardholder(subscriptionId: number, cardholderId: number, dtoData: CardholderDto): Promise<Cardholder[]> {
  const locationId = store.getState().locations.selectedLocation;

  return httpService
    .patch<Cardholder[]>(`/api/locations/${locationId}/subscriptions/${subscriptionId}/cardholders/${cardholderId}`, dtoData)
    .then(({ data }): Cardholder[] => data);
}

export enum Days {
    MONDAY,
    TUESDAY,
    WEDNESDAY,
    THURSDAY,
    FRIDAY,
    SATURDAY,
    SUNDAY
}

export type DayGroupCreateOrUpdate = {
    day: Days;
    startTime: string;
    endTime: string;
    capacity: number;
    active: boolean;
    ticketTypeToPricingPolicy: TicketTypeToPricingPolicy;
}

export type DayGroup = {
    id: number;
    day: Days;
    startTime: string;
    endTime: string;
    capacity: number;
    active: boolean;
    archived: boolean;
    locationId: number;
}

export type DayGroupWithTicketTypePricingPolicies = DayGroup & {
    ticketTypeToPricingPolicy: TicketTypeToPricingPolicy;
}

export enum PricingPolicyType {
    STANDARD,
    DYNAMIC
}

/**
 * Index signature for the ticket type to pricing policy map
 */
export type TicketTypeToPricingPolicy = {
    [ticketTypeId: number]: TicketTypePricingPolicy;
}

export type TicketTypePricingPolicy = {
    /**
     * Pricing policy type, indicating the type of pricing policy for this ticket type for this day group
     */
    pricingPolicyType: PricingPolicyType;

    /**
     * The id of the dynamic pricing policy, if the pricing policy type is dynamic
     */
    pricingPolicyId?: number;

    /**
     * A flag indicating whether the ticket type is available for this day group
     */
    active: boolean;
}

import React from 'react';
import { flexRender, RowData, Table as TanstackTable } from '@tanstack/react-table';
import { twMerge } from 'tailwind-merge';

declare module '@tanstack/react-table' {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface ColumnMeta<TData extends RowData, TValue> {
    align?: string;
  }
}

export default function Table<T>({ table }: { table: TanstackTable<T> }): JSX.Element {
  return (
    <table className="table-auto overflow-scroll w-full text-sm">
      <thead
        className="[&>*:nth-child(1)>*:nth-child(1)]:rounded-tl-xl
        [&>*:nth-child(1)>*:nth-last-child(1)]:rounded-tr-xl
        bg-[#1C2434] text-white"
      >
      {table.getHeaderGroups().map(headerGroup => (
        <tr key={headerGroup.id}>
          {headerGroup.headers.map(({ id, column: { columnDef }, ...header }) => (
            <th className={
              twMerge(
                columnDef.meta?.align || 'text-left',
                'py-4 px-2',
              )
            } key={id}>{flexRender(
              columnDef.header,
              header.getContext(),
            )}</th>
          ))}
        </tr>
      ))}
      </thead>
      <tbody
        className="[&>*:nth-last-child(1)>*:nth-child(1)]:rounded-bl-xl
        [&>*:nth-last-child(1)>*:nth-last-child(1)]:rounded-br-xl"
      >
      {table.getRowModel().rows.map(row => (
        <tr className={`bg-gray-200 hover:bg-gray-300 duration-300 transition-all ${row.getIsSelected() && 'bg-gray-300'}`} key={row.id}>
          {row.getVisibleCells().map(cell => (
            <td className={
              twMerge(
                cell.column.columnDef.meta?.align || 'text-left',
                'py-2 px-2',
              )} key={cell.id}>{flexRender(
              cell.column.columnDef.cell,
              cell.getContext(),
            )}</td>
          ))}
        </tr>
      ))}
      </tbody>
    </table>
  );
}

import { Status } from './filter';
import { EmailTemplateType, ShopEmailTemplate } from './emailTemplates';

export type SubscriptionType = {
    id: number;
    name: string;
    price: number;
    description: string;
    defaultRidesCount: number;
    cardholdersCount: number;
    archived: boolean;
    discountable: boolean;
    alwaysAvailable: boolean;
    privileged: boolean;
    vatPercentage: number;
    sellingFrom: Date;
    sellingTo: Date;
    locationId: number;
    capacityGroupId: number | null;
    capacityGroup: { name: string } | null;
    scanDefinitionId: number | null;
    scanDefinition: { name: string } | null;
    layoutId: number | null;
    layout: { name: string } | null;
    subscriptionShopsAvailableAt?: { id: number }[],
}

export type SubscriptionTypeCreateDto = {
    name: string;
    price: number;
    description: string;
    defaultRidesCount: number;
    cardholdersCount: number;
    vatPercentage?: number;
    sellingFrom: string;
    sellingTo: string;
    discountable: boolean;
    alwaysAvailable: boolean;
    validFrom?: string;
    validTo?: string;
    privileged: boolean;
    capacityGroupId?: number | null;
    scanDefinitionId?: number | null;
    layoutId?: number | null;
    subscriptionShopsIds?: number[];
    orderPriority: number | undefined
}

export type SubscriptionTypeUpdateDto = {
    name?: string;
    price?: number;
    description?: string;
    defaultRidesCount?: number;
    cardholdersCount?: number;
    vatPercentage?: number;
    sellingFrom?: string;
    sellingTo?: string;
    discountable?: boolean;
    alwaysAvailable?: boolean;
    validFrom?: string;
    validTo?: string;
    capacityGroupId?: number | null;
    scanDefinitionId?: number | null;
    layoutId?: number | null;
    subscriptionShopsIds?: number[];
}

export type ExtendedSubscriptionType = {
    id: number;
    name: string;
    price: number;
    description: string;
    defaultRidesCount: number;
    cardholdersCount: number;
    sellingFrom: string;
    sellingTo: string;
    capacityGroupId: number | null;
    capacityGroup: { name: string } | null;
    discountable: boolean;
    alwaysAvailable: boolean;
    vatPercentage: number;
    privileged: boolean;
    validFrom?: string;
    validTo?: string;
    locationId: number;
    scanDefinitionId: number | null;
    scanDefinition: { name: string } | null;
    layoutId: number | null;
    layout: { name: string } | null;
    subscriptionShopsAvailableAt?: { id: number }[];
    orderPriority: number,
}

export type SubscriptionTypeFilter = {
    status: Status;
    privileged: PrivilegedStatus;
}

export enum PrivilegedStatus {
    PRIVILEGED = 'PRIVILEGED',
    NONPRIVILEGED = 'NONPRIVILEGED',
  }

export type SubscriptionShop = {
    id: number;
    name: string;
    bookingFee: number;
    link: string;
    locationId: number;
    emailTemplates: ShopEmailTemplate[];
}

export type SubscriptionShopDto = {
    name: string;
    bookingFee: number;
    link: string;
    emailTemplates: Record<EmailTemplateType, number | null>;
}

export type SubscriptionCreateDto = {
    userDetails: UserDetailsDto;
    subscriptionTypeId: number;
    cardholders: SubscriptionCardholderIdentityDto[];
}

export type UserDetailsDto = {
    salutation?: string;
    firstName: string;
    lastName: string;
    birthdate?: Date;
    email: string;
    zipCode: string;
    city: string;
}

export type SubscriptionCardholderIdentityDto = {
    firstName: string;
    lastName: string;
    birthdate: Date;
    pictureId?: number;
}

export type Subscription = {
    id: number,
    expiry: Date,
    rides: number,
    status: string,
    createdAt: Date,
    cardholders: Cardholder[]
    subscriptionType: SubscriptionType
}

export type Cardholder = {
    id: number,
    identity: Identity,
    qrCodeValue: string,
    disabled: boolean
}

export type CardholderDto = {
    disabled: boolean
}

export type Identity = {
    id: number,
    firstName: string,
    lastName: string,
    birthdate: Date,
    pictureId?: number,
    picture?: Picture
}

export type Picture = {
    path: string,
}
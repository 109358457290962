import React from 'react';
import { formatCurrency } from '../../utils/currencyUtil';

type PriceProps = {
    price: number;
    currency?: string;
    locale?: string;
};
  
function Price({ price, currency, locale } : PriceProps): JSX.Element {
    return <div>{formatCurrency(price, { currency, locale })}</div>;
}

export default Price;
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Outlet, useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { FaBars, FaHome, FaIdCard, FaShoppingBasket } from 'react-icons/fa';
import { FaRepeat, FaTicket } from 'react-icons/fa6';
import { RxDashboard } from 'react-icons/rx';
import { MdAccountBox, MdCategory, MdOutlineEmail, MdPayments } from 'react-icons/md';
import { TbDiscount2 } from 'react-icons/tb';
import { IoMdPricetag, IoMdQrScanner } from 'react-icons/io';
import { IconType } from 'react-icons';
import { IoPeople } from 'react-icons/io5';
import { TiDevicePhone } from 'react-icons/ti';
import { HiAdjustments } from 'react-icons/hi';
import { LuLayoutList } from 'react-icons/lu';
import HoverButton from '../buttons/HoverButton';
import SidebarIcon from '../icons/SidebarIcon';
import Icon from '../icons/Icon';
import LanguageButton from '../buttons/LanguageButton';
import LocationButton from '../buttons/LocationButton';
import Logo from '../misc/Logo';
import { accountService } from '../../services';
import useSelectedLocationId from '../../hooks/selectors/useSelectedLocationId';
import UserMenu from '../misc/UserMenu';
import { Permissions } from '../../types/misc';
import usePermissions from '../../hooks/selectors/usePermissions';

interface DefaultLayoutProps {
  children?: React.ReactNode;
  collapsed?: boolean;
}

export default function ApplicationLayout({
  children,
  collapsed: preCollapsed = false,
}: DefaultLayoutProps): JSX.Element {
  const [collapsed, setCollapsed] = useState(preCollapsed);
  const selectedLocationId = useSelectedLocationId();

  useEffect((): void => {
    accountService.getAccountDetails();
  }, []);

  return (
    <div
      className={classNames(
        'bg-[#F1F5F9] transition-all group h-screen w-full flex-1 flex flex-col lg:flex-row no-scrollbar',
        {
          collapsed,
        },
      )}>
      <Sidebar key={selectedLocationId} />

      <div className="h-screen lg:w-[calc(100%-288px)] group-[.collapsed]:w-[calc(100%-80px)] overflow-y-scroll z-5">
        <div className="z-50 w-full sticky top-0 flex flex-row h-20 bg-white items-center justify-between px-6">
          <div className="flex gap-2 items-center justify-center w-fit">
            <div
              onClick={() => setCollapsed(!collapsed)}
              className="p-2 flex-shrink-0 hidden lg:block rounded-full cursor-pointer bg-[#EFF4FB] border border-[#E2E8F0]">
              <SidebarIcon className="h-5 w-5" />
            </div>
            <LocationButton />
          </div>
          <div className="flex items-center justify-center gap-2">
            <LanguageButton />
            <UserMenu />
          </div>
        </div>
        <div key={selectedLocationId} className="flex flex-col w-full p-6">{children || <Outlet />}</div>
      </div>
    </div>
  );
}

interface NavigationProps {
  path: string;
  icon: IconType;
  name: string;
  permission: Permissions;
}

function Sidebar(): JSX.Element {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation("default_layout");

  const [closed, setClosed] = useState(false);

  useEffect(() => setClosed(true), [location.pathname]);

  return (
    <div className="lg:h-screen transition-all flex-shrink-0 w-full lg:w-72 overflow-hidden lg:overflow-visible lg:group-[.collapsed]:w-24 bg-[#1C2434] px-4 lg:pb-4 flex flex-col lg:overflow-y-auto no-scrollbar">
      <div className="justify-between flex items-center">
        <Logo
          onClick={() => navigate('/dashboard')}
          className="w-[75px] cursor-pointer lg:w-[60%] lg:group-[.collapsed]:w-full lg:mx-auto duration-[300] transition-all"
        />
        <FaBars
          onClick={() => setClosed(!closed)}
          className="h-5 w-5 text-white cursor-pointer lg:hidden"
        />
      </div>

      <div
        className={classNames(
          'flex flex-col justify-between transition-all gap-2 flex-1 no-scrollbar group-[.collapsed]:items-center',
          {
            'max-h-[0px] ease-[cubic-bezier(0,1,0,1)] duration-500': closed,
            'max-h-[2000px] ease-[ease-in-out] duration-1000': !closed,
          },
        )}>
        <div className="flex flex-col">
          <h3 className="text-[#8A99AF] group-[.collapsed]:hidden text-sm font-semibold">{t("menu")}</h3>

          <Navigation path="/dashboard" icon={RxDashboard} name={t("dashboard")} permission={Permissions.ViewConfigurations}/>
          <Navigation path="/payments" icon={MdPayments} name={t("payments")} permission={Permissions.ManagePayments}/>
          <Navigation path="/ticket-types" icon={FaTicket} name={t("ticket_types")} permission={Permissions.ViewConfigurations}/>
          <Navigation path="/ticket-shops" icon={FaShoppingBasket} name={t("ticket_shops")} permission={Permissions.ViewConfigurations}/>
          <Navigation path="/ticket-categories" icon={MdCategory} name={t("ticket_categories")} permission={Permissions.ViewConfigurations}/>
          <Navigation path="/capacity-groups" icon={IoPeople} name={t("capacity_groups")} permission={Permissions.ViewConfigurations}/>
          <Navigation path="/scan-groups" icon={IoMdQrScanner} name={t("scan_groups")} permission={Permissions.ViewConfigurations}/>
          <Navigation path="/scan-definitions" icon={HiAdjustments} name={t("scan_definitions")} permission={Permissions.ViewConfigurations}/>
          <Navigation path="/scanners" icon={TiDevicePhone} name={t("scanners")} permission={Permissions.ViewConfigurations}/>
          <Navigation path="/pricing-policies" icon={IoMdPricetag} name={t("pricing_policies")} permission={Permissions.ViewConfigurations}/>
          <Navigation path="/subscription-types" icon={FaIdCard} name={t("subscription_types")} permission={Permissions.ViewConfigurations}/>
          <Navigation path="/subscription-shops" icon={FaHome} name={t("subscription_shops")} permission={Permissions.ViewConfigurations}/>
          <Navigation path="/subscriptions" icon={FaRepeat} name={t("subscriptions")} permission={Permissions.ViewConfigurations}/>
          <Navigation path="/accounts" icon={MdAccountBox} name={t("accounts")} permission={Permissions.ManageUsers}/>
          <Navigation path="/layouts" icon={LuLayoutList} name={t("layouts")} permission={Permissions.ViewConfigurations}/>
          <Navigation path="/templates" icon={MdOutlineEmail} name={t("templates")} permission={Permissions.ViewConfigurations}/>
          <Navigation path="/discount-offers" icon={TbDiscount2} name={t("discount_offers")} permission={Permissions.ViewConfigurations | Permissions.ManageDiscounts}/>
        </div>
      </div>
    </div>
  );
}

function Navigation({ path, icon, name, permission }: NavigationProps): JSX.Element {
  const location = useLocation();
  if (!usePermissions([permission])) {
    return (<></>);
  }
  return (
    <HoverButton clickable={false} className="lg:group-[.collapsed]:w-fit w-full">
      <HoverButton.Button>
        <Link
          to={path}
          className={classNames(
            'flex w-full items-center lg:group-[.collapsed]:rounded-full lg:group-[.collapsed]:w-fit lg:group-[.collapsed]:p-3 gap-2.5 font-medium rounded-lg py-2 px-4 text-[#DEE4EE] hover:bg-[#333A48]',
            { 'bg-[#333A48]': location.pathname === path },
          )}>
          <Icon className="w-5 h-5" icon={icon} />
          <p className="lg:group-[.collapsed]:hidden">{name}</p>
        </Link>
      </HoverButton.Button>
      <HoverButton.Div className="left-[180%] hidden lg:group-[.collapsed]:block z-[1000] transition-50 rounded-[6px] top-[50%] -translate-y-[50%] px-3 py-2 bg-[#1C2434]">
        <p className="text-sm whitespace-nowrap text-[#DEE4EE]">{name}</p>
        <div className="h-[15px] w-[15px] rotate-45 bg-[#1C2434] absolute top-[50%] -translate-y-[50%] -left-1 rounded-[2px]" />
      </HoverButton.Div>
    </HoverButton>
  );
}

import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { createColumnHelper, Row } from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { TbCirclePlus, TbEdit, TbTrash } from 'react-icons/tb';
import SelectionTable from '../../components/tables/SelectionTable';
import { TicketShop } from '../../types/tickets';
import { store } from '../../redux/store';
import IconButton from '../../components/buttons/IconButton';
import DeleteModal from '../../components/modals/DeleteModal';
import useTicketShops from '../../hooks/selectors/useTicketShops';
import { deleteTicketShop, getTicketShopsForCurrentLocation } from '../../services/ticketShopService';
import { setTicketShops } from '../../redux/slices/ticketShopsSlice';
import useLanguage from '../../hooks/selectors/useLanguage';
import usePermissions from '../../hooks/selectors/usePermissions';
import { Permissions } from '../../types/misc';

function RenderLinkToShop({ row: { original: { link: shopLink } } }: { row: Row<TicketShop> }): JSX.Element {
  return (
    <a href={shopLink} target="_blank" rel="noreferrer" className="underline text-purple-600 visited:text-purple-950">
      {shopLink}
    </a>
  );
}

function RenderActionsCell({ row }: { row: Row<TicketShop> }, resetPagination: () => void): JSX.Element {
  const [deleteModalOpen, setDeleteModalOpen] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const { t } = useTranslation("ticket_shop");

  const onDelete = (): void => {
    deleteTicketShop(row.original.id)
      .then((): void => {
        toast.success(t("deleted"));
        resetPagination();
        setDeleteModalOpen(false);
      })
      .catch((error): void => {
        toast.error(error.message);
      });
  };

  return (
    <>
      <div className="flex justify-end items-center gap-1">
        <IconButton
          onClick={(): void => navigate(`/ticket-shops/edit/${row.original.id}`)}
          icon={TbEdit} label="" variant="accent"
          className="py-1 px-1 text-lg"
          disabled={!usePermissions([Permissions.ManageConfigurations])}
        />
        <IconButton
          onClick={(): void => setDeleteModalOpen(true)}
          icon={TbTrash} label="" variant="danger"
          className="py-1 px-1 text-lg"
          disabled={!usePermissions([Permissions.ManageConfigurations])}
        />
      </div>
      <DeleteModal open={deleteModalOpen} setOpen={setDeleteModalOpen} onActionButtonClicked={onDelete}
                   message={`${t("delete_confirmation")} "${row.original.name}"`}
      />
    </>
  );
}

export default function TicketShopsListPage(): JSX.Element {
  if (!usePermissions([Permissions.ViewConfigurations])) {
    return (<></>);
  }
  const [pagination, setPagination] = React.useState({ pageSize: 25, pageIndex: 0 });
  const ticketShops = useTicketShops();
  const language = useLanguage();
  const navigate = useNavigate();
  const { t } = useTranslation("ticket_shop");

  const resetPagination = (): void => {
    setPagination(prev => ({ pageSize: prev.pageSize, pageIndex: prev.pageIndex }));
  }

  useEffect((): void => {
    getTicketShopsForCurrentLocation(pagination.pageIndex * pagination.pageSize, pagination.pageSize)
      .then((data): void => {
        store.dispatch(setTicketShops(data));
      })
      .catch((error): void => {
        toast.error(error.message);
      });
  }, [pagination]);

  const columns = useMemo((): any[] => {
    const columnHelper = createColumnHelper<TicketShop>();
    return [
      columnHelper.accessor((row) => row.id, {
        id: 'id',
        header: t("id"),
      }),
      columnHelper.accessor((row) => row.name, {
        id: 'name',
        header: t("name"),
      }),
      columnHelper.accessor((row) => row.bookingFee, {
        id: 'booking-fee',
        header: t("booking_fee"),
        cell: ({ row: { original: { bookingFee } } }): string => `€${bookingFee.toFixed(2)}`,
      }),
      columnHelper.accessor((row) => row.name, {
        id: 'link-to-shop',
        header: t("link_to_shop"),
        cell: RenderLinkToShop,
      }),
      {
        id: 'actions',
        header: t("actions"),
        cell: (row: { row: Row<TicketShop> }) => RenderActionsCell(row, resetPagination),
        meta: {
          align: 'text-right',
        },
      },
    ];
  }, [language]);

  return (
    <>
      <div className="flex justify-between items-center mb-2">
        <h2 className="font-gintobold font-bold text-2xl">{t("title")}</h2>
        <div className="flex gap-1">
          {
            usePermissions([Permissions.ManageConfigurations]) &&
            <IconButton onClick={(): void => navigate('/ticket-shops/create')} icon={TbCirclePlus} label={t("new")}
                        className="bg-gradient-to-r from-[#E700F9] hover:shadow-purple-300 hover:shadow-md
                      to-pink-500 duration-300 bg-[position:_0%_0%] hover:bg-[position:_100%_100%]
                      bg-[size:_200%] transition-all font-bold text-white rounded-full cursor-pointer" />
          }
        </div>
      </div>
      <SelectionTable
        columns={columns}
        data={ticketShops}
        pagination={pagination}
        setPagination={setPagination}
        enableRowSelection={false}
      />
    </>
  );
}

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { CapacityGroup, ScanDefinition, TicketCategory, TicketShop } from '../../types/tickets';
import { getTicketCategoriesForCurrentLocation } from '../../services/ticketCategoryService';
import { getCapacityGroupsForCurrentLocation } from '../../services/capacityGroupService';
import { getScanDefinitionsCurrentLocation } from '../../services/scanDefinitionService';
import { getTicketShopsForCurrentLocation } from '../../services/ticketShopService';
import { Layout } from '../../types/layout';
import { getLayoutsForCurrentLocation } from '../../services/layoutService';

type TicketTypeFormData = {
  loading: boolean;
  ticketCategories: TicketCategory[];
  ticketShops: TicketShop[];
  capacityGroups: CapacityGroup[];
  scanDefinitions: ScanDefinition[];
  layouts: Layout[];
};

export default function useTicketTypeFormData(): TicketTypeFormData {
  const [ticketCategories, setTicketCategories] = useState<TicketCategory[]>([]);
  const [capacityGroups, setCapacityGroups] = useState<CapacityGroup[]>([]);
  const [scanDefinitions, setScanDefinitions] = useState<ScanDefinition[]>([]);
  const [ticketShops, setTicketShops] = useState<TicketShop[]>([]);
  const [layouts, setLayouts] = useState<Layout[]>([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { t } = useTranslation("service_errors");

  useEffect((): void => {
    Promise.all([
      getTicketCategoriesForCurrentLocation(),
      getCapacityGroupsForCurrentLocation(),
      getScanDefinitionsCurrentLocation(0, Number.MAX_SAFE_INTEGER),
      getTicketShopsForCurrentLocation(0, Number.MAX_SAFE_INTEGER),
      getLayoutsForCurrentLocation(0, Number.MAX_SAFE_INTEGER),
    ])
      .then(([tc, cg, sd, ts, ls]): void => {
        setTicketCategories(tc);
        setCapacityGroups(cg);
        setScanDefinitions(sd);
        setTicketShops(ts);
        setLayouts(ls);
      })
      .catch((): void => {
        toast.error(t("failed_fetch_information_ticket_types"));
        navigate('/ticket-types');
      })
      .finally((): void => setLoading(false));
  }, []);

  return { loading, ticketCategories, ticketShops, capacityGroups, scanDefinitions, layouts };
}

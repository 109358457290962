import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import CreatePasswordInput from "../../components/forms/CreatePasswordInput";
import useAuth from "../../hooks/selectors/useAuth";
import useQueryParams from "../../hooks/useQuery";
import Button from "../../components/buttons/Button";
import { authService } from "../../services";
import { validatePassword } from "../../utils/validateUtil";
import AnonymousUserForm from '../../components/forms/AnonymousUserForm';

export default function ResetPasswordPage(): JSX.Element {

    const auth = useAuth();
    const {t} = useTranslation('auth');
    const navigate = useNavigate();
    const { token } = useQueryParams<{token: string}>();

    useEffect(() => {
        if (auth) navigate('/dashboard');
      }, [auth]);

    const [password, setPassword] = useState<string>('');
    const [isValid, setIsValid] = useState<boolean>(false);

    const handleSubmit = (): void => {

        validatePassword(password);

        if (!isValid) {
            toast.error(t("password_required"));
            return;
        }

        authService.resetPassword(token, password)
        .then(() => {
            toast.success(t("password_changed"));
            navigate('/login');
        })
        .catch((error) => {
            console.log(error);
            toast.error(t("unknown_error"));
        });
    };
  return (
    <AnonymousUserForm>
      <h1>{t("password_set")}</h1>
      <CreatePasswordInput
        password={password}
        changePassword={setPassword}
        isValid={isValid}
        setIsValid={setIsValid}
        className="text-white focus:border-purple-300 active:border-purple-300"
      />
      <Button className="w-full self-end bg-purple-300 text-[#1C2434]" onClick={handleSubmit}>
            {t("submit_button")}
          </Button>
    </AnonymousUserForm>

  );
}

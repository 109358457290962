import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { useTranslation } from 'react-i18next';

interface DraggableItemWithInputsProps<T> {
  id: string;
  item: T;
  handleChange: (id: string, field: keyof T, checked: boolean | string | number) => void;
}

export default function DraggableItemWithInputs<T extends { id: number, name: string, scanNumber: number, scanDelayInMinutes: number, checked: boolean }>({ id, item, handleChange }: DraggableItemWithInputsProps<T>): JSX.Element {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const { t } = useTranslation("scan_definition_form");

  return (
    <div ref={setNodeRef} style={style} {...attributes} {...listeners} className="p-[10px] mx-[0] my-[5px] border-[1px] border-[solid] border-[#ccc] bg-[#fff] cursor-move text-center flex items-center justify-start
 rounded-lg focus:border-[#3C50E0] active:border-[#3C50E0]">
        <input type='checkbox' defaultChecked={item.checked} onChange={(e) => handleChange(id, 'checked', e.target.checked)} className='h-[30px] w-[30px]'/>
        <span className='w-[300px] p-[5px] border-[none] bg-transparent text-center'>{item.name}</span>
        <div className='w-full'>
          <div className='block mb-4 float-right'>
            <span>{t("number_of_scans")}:</span><input type='number' required defaultValue={item.scanNumber ?? 0} onChange={(e) => handleChange(id, 'scanNumber', Number(e.target.value))} className='border border-black rounded-lg mx-4 p-2' />
          </div>
          <div className='block mb-4 float-right'>
            <span>{t("delay_in_minutes")}:</span><input type='number' defaultValue={item.scanDelayInMinutes ?? 0} onChange={(e) => handleChange(id, 'scanDelayInMinutes', Number(e.target.value))} className='border border-black rounded-lg mx-4 p-2' />
          </div>
        </div>
    </div>
  );
};
import { useSelector } from 'react-redux';
import { Permissions } from '../../types/misc';
import { RootState } from '../../types/redux';

export default function usePermissions(reqs: Permissions[]): boolean {
  const permissions = useSelector(
    (state: RootState): number | undefined => state.application.permissions,
  );
  if (permissions === undefined)
    return !reqs.length || (reqs.length === 1 && reqs[0] === Permissions.None);

  return reqs.every((req): boolean => (permissions & req) === req);
}

import { configureStore } from '@reduxjs/toolkit';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';
import { createStateSyncMiddleware } from 'redux-state-sync';
import thunk from 'redux-thunk';
import rootReducer from './reducer';

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [
    thunk,
    createStateSyncMiddleware({
      whitelist: ['application/setAuthenticated', 'application/setAccountDetails'],
    }),
  ],
});
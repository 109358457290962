import httpService from './httpService';
import { store } from '../redux/store';
import { TicketShop, TicketShopDTO } from '../types/tickets';

export async function getTicketShopsForCurrentLocation(offset: number, limit: number): Promise<TicketShop[]> {
  const locationId = store.getState().locations.selectedLocation;

  return httpService
    .get<TicketShop[]>(`/api/locations/${locationId}/ticket-shops?offset=${offset}&limit=${limit}`)
    .then((response): TicketShop[] => {
      return response.data;
    });
}

export async function deleteTicketShop(ticketShopId: number): Promise<TicketShop> {
  const locationId = store.getState().locations.selectedLocation;

  return httpService
    .delete<TicketShop>(`/api/locations/${locationId}/ticket-shops/${ticketShopId}`)
    .then((response): TicketShop => response.data);
}

export async function createTicketShop(ticketShop: TicketShopDTO): Promise<TicketShop> {
  const locationId = store.getState().locations.selectedLocation;

  return httpService
    .post<TicketShop>(`/api/locations/${locationId}/ticket-shops`, ticketShop)
    .then((response): TicketShop => response.data);
}

export async function updateTicketShop(ticketShopId: number, ticketShop: Partial<TicketShopDTO>): Promise<TicketShop> {
  const locationId = store.getState().locations.selectedLocation;

  return httpService
    .patch<TicketShop>(`/api/locations/${locationId}/ticket-shops/${ticketShopId}`, ticketShop)
    .then((response): TicketShop => response.data);
}

export async function getTicketShopForCurrentLocation(ticketShopId: number): Promise<TicketShop> {
  const locationId = store.getState().locations.selectedLocation;

  return httpService
    .get<TicketShop[]>(`/api/locations/${locationId}/ticket-shops?filter=${encodeURI(JSON.stringify({ id: { equals: ticketShopId } }))}`)
    .then(({ data }): TicketShop => data[0]);
}

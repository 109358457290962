import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { TicketType } from '../../types/tickets';
import { getTicketTypesForCurrentLocation } from '../../services/ticketTypeService';
import { EmailTemplate } from '../../types/emailTemplates';
import { getEmailTemplatesForCurrentLocation } from '../../services/emailTemplatesService';

type TicketShopFormData = {
  loading: boolean;
  emailTemplates: EmailTemplate[];
  ticketTypes: TicketType[];
}

export default function useTicketShopFormData(): TicketShopFormData {
  const { t } = useTranslation("service_errors");
  const [loading, setLoading] = useState(true);
  const [emailTemplates, setEmailTemplates] = useState<EmailTemplate[]>([]);
  const [ticketTypes, setTicketTypes] = useState<TicketType[]>([]);
  const navigate = useNavigate();

  useEffect((): void => {
    Promise.all([
      getTicketTypesForCurrentLocation(0, Number.MAX_SAFE_INTEGER),
      getEmailTemplatesForCurrentLocation(0, Number.MAX_SAFE_INTEGER),
    ])
      .then(([tt, et]): void => {
        setTicketTypes(tt);
        setEmailTemplates(et);
      })
      .catch((): void => {
        toast.error(t("failed_fetch_information_ticket_shops"));
        navigate('/tickets');
      })
      .finally((): void => setLoading(false));
  }, []);

  return { ticketTypes, loading, emailTemplates };
}

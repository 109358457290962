import { AxiosError, HttpStatusCode } from 'axios';
import { TFunction } from 'i18next';
import { toast } from 'react-toastify';

export function handleLoginError(t: TFunction<"auth", undefined>) {
  return (err: Error): void => {
    if (err instanceof AxiosError) {
      switch (err.response?.status) {
        case HttpStatusCode.Unauthorized:
          toast.error(t("wrong_password"));
          return;
        default:
          toast.error(t("unknown_error"));
      }
    } else toast.error(t("unknown_error"));
  }
}

export function handleRegisterError(t: TFunction<"auth", undefined>) {
  return (_err: Error): void => {
    toast.error(t("unknown_error"));
  }
}

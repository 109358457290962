import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import AccountForm from '../../components/forms/AccountForm';
import { AccountDto, AccountWithUserAndIdentities, LocationAndRole } from '../../types/account';
import { getAccountById, updateEmail, updateGeneralRole, updateLocationRole } from '../../services/accountService';
import ProgressBar from '../../components/misc/ProgressBar';

export default function EditAccountPage(): JSX.Element {
  const navigate = useNavigate();
  const { t } = useTranslation('accounts_form');
  const { id: accountId } = useParams();
  const [progress, setProgress] = React.useState<{ progress: number, statusText: string; }>();
  const [oldAccount, setOldAccount] = React.useState<AccountWithUserAndIdentities | null>(null);

  useEffect(() => {
    if (accountId) {
      getAccountById(+accountId)
        .then(setOldAccount)
        .catch(() => {
          toast.error(t('error_loading_account'));
          navigate('/accounts');
        });
    } else {
      toast.error(t('error_loading_account'));
      navigate('/accounts');
    }
  }, [accountId]);

  const onSubmit = async (account: AccountDto, locationRolePairs: LocationAndRole[]): Promise<void> => {
    if (!oldAccount) {
      toast.error(t('error_loading_account'));
      return;
    }

    setProgress({ progress: 0.1, statusText: t('progress_email') });
    if (account.email !== oldAccount.email) {
      try {
        await updateEmail(oldAccount.id, account.email);
      } catch (_) {
        toast.error(t('error_updating_email'));
        setProgress(undefined);
        return;
      }
    }
    setProgress({ progress: 0.33, statusText: t('progress_general_role') });

    if (account.roleId !== oldAccount.roleId) {
      try {
        await updateGeneralRole(oldAccount.id, account.roleId);
      } catch (_) {
        toast.error(t('error_assigning_roles'));
        setProgress(undefined);
        return;
      }
    }
    setProgress({ progress: 0.67, statusText: t('progress_location_roles') });

    try {
      await Promise.all(
        locationRolePairs
          .map((locationRolePair) =>
            updateLocationRole(oldAccount.id, locationRolePair.locationId, locationRolePair.roleId),
          ),
      );
    } catch (err) {
      toast.error(t('error_assigning_roles'));
      setProgress(undefined);
      return;
    }

    setProgress({ progress: 1, statusText: t('progress_done') });

    toast.success(t('account_updated'));
    setTimeout(() => navigate('/accounts'), 700); // to give the user a chance to see the progress bar
  };

  if (!oldAccount) {
    return <></>;
  }

  return (
    <>
      {progress && (
        <div className="py-2 flex items-center gap-2">
          <ProgressBar outerClassName="flex-1" progress={progress.progress} />
          <p className="text-green-500 min-w-40 text-center">{progress.statusText}</p>
        </div>
      )}
      <AccountForm formType="edit" onSubmit={onSubmit} initialAccount={oldAccount} />
    </>
  );
}

import { ScanGroup, ScanGroupDTO } from '../types/tickets';
import httpService from './httpService';
import { store } from '../redux/store';

export async function getScanGroupsForCurrentLocation(offset: number, limit: number): Promise<ScanGroup[]> {
  const locationId = store.getState().locations.selectedLocation;

  return httpService
    .get<ScanGroup[]>(`/api/locations/${locationId}/scan-groups?offset=${offset}&limit=${limit}`)
    .then((response): ScanGroup[] => response.data);
}

export async function createScanGroup(data: ScanGroupDTO): Promise<ScanGroup> {
    const locationId = store.getState().locations.selectedLocation;
  
    return httpService
      .post<ScanGroup>(`/api/locations/${locationId}/scan-groups`, data)
      .then((response): ScanGroup => response.data);
}

export async function deleteScanGroup(scanGroupId: number): Promise<ScanGroup> {
    const locationId = store.getState().locations.selectedLocation;
  
    if (!locationId) {
      throw new Error('No location selected');
    }
  
    return httpService
      .delete<ScanGroup>(`/api/locations/${locationId}/scan-groups/${scanGroupId}`)
      .then((response): ScanGroup => response.data);
}
  
export async function updateScanGroup(scanGroupId: number, data: Partial<ScanGroupDTO>): Promise<ScanGroup> {
    const locationId = store.getState().locations.selectedLocation;

    return httpService
        .put<ScanGroup>(`/api/locations/${locationId}/scan-groups/${scanGroupId}`, data)
        .then((response): ScanGroup => response.data);
}
  
export async function getScanGroup(scanGroupId: number): Promise<ScanGroup> {
    const locationId = store.getState().locations.selectedLocation;
  
    return httpService
      .get<ScanGroup>(`/api/locations/${locationId}/scan-groups/${scanGroupId}`)
      .then((response): ScanGroup => {
        return response.data;
      });
}
  

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AccountsState } from '../../types/redux';
import { Account } from '../../types/account';

const initialState: AccountsState = {
  accounts: [],
};

const accountsSlice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    setAccounts(state, action: PayloadAction<Account[]>): void {
      state.accounts = action.payload;
    },
  },
});

const accountsReducer = accountsSlice.reducer;

export const { setAccounts } = accountsSlice.actions;
export default accountsReducer;

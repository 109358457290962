import React, { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { TbCalendarSearch, TbLoader } from 'react-icons/tb';
import { toast } from 'react-toastify';
import { useParams } from 'react-router-dom';
import {
  availableCapacityFilterOptions,
  AvailableCapacityOption,
  dayFilterOptions,
  DayGroupFilters,
  Weekday,
} from '../../types/filtering';
import TextInput from '../forms/TextField';
import StyledCheckboxWithLabel from '../forms/StyledCheckboxWithLabel';
import { parseToYYYYMMDD } from '../../utils/dateUtil';
import IconButton from '../buttons/IconButton';
import { getDayGroupsForCapacityGroup } from '../../services/capacityGroupService';
import { store } from '../../redux/store';
import { setDayGroups } from '../../redux/slices/dayGroupSlice';

type CapacityGroupEditorFiltersProps = {
  filters: DayGroupFilters;
  setFilters: Dispatch<SetStateAction<DayGroupFilters>>;
  slotIds: string[];
  // dateIds: DateIdentifier[];
  totalCapacities: number[];
}

type FilterCategory = {
  array: readonly any[];
  filterKey: Exclude<keyof DayGroupFilters, 'fromDate' | 'toDate'>;
  label: string;
  useTranslationForArrayEntries: boolean;
}

export default function CapacityGroupEditorFilters(
  {
    filters,
    setFilters,
    slotIds,
    // dateIds,
    totalCapacities,
  }: CapacityGroupEditorFiltersProps): JSX.Element {
  const [dateRange, setDateRange] = useState({ fromDate: filters.fromDate, toDate: filters.toDate });
  const [dateRangeLoading, setDateRangeLoading] = useState(false);
  const { t } = useTranslation('capacity_group_editor');
  const { id: capacityGroupId } = useParams();

  const handleInternalDateChange = (ev: React.ChangeEvent<HTMLInputElement>): void => {
    setDateRange({ ...dateRange, [ev.target.name]: new Date(ev.target.value) });
  };

  useEffect((): void => {
    setDateRangeLoading(true);

    getDayGroupsForCapacityGroup(Number(capacityGroupId), filters.fromDate, filters.toDate)
      .then((newDays): void => {
        store.dispatch(setDayGroups(newDays));
      })
      .catch((): void => {
        toast.error(t('invalid_date_range'));
      })
      .finally((): void => {
        setDateRangeLoading(false);
      });
  }, [filters.fromDate, filters.toDate]);

  const handleDateChange = (): void => {
    setFilters({ ...filters, ...dateRange });
  };

  const filterCategories: FilterCategory[] = useMemo((): FilterCategory[] => [
    {
      array: dayFilterOptions,
      filterKey: 'dayFilter',
      label: 'weekdays',
      useTranslationForArrayEntries: true,
    },
    {
      array: slotIds,
      filterKey: 'timeSlotIds',
      label: 'time_slots',
      useTranslationForArrayEntries: false,
    },
    {
      array: totalCapacities,
      filterKey: 'totalCapacityFilter',
      label: 'total_capacity',
      useTranslationForArrayEntries: false,
    },
    {
      array: availableCapacityFilterOptions,
      filterKey: 'availableCapacityFilter',
      label: 'available_capacity',
      useTranslationForArrayEntries: true,
    },
  ], [slotIds, totalCapacities]);

  return (
    <aside className="bg-indigo-100 flex-1 p-4">
      <p className="text-lg font-bold">{t('filters')}</p>
      <div>
        <p>{t('date_range')}:</p>
        <div className="flex items-center gap-1">
          <TextInput value={parseToYYYYMMDD(dateRange.fromDate)} onChange={handleInternalDateChange} type="date"
                     name="fromDate"
                     className="bg-white border-black w-min p-1" />
          {' - '}
          <TextInput value={parseToYYYYMMDD(dateRange.toDate)} onChange={handleInternalDateChange} type="date"
                     name="toDate"
                     className="bg-white border-black w-min p-1" />
          <IconButton variant="view" className="ml-1 justify-self-end p-2 text-sm aspect-square disabled:cursor-pointer" type="button"
                      icon={dateRangeLoading ? TbLoader : TbCalendarSearch} disabled={dateRangeLoading} label=""
                      onClick={handleDateChange} />
        </div>
      </div>
      {filterCategories.map((filterCategory, idx): JSX.Element => (
        <CheckboxFilterCategory key={`${filterCategory.label}_${idx}`} filterCategory={filterCategory} filters={filters} setFilters={setFilters} />
      ))}
    </aside>
  );
}

type CheckboxFilterCategoryProps = {
  filterCategory: FilterCategory;
  filters: DayGroupFilters;
  setFilters: Dispatch<SetStateAction<DayGroupFilters>>
}

function CheckboxFilterCategory(
  {
    filterCategory: { array, filterKey, useTranslationForArrayEntries, label },
    filters,
    setFilters,
  }: CheckboxFilterCategoryProps): JSX.Element {
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation('capacity_group_editor');
  const expandable = array.length > 5;
  const entries = expanded ? array : array.slice(0, 5);

  return (
    <div>
      <p>{t(label)}:</p>
      {entries.map((option, idx): JSX.Element => (
        <CheckboxFilterEntry key={`${label}_${idx}`} option={option} filters={filters} setFilters={setFilters} arrayFilterKey={filterKey}
                             useTranslationForOption={useTranslationForArrayEntries} />
      ))}
      {expandable
        && (
          expanded
            ? <button className="underline text-blue-600" onClick={(): void => setExpanded(false)}
                      type="button">{t('show_less')}...</button>
            : <button className="underline text-blue-600" onClick={(): void => setExpanded(true)}
                      type="button">{t('expand')}...</button>
        )
      }
    </div>
  );
}

type CheckboxFilterEntryProps = {
  option: string | number | Weekday | AvailableCapacityOption;
  filters: DayGroupFilters;
  setFilters: Dispatch<SetStateAction<DayGroupFilters>>;
  arrayFilterKey: Exclude<keyof DayGroupFilters, 'fromDate' | 'toDate'>;
  useTranslationForOption: boolean;
}

function CheckboxFilterEntry(
  { option, filters, setFilters, arrayFilterKey, useTranslationForOption }: CheckboxFilterEntryProps): JSX.Element {
  const { t } = useTranslation('capacity_group_editor');

  return <StyledCheckboxWithLabel
    // @ts-ignore
    checked={filters[arrayFilterKey].includes(option)}
    onChange={(newValue): void => {
      if (newValue) {
        setFilters({ ...filters, [arrayFilterKey]: [...filters[arrayFilterKey], option] });
      } else {
        setFilters({
          ...filters,
          [arrayFilterKey]: filters[arrayFilterKey].filter((x): boolean => x !== option),
        });
      }
    }}
    containerClassName="flex gap-1 items-center"
    label={useTranslationForOption ? t(option.toString()) : option.toString()}
  />;
}

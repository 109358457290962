import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Scanner, ScannerDTO } from '../../types/tickets';
import { getScanner, updateScanner } from '../../services/scannerService';
import ScannerForm from '../../components/forms/ScannerForm';

export default function ScannerEditPage(): JSX.Element | null {
  const navigate = useNavigate();
  const { id } = useParams();
  const [scanner, setScanner] = useState<Scanner>();
  const { t } = useTranslation("scanners");

  useEffect((): void => {
    if (!id) {
      return;
    }

  getScanner(Number(id))
    .then((oldScanner): void => {
      setScanner(oldScanner);
    })
    .catch((error): void => {
      toast.error(error.message);
      navigate('/scanners');
    });
}, []);

  const onSubmit = (updatedScanner: ScannerDTO): void => {
    updateScanner(Number(id), updatedScanner)
      .then((): void => {
        toast.success(t("updated"));
        navigate('/scanners');
      })
      .catch((error): void => {
        toast.error(`${t("failed_edit")}: ${error}`);
      });
  };

  if (!id) {
    toast.error('No scanner ID provided');
    navigate('/scanners');
  }

  if (!scanner) return null;

  return (
    <ScannerForm onSubmit={onSubmit} formType="update" initialState={scanner} />
  );
}
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { SubscriptionShop } from '../../types/subscriptions';
import { SubscriptionShopsState } from '../../types/redux';

const initialState: SubscriptionShopsState = {
  subscriptionShops: [],
};

const subscriptionShopsSlice = createSlice({
  name: 'subscriptionShops',
  initialState,
  reducers: {
    setSubscriptionShops(state, action: PayloadAction<SubscriptionShop[]>): void {
      state.subscriptionShops = action.payload;
    },
    updateSubscriptionShop(state, action: PayloadAction<SubscriptionShop>): void {
      state.subscriptionShops = state.subscriptionShops.map((s) =>
        s.id === action.payload.id ? action.payload : s
      );
    }
  },
});

const subscriptionShopsReducer = subscriptionShopsSlice.reducer;

export const { setSubscriptionShops, updateSubscriptionShop } = subscriptionShopsSlice.actions;
export default subscriptionShopsReducer;

import React, { Dispatch, SetStateAction, useEffect } from 'react';
import {
  ColumnDef,
  getCoreRowModel,
  PaginationState,
  Row,
  RowSelectionState,
  Table as TableType,
  useReactTable,
} from '@tanstack/react-table';
import Table from './Table';
import TablePagination from './TablePagination';

type SelectionTableProps<T> = {
  columns: ColumnDef<T, any>[],
  data: T[],
  rowSelection?: RowSelectionState,
  setRowSelection?: Dispatch<SetStateAction<RowSelectionState>>,
  pagination: PaginationState,
  setPagination: Dispatch<SetStateAction<PaginationState>>,
  enableRowSelection?: boolean,
  isRowDisabled?: (original: T) => boolean,
};

type WithId = {
  id: number | string,
};

function areAllRowsSelected<T>(table: TableType<T>, isRowDisabled?: (original: T) => boolean): boolean {
  return table.getCoreRowModel().rows.every(r => r.getIsSelected() || (isRowDisabled ? isRowDisabled(r.original) : false));
}

function toggleAllSelected<T>(table: TableType<T>, isRowDisabled?: (original: T) => boolean): void {
  if (!isRowDisabled) {
    table.toggleAllRowsSelected();
  } else {
    const areAllSelected = areAllRowsSelected(table, isRowDisabled);
    table.getCoreRowModel().rows.forEach(r => r.toggleSelected(isRowDisabled(r.original) ? false : !areAllSelected));
  }
}

function SelectionHeaderRenderer<T>({ table }: { table: TableType<T> }, isRowDisabled?: (original: T) => boolean): JSX.Element {
  const ref = React.useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (ref.current) {
      ref.current.indeterminate = table.getIsSomeRowsSelected();
      ref.current.checked = areAllRowsSelected(table, isRowDisabled);
    }
  }, [table.getState().rowSelection]);

  useEffect(() => {
    if (isRowDisabled) {
      table.getCoreRowModel().rows.filter(r => isRowDisabled(r.original)).forEach(r => r.toggleSelected(false));
    }
  });

  return <input
    type="checkbox"
    ref={ref}
    onChange={() => toggleAllSelected(table, isRowDisabled)}
  />;
}

function SelectionCellRenderer<T>({ row }: { row: Row<T> }, isRowDisabled: (original: T) => boolean): JSX.Element {
  return <input
    type="checkbox"
    checked={row.getIsSelected()}
    disabled={isRowDisabled(row.original)}
    onChange={row.getToggleSelectedHandler()}
  />;
}

export default function SelectionTable<T extends WithId>
({ columns, data, setRowSelection, rowSelection, pagination, setPagination, isRowDisabled, enableRowSelection = true }: SelectionTableProps<T>): JSX.Element {
  if (enableRowSelection && (!setRowSelection || !rowSelection)) {
    throw new Error('setRowSelection and rowSelection must be provided when enableRowSelection is true');
  }

  const table = useReactTable({
    columns: [
      {
        id: 'select-row',
        header: enableRowSelection ? row => SelectionHeaderRenderer(row, isRowDisabled) : () => <></>,
        cell: enableRowSelection ? row => SelectionCellRenderer(row, isRowDisabled ?? ((_) => false)) : () => <></>,
        meta: {
          align: 'text-center',
        },
      },
      ...columns,
    ],
    data,
    getCoreRowModel: getCoreRowModel(),
    getRowId: row => row.id.toString(),
    state: {
      ...(rowSelection && { rowSelection }),
      pagination,
    },
    enableRowSelection,
    manualPagination: true,
    rowCount: data.length >= pagination.pageSize ? Infinity : -1,
    onRowSelectionChange: setRowSelection,
    onPaginationChange: setPagination,
  });

  return (
    <div className="flex flex-col gap-2 justify-center items-center">
      <Table table={table} />
      <TablePagination table={table} />
    </div>
  );
}

import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { createColumnHelper, Row } from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { TbArchive, TbCirclePlus, TbEdit } from 'react-icons/tb';
import SelectionTable from '../../components/tables/SelectionTable';
import { store } from '../../redux/store';
import IconButton from '../../components/buttons/IconButton';
import { PricingPolicy } from '../../types/pricingPolicies';
import { getPricingPoliciesForCurrentLocation } from '../../services/pricingPoliciesService';
import { setPricingPolicies } from '../../redux/slices/pricingPoliciesSlice';
import usePricingPolicies from '../../hooks/selectors/usePricingPolicies';
import useLanguage from '../../hooks/selectors/useLanguage';
import usePermissions from '../../hooks/selectors/usePermissions';
import { Permissions } from '../../types/misc';

function RenderActionsCell({ row }: { row: Row<PricingPolicy> }): JSX.Element {
  const navigate = useNavigate();

  return (
    <>
      <div className="flex justify-end items-center gap-1">
        <IconButton
          onClick={(): void => navigate(`/pricing-policies/edit/${row.original.id}`)}
          icon={TbEdit} label="" variant="accent"
          className="py-1 px-1 text-lg"
          disabled={!usePermissions([Permissions.ManageConfigurations])}
        />
        <IconButton
          onClick={(): void => {}}
          icon={TbArchive} label="" variant="archive"
          className="py-1 px-1 text-lg"
          disabled={!usePermissions([Permissions.ManageConfigurations])}
        />
      </div>
    </>
  );
}

function RenderName({ row: { original: { name, archived } } }: {
  row: Row<PricingPolicy>
}): JSX.Element {
  if (!archived) return <>{name}</>;

  return (
    <div className="flex items-center gap-1 text-gray-600"><TbArchive size={16} />{name}</div>
  );
}

export default function PricingPoliciesListPage(): JSX.Element {
  if (!usePermissions([Permissions.ViewConfigurations])) {
    return (<></>);
  }
  const [pagination, setPagination] = React.useState({ pageSize: 25, pageIndex: 0 });
  const language = useLanguage();
  const navigate = useNavigate();
  const { t } = useTranslation("pricing_policy");
  const pricingPolicies = usePricingPolicies();

  useEffect((): void => {
    getPricingPoliciesForCurrentLocation(pagination.pageIndex * pagination.pageSize, pagination.pageSize)
      .then((data): void => {
        store.dispatch(setPricingPolicies(data));
      })
      .catch((error): void => {
        toast.error(error.message);
      });
  }, [pagination]);

  const columns = useMemo((): any[] => {
    const columnHelper = createColumnHelper<PricingPolicy>();
    return [
      columnHelper.accessor((row) => row.id, {
        id: 'id',
        header: t("id"),
      }),
      columnHelper.accessor((row) => row.name, {
        id: 'name',
        header: t("name"),
        cell: RenderName,
      }),
      {
        id: 'actions',
        header: t("actions"),
        cell: RenderActionsCell,
        meta: {
          align: 'text-right',
        },
      },
    ];
  }, [language]);

  return (
    <>
      <div className="flex justify-between items-center mb-2">
        <h2 className="font-gintobold font-bold text-2xl">{t("title")}</h2>
        <div className="flex gap-1">
          {
            usePermissions([Permissions.ManageConfigurations]) &&
            <IconButton onClick={(): void => navigate('/pricing-policies/create')} icon={TbCirclePlus} label={t("new")}
                        className="bg-gradient-to-r from-[#E700F9] hover:shadow-purple-300 hover:shadow-md
                      to-pink-500 duration-300 bg-[position:_0%_0%] hover:bg-[position:_100%_100%]
                      bg-[size:_200%] transition-all font-bold text-white rounded-full cursor-pointer" />
          }
        </div>
      </div>
      <SelectionTable
        columns={columns}
        data={pricingPolicies}
        pagination={pagination}
        setPagination={setPagination}
        enableRowSelection={false}
      />
    </>
  );
}

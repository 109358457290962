import React, { useEffect } from 'react';
import { AxiosError } from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getDayGroup, updateDayGroup } from '../../services/dayGroupService';
import { DayGroupCreateOrUpdate, DayGroupWithTicketTypePricingPolicies } from '../../types/dayGroups';
import DayGroupForm from '../../components/forms/DayGroupForm';

export default function EditDayGroupPage(): JSX.Element {
  const navigate = useNavigate();
  const { capacityGroupId, dayGroupId } = useParams();
  const [dayGroupWithTicketTypePricingPolicies, setDayGroupWithTicketTypePricingPolicies] = React.useState<DayGroupWithTicketTypePricingPolicies>();


  useEffect((): void => {
    if (!capacityGroupId || !dayGroupId) {
      navigate('/capacity-groups');
    }
    getDayGroup(Number(capacityGroupId), Number(dayGroupId))
      .then(dayGroup => setDayGroupWithTicketTypePricingPolicies(dayGroup))
      .catch((error): void => {
        toast.error(error.message);
        navigate(`/capacity-groups`);
      })
  }, []);

  const onSubmit = (dayGroup: DayGroupCreateOrUpdate): void => {
    updateDayGroup(Number(capacityGroupId), Number(dayGroupId), dayGroup).then((): void => {
      toast.success('Day Group created');
      navigate(`/capacity-groups/edit/${capacityGroupId}`);
    }).catch((error): void => {
      if (error instanceof AxiosError && error.response?.data.errors) {
        toast.error(
          error.response?.data.errors
            .flatMap((e: { constraints: { [k: string]: string }[] }): {
              [k: string]: string
            }[] => Object.values(e.constraints))
            .map((x: string): string => x.charAt(0).toUpperCase() + x.slice(1))
            .join('. '),
        );
      } else {
        toast.error(error.message);
      }
    });
  };

  if (!dayGroupWithTicketTypePricingPolicies) return (<></>);

  return (
    <>
      <DayGroupForm onSubmit={onSubmit} formType="update" initialState={dayGroupWithTicketTypePricingPolicies} />
    </>
  );
}
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ScanDefinition, ScanDefinitionDTO } from '../../types/tickets';
import { getScanDefinition, updateScanDefinitions } from '../../services/scanDefinitionService';
import ScanDefinitionForm from '../../components/forms/ScanDefinitionForm';

export default function ScanDefinitionEditPage(): JSX.Element | null {
  const navigate = useNavigate();
  const { t } = useTranslation("scan_definition");
  const { id } = useParams();
  const [scanDefinition, setScanDefinition] = useState<ScanDefinition>();

  useEffect((): void => {
    if (!id) {
      return;
    }

  getScanDefinition(Number(id))
    .then((oldScanDefinition): void => {
      setScanDefinition(oldScanDefinition);
    })
    .catch((error): void => {
      toast.error(error.message);
      navigate('/scan-definitions');
    });
}, []);

  const onSubmit = (updatedScanDefinitions: ScanDefinitionDTO): void => {
    updateScanDefinitions(Number(id), updatedScanDefinitions)
      .then((): void => {
        toast.success(t("updated_scan_definition"));
        navigate('/scan-definitions');
      })
      .catch((error): void => {
        toast.error(`${t("failed_edit_scan_definition")}: ${error}`);
      });
  };

  if (!id) {
    toast.error('No scandefinitions ID provided');
    navigate('/scan-definitions');
  }

  if (!scanDefinition) return null;

  return (
    <ScanDefinitionForm onSubmit={onSubmit} formType="update" initialState={scanDefinition} />
  );
}
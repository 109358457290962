import { setAccountDetails } from '../redux/slices/applicationSlice';
import { store } from '../redux/store';
import httpService from './httpService';
import { AccountDetailsDto } from './models/account.model';
import { Account, AccountDto, AccountFilter, AccountWithUser, AccountWithUserAndIdentities, Role } from '../types/account';
import { Filter } from '../types/filter';

export function getAccountDetails(): Promise<AccountDetailsDto> {
  return httpService.get<AccountDetailsDto>('/api/accounts/@me').then(({ data }) => {
    store.dispatch(setAccountDetails(data));
    return data;
  });
}

export function getAccounts(
  offset: number | undefined = undefined,
  limit: number | undefined = undefined,
  filter: AccountFilter | undefined = undefined,
  search: string | undefined = undefined
): Promise<Account[]> {
  return httpService
    .get<Account[]>(`/api/accounts/?${(offset || offset === 0) && limit ? `offset=${offset}&limit=${limit}` : ''}&filter=${encodeURI(JSON.stringify(constructFilter(filter)))}&search="${search ?? ''}"`)
    .then((response): Account[] => response.data);
}

export function deleteAccount(_accountId: number): Promise<null> {
  return Promise.resolve(null);
}

export function getAllRoles(): Promise<Role[]> {
  return httpService
    .get<Role[]>(`api/accounts/roles`)
    .then((response): Role[] => response.data);
}

export async function createAccount(newAccount: AccountDto): Promise<Account> {
  return httpService
    .post<Account>(`/api/accounts/`, newAccount)
    .then((response) => response.data);
}

export async function updateEmail(accountId: number, newEmail: string): Promise<void> {
  return httpService.put(`/api/accounts/${accountId}/email`, { email: newEmail });
}

export async function updateGeneralRole(accountId: number, roleId: number): Promise<void> {
  return httpService.put(`/api/accounts/${accountId}/roles`, { roleId });
}

export async function updateLocationRole(accountId: number, locationId: number, roleId: number): Promise<void> {
  return httpService.put(`/api/accounts/${accountId}/roles`, { roleId, locationId });
}

export async function getAccountById(accountId: number): Promise<AccountWithUserAndIdentities> {
  return httpService
    .get<AccountWithUserAndIdentities>(`/api/accounts/${accountId}`)
    .then((response) => response.data);
}

export async function getAccountByEmail(email: string): Promise<AccountWithUser | null> {
  const response = await httpService
    .get<AccountWithUser>(`/api/accounts/email`, { params: { email } })
    .then((res) => res.data)
    .catch(() => null);

  return response;
}

function constructFilter(filter: AccountFilter | undefined): Filter<Account> {
  if (!filter || filter.roles.length === 0) return {};
  return { roleId: { in:  filter.roles } };
}

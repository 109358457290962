import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { SubscriptionShopDto } from '../../types/subscriptions';
import { createSubscriptionShop } from '../../services/subscriptionShopService';
import SubscriptionShopForm from '../../components/forms/SubscriptionShopForm';

export default function SubscriptionShopsCreatePage(): JSX.Element {
  const navigate = useNavigate();
  const { t } = useTranslation("subscription_shop");

  const onSubmit = (subscriptionShop: SubscriptionShopDto): void => {
    createSubscriptionShop(subscriptionShop)
      .then((): void => {
        toast.success(t("created"));
        navigate('/subscription-shops');
      })
      .catch((error): void => {
        toast.error(`${t("failed_create")}: ${error}`);
      });
  };

  return (
    <SubscriptionShopForm onSubmit={onSubmit} />
  );
}

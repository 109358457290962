import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ScanGroupsState } from '../../types/redux';
import { ScanGroup } from '../../types/tickets';

const initialState: ScanGroupsState = {
  scanGroups: [],
};

const scanGroupsSlice = createSlice({
  name: 'scanGroups',
  initialState,
  reducers: {
    setScanGroups(state, action: PayloadAction<ScanGroup[]>): void {
      state.scanGroups = action.payload;
    },
  },
});

const scanGroupsReducer = scanGroupsSlice.reducer;

export const { setScanGroups } = scanGroupsSlice.actions;
export default scanGroupsReducer;

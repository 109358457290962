import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ScanGroup, ScanGroupDTO } from '../../types/tickets';
import { getScanGroup, updateScanGroup } from '../../services/scanGroupService';
import ScanGroupForm from '../../components/forms/ScanGroupForm';

export default function ScanGroupsEditPage(): JSX.Element | null {
  const navigate = useNavigate();
  const { id } = useParams();
  const [scanGroup, setScanGroup] = useState<ScanGroup>();
  const { t } = useTranslation("scan_groups");

  useEffect((): void => {
    if (!id) {
      return;
    }

  getScanGroup(Number(id))
    .then((oldScanGroup): void => {
      setScanGroup(oldScanGroup);
    })
    .catch((error): void => {
      toast.error(error.message);
      navigate('/scan-groups');
    });
}, []);

  const onSubmit = (updatedScanGroup: ScanGroupDTO): void => {
    updateScanGroup(Number(id), updatedScanGroup)
      .then((): void => {
        toast.success(t("updated"));
        navigate('/scan-groups');
      })
      .catch((error): void => {
        toast.error(`${t("failed_edit")}: ${error}`);
      });
  };

  if (!id) {
    toast.error('No scangroup ID provided');
    navigate('/scan-groups');
  }

  if (!scanGroup) return null;

  return (
    <ScanGroupForm onSubmit={onSubmit} type="update" initialState={scanGroup} />
  );
}

import { addLocation, setLocations, setSelected, updateLocation as updateLocationSlice } from '../redux/slices/locationsSlice';
import { store } from '../redux/store';
import { Location, LocationCreate, LocationUpdate } from '../types/location';
import httpService from './httpService';

export function getLocationsForUser(): Promise<Location[]> {
  return httpService.get<Location[]>('/api/locations').then(({ data }): Location[] => {
    store.dispatch(setLocations(data));
    if (!store.getState().locations.selectedLocation && data.length)
      store.dispatch(setSelected(data[0].id));
    return data;
  });
}

export function getSelectedLocation(): Location | undefined {
  const {selectedLocation} = store.getState().locations;
  if (!selectedLocation) return undefined;
  return store.getState().locations.locations.filter(location => location.id === selectedLocation)[0];
}

export function createLocation(model: LocationCreate): Promise<Location> {
  return httpService
    .post<Location>('/api/locations', { name: model.name, timezone: model.timezone })
    .then(({ data }): Location => {
      store.dispatch(addLocation(data));
      return data;
    });
}

export function updateLocation(model: LocationUpdate): Promise<Location> {
  const locationId = store.getState().locations.selectedLocation;

  return httpService
    .patch<Location>(`/api/locations/${locationId}`, { name: model.name })
    .then(({ data }): Location => {
      store.dispatch(updateLocationSlice(data));
      return data;
    });
}
import httpService from './httpService';
import { ConfirmationEmail, Payment, PaymentFilter, RefundPaymentData } from '../types/payment';
import { Filter, PaymentStatus, PurchaseType } from '../types/filter';

export async function getPayments(
  offset: number | undefined = undefined, 
  limit: number | undefined = undefined, 
  search: string | undefined = undefined, 
  filter: PaymentFilter | undefined = undefined,): Promise<Payment[]> {
  return httpService
  .get<Payment[]>(`/api/payments/?${(offset || offset === 0) && limit ? `offset=${offset}&limit=${limit}` : ''}&filter=${encodeURI(JSON.stringify(constructFilter(filter)))}&search="${search ?? ''}"`)
    .then((response): Payment[] => response.data);
}
  
export async function getPayment(paymentId: number): Promise<Payment> {
    return httpService
      .get<Payment>(`/api/payments/${paymentId}`)
      .then((response): Payment => {
        return response.data;
      });
}

export async function postEmailConfirmation(paymentId: number, data: ConfirmationEmail): Promise<void> {
  return httpService
    .post<void>(`/api/payments/${paymentId}/send`, data)
    .then((response): void => response.data);
}

export async function postPaymentRefund(paymentId: number, data: RefundPaymentData): Promise<void> {
  return httpService
    .post<void>(`/api/payments/${paymentId}/refund`, data)
    .then((response): void => response.data);
}

function constructFilter(paymentFilter: PaymentFilter | undefined): Filter<Payment & { archived: boolean }> {
  if (!paymentFilter) {
    return {};
  }
  const paymentStatusFilter: Filter<Payment> =
    paymentFilter.status === PaymentStatus.ALL ? {} :
    { status: { in: [paymentFilter.status] }};
  const purchaseTypeFilter: Filter<Payment> =
    paymentFilter.type === PurchaseType.ALL ? {} :
    paymentFilter.type === PurchaseType.TICKET ? { purchases: { some: { type: { in: [paymentFilter.type]} }}} :
    paymentFilter.type === PurchaseType.SUBSCRIPTION ? { purchases: { some: { type: { in: [paymentFilter.type]} }}} :
    paymentFilter.type === PurchaseType.REFUND ? { purchases: { some: { type: { in: [paymentFilter.type]} }}} : {};
  return {
    AND: [
      paymentStatusFilter,
      purchaseTypeFilter
    ]
  }
}

  

import { DAY_IN_MS } from '../utils/dateUtil';

export const dayFilterOptions = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'] as const;
export const availableCapacityFilterOptions = ['zero', '1_to_10', '11_to_100', '101_to_1000', 'more_than_1000'] as const;
export type AvailableCapacityOption = typeof availableCapacityFilterOptions[number]
export type Weekday = typeof dayFilterOptions[number];

export type AvailableCapacityFilters = {
  // low and high should be included in comparison, i.e. low <= x <= high
  [key in AvailableCapacityOption]: { low: number, high: number }
}

export const availableCapacityFilters: AvailableCapacityFilters = {
  'zero': { low: 0, high: 0 },
  '1_to_10': { low: 0, high: 10 },
  '11_to_100': { low: 11, high: 100 },
  '101_to_1000': { low: 101, high: 1000 },
  'more_than_1000': { low: 1001, high: Infinity },
};

export type DayGroupFilters = {
  fromDate: Date;
  toDate: Date;
  timeSlotIds: string[];
  dayFilter: Weekday[];
  availableCapacityFilter: AvailableCapacityOption[];
  totalCapacityFilter: number[];
}

export const initialDayGroupFilters: DayGroupFilters = {
  fromDate: new Date(),
  toDate: new Date(Date.now() + 30 * DAY_IN_MS),
  timeSlotIds: [],
  dayFilter: [],
  availableCapacityFilter: [],
  totalCapacityFilter: [],
};

import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CapacityGroupState } from '../../types/redux';
import { CapacityGroup } from '../../types/tickets';

const initialState: CapacityGroupState = {
  capacityGroups: [],
}

const capacityGroupSlice = createSlice({
  name: 'capacityGroups',
  initialState,
  reducers: {
    setCapacityGroups(state, action: PayloadAction<CapacityGroup[]>): void {
      state.capacityGroups = action.payload;
    }
  }
})

const capacityGroupReducer = capacityGroupSlice.reducer;

export const { setCapacityGroups } = capacityGroupSlice.actions;
export default capacityGroupReducer;

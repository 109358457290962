import { ScanDefinition, ScanDefinitionDTO } from '../types/tickets';
import httpService from './httpService';
import { store } from '../redux/store';

export async function getScanDefinitionsCurrentLocation(offset: number, limit: number): Promise<ScanDefinition[]> {
  const locationId = store.getState().locations.selectedLocation;

  return httpService
    .get<ScanDefinition[]>(`/api/locations/${locationId}/scan-definitions?offset=${offset}&limit=${limit}`)
    .then((response): ScanDefinition[] => response.data);
}

export async function createScanDefinition(data: ScanDefinitionDTO): Promise<ScanDefinition> {
    const locationId = store.getState().locations.selectedLocation;
  
    return httpService
      .post<ScanDefinition>(`/api/locations/${locationId}/scan-definitions`, data)
      .then((response): ScanDefinition => response.data);
}

export async function deleteScanDefinition(scanDefinitionId: number): Promise<ScanDefinition> {
    const locationId = store.getState().locations.selectedLocation;
  
    if (!locationId) {
      throw new Error('No location selected');
    }
  
    return httpService
      .delete<ScanDefinition>(`/api/locations/${locationId}/scan-definitions/${scanDefinitionId}`)
      .then((response): ScanDefinition => response.data);
}
  
export async function updateScanDefinitions(scanGroupId: number, data: Partial<ScanDefinitionDTO>): Promise<ScanDefinition> {
    const locationId = store.getState().locations.selectedLocation;

    return httpService
        .put<ScanDefinition>(`/api/locations/${locationId}/scan-definitions/${scanGroupId}`, data)
        .then((response): ScanDefinition => response.data);
}
  
export async function getScanDefinition(scanDefinitionId: number): Promise<ScanDefinition> {
    const locationId = store.getState().locations.selectedLocation;
  
    return httpService
      .get<ScanDefinition>(`/api/locations/${locationId}/scan-definitions/${scanDefinitionId}`)
      .then((response): ScanDefinition => {
        return response.data;
      });
}
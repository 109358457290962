import React, { ButtonHTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';
import { IconType } from 'react-icons';
import IconButton from './IconButton';

type HeroButtonProps = {
  label: string,
  icon: IconType,
} & ButtonHTMLAttributes<HTMLButtonElement>;

export default function HeroButton({ className, ...props }: HeroButtonProps): JSX.Element {
  return (
    <IconButton
      {...props}
      className={
        twMerge(
          'bg-gradient-to-r from-[#E700F9] hover:shadow-purple-300 hover:shadow-md to-pink-500 duration-300 ' +
          'bg-[position:_0%_0%] hover:bg-[position:_100%_100%] ' +
          'bg-[size:_200%] transition-all font-bold text-white rounded-full cursor-pointer',
          className,
        )
      }
    />
  );
}

import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { toast } from 'react-toastify';
import { EmailTemplateDto } from '../../types/emailTemplates';
import { createEmailTemplateForLocation } from '../../services/emailTemplatesService';
import EmailTemplateForm from '../../components/forms/EmailTemplateForm';

export default function EmailTemplatesCreatePage(): JSX.Element {
  const navigate = useNavigate();
  const { t } = useTranslation("email_templates");
  const onSubmit = (emailTemplate: EmailTemplateDto): void => {
    createEmailTemplateForLocation(emailTemplate)
      .then(() => {
        toast.success(t("created"))
        navigate('/templates')
      })
      .catch((error): void => {
        toast.error(`${t("failed_create")}: ${error}`);
      });
  }
  return (
    <EmailTemplateForm onSubmit={onSubmit} />
  )
}

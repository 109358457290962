import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { DiscountOffer } from '../../types/discounts';
import { DiscountOffersState } from '../../types/redux';

const initialState: DiscountOffersState = {
    discountOffers: [],
};

const discountOffersSlice = createSlice({
  name: 'subscriptionType',
  initialState,
  reducers: {
    setDiscountOffers(state, action: PayloadAction<DiscountOffer[]>): void {
      state.discountOffers = action.payload;
    },
    setDiscountOffer(state, action: PayloadAction<DiscountOffer>): void {
      state.discountOffers = state.discountOffers.map((s) =>
        s.id === action.payload.id ? action.payload : s
      );
    }
  },
});

const discountOffersReducer = discountOffersSlice.reducer;

export const { setDiscountOffers, setDiscountOffer } = discountOffersSlice.actions;
export default discountOffersReducer;

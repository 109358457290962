import React from 'react';
import { SelectProps } from '@headlessui/react';
import Select from './Select';

type OptionItem = {
  id: any;
  name: string;
}

type SelectWithDataProps = {
  placeholder: string;
  data: OptionItem[];
  selectedValueId?: any;
  containerClassName?: string;
} & SelectProps;

export default function SelectWithoutLabel(
  {
    data,
    placeholder,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    selectedValueId,
    id,
    containerClassName,
    required,
    ...props
  }: SelectWithDataProps,
): JSX.Element {
  return (
    <div className={containerClassName}>
      <Select defaultValue="" id={id} required={required} {...props}>
        <option value="" disabled>{placeholder}</option>
        {!required && <option value="undefined">None</option>}
        {data.map((entry) => (
          <option key={entry.id} value={entry.id}>
            {entry.name}
          </option>
        ))}
      </Select>
    </div>
  );
}

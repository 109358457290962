import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import {  DiscountOfferUpdateDto, ExtendedDiscountOffer } from '../../types/discounts';
import { getDiscountOffer, updatDiscountOffer } from '../../services/discountService';
import DiscountOfferForm from '../../components/forms/DiscountOfferForm';

export default function EditDiscountOfferPage(): JSX.Element | null {
  const navigate = useNavigate();
  const [discountOffer, setDiscountOffer] = useState<ExtendedDiscountOffer>();
  const { id } = useParams();
  const { t } = useTranslation("discount_offer");

  useEffect((): void => {
    if (!id) {
      navigate('/discount-offers');
    }

    getDiscountOffer(Number(id))
      .then((oldDiscountOffer): void => {
        setDiscountOffer(oldDiscountOffer);
      })
      .catch((error): void => {
        toast.error(error.message);
        navigate('/discount-offers');
      });
  }, []);

  const onSubmit = (updatediscountOffer: DiscountOfferUpdateDto): void => {
    updatDiscountOffer(Number(id), updatediscountOffer).then((): void => {
      toast.success(t("updated"));
      navigate('/discount-offers');
    }).catch((error): void => {
      toast.error(error.message);
    });
  };

  if (!discountOffer) return null;

  return (
    <DiscountOfferForm initialState={discountOffer} onSubmit={onSubmit} formType="update" />
  );
}

import React from 'react';
import { Select as HeadlessSelect, SelectProps } from '@headlessui/react';
import { TbChevronDown } from 'react-icons/tb';
import { twMerge } from 'tailwind-merge';

export default function Select({ className, ...props }: SelectProps): JSX.Element {

  return (
    <div className="relative">
      <HeadlessSelect {...props}
                      className={twMerge(
                        'block w-full appearance-none border-[1.5px] border-[#E2E8F0] bg-white py-3 px-5 text-black outline-none transition focus:border-[#3C50E0] active:border-[#3C50E0] disabled:cursor-default disabled:bg-[#F5F7FD]',
                        !props.value ? 'text-gray-400' : 'text-black',
                        className as string,
                      )}
      />
      <TbChevronDown size={24} className="group pointer-events-none absolute top-1/2 translate-y-[-50%] right-4" />
    </div>
  );
}

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextInputWithLabel from './TextInputWithLabel';
import Button from '../buttons/Button';
import { ConfirmationEmail } from '../../types/payment';

type EmailConfirmationProps = {
  onSubmit: (confirmationEmail: ConfirmationEmail) => void;
  onCancel: () => void;
  email: string,
}

type EmailConfirmationFormState = {
  email: string,
}

function TicketConfirmationForm({
  onSubmit,
  onCancel,
  email,
}: EmailConfirmationProps): JSX.Element {
const [emailConfirmation, setEmailConfirmation] = useState<EmailConfirmationFormState>({
    email: email || '',
  });

  const { t } = useTranslation("email_confirmation_form");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {
    setEmailConfirmation((prev): EmailConfirmationFormState => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    onSubmit({
      email: emailConfirmation.email,
    });
  };

  return (
    <>
      <h2 className="text-2xl font-bold text-center mb-4">{t("title")}</h2>
      <form className="flex flex-col gap-4 max-w-3xl m-auto" onSubmit={handleSubmit}>
        <TextInputWithLabel label={t("email")} id="email" placeholder={t("email_placeholder")} type='email' name="email" onChange={handleChange}
                            value={emailConfirmation.email} className="bg-white" required />

        <div className="flex justify-end gap-2 my-4">
          <Button type="button" className="bg-gray-500" onClick={onCancel}>
            {t("cancel_button")}
          </Button>
          <Button type="submit">{t("send_button")}</Button>
        </div>
      </form>
    </>
  );
}

export default TicketConfirmationForm;

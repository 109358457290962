import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Language } from '../../types/misc';
import { ApplicationState } from '../../types/redux';
import { AccountDetailsDto } from '../../services/models/account.model';

const initialState: ApplicationState = {
  language: Language.NL,
  isAuthenticated: false,
};

const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    setLanguage(state, action: PayloadAction<Language>): void {
      state.language = action.payload;
    },
    setAuthenticated(state, action: PayloadAction<boolean>): void {
      state.isAuthenticated = action.payload;
    },
    setAccountDetails(state, action: PayloadAction<AccountDetailsDto>): void {
      state.email = action.payload.email;
      state.permissions = action.payload.permissions;
    },
  },
});

const applicationReducer = applicationSlice.reducer;

export const { setLanguage, setAuthenticated, setAccountDetails } = applicationSlice.actions;
export default applicationReducer;

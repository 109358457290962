/* eslint-disable react/destructuring-assignment */
import React, { InputHTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

type TextInputProps = {
  input?: string;
  setInput?: (input: string) => void;
} & InputHTMLAttributes<HTMLInputElement>;

export default function TextInput({
  className,
  input,
  setInput,
  ...props
}: TextInputProps): JSX.Element {
  return (
    <input
      {...props}
      className={twMerge(
        'w-full rounded-lg border-[1.5px] border-[#E2E8F0] bg-transparent py-3 px-5 text-black outline-none transition focus:border-[#3C50E0] active:border-[#3C50E0] disabled:cursor-default disabled:bg-[#F5F7FD]',
        className,
      )}
      value={input ?? props.value}
      onChange={setInput ? (e): void => setInput(e.target.value) : props.onChange}
    />
  );
}

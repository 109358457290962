import { createColumnHelper, PaginationState, Row, RowSelectionState } from '@tanstack/react-table';
import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { TbArchive, TbCirclePlus, TbEdit, TbTrash } from 'react-icons/tb';
import { toast } from 'react-toastify';
import SelectionTable from '../../components/tables/SelectionTable';
import IconButton from '../../components/buttons/IconButton';
import DeleteModal from '../../components/modals/DeleteModal';
import { DayGroup } from '../../types/dayGroups';
import { secondsToHHMM } from '../../utils/dateUtil';
import { deleteDayGroup } from '../../services/dayGroupService';
import useLanguage from '../../hooks/selectors/useLanguage';

type DayGroupTableProps = {
  initialState?: DayGroup[];
  capacityGroupId: number | undefined;
};

const columnHelper = createColumnHelper<DayGroup>();

function RenderActionsCell(
  capacityGroupId: number | undefined,
  { row }: { row: Row<DayGroup> },
  onDeleteSuccess: (id: number) => void
): JSX.Element {
  const [deleteModalOpen, setDeleteModalOpen] = React.useState<boolean>(false);
  const navigate = useNavigate();

  const onDelete = (): void => {
    if (capacityGroupId) {
      deleteDayGroup(capacityGroupId, row.original.id)
        .then(() => {
          toast.success('Day group deleted');
          onDeleteSuccess(row.original.id);
        })
    }
    toast.update("Delete");
  };

  return (
    <>
      <div className="flex justify-end items-center gap-1">
        <IconButton
          onClick={(): void => navigate(`/capacity-groups/edit/${capacityGroupId}/edit-day-group/${row.original.id}`)}
          icon={TbEdit} label="" variant="accent"
          className="py-1 px-1 text-lg"
        />
        <IconButton
          onClick={(): void => {}}
          icon={TbArchive} label="" variant="archive"
          className="py-1 px-1 text-lg"
        />
        <IconButton
          onClick={(): void => setDeleteModalOpen(true)}
          icon={TbTrash} label="" variant="danger"
          className="py-1 px-1 text-lg"
        />
      </div>
      <DeleteModal open={deleteModalOpen} setOpen={setDeleteModalOpen} onActionButtonClicked={onDelete}
                   message={`Are you sure you want to delete the day group "${row.original.day}"?`}
      />
    </>
  );
}

export default function DayGroupTable({
  initialState,
  capacityGroupId,
}: DayGroupTableProps): JSX.Element {
  if (!initialState) {
    return (<></>)
  }

  console.log(capacityGroupId);

  const [dayGroups, setDayGroups] = useState<DayGroup[]>(initialState || []);
  const [rowSelection, setRowSelection] = React.useState<RowSelectionState>({});
  const [pagination, setPagination] = React.useState<PaginationState>({ pageIndex: 0, pageSize: 25 });

  const language = useLanguage();
  const navigate = useNavigate();
  const { t } = useTranslation("day_groups_table");

  const columns = useMemo((): any[] => [
    columnHelper.accessor((row): number => row.day, {
      id: 'dayOfWeek',
      header: t("weekday"),
      cell: ({ row: { original: { day } } }): string => day.toString()
    }),
    columnHelper.accessor((row): string => row.startTime, {
      id: 'startTime',
      header: t("start_time"),
      cell: ({ row: { original: { startTime } } }): string => secondsToHHMM(startTime )
    }),
    columnHelper.accessor((row): string => row.startTime, {
      id: 'endTime',
      header: t("end_time"),
      cell: ({ row: { original: { endTime } } }): string => secondsToHHMM(endTime)
    }),
    columnHelper.accessor((row): number => row.capacity, {
      id: 'capacity',
      header: t("capacity"),
      cell: ({ row: { original: { capacity } } }): string => capacity.toString(),
    }),
    columnHelper.accessor((row): boolean => row.active, {
      id: 'active',
      header: t("active"),
      cell: ({ row: { original: { active } } }): string => active.toString(),
    }),
    {
      id: 'actions',
      header: t("actions"),
      cell: (row: { row: Row<DayGroup> }) => RenderActionsCell(capacityGroupId, row, (id: number) => setDayGroups((prev) => prev.filter((dg) => dg.id !== id))),
      meta: {
        align: 'text-right',
      },
    },
  ], [language]);

  return <div>
    <div className="flex justify-between items-center mb-4">
      <h2 className="font-gintobold font-bold text-2xl">{t("title")}</h2>
      <div className="flex gap-1">
        <IconButton onClick={(): void => navigate(`/capacity-groups/edit/${capacityGroupId}/create-day-group`)} icon={TbCirclePlus}
                    label={t("new")}
                    className="bg-gradient-to-r from-[#E700F9] hover:shadow-purple-300 hover:shadow-md
                      to-pink-500 duration-300 bg-[position:_0%_0%] hover:bg-[position:_100%_100%]
                      bg-[size:_200%] transition-all font-bold text-white rounded-full cursor-pointer" />
      </div>
    </div>
    <div>
      <SelectionTable
        columns={columns}
        data={dayGroups}
        rowSelection={rowSelection}
        setRowSelection={setRowSelection}
        pagination={pagination}
        setPagination={setPagination}
        enableRowSelection
      />
    </div>
  </div>

}
import httpService from './httpService';
import { store } from '../redux/store';
import { Filter, Status } from '../types/filter';
import { BlockStatus, DiscountApplicabilityTypeSelection, DiscountCode, DiscountCodeDto, DiscountCodeFilter, DiscountOffer, DiscountOfferFilter, DiscountOfferUpdateDto, DiscountTypeSelection, ExtendedDiscountOffer } from '../types/discounts';

export async function createDiscountOffer(model: DiscountOfferUpdateDto): Promise<DiscountOffer> {
  const locationId = store.getState().locations.selectedLocation;

  return httpService
    .post<DiscountOffer>(`/api/locations/${locationId}/discount-offers`, model)
    .then(({ data }): DiscountOffer => data);
}

export async function getDiscountOffersForCurrentLocation(offset: number, limit: number, filter?: DiscountOfferFilter): Promise<DiscountOffer[]> {
  const locationId = store.getState().locations.selectedLocation;

  return httpService
    .get<DiscountOffer[]>(`/api/locations/${locationId}/discount-offers?filter=${encodeURI(JSON.stringify(constructFilter(filter)))}&offset=${offset}&limit=${limit}`)
    .then(({ data }): DiscountOffer[] => data);
}

export async function deleteDiscountOffer(discountOfferId: number): Promise<DiscountOffer> {
  const locationId = store.getState().locations.selectedLocation;

  if (!locationId) {
    throw new Error('No location selected');
  }

  return httpService
    .delete<DiscountOffer>(`/api/locations/${locationId}/discount-offers/${discountOfferId}`)
    .then(({ data }): DiscountOffer => data);
}

export async function updatDiscountOffer(discountOfferId: number, model: DiscountOfferUpdateDto): Promise<DiscountOffer> {
  const locationId = store.getState().locations.selectedLocation;

  return httpService
    .put<DiscountOffer>(`/api/locations/${locationId}/discount-offers/${discountOfferId}`, model)
    .then(({ data }): DiscountOffer => data);
}

export async function getDiscountOffer(discountOfferId: number): Promise<ExtendedDiscountOffer> {
  const locationId = store.getState().locations.selectedLocation;

  return httpService
    .get<ExtendedDiscountOffer>(`/api/locations/${locationId}/discount-offers/${discountOfferId}`)
    .then(({ data }): ExtendedDiscountOffer => data);
}

function constructFilter(discountOfferFilter: DiscountOfferFilter | undefined): Filter<DiscountOffer> {
  if (!discountOfferFilter) {
    return {};
  }

  const applicableToFilter = discountOfferFilter.applicabilityType === DiscountApplicabilityTypeSelection.ALL 
    ? {} 
    : { applicableTo: { equals: discountOfferFilter.applicabilityType } } as Filter<DiscountOffer>;
  
  const discountTypeFilter = discountOfferFilter.discountType === DiscountTypeSelection.ALL 
    ? {}
    : { discountType: { equals: discountOfferFilter.discountType } } as Filter<DiscountOffer>;

  const activeFilter: Filter<DiscountOffer> =
    discountOfferFilter.status === Status.ALL ? {} :
    discountOfferFilter.status === Status.ACTIVE ? { archived: { equals: false } } :
    discountOfferFilter.status === Status.ARCHIVED ? { archived: { equals: true} } : {};

  return {
    ...applicableToFilter,
    ...discountTypeFilter,
    ...activeFilter,
  }
}

export async function getDiscountCodesForDiscountOffer(discountOfferId: number, offset: number, limit: number, filter?: DiscountCodeFilter): Promise<DiscountCode[]> {
  const locationId = store.getState().locations.selectedLocation;

  return httpService
    .get<DiscountCode[]>(`/api/locations/${locationId}/discount-offers/${discountOfferId}/codes?filter=${encodeURI(JSON.stringify(constructCodeFilter(filter)))}&offset=${offset}&limit=${limit}`)
    .then(({ data }): DiscountCode[] => data);
}

export async function generateDiscountCodesForDiscountOffer(discountOfferId: number, model: DiscountCodeDto, count: number = 1): Promise<void> {
  const locationId = store.getState().locations.selectedLocation;

  return httpService
    .post<void>(`/api/locations/${locationId}/discount-offers/${discountOfferId}/codes/generate?count=${count}`, model)
    .then((_): void => {});
}

export async function blockDiscountCodesForDiscountOffer(discountOfferId: number, codes: string[]): Promise<void> {
  const locationId = store.getState().locations.selectedLocation;

  return httpService
    .post<void>(`/api/locations/${locationId}/discount-offers/${discountOfferId}/codes/block`, { codes })
    .then((_): void => {});
}

export async function unblockDiscountCodesForDiscountOffer(discountOfferId: number, codes: string[]): Promise<void> {
  const locationId = store.getState().locations.selectedLocation;

  return httpService
    .post<void>(`/api/locations/${locationId}/discount-offers/${discountOfferId}/codes/unblock`, { codes })
    .then((_): void => {});
}

export async function deleteDiscountCodesForDiscountOffer(discountOfferId: number, codes: string[]): Promise<void> {
  const locationId = store.getState().locations.selectedLocation;

  return httpService
    .delete<void>(`/api/locations/${locationId}/discount-offers/${discountOfferId}/codes`, { data: { codes } })
    .then((_): void => {});
}

function constructCodeFilter(discountCodeFilter: DiscountCodeFilter | undefined): Filter<DiscountCode> {
  if (!discountCodeFilter) {
    return {};
  }

  const blockFilter: Filter<DiscountCode> =
    discountCodeFilter.block === BlockStatus.ALL ? {} :
    discountCodeFilter.block === BlockStatus.NOT_BLOCKED ? { frozen: { equals: false } } :
    discountCodeFilter.block === BlockStatus.BLOCKED ? { frozen: { equals: true} } : {};

  return {
    ...blockFilter,
  }
}
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { createColumnHelper, PaginationState, Row } from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';
import { TbArchive, TbCirclePlus, TbDragDrop2, TbEdit, TbTrash } from 'react-icons/tb';
import { toast } from 'react-toastify';
import { BiDuplicate } from 'react-icons/bi';
import { CapacityGroup } from '../types/tickets';
import SelectionTable from '../components/tables/SelectionTable';
import { store } from '../redux/store';
import IconButton from '../components/buttons/IconButton';
import CheckboxDropdown from '../components/forms/CheckboxDropdown';
import {
  deleteCapacityGroup,
  getCapacityGroupsForCurrentLocation,
  getCapacityGroupYears,
} from '../services/capacityGroupService';
import { setCapacityGroups } from '../redux/slices/capacityGroupSlice';
import DeleteModal from '../components/modals/DeleteModal';
import { getSelectedLocation } from '../services/locationService';
import useCapacityGroups from '../hooks/selectors/useCapacityGroups';
import { Status, Validity } from '../types/filter';
import { CapacityGroupFilter, CapacityGroupYears } from '../types/capacityGroups';
import useLanguage from '../hooks/selectors/useLanguage';
import { OptionItem } from '../components/forms/SelectWithData';
import usePermissions from '../hooks/selectors/usePermissions';
import { Permissions } from '../types/misc';

const columnHelper = createColumnHelper<CapacityGroup>();

function RenderName({ row: { original: { name } } }: {
  row: Row<CapacityGroup>
}): JSX.Element {
  return <>{name}</>;
}

function constructDayRenderer(dateName: 'startDate' | 'endDate') {
  return function({ row: { original } }: {
    row: Row<CapacityGroup>
  }): JSX.Element {
    const timeZone: string = getSelectedLocation()?.timezone ?? 'UTC';
    return <>{new Date(original[dateName]).toLocaleDateString('nl-NL', {day: '2-digit', month: '2-digit', year: 'numeric', timeZone})}</>;
  }
}

function RenderActionsCell({ row }: { row: Row<CapacityGroup> }, resetPagination: () => void): JSX.Element {
  const [deleteModalOpen, setDeleteModalOpen] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const { t } = useTranslation("capacity_group");

  const onDelete = (): void => {
    deleteCapacityGroup(row.original.id)
      .then((): void => {
        toast.success(t("deleted"));
        resetPagination();
        setDeleteModalOpen(false);
      })
      .catch((error): void => {
        toast.error(error.message);
      });
  };

  return (
    <>
      <div className="flex justify-end items-center gap-1">
        <IconButton
          onClick={(): void => navigate(`/capacity-groups/editor/${row.original.id}`)}
          icon={TbDragDrop2} label={t('slot_editor')} variant="view"
          className="py-1 px-1 text-lg"
          disabled={!usePermissions([Permissions.ManageConfigurations])}
        />
        <IconButton
          onClick={(): void => navigate(`/capacity-groups/edit/${row.original.id}`)}
          icon={TbEdit} label="" variant="accent"
          className="py-1 px-1 text-lg"
          disabled={!usePermissions([Permissions.ManageConfigurations])}
        />
        <IconButton
          onClick={(): void => {}}
          icon={BiDuplicate} label="" variant="duplicate"
          className="py-1 px-1 text-lg"
          disabled={!usePermissions([Permissions.ManageConfigurations])}
        />
        <IconButton
          onClick={(): void => {}}
          icon={TbArchive} label="" variant="archive"
          className="py-1 px-1 text-lg"
          disabled={!usePermissions([Permissions.ManageConfigurations])}
        />
        <IconButton
          onClick={(): void => setDeleteModalOpen(true)}
          icon={TbTrash} label="" variant="danger"
          className="py-1 px-1 text-lg"
          disabled={!usePermissions([Permissions.ManageConfigurations])}
        />
      </div>
      <DeleteModal open={deleteModalOpen} setOpen={setDeleteModalOpen} onActionButtonClicked={onDelete}
                   message={`${t("delete_confirmation")} "${row.original.name}"?`}
      />
    </>
  );
}


export default function CapacityGroupPage(): JSX.Element {
  if (!usePermissions([Permissions.ViewConfigurations])) {
    return (<></>);
  }
  const [pagination, setPagination] = React.useState<PaginationState>({ pageIndex: 0, pageSize: 25 });
  const [filter, setFilter] = React.useState<CapacityGroupFilter>({ validity: Validity.ALL, years: [], status: Status.ACTIVE });
  const [capacityGroupYears, setCapacityGroupYears] = React.useState<CapacityGroupYears[]>([]);
  const capacityGroups = useCapacityGroups();
  const language = useLanguage();
  const navigate = useNavigate();
  const { t } = useTranslation("capacity_group");

  const capacityGroupValidity: Record<Validity, OptionItem> = {
    [Validity.ALL]: {id: Validity.ALL, name: t("all")},
    [Validity.CURRENT]: {id: Validity.CURRENT, name: t("current")},
    [Validity.EXPIRED]: {id: Validity.EXPIRED, name: t("expired")},
  }

  const capacityGroupStatus : Record<Status, OptionItem>= {
    [Status.ALL]: {id: Status.ALL, name: t("all")},
    [Status.ACTIVE]: {id: Status.ACTIVE, name: t("active")},
    [Status.ARCHIVED]: {id: Status.ARCHIVED, name: t("archived")},
  }

  const resetPagination = (): void => {
    setPagination(prev => ({ pageSize: prev.pageSize, pageIndex: prev.pageIndex }));
  }

  React.useEffect((): void => {
    getCapacityGroupsForCurrentLocation(pagination.pageIndex * pagination.pageSize, pagination.pageSize, filter)
      .then((data): void => {
        store.dispatch(setCapacityGroups(data));
      })
      .catch((error): void => {
        toast.error(error);
      });
  }, [pagination, filter]);

  React.useEffect((): void => {
    getCapacityGroupYears()
      .then((data) => {
        setCapacityGroupYears(data);
      });
  }, []);

  const onChangeValidity = (selected: { id: Validity, name: string }[]): void => {
    if (selected.length === 0) return;
    setFilter(prevFilter => ({ ...prevFilter, validity: selected[0].id }));
  }

  const onChangeStatus = (selected: { id: Status, name: string }[]): void => {
    if (selected.length === 0) return;
    setFilter(prevFilter => ({ ...prevFilter, status: selected[0].id }));
  }

  const onChangeYears = (selected: CapacityGroupYears[]): void => {
    setFilter(prevFilter => ({ ...prevFilter, years: selected.map((year) => year.id) }));
  }

  const columns = useMemo((): any[] => [
    columnHelper.accessor((row): string => row.name, {
      id: 'name',
      header: t("name"),
      cell: RenderName,
    }),
    columnHelper.accessor((row): string => row.name, {
      id: 'startDate',
      header: t("start_date"),
      cell: constructDayRenderer('startDate'),
    }),
    columnHelper.accessor((row): string => row.name, {
      id: 'endDate',
      header: t("end_date"),
      cell: constructDayRenderer('endDate'),
    }),
    columnHelper.accessor((row): string => row.name, {
      id: 'slotDuration',
      header: t("slot_duration"),
      cell: ({ row: { original: { slotDuration } } }): string => `${slotDuration < 0 ? '-' : slotDuration.toString()}`
    }),
    {
      id: 'actions',
      header: t("actions"),
      cell: (row: { row: Row<CapacityGroup> }) => RenderActionsCell(row, resetPagination),
      meta: {
        align: 'text-right',
      },
    },
  ], [language]);

  return (
    <>
      <div className="flex justify-between items-center">
        <h2 className="font-gintobold font-bold text-2xl">{t("title")}</h2>
        <div className="flex gap-1">
          {
            usePermissions([Permissions.ManageConfigurations]) &&
            <IconButton onClick={(): void => navigate('/capacity-groups/create')} icon={TbCirclePlus} label={t('new')}
                       className='bg-gradient-to-r from-[#E700F9] hover:shadow-purple-300 hover:shadow-md
                      to-pink-500 duration-300 bg-[position:_0%_0%] hover:bg-[position:_100%_100%]
                      bg-[size:_200%] transition-all font-bold text-white rounded-full cursor-pointer'
            />
          }
        </div>
      </div>
      <div className="flex gap-1 items-center py-2">
        <p className="font-semibold text-md">{t("filters")}: </p>
        <CheckboxDropdown label={t("validity")} values={Object.values(capacityGroupValidity)} onChange={onChangeValidity} type="radio" initialValues={[capacityGroupValidity[filter.validity]]}/>
        <CheckboxDropdown label={t("year")} values={capacityGroupYears} onChange={onChangeYears} type="checkbox"/>
        <CheckboxDropdown label={t("status")} values={Object.values(capacityGroupStatus)} onChange={onChangeStatus} type="radio" initialValues={[capacityGroupStatus[filter.status]]} />
      </div>
      <div>
        <SelectionTable
          columns={columns}
          data={capacityGroups}
          pagination={pagination}
          setPagination={setPagination}
          enableRowSelection={false}
        />
      </div>
    </>
  )
}

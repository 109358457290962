import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { toast } from 'react-toastify';
import { EmailTemplateDto, EmailTemplateWithData } from '../../types/emailTemplates';
import TextInputWithLabel from './TextInputWithLabel';
import UploadField from '../misc/UploadField';
import Button from '../buttons/Button';
import usePermissions from '../../hooks/selectors/usePermissions';
import { Permissions } from '../../types/misc';

type EmailFormProps = {
  onSubmit: (emailTemplate: EmailTemplateDto) => void;
  initialState?: EmailTemplateWithData;
  formType?: 'create' | 'update';
}

type EmailTemplateFormState = {
  name: string;
  subject: string;
  data: string;
}

export default function EmailTemplateForm({ onSubmit, initialState, formType = 'create' }: EmailFormProps): JSX.Element {
  if (!usePermissions([Permissions.ManageConfigurations])) {
    return (<></>);
  }
  const navigate = useNavigate();
  const { t } = useTranslation("email_templates");
  const [emailTemplate, setEmailTemplate] = React.useState<EmailTemplateFormState>({
    name: initialState?.name || '',
    subject: initialState?.subject || '',
    data: initialState?.data || '',
  });
  const [isFileUploaded, setIsFileUploaded] = React.useState<boolean>(!!initialState?.data);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {
    setEmailTemplate((prev): EmailTemplateFormState => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const handleFileUpload = ((file: File): void => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const fileContent = event.target?.result as string;
      setEmailTemplate((prev) => ({
        ...prev,
        data: fileContent,
      }));
      setIsFileUploaded(true);
    };

    reader.onerror = (error) => {
      toast.error(`${t("read_error_html")}${error}`);
    };

    reader.readAsText(file);
  });

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    onSubmit({
      name: emailTemplate.name,
      data: emailTemplate.data,
      subject: emailTemplate.subject,
    });
  }
  const handleFileSelection = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const file = event.target.files?.[0];
    if (file) {
      handleFileUpload(file);
    }
  };

  return (
    <form className="max-w-3xl mx-auto w-full" onSubmit={handleSubmit}>
      <h2
        className="text-2xl font-semibold mb-4 text-center">{formType === 'create' ? t("create_title") : t("edit_title")}</h2>
      <TextInputWithLabel label={t("name")} id="name" value={emailTemplate.name} onChange={handleChange} name="name"
                          placeholder={t("name_placeholder")} className="bg-white" required />
      <TextInputWithLabel label={t("subject")} id="name" value={emailTemplate.subject} onChange={handleChange} name="subject"
                          placeholder={t("subject_placeholder")} className="bg-white" required />
      <UploadField isFileUploaded={isFileUploaded} handleFileSelection={handleFileSelection} translation="email_templates" fileTypes={['html']} />
      <div className="flex justify-end gap-2 my-4">
        <Button type="button" className="bg-gray-500" onClick={(): void => navigate('/templates')}>
          {t("cancel_button")}
        </Button>
        <Button type="submit">{formType === 'create' ? t("create_button") : t("save_button")}</Button>
      </div>
    </form>
  )
}

import { TicketType, TicketTypeDTO, TicketTypeFilter, TicketTypeGetEntity } from '../types/tickets';
import httpService from './httpService';
import { store } from '../redux/store';
import { Filter, Status } from '../types/filter';

export async function createTicketType(data: TicketTypeDTO): Promise<TicketType> {
  const locationId = store.getState().locations.selectedLocation;

  return httpService
    .post<TicketType>(`/api/locations/${locationId}/ticket-types`, data)
    .then((response): TicketType => response.data);
}

export async function getTicketTypesForCurrentLocation(offset: number, limit: number, filter?: TicketTypeFilter): Promise<TicketType[]> {
  const locationId = store.getState().locations.selectedLocation;

  return httpService
    .get<TicketType[]>(`/api/locations/${locationId}/ticket-types?filter=${encodeURI(JSON.stringify(constructFilter(filter)))}&offset=${offset}&limit=${limit}`)
    .then(({ data }): TicketType[] => data);
}

/**
 * Fetch the ticket types an admin can book for other users, without defining a use-date.
 * @returns the ticket types
 */
export async function getBookableTicketTypesForCurrentLocation(): Promise<TicketType[]> {
  const locationId = store.getState().locations.selectedLocation;

  return httpService
    .get<TicketType[]>(`/api/locations/${locationId}/ticket-types/bookable`)
    .then(({ data }): TicketType[] => data);
}

/**
 * Get all ticket types available in a capacity group 
 *
 * @param capacityGroupId the id of the capacity group
 * @returns the ticket types
 */
export async function getTicketTypesForCapacityGroup(capacityGroupId: number): Promise<TicketType[]> {
  const locationId = store.getState().locations.selectedLocation;
  
  return httpService
    .get<TicketType[]>(`/api/locations/${locationId}/capacity-groups/${capacityGroupId}/ticket-types`)
    .then((response): TicketType[] => response.data);
}

export async function deleteTicketType(ticketTypeId: number): Promise<TicketType> {
  const locationId = store.getState().locations.selectedLocation;

  if (!locationId) {
    throw new Error('No location selected');
  }

  return httpService
    .delete<TicketType>(`/api/locations/${locationId}/ticket-types/${ticketTypeId}`)
    .then((response): TicketType => response.data);
}

export async function updateTicketType(ticketTypeId: number, data: Partial<TicketTypeDTO>): Promise<TicketType> {
  const locationId = store.getState().locations.selectedLocation;
console.log(data);
  return httpService
    .patch<TicketType>(`/api/locations/${locationId}/ticket-types/${ticketTypeId}`, data)
    .then((response): TicketType => response.data);
}

export async function getTicketType(ticketTypeId: number): Promise<TicketTypeGetEntity> {
  const locationId = store.getState().locations.selectedLocation;

  return httpService
    .get<TicketTypeGetEntity>(`/api/locations/${locationId}/ticket-types/${ticketTypeId}`)
    .then(({ data }): TicketTypeGetEntity => data);
}

function constructFilter(ticketTypeFilter: TicketTypeFilter | undefined): Filter<TicketType> {
  if (!ticketTypeFilter) {
    return {};
  }

  const activeFilter: Filter<TicketType> =
    ticketTypeFilter.status === Status.ALL ? {} :
    ticketTypeFilter.status === Status.ACTIVE ? { archived: { equals: false } } :
    ticketTypeFilter.status === Status.ARCHIVED ? { archived: { equals: true} } : {};
  return {
    ...activeFilter
  }
}

import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { TicketShop, TicketShopDTO } from '../../types/tickets';
import { ticketShopService } from '../../services';
import TicketShopForm from '../../components/forms/TicketShopForm';
import { updateTicketShop } from '../../redux/slices/ticketShopsSlice';
import { fromShopEmailArrayToShopEmailMap } from '../../services/emailTemplatesService';

export default function TicketShopsEditPage(): JSX.Element | null {
  const navigate = useNavigate();
  const { id } = useParams();
  const [oldShop, setOldShop] = React.useState<TicketShop | undefined>(undefined);
  const { t } = useTranslation("ticket_shop");

  const onSubmit = (ticketShop: TicketShopDTO): void => {
    ticketShopService.updateTicketShop(Number(id), ticketShop)
      .then((): void => {
        toast.success(t("updated"));
        navigate('/ticket-shops');
      })
      .catch((error): void => {
        toast.error(`${t("failed_edit")}: ${error}`);
      });
  };

  if (!id) {
    toast.error('No ticket shop ID provided');
    navigate('/ticket-shops');
  }

  useEffect(() => {
    let ignore = false;
  
    const startFetchingShop = async (): Promise<void> => {
      const shop = await ticketShopService.getTicketShopForCurrentLocation(Number(id));

      if (ignore) {
        return;
      }

      if (shop) {
        setOldShop(shop);
        updateTicketShop(shop);
      } else {
        toast.error(`${t("failed_fetch")} ${id}`);
        navigate('/ticket-shops');
      }
    }

    startFetchingShop();
    return () => {
      ignore = true;
    }
  }, [id]);

  if (!oldShop) {
    return null;
  }

  return (
    <TicketShopForm onSubmit={onSubmit} type="update" initialState={{
      name: oldShop.name,
      bookingFee: oldShop.bookingFee.toString(),
      link: oldShop.link,
      primaryTicketTypeId: oldShop.primaryTicketTypeId?.toString(),
      emailTemplates: fromShopEmailArrayToShopEmailMap(oldShop.emailTemplates),
    }} />
  );
}

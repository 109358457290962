import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { TbCurrencyEuro } from 'react-icons/tb';
import TextInputWithLabel from './TextInputWithLabel';
import Button from '../buttons/Button';
import PrefixedTextInputWithLabel from './PrefixedTextInputWithLabel';
import { SubscriptionShopDto } from '../../types/subscriptions';
import { EmailTemplateType, ShopEmailTemplate } from '../../types/emailTemplates';
import { constructDefaultMapToShopEmailTemplate, fromShopEmailMapToIdMap } from '../../services/emailTemplatesService';
import useSubscriptionShopFormData from '../../hooks/selectors/useSubscriptionShopFormData';
import SelectWithData from './SelectWithData';
import usePermissions from '../../hooks/selectors/usePermissions';
import { Permissions } from '../../types/misc';

type SubscriptionShopFormProps = {
  onSubmit: (subscriptionShop: SubscriptionShopDto) => void;
  initialState?: SubscriptionShopFormState;
  type?: 'create' | 'update';
}

type SubscriptionShopFormState = {
  name: string;
  bookingFee: string;
  link: string;
  emailTemplates: Record<EmailTemplateType, ShopEmailTemplate>;
}

export default function SubscriptionShopForm({ initialState, onSubmit, type = 'create' }: SubscriptionShopFormProps): JSX.Element {
  if (!usePermissions([Permissions.ManageConfigurations])) {
    return (<></>);
  }
  const navigate = useNavigate();
  const { t } = useTranslation("subscription_shop_form");
  const { loading, emailTemplates } = useSubscriptionShopFormData();
  const [subscriptionShop, setSubscriptionShop] = React.useState<SubscriptionShopFormState>({
    name: initialState?.name || '',
    bookingFee: initialState?.bookingFee.toString() || '',
    link: initialState?.link || '',
    emailTemplates: initialState?.emailTemplates ?? constructDefaultMapToShopEmailTemplate(),
  });

  const formatNumber = (): void => setSubscriptionShop((prevState) => ({
    ...prevState,
    bookingFee: parseFloat(prevState.bookingFee).toFixed(2),
  }));

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {
    setSubscriptionShop((prev): SubscriptionShopFormState => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    onSubmit({
      name: subscriptionShop.name,
      bookingFee: parseFloat(subscriptionShop.bookingFee),
      link: subscriptionShop.link,
      emailTemplates: fromShopEmailMapToIdMap(subscriptionShop.emailTemplates),
    });
  };

  const handleEmailTemplateSelection = (emailTemplateType: EmailTemplateType) => {
    return (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {
      setSubscriptionShop((prev): SubscriptionShopFormState =>  {
        const newTemplates = prev.emailTemplates;
        newTemplates[emailTemplateType] = {
          emailTemplateId: parseInt(event.target.value, 10) ?? undefined,
          type: emailTemplateType,
          emailTemplate: emailTemplates.filter(template => template.id === parseInt(event.target.value, 10))[0] ?? undefined
        }
        return {
          ...prev,
          emailTemplates: newTemplates,
        };
      });
    }
  }

  if (loading) return (<></>);

  return (
    <form className="max-w-3xl mx-auto w-full" onSubmit={handleSubmit}>
      <h2
        className="text-2xl font-semibold mb-4 text-center">{type === 'create' ? t("create_title") : t("edit_title")}</h2>
      <TextInputWithLabel label={t("name")} id="name" name="name" value={subscriptionShop.name} onChange={handleChange}
                          placeholder={t("name_placeholder")} className="bg-white" required />
      <PrefixedTextInputWithLabel label={t("booking_fee")}
                                  prefix={<TbCurrencyEuro size={20} strokeWidth={2.25} color="#303030" />}
                                  id="booking-fee" name="bookingFee" value={subscriptionShop.bookingFee} type="number"
                                  onChange={handleChange} placeholder={t("booking_fee_placeholder")} className="bg-white"
                                  required onBlur={formatNumber} />
      <TextInputWithLabel label={t("link_to_shop")} id="shop-link" name="link" value={subscriptionShop.link}
                          type="url" onChange={handleChange} placeholder={t("link_to_shop_placeholder")}
                          className="bg-white" required maxLength={100} minLength={2} />
      <h2 className="text-xl font-semibold mb-4 mt-8">{t("templates")}</h2>
      {
        Object.values(EmailTemplateType).map(emailTemplateType => {
          return <div className="flex gap-2 w-full">
            <SelectWithData label={t(emailTemplateType)} placeholder={t(`email_template_placeholder`)}
                            data={emailTemplates}
                            name={`${emailTemplateType}_TEMPLATE`} id={`${emailTemplateType}_TEMPLATE`}
                            containerClassName="flex-1"
                            value={subscriptionShop.emailTemplates[emailTemplateType].emailTemplateId ?? undefined}
                            onChange={handleEmailTemplateSelection(emailTemplateType)} />
          </div>
        })
      }
      <div className="flex justify-end gap-2 my-4">
        <Button type="button" className="bg-gray-500" onClick={(): void => navigate('/subscription-shops')}>
          {t("cancel_button")}
        </Button>
        <Button type="submit">{type === 'create' ? t("create_button") : t("save_button")}</Button>
      </div>
    </form>
  );
}

import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import SubscriptionShopForm from '../../components/forms/SubscriptionShopForm';
import { SubscriptionShop, SubscriptionShopDto } from '../../types/subscriptions';
import { subscriptionShopService } from '../../services';
import { updateSubscriptionShop } from '../../redux/slices/subscriptionShopsSlice';
import { fromShopEmailArrayToShopEmailMap } from '../../services/emailTemplatesService';

export default function SubscriptionShopsEditPage(): JSX.Element | null {
  const navigate = useNavigate();
  const { id } = useParams();
  const [subscriptionShop, setSubscriptionShop] = React.useState<SubscriptionShop | undefined>(undefined);
  const { t } = useTranslation("subscription_shop");
  
  const onSubmit = (updateDto: SubscriptionShopDto): void => {
    subscriptionShopService.updateSubscriptionShop(Number(id), updateDto)
      .then((): void => {
        toast.success(t("updated"));
        navigate('/subscription-shops');
      })
      .catch((error): void => {
        toast.error(`${t("failed_edit")}: ${error}`);
      });
  };

  if (!id) {
    toast.error('No subscription shop ID provided');
    navigate('/subscription-shops');
  }


  useEffect(() => {
    let ignore = false;
  
    const startFetchingShop = async (): Promise<void> => {
      const shop = await subscriptionShopService.getSubscriptionShopForCurrentLocation(Number(id));

      if (ignore) {
        return;
      }

      if (shop) {
        setSubscriptionShop(shop);
        updateSubscriptionShop(shop);
      } else {
        toast.error(`${t("failed_fetch")} ${id}`);
        navigate('/subscription-shops');
      }
    }

    startFetchingShop();
    return () => {
      ignore = true;
    }
  }, [id]);

  if (!subscriptionShop) {
    return null;
  }

  return (
    <SubscriptionShopForm onSubmit={onSubmit} type="update" initialState={{
      name: subscriptionShop.name,
      bookingFee: subscriptionShop.bookingFee.toString(),
      link: subscriptionShop.link,
      emailTemplates: fromShopEmailArrayToShopEmailMap(subscriptionShop.emailTemplates),
    }} />
  );
}

import { Radio, RadioProps } from '@headlessui/react';
import { twMerge } from 'tailwind-merge';
import React from 'react';

type RadioCardProps = {
  label: string;
  value: any;
  className?: string;
} & Omit<RadioProps, 'className' | 'value'>;

export default function RadioCard({ label, className, ...props }: RadioCardProps): JSX.Element {
  return (
    <Radio {...props}
           className={twMerge(
             'group data-[checked]:border-2 data-[checked]:border-purple-600 data-[checked]:bg-purple-100 border px-4 py-1 data-[disabled]:opacity-25 rounded-lg border-gray-500 flex items-center gap-2 cursor-pointer hover:bg-gray-100',
             className,
           )}>
      {label}
    </Radio>
  );
}

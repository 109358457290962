import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { UsernamePasswordDto } from '../../services/models/auth.model';
import TextInput from '../../components/forms/TextField';
import Button from '../../components/buttons/Button';
import { validateUtil } from '../../utils';
import { authService } from '../../services';
import useAuth from '../../hooks/selectors/useAuth';
import { handleLoginError } from '../../services/errors/auth.error';

export default function LoginPage(): JSX.Element {
  const [data, setData] = useState<Partial<UsernamePasswordDto>>({});
  const auth = useAuth();
  const navigate = useNavigate();
  const { t } = useTranslation("auth");

  useEffect(() => {
    if (auth) navigate('/dashboard');
  }, [auth]);

  const handleSubmit = (): void => {
    if (!validateUtil.validateEmail(data.email)) {
      toast.error(t("invalid_email"));
      return;
    }
    if (!validateUtil.validateNonEmpty(data.password)) {
      toast.error(t("password_required"));
      return;
    }
    if (!validateUtil.validatePassword(data.password)) { 
      // we know that the password cannot be valid, so there is no need to send a request to the server
      toast.error('Wrong password');
      return;
    }

    authService.login(data as UsernamePasswordDto).catch(handleLoginError(t));
  };

  return (
    <div className="w-full h-full flex items-center justify-center">
      <div className="rounded-xl bg-white px-6 py-4 w-full max-w-xl h-fit flex flex-col gap-4">
        <div className="flex justify-between items-center pb-4 border-[#EFF4FB] border-b">
          <h1 className="text-3xl">{t("login")}</h1>
        </div>
        <form className="flex flex-col gap-4">
          <div className="w-full flex flex-col gap-1">
            <p className="text-md">{t("email")}</p>
            <TextInput
              type='email'
              autoComplete='email'
              onChange={(e) => setData({ ...data, email: e.target.value })} />
          </div>
          <div className="w-full flex flex-col gap-1">
            <div className="flex justify-between">
              <p className="text-md">{t("password")}</p>
              <a href='/forgotten-password'>{t("forgot_password")}</a>
            </div>
            <TextInput
              type="password"
              autoComplete='on'
              onChange={(e) => setData({ ...data, password: e.target.value })}
            />
          </div>
          <Button className="w-full" onClick={handleSubmit}>
            {t("sign_in_button")}
          </Button>
          <Button className="w-full" onClick={() => navigate('/register')}>
            {t("register_button")}
          </Button>
        </form>
      </div>
    </div>
  );
}

import React from 'react';
import { SelectProps } from '@headlessui/react';
import { useTranslation } from 'react-i18next';
import Select from './Select';

export type OptionItem = {
  id: any;
  name: string;
}

type SelectWithDataProps = {
  label: string;
  placeholder: string;
  data: OptionItem[];
  selectedValueId?: any;
  containerClassName?: string;
} & SelectProps;

export default function SelectWithData(
  {
    data,
    label,
    placeholder,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    selectedValueId,
    id,
    containerClassName,
    required,
    ...props
  }: SelectWithDataProps,
): JSX.Element {
  const { t } = useTranslation('select_with_data');
  return (
    <div className={containerClassName}>
      <label htmlFor={id}>{label}</label>
      <Select id={id} required={required} {...props}>
        <option value="" disabled>{placeholder}</option>
        {!required && <option value="undefined">{t('select_none')}</option>}
        {data.map((entry) => (
          <option key={entry.id} value={entry.id}>
            {entry.name}
          </option>
        ))}
      </Select>
    </div>
  );
}

import React, { useState, Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { TbChevronDown } from 'react-icons/tb';

type CheckboxDropdownProps<T> = {
  label: string;
  values: T[];
  onChange: (newEntries: T[]) => void;
  type: 'checkbox' | 'radio';
  initialValues?: T[];
};

export default function CheckboxDropdown<T extends { id: any; name: string; }>({
    label,
    values,
    onChange,
    type,
    initialValues = [],
  }: CheckboxDropdownProps<T>): JSX.Element {
  const [selectedValues, setSelectedValues] = useState<T[]>(initialValues);
 
  const handleSelection = (value: T): void => {
    setSelectedValues((prevSelectedValues) => {
      if (type === 'checkbox') {
        return prevSelectedValues.some(s => s.id === value.id)
          ? prevSelectedValues.filter((v): boolean => v.id !== value.id)
          : [...prevSelectedValues, value];
      }
      return prevSelectedValues.length === 1 && prevSelectedValues[0].id === value.id ? prevSelectedValues : [value];
    });
  };

  React.useEffect((): void => {
    onChange(selectedValues);
  }, [selectedValues]);

  return (
    <div className="relative inline-block text-left ml-10">
      <Menu as="div" className="relative">
        <div>
          <Menu.Button className="z-0 relative inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none">
            {label}
            <TbChevronDown className="ml-2 -mr-1 h-5 w-5" aria-hidden="true" />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="py-1">
              {values.map((value: T): JSX.Element =>
                (
                  <div
                    key={value.id}
                    className="flex items-center px-4 py-2 text-sm text-gray-700 cursor-pointer hover:bg-gray-100"
                    onClick={(e): void => {
                      e.stopPropagation(); // Prevent the menu from closing
                      handleSelection(value);
                    }}
                  >
                    <input
                      type={type}
                      className='mr-2'
                      checked={selectedValues.some(s => s.id === value.id)}
                      readOnly
                    />
                    <span>{value.name}</span>
                  </div>
                ))}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}

import React from 'react';
import ModalLayout from '../layouts/ModalLayout';
import { Purchase, RefundPaymentData } from '../../types/payment';
import RefundPaymentForm from '../forms/RefundPaymentForm';
import usePermissions from '../../hooks/selectors/usePermissions';
import { Permissions } from '../../types/misc';

interface RefundModalProps {
  open: boolean;
  purchases: Purchase[]
  setOpen: (open: boolean) => void;
  onSubmit: (refundPaymentData: RefundPaymentData) => void;
}

export default function RefundPaymentModal({
  open,
  setOpen,
  purchases,
  onSubmit
}: RefundModalProps): JSX.Element {
  if (!usePermissions([Permissions.ManagePayments])) {
    return (<></>);
  }
  return (
    <ModalLayout
      open={open}
      setOpen={setOpen}
      closeButton
      className="bg-white max-w-3xl rounded-3xl p-16">
      <RefundPaymentForm purchases={purchases} onSubmit={onSubmit} onCancel={() => setOpen(false)} />
    </ModalLayout>
  );
}

import React, { ComponentProps } from 'react';
import { useTranslation } from 'react-i18next';

type FileInputWithLabelProps = {
  id: string;
  label: string;
  currentFileName?: string;
} & Omit<ComponentProps<'input'>, 'type' | 'id'>;

export default function FileInputWithLabel({ id, label, currentFileName, ...props }: FileInputWithLabelProps): JSX.Element {
  const { t } = useTranslation('image_upload');

  return (
    <>
      <input id={id} type="file" hidden {...props} />
      <label>{label}</label>
      <div className="flex items-center gap-2">
        <label className="block px-4 py-2 bg-purple-600 text-white rounded-md cursor-pointer w-fit" htmlFor={id}>{t('choose_an_image')}</label>
        <span>{currentFileName || t('no_name')}</span>
      </div>
    </>
  );
}

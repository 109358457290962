import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TbCirclePlus } from 'react-icons/tb';
import { DateTime } from 'luxon';
import { twMerge } from 'tailwind-merge';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import classNames from 'classnames';
import { Purchase } from '../../types/payment';
import { getSelectedLocation } from '../../services/locationService';
import useLanguage from '../../hooks/selectors/useLanguage';
import { Reservation } from '../../types/reservation';
import IconButton from '../../components/buttons/IconButton';
import Button from '../../components/buttons/Button';
import { getDayGroupsForCapacityGroup } from '../../services/capacityGroupService';
import { format, getAllDaysInMonthAtMidnight, isSameDay, makeTimeIrrelevantDate, max } from '../../utils/dateUtil';
import { Language } from '../../types/misc';
import { Day } from '../../types/slots';

interface PaymentsRebookSelectTicketsStepProps {
  paymentId: number,
  purchases: Purchase[],
  reservations: Reservation[],
}

export default function PaymentsRebookSelectDateStep({ paymentId, purchases, reservations }: PaymentsRebookSelectTicketsStepProps): JSX.Element | null {
  const language = useLanguage();
  const navigate = useNavigate();
  const timeZone = getSelectedLocation()?.timezone ?? 'UTC';

  const [queryParams, setQueryParams] = useSearchParams();

  const { t } = useTranslation("payments_rebook");

  const products = useMemo(() => queryParams.get('products')?.split(',').map(Number), [queryParams.get('products')]);

  const capacityGroupId = useMemo(() => {
    const firstProductId = products?.at(0);
    const firstProduct = purchases.find(p => p.id === firstProductId);

    const reservation = reservations.find(r => r.id === firstProduct?.extendedPurchaseData.reservation?.id);
    return reservation?.ticketTypeOnSlot.ticketType.capacityGroupId;
  }, [products, reservations, purchases]);

  const [monthDiff, setMonthDiff] = useState<number>(0);
  const [monthDays, setMonthDays] = useState<Date[]>([]);

  const month = DateTime.now()
    .setZone(timeZone)
    .plus({ months: monthDiff })
    .startOf('day');

  const today = makeTimeIrrelevantDate(new Date(), timeZone);

  const [days, setDays] = useState<Day[]>([]);
  const [selectedDay, setSelectedDay] = useState<Date | null>(queryParams.has('selected') && queryParams.get('selected') !== '' ? new Date(queryParams.get('selected') ?? new Date()) : null);

  useEffect(() => {
    if (!capacityGroupId) {
      navigate(`/payments/${paymentId}/details`);
      return;
    }

    const newMonthDays = getAllDaysInMonthAtMidnight(monthDiff, timeZone);
    setMonthDays(newMonthDays);
  
    getDayGroupsForCapacityGroup(capacityGroupId, max(newMonthDays[0], today), DateTime.fromJSDate(newMonthDays[newMonthDays.length - 1]).plus({ days: 1 }).toJSDate())
      .then((data): void => {
        setDays(data);
      })
  }, [monthDiff, capacityGroupId]);

  useEffect(() => {
    setQueryParams((current) => {
      current.set('selected', selectedDay ? selectedDay.toISOString() : '');
      return current;
    });
  }, [selectedDay]);

  const daysOfWeek = language === Language.EN
    ? ['mo', 'tu', 'we', 'th', 'fr', 'sa', 'su']
    : ['ma', 'di', 'wo', 'do', 'vr', 'za', 'zo'];

  return (
    <>
      <div className="flex justify-between items-center mb-4">
        <h2 className="font-gintobold font-bold text-2xl">{t("title_select_date", { paymentId })}</h2>
        <div className="flex gap-1">
          <Button type="button" className="bg-gray-500" onClick={(): void => setQueryParams(current => ({ selected: current.get('products') ?? '' }))}>
                  {t("back")}
          </Button>
          <IconButton onClick={(): void => { setQueryParams(current => ({ products: current.get('products') ?? '', date: current.get('selected') ?? '' })); }} icon={TbCirclePlus} label={t("next")}
                      className="bg-gradient-to-r from-[#E700F9] hover:shadow-purple-300 hover:shadow-md
                    to-pink-500 duration-300 bg-[position:_0%_0%] hover:bg-[position:_100%_100%]
                    bg-[size:_200%] transition-all font-bold text-white rounded-full cursor-pointer" 
                    disabled={selectedDay === null}/>
        </div>
      </div>
      <div>

      <div className='flex flex-col gap-2'>
        <div className="flex justify-between w-full max-w-[320px] gap-2 mx-auto items-center">
          <IconButton
            disabled={monthDiff === 0}
            onClick={() => setMonthDiff(prev => prev - 1)}
            className="bg-black text-white h-[60px] w-[60px]"
            icon={FaChevronLeft}
            label=''
            />
           <p className="text-[21px] leading-[17px] whitespace-nowrap">
             {format(month.toJSDate(), 'LLLL yyyy', timeZone, language).toLowerCase()}
           </p>
          <IconButton
            onClick={() => setMonthDiff(prev => prev + 1)}
            className="bg-black text-white h-[60px] w-[60px]"
            icon={FaChevronRight}
            label=''
          />
        </div>
        <div className="grid grid-cols-7 gap-x-2 gap-y-2.5 w-fit mx-auto items-center"> 
         {daysOfWeek.map((d) => (
            <label key={d} className="flex justify-center text-[17px] leading-[22px]">
              {d}
           </label>
         ))}
         {monthDays.map((d, i) => { 
            const day = days.find(dd => isSameDay(new Date(dd.startDateAndTime), d));
            const possibleSlots = day?.slots.filter(slot => slot.availableCapacity >= (products?.length ?? 1) 
              && slot.active && !slot.archived);
           
            const disabled = d < today || !day || !possibleSlots || possibleSlots.length === 0;
            const selected = selectedDay ? isSameDay(d, selectedDay) : false;
            
            return (
              <div
                onClick={() => {
                  if (disabled) {
                    return;
                  }
                  setSelectedDay(new Date(day.startDateAndTime));
                }}
                key={i}
                className={twMerge(
                  'flex flex-col w-fit h-fit min-w-[40px] gap-1 items-center p-2.5 px-1 mx-auto rounded-sb-sm bg-sb-light-pink',
                  classNames({
                    'bg-sb-purple text-white bg-[#000000] cursor-pointer': !disabled,
                    'bg-opacity-10 bg-[#979797]': disabled,
                    'bg-[#E700F9]': selected,
                  }),
                )}>
                <p
                  className={classNames('text-[17px] leading-[17px] font-ginto-bold', {
                    'opacity-20': disabled,
                  })}>
                    {format(d, 'dd', timeZone, language).toLowerCase()}
                </p>
              </div>
            );
          })}
         </div> 
        </div>
      </div>
    </>
  );
}
import { useEffect, useState } from 'react';
import { FetchData } from '../../types/misc';
import { getTicketTypesOnSlots } from '../../services/slotService';
import { TicketTypeWithSlotsAndPricing } from '../../types/slots';

export default function useTicketTypesOnSlots(capacityGroupId: number, slotIds: number[]): FetchData<TicketTypeWithSlotsAndPricing[]> {
  const [data, setData] = useState<TicketTypeWithSlotsAndPricing[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    getTicketTypesOnSlots(capacityGroupId, slotIds)
      .then(setData)
      .catch(setError)
      .finally(() => setLoading(false));
  }, []);

  return { data, loading, error };
}

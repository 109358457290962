import { ExtendedSubscriptionType, PrivilegedStatus, SubscriptionType, SubscriptionTypeCreateDto, SubscriptionTypeFilter, SubscriptionTypeUpdateDto } from '../types/subscriptions';
import httpService from './httpService';
import { store } from '../redux/store';
import { Filter, Status } from '../types/filter';

export async function getSubscriptionTypesForCurrentLocation(offset: number, limit: number, filter?: SubscriptionTypeFilter): Promise<SubscriptionType[]> {
  const locationId = store.getState().locations.selectedLocation;

  return httpService
    .get<SubscriptionType[]>(`/api/locations/${locationId}/subscription-types?filter=${encodeURI(JSON.stringify(constructFilter(filter)))}&offset=${offset}&limit=${limit}`)
    .then(({ data }): SubscriptionType[] => data);
}

/**
 * Get the subscription types that an admin can use to create a subscription.
 * @returns the subscription types
 */
export async function getAppropriateSubscriptionTypeForSubscriptionCreation(): Promise<SubscriptionType[]> {
  const locationId = store.getState().locations.selectedLocation;

  return httpService
    .get<SubscriptionType[]>(`/api/locations/${locationId}/subscription-types/bookable`)
    .then(({ data }): SubscriptionType[] => data);
}

export async function deleteSubscriptionType(subscriptionTypeId: number): Promise<SubscriptionType> {
  const locationId = store.getState().locations.selectedLocation;

  if (!locationId) {
    throw new Error('No location selected');
  }

  return httpService
    .delete<SubscriptionType>(`/api/locations/${locationId}/subscription-types/${subscriptionTypeId}`)
    .then(({ data }): SubscriptionType => data);
}

export async function createSubscriptionType(data: SubscriptionTypeCreateDto): Promise<SubscriptionType> {
  const locationId = store.getState().locations.selectedLocation;

  return httpService
    .post<SubscriptionType>(`/api/locations/${locationId}/subscription-types`, data)
    .then((response): SubscriptionType => response.data);
}

export async function updateSubscriptionType(subscriptionTypeId: number, updateModel: SubscriptionTypeUpdateDto): Promise<SubscriptionType> {
  const locationId = store.getState().locations.selectedLocation;

  return httpService
    .patch<SubscriptionType>(`/api/locations/${locationId}/subscription-types/${subscriptionTypeId}`, updateModel)
    .then(({ data }): SubscriptionType => data);
}

export async function getSubscriptionType(subscriptionTypeId: number): Promise<ExtendedSubscriptionType> {
  const locationId = store.getState().locations.selectedLocation;

  return httpService
    .get<ExtendedSubscriptionType>(`/api/locations/${locationId}/subscription-types/${subscriptionTypeId}`)
    .then(({ data }): ExtendedSubscriptionType => data);
}

function constructFilter(subscriptionTypeFilter: SubscriptionTypeFilter | undefined): Filter<SubscriptionType> {
  if (!subscriptionTypeFilter) {
    return {};
  }

  const activeFilter: Filter<SubscriptionType> =
    subscriptionTypeFilter.status === Status.ALL ? {} :
    subscriptionTypeFilter.status === Status.ACTIVE ? { archived: { equals: false } } :
    subscriptionTypeFilter.status === Status.ARCHIVED ? { archived: { equals: true} } : {};

  const privilegedFilter: Filter<SubscriptionType>  = 
    subscriptionTypeFilter.privileged === PrivilegedStatus.PRIVILEGED ? { privileged: { equals: true } } : 
    subscriptionTypeFilter.privileged === PrivilegedStatus.NONPRIVILEGED ? { privileged: { equals: false } } : {};

  return {
    ...activeFilter,
    ...privilegedFilter,
  }
}

import {
  EmailTemplate,
  EmailTemplateDto,
  EmailTemplateType,
  EmailTemplateWithData,
  ShopEmailTemplate,
} from '../types/emailTemplates';
import { store } from '../redux/store';
import httpService from './httpService';

export async function getEmailTemplatesForCurrentLocation(offset: number, limit: number): Promise<EmailTemplate[]> {
  const locationId = store.getState().locations.selectedLocation;

  return httpService
    .get<EmailTemplate[]>(`/api/locations/${locationId}/templates?offset=${offset}&limit=${limit}`)
    .then(({ data }): EmailTemplate[] => data)
}

export async function deleteEmailTemplateForLocation(templateId: number): Promise<EmailTemplate> {
  const locationId = store.getState().locations.selectedLocation;

  return httpService
    .delete<EmailTemplate>(`/api/locations/${locationId}/templates/${templateId}`)
    .then(({ data }): EmailTemplate => data);
}

export async function getEmailTemplateForLocation(templateId: number): Promise<EmailTemplateWithData> {
  const locationId = store.getState().locations.selectedLocation;

  return httpService
    .get<EmailTemplateWithData>(`/api/locations/${locationId}/templates/${templateId}`)
    .then(({ data }): EmailTemplateWithData => data);
}

export async function createEmailTemplateForLocation(emailTemplate: EmailTemplateDto): Promise<EmailTemplateWithData> {
  const locationId = store.getState().locations.selectedLocation;

  return httpService
    .post<EmailTemplateWithData>(`/api/locations/${locationId}/templates/`, emailTemplate)
    .then(({ data }): EmailTemplateWithData => data);
}

export async function updateEmailTemplateForLocation(emailTemplate: EmailTemplateDto, templateId: number): Promise<EmailTemplateWithData> {
  const locationId = store.getState().locations.selectedLocation;

  return httpService
    .put<EmailTemplateWithData>(`/api/locations/${locationId}/templates/${templateId}`, emailTemplate)
    .then(({ data }): EmailTemplateWithData => data);
}

export function constructDefaultMap(): Record<EmailTemplateType, number | null> {
  return Object.values(EmailTemplateType).reduce((acc, emailType) => {
    acc[emailType] = null;
    return acc;
  }, {} as Record<EmailTemplateType, number | null>)
}

export function constructDefaultMapToShopEmailTemplate(): Record<EmailTemplateType, ShopEmailTemplate> {
  return Object.values(EmailTemplateType).reduce((acc, emailType) => {
    acc[emailType] = { type: emailType };
    return acc;
  }, {} as Record<EmailTemplateType, ShopEmailTemplate>)
}

export function fromShopEmailMapToIdMap(emailTemplates: Record<EmailTemplateType, ShopEmailTemplate>): Record<EmailTemplateType, number | null> {
  return Object.values(EmailTemplateType).reduce((acc, emailTemplateType) => {
    acc[emailTemplateType] = emailTemplates[emailTemplateType].emailTemplateId ?? null;
    return acc;
  }, constructDefaultMap());
}

export function fromShopEmailArrayToShopEmailMap(emailTemplates: ShopEmailTemplate[]): Record<EmailTemplateType, ShopEmailTemplate> {
  return emailTemplates.reduce((acc, emailTemplate) => {
    acc[emailTemplate.type] = emailTemplate;
    return acc;
  }, constructDefaultMapToShopEmailTemplate());
}
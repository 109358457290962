import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import TextInput from './TextField';
import { passwordValidators } from '../../utils/validateUtil';

interface CreatePasswordInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
    password: string;
    changePassword: (password: string) => void;
    isValid: boolean;
    setIsValid: (isValid: boolean) => void;
}

export default function CreatePasswordInput({
    password,
    changePassword,
    setIsValid,
    ...props
}: CreatePasswordInputProps): JSX.Element 
{
    const [passwordError, setPasswordError] = useState<string>('');
    const [repeatPassword, setRepeatPassword] = useState<string>('');
    const [repeatPasswordError, setRepeatPasswordError] = useState<string>('');
    const {t} = useTranslation('auth');

    const validatePassword = (value: string): void => {
        setPasswordError('');
        const validators = Object.values(passwordValidators);
        let isValidTemp = true;
        const errorMessages: string[] = [];
        validators.forEach((validator) => {
            isValidTemp = validator(value, errorMessages) && isValidTemp;
        });
        setIsValid(isValidTemp);
        setPasswordError(errorMessages.map((e) => t(e)).join('. '));

        setRepeatPasswordError('');
        if (password !== repeatPassword) {
            setRepeatPasswordError(t('passwords_do_not_match'));
            setIsValid(false);
        }
    }

    useEffect(() => {
        validatePassword(password);

    },[password, repeatPassword]);

    const handlePasswordChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        e.preventDefault();
        changePassword(e.target.value);
    };


    return (
        <>
            <TextInput
                type="password"
                placeholder={t('password')}
                value={password}
                onChange={handlePasswordChange}
                {...props}
            />  
            <p className="text-red-500 text-sm">{passwordError}</p>
            <TextInput
                type="password"
                placeholder={t('repeat_password')}
                value={repeatPassword}
                onChange={(e) => setRepeatPassword(e.target.value)}
                {...props}
            />
            <p className="text-red-500 text-sm">{repeatPasswordError}</p>
        </>
    );
}
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import TextInputWithLabel from './TextInputWithLabel';
import Button from '../buttons/Button';
import { DayGroupCreateOrUpdate, DayGroupWithTicketTypePricingPolicies, Days, PricingPolicyType, TicketTypeToPricingPolicy } from '../../types/dayGroups';
import SelectWithData from './SelectWithData';
import CheckboxWithLabel from './CheckboxWithLabel';
import { TicketType } from '../../types/tickets';
import { getTicketTypesForCapacityGroup } from '../../services/ticketTypeService';
import { PricingPolicy } from '../../types/pricingPolicies';
import { getPricingPoliciesForCurrentLocation } from '../../services/pricingPoliciesService';
import SelectWithoutLabel from './SelectWithoutLabel';
import '../../pages/day-groups/TicketTable.css';
import { secondsToHHMM } from '../../utils/dateUtil';
import usePermissions from '../../hooks/selectors/usePermissions';
import { Permissions } from '../../types/misc';

type DayGroupFormProps = {
  onSubmit: (capacityGroup: DayGroupCreateOrUpdate) => void;
  formType?: 'create' | 'update';
  initialState?: DayGroupWithTicketTypePricingPolicies;
};

type DayGroupFormState = {
    day: string;
    startTime: string;
    endTime: string;
    capacity: string;
    active: boolean;
    ticketTypeToPricingPolicy: TicketTypeToPricingPolicyState,
};

type TicketTypeToPricingPolicyState = {
  [ticketTypeId: number]: {
    pricingPolicyType: string;
    pricingPolicyId?: string;
    active: boolean;
  };
}

function DayGroupForm({
  onSubmit,
  formType = 'create',
  initialState = {} as DayGroupWithTicketTypePricingPolicies,
}: DayGroupFormProps): JSX.Element {
  if (!usePermissions([Permissions.ManageConfigurations])) {
    return (<></>);
  }
  const navigate = useNavigate();
  const { capacityGroupId } = useParams();
  const { t } = useTranslation("day_group_form");
  
  const [ticketTypes, setTicketTypes] = React.useState<TicketType[]>([]);
  const [pricingPolicies, setPricingPolicies] = React.useState<PricingPolicy[]>([]);

  useEffect((): void => {
    getTicketTypesForCapacityGroup(Number(capacityGroupId)).then((data): void => {
      setTicketTypes(data);
    }).catch((error): void => {
      toast.error(error.message);
    });
  }, []);

  useEffect((): void => {
    getPricingPoliciesForCurrentLocation(0, Number.MAX_SAFE_INTEGER).then((data): void => {
      setPricingPolicies(data);
    }).catch((error): void => {
      toast.error(error.message);
    });
  }, []);

  const [dayGroup, setDayGroup] = useState<DayGroupFormState>({
    day: initialState?.day ? Days[initialState.day as unknown as keyof typeof Days].toString() : '',
    startTime: initialState?.startTime ? secondsToHHMM(initialState.startTime) : '',
    endTime: initialState?.endTime ? secondsToHHMM(initialState.endTime) : '',
    capacity: String(initialState?.capacity ?? ''),
    active: initialState.active || false,
    ticketTypeToPricingPolicy: Object.keys(initialState?.ticketTypeToPricingPolicy ?? {})
      .reduce((acc, key) => {
        acc[Number(key)] = {
          pricingPolicyType: String(PricingPolicyType[initialState?.ticketTypeToPricingPolicy[Number(key)].pricingPolicyType]),
          pricingPolicyId: String(initialState?.ticketTypeToPricingPolicy[Number(key)].pricingPolicyId),
          active: initialState?.ticketTypeToPricingPolicy[Number(key)].active,
        };
        return acc;
      }, {} as TicketTypeToPricingPolicyState),
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {
    setDayGroup((prev): DayGroupFormState => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const handleActiveCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setDayGroup((prev): DayGroupFormState => {
      const ticketTypeToPricingPolicy = { ...prev.ticketTypeToPricingPolicy };

      Object.keys(ticketTypeToPricingPolicy).forEach((key) => {
        ticketTypeToPricingPolicy[Number(key)] = {
          ...ticketTypeToPricingPolicy[Number(key)],
          [event.target.name]: event.target.checked && prev.active,
        };
      });

      return {
        ...prev,
        active: event.target.checked,
        ticketTypeToPricingPolicy,
      };
    });
  };

  const handleTicketTypeToPricingPolicyChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {
    setDayGroup((prev): DayGroupFormState => {
      const ticketTypeToPricingPolicy = { ...prev.ticketTypeToPricingPolicy };

      ticketTypeToPricingPolicy[Number(event.target.id)] = {
        ...ticketTypeToPricingPolicy[Number(event.target.id)],
        [event.target.name]: event.target.value,
        ...(event.target.name === 'pricingPolicyType' && { pricingPolicyId: undefined }),
      };

      return {
        ...prev,
        ticketTypeToPricingPolicy,
      };
    });
  };

  const handleTicketTypeToPricingPolicyCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setDayGroup((prev): DayGroupFormState => {
      const ticketTypeToPricingPolicy = { ...prev.ticketTypeToPricingPolicy };

      ticketTypeToPricingPolicy[Number(event.target.id)] = {
        ...ticketTypeToPricingPolicy[Number(event.target.id)],
        [event.target.name]: event.target.checked && prev.active,
      };

      return {
        ...prev,
        ticketTypeToPricingPolicy,
      };
    });
  }

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    onSubmit({
        day: Days[dayGroup.day as keyof typeof Days],
        startTime: dayGroup.startTime,
        endTime: dayGroup.endTime,
        capacity: Number(dayGroup.capacity),
        active: dayGroup.active,
        ticketTypeToPricingPolicy: Object.keys(dayGroup.ticketTypeToPricingPolicy).reduce((acc, key) => {
          acc[Number(key)] = {
            pricingPolicyType: PricingPolicyType[dayGroup.ticketTypeToPricingPolicy[Number(key)].pricingPolicyType as keyof typeof PricingPolicyType],
            ...(dayGroup.ticketTypeToPricingPolicy[Number(key)].pricingPolicyType === String(PricingPolicyType.DYNAMIC) && { pricingPolicyId:  Number(dayGroup.ticketTypeToPricingPolicy[Number(key)].pricingPolicyId) }),
            active: (dayGroup.ticketTypeToPricingPolicy[Number(key)].active && dayGroup.active) || false,
          };
          return acc;
        }, {} as TicketTypeToPricingPolicy),
    });
  };

  return (
    <>
      <h2 className="text-2xl font-bold text-center mb-4">{formType === 'create' ? t("create_title") : t("edit_title")}</h2>
      <form className="flex flex-col gap-4 max-w-full m-auto" onSubmit={handleSubmit}>
        <SelectWithData label={t("day")} placeholder={t("day_placeholder")} data={Object.keys(Days).filter(key => Number.isNaN(Number(key))).map((day, i) => ({ id: i, name: day })) }
                            name="day" id="day" containerClassName="flex-1"
                            value={dayGroup.day} onChange={handleChange} required />
        <TextInputWithLabel label={t("start_time")} id="startTime" placeholder={t("start_time_placeholder")} name="startTime" onChange={handleChange}
                            value={dayGroup.startTime} className="bg-white" required />
        <TextInputWithLabel label={t("end_time")} id="endTime" placeholder={t("end_time_placeholder")} name="endTime" onChange={handleChange}
                            value={dayGroup.endTime} className="bg-white" required />
        <TextInputWithLabel label={t("capacity")} id="capacity" placeholder={t("capacity_placeholder")} name="capacity" type="number" min="0" onChange={handleChange}
                            value={dayGroup.capacity} className="bg-white" required />
        <CheckboxWithLabel containerClassName="flex" label={t("active")} id="active" name="active"
                            onChange={handleActiveCheckboxChange} checked={dayGroup.active} 
                            className="w-4 h-4 border-2 rounded-sm bg-white mt-1 shrink-0 
                            checked:bg-[#3C50E0] checked:border-0 cursor-pointer"
                            labelClassName="cursor-pointer pl-2" />
        <div className="ticket-grid">
            <div className="ticket-grid-header">
                <div>{t("ticket_type_name")}</div>
                <div>{t("pricing_policy_type")}</div>
                <div>{t("pricing_policy")}</div>
                <div>{t("active")}</div>
            </div>
            {ticketTypes.map(ticketType => (
                <div className="ticket-grid-row" key={ticketType.id}>
                    <div>{ticketType.name}</div>
                    <div>
                        <SelectWithoutLabel 
                          placeholder={t("pricing_policy_type_placeholder")} 
                          data={Object.keys(PricingPolicyType).filter(key => Number.isNaN(Number(key))).map((type, i) => ({ id: i, name: type })) }
                          name="pricingPolicyType"
                          id={String(ticketType.id)}
                          containerClassName="flex-1"
                          value={dayGroup.ticketTypeToPricingPolicy[ticketType.id]?.pricingPolicyType}
                          onChange={handleTicketTypeToPricingPolicyChange} />
                    </div>
                    <div id={`pricingPolicySelect${ticketType.id}`}>
                        <SelectWithoutLabel 
                          placeholder={t("pricing_policy_placeholder")}
                          data={pricingPolicies.map(policy => ({ id: policy.id, name: policy.name })) }
                          name="pricingPolicyId" 
                          id={String(ticketType.id)} 
                          containerClassName={`flex-1 ${ dayGroup.ticketTypeToPricingPolicy[ticketType.id]?.pricingPolicyType === String(PricingPolicyType.STANDARD) ? 'invisible' : '' }` }
                          value={dayGroup.ticketTypeToPricingPolicy[ticketType.id]?.pricingPolicyId ?? ''}
                          onChange={handleTicketTypeToPricingPolicyChange} /> 
                    </div>
                    <div>
                        <input 
                          type="checkbox"
                          id={String(ticketType.id)}
                          name="active" 
                          checked={dayGroup.ticketTypeToPricingPolicy[ticketType.id]?.active}
                          className="w-4 h-4 border-2 rounded-sm bg-white mt-1 shrink-0 
                            checked:bg-[#3C50E0] checked:border-0 cursor-pointer" 
                          onChange={handleTicketTypeToPricingPolicyCheckboxChange}
                          disabled={!dayGroup.active} />   
                    </div>
                </div>
            ))}
        </div>
        <div className="flex justify-center	gap-8">
          <Button type="button" className="self-center px-12" onClick={(): void => navigate(`/capacity-groups/edit/${capacityGroupId}`)}>{t("cancel_button")}</Button>
          <Button type="submit" className="self-center px-12">{formType === 'create' ? t("create_button") : t("save_button")}</Button>
        </div>
      </form>
    </>
  );
}

export default DayGroupForm;
import React, { useEffect, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { useTranslation } from 'react-i18next';
import { FaCheck, FaChevronDown, FaEdit } from 'react-icons/fa';
import { FaPlus } from 'react-icons/fa6';
import { useDispatch } from 'react-redux';
import classNames from 'classnames';
import HoverButton from './HoverButton';
import useSelectedLocation from '../../hooks/selectors/useSelectedLocation';
import useLocations from '../../hooks/selectors/useLocations';
import { setSelected } from '../../redux/slices/locationsSlice';
import { locationService } from '../../services';
import CreateLocationModal from '../modals/CreateLocationModal';
import usePermissions from '../../hooks/selectors/usePermissions';
import { Permissions } from '../../types/misc';
import Button from './Button';
import EditLocationModal from '../modals/EditLocationModal';

export default function LocationButton(): JSX.Element | null {
  const { t } = useTranslation("location_button");
  const locations = useLocations();
  const selectedLocation = useSelectedLocation();
  const dispatch = useDispatch();
  const [modalOpen, setModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const permission = usePermissions([Permissions.ManageLocations]);

  useEffect(() => {
    locationService.getLocationsForUser();
  }, []);

  if (!locations.length && !permission) return null;

  if (!locations.length)
    return (
      <>
        <Button onClick={() => setModalOpen(true)}>{t("new_location_button")}</Button>
        <CreateLocationModal open={modalOpen} setOpen={setModalOpen} />
      </>
    );

  return (
    <>
      <HoverButton>
        <HoverButton.Button>
          <div className=" flex justify-between text-[18px] items-center px-3 py-[6px] gap-2 w-fit rounded-full group-[.expanded]:bg-[#EFF4FB] group-[.expanded]:border-[#E2E8F0] hover:border-[#E2E8F0] hover:bg-[#EFF4FB] border border-white transition-all">
            <div className="flex gap-2 items-center">
              <span className="font-gintobold">{t("location")}</span>
              <span className="text-primary font-medium">{selectedLocation?.name}</span>
            </div>
            <FaChevronDown className="w-4 h-4 text-primary" />
          </div>
        </HoverButton.Button>

        <HoverButton.Div className="z-50 bg-white min-w-[250px] border border-primary-300 w-full p-3 rounded-[28px] mt-2 gap-[10px] flex flex-col ">
          <div className="w-full text-secondary-50 items-center">{t("location")}</div>
          <div className="w-full border border-primary-300 " />
          {(locations ?? []).map((l) => (
            <div
              key={l.id}
              onClick={() => dispatch(setSelected(l.id))}
              className={twMerge(
                'py-2 px-3 leading-[19px] rounded-full items-center cursor-pointer justify-between',
                classNames({
                  'bg-[#EFF4FB] hover:bg-opacity-100': selectedLocation?.id === l.id,
                }),
              )}>
              <div className="flex justify-between items-center">
                <div className="flex gap-2 items-center w-full">
                  {/* <Icon icon={businessTypeIcons[b.type]} className="w-5 h-5 flex-shrink-0" /> */}
                  <p
                    className={twMerge(
                      'text-ellipsis font-medium overflow-hidden w-full pr-2',
                      selectedLocation?.id === l.id && 'text-primary-900',
                    )}>
                    {l.name}
                  </p>
                </div>
                {selectedLocation?.id === l.id && (
                  <FaCheck className="w-3 h-3 text-primary-900 flex-shrink-0" />
                )}
              </div>
            </div>
          ))}
          <div className="w-full border border-primary-300 " />
          {permission && (
            <button
              type="button"
              onClick={() => setModalOpen(true)}
              className={twMerge('py-2 px-3 text-sm gap-2 flex items-center')}>
              <FaPlus className={twMerge('w-4 h-4')} />
              <p className={twMerge('text-base leading-[19px]')}>{t("new_location_button")}</p>
            </button>
          )}
          {permission && selectedLocation !== undefined && (
            <button
              type="button"
              onClick={() => setEditModalOpen(true)}
              className={twMerge('py-2 px-3 text-sm gap-2 flex items-center')}>
              <FaEdit className={twMerge('w-4 h-4')} />
              <p className={twMerge('text-base leading-[19px]')}>{t("edit_location_button")}</p>
            </button>
          )}
        </HoverButton.Div>
      </HoverButton>
      <CreateLocationModal open={modalOpen} setOpen={setModalOpen} />
      <EditLocationModal open={editModalOpen} setOpen={setEditModalOpen} />
    </>
  );
}

import React, { ReactElement } from 'react';
import { twMerge } from 'tailwind-merge';
import TextInput from './TextField';

export type PrefixedTextInputProps = {
  prefix: ReactElement;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'prefix'>;

export default function PrefixedTextInput({ prefix, className, ...props }: PrefixedTextInputProps): JSX.Element {
  return (
    <div className="flex">
      <div
        className="bg-gray-100 w-full rounded-tl-lg rounded-bl-lg border-r-0 border-[1.5px] border-[#E2E8F0] py-3 px-5 text-black transition flex-1 aspect-square flex justify-center items-center"
      >
        {prefix}
      </div>
      <TextInput className={twMerge(
        className,
        'rounded-none rounded-tr-lg rounded-br-lg',
      )} {...props} />
    </div>
  );
}

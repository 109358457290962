import { store } from '../redux/store';
import { CreateTicketReservationsAdminDto } from '../types/tickets';
import httpService from './httpService';

export async function createTicket(createTicketReservationDto: CreateTicketReservationsAdminDto): Promise<void> {
    const locationId = store.getState().locations.selectedLocation;
  
    return httpService
      .post(`/api/locations/${locationId}/tickets`, createTicketReservationDto)
      .then(({ data }): void => data);
  }
import { persistStore } from 'redux-persist';
import { store } from './store';
import { scheduleTokenRefresh } from '../services/authService';

const onComplete = (): void => {
  const state = store.getState();
  const {isAuthenticated} = state.application;
  if (!isAuthenticated) {
    return;
  }
  
  scheduleTokenRefresh();
};
  

export const persistor = persistStore(store, null, onComplete);
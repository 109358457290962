import React from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from '@tanstack/react-table';
import { TiChevronLeft, TiChevronRight } from 'react-icons/ti';
import IconButton from '../buttons/IconButton';

export default function TablePagination<T>({ table }: {
  table: Table<T>,
}): JSX.Element {
  const { t } = useTranslation("default_layout");
  return (
    <div className="flex gap-2 items-center -z-1">
      <IconButton
        className="bg-[#1C2434] min-h-6 min-w-6 disabled:opacity-25 text-white px-1 py-1"
        icon={TiChevronLeft} label="" disabled={!table.getCanPreviousPage()} onClick={table.previousPage} />
      <div
        className="bg-[#1C2434] font-semibold text-white px-2 py-1 min-h-8 min-w-8 flex items-center justify-center rounded-md">
        {t("page")}: {table.getState().pagination.pageIndex + 1}
      </div>
      <IconButton
        className="bg-[#1C2434] min-h-6 min-w-6 disabled:opacity-25 text-white px-1 py-1"
        icon={TiChevronRight} label="" disabled={!table.getCanNextPage()} onClick={table.nextPage} />
    </div>
  );
}

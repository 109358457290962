import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EmailTemplatesState } from '../../types/redux';
import { EmailTemplate } from '../../types/emailTemplates';

const initialState: EmailTemplatesState = {
  emailTemplates: []
};

const emailTemplatesSlice = createSlice({
  name: "emailTemplates",
  initialState,
  reducers: {
    setEmailTemplates(state, action: PayloadAction<EmailTemplate[]>): void {
      state.emailTemplates = action.payload;
    },
  },
});

const emailTemplatesReducer = emailTemplatesSlice.reducer;

export const { setEmailTemplates } = emailTemplatesSlice.actions;
export default emailTemplatesReducer;
import React from 'react';
import { twMerge } from 'tailwind-merge';

type ProgressBarVariant = 'success';

type ProgressBarProps = {
  progress: number;
  variant?: ProgressBarVariant;
  outerClassName?: string;
  innerClassName?: string;
};

const variantClasses: { [key in ProgressBarVariant]: string } = {
  success: 'bg-green-500',
};

/**
 * ProgressBar component
 * @param progress A number between 0 and 1 (inclusive) representing the progress of the bar
 * @param variant The color scheme of the progress bar
 * @param outerClassName Additional classes for the outer div
 * @param innerClassName Additional classes for the inner div
 */
export default function ProgressBar
({ progress, variant = 'success', outerClassName, innerClassName }: ProgressBarProps): JSX.Element {
  return (
    <div className={twMerge('w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700', outerClassName)}>
      <div
        className={twMerge(`${variantClasses[variant]} h-2.5 rounded-full transition-[width] duration-200`, innerClassName)}
        style={{ width: `${progress * 100}%` }} />
    </div>
  );
}

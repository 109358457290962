import { DateTime } from 'luxon';
import { Language } from '../types/misc';

export const DAY_IN_MS = 86400000;

/**
 * Function for IANA time zone
 * @returns The current time zone of the user
 */
export function getTimeZone(): string {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

/**
 * Parse a date to YYYY-MM-DD format from date and time zone
 *
 * @param date the date
 * @param timeZone the timezone
 */
export function parseToYYYYMMDD(date: Date, timeZone?: string): string {
  const parsedDateTime = date.toLocaleDateString('nl-NL', {day: '2-digit', month: '2-digit', year: 'numeric', timeZone});
  const [dd, mm, yyyy] = parsedDateTime.split('-')
  return `${yyyy}-${mm}-${dd}`;
}

/**
 * Parse a date to YYYY-MM-DD, HH:mm format from date and time zone
 *
 * @param date the date
 * @param timeZone the timezone
 */
export function parseToYYYYMMDDHHmm(date: Date, timeZone: string): string {
  return new Date(date).toLocaleDateString('nl-NL', {day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', timeZone});
}

/**
 * Parse a date to DD-MM-YYYY format from date and time zone
 *
 * @param date the date
 * @param timeZone the timezone
 */
export function parseToDDMMYYYY(date: Date, timeZone: string): string {
  return new Date(date).toLocaleDateString('nl-NL', {day: '2-digit', month: '2-digit', year: 'numeric', timeZone});
}

/**
 * Parse a YYYY-MM-DD to Date object considering the time zone
 * @param dateString
 * @param timeZone
 */
export function parseFromYYYYMMDDtoDate(dateString: string, timeZone: string): Date {
  const [yyyy, mm, dd] = dateString.split('-').map(Number);

  const utcDate = new Date(Date.UTC(yyyy, mm - 1, dd));

  const options: Intl.DateTimeFormatOptions = {
    timeZone,
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric'
  };

  return new Date(utcDate.toLocaleString('en-US', options));
}

export function secondsToHHMM(seconds: string): string {
  const secondsNumber = Number(seconds);
  const hours = Math.floor(secondsNumber / 3600);
  const minutes = secondsNumber / 60 % 60;

  const hoursString = hours < 10 ? `0${  hours}` : hours;
  const minutesString = minutes < 10 ? `0${minutes}` : minutes;
  return `${hoursString}:${minutesString}`;
}


/**
 * Set the timezone to a string date, setting the time to 00:00
 *
 * @param date the date
 * @param timezone the timezone
 * @returns string representation of the date
 */
export function setTimezoneToStringDate(date: string, timezone: string): string | undefined {
  const [year, month, day] = date.split('-').map(Number);
  return DateTime.fromObject({ day, month, year, hour: 0 }, { zone: timezone }).toISO() ?? undefined;
}

function getMonday(date: DateTime): DateTime {
  const day = date.weekday;
  const diff = day === 1 ? 0 : 1 - day; // Monday is 1
  return date.plus({ days: diff }).startOf('day');
}

function getSunday(date: DateTime): DateTime {
  const day = date.weekday;
  const diff = day === 7 ? 0 : 7 - day; // Sunday is 7
  return date.plus({ days: diff }).startOf('day');
}

export function getAllDaysInMonthAtMidnight(monthsFromNow: number, timeZone: string): Date[] {
  const now = DateTime.now().setZone(timeZone);

  const targetDate = now.plus({ months: monthsFromNow });
  const { year } = targetDate;
  const { month } = targetDate;

  const start = DateTime.fromObject({ year, month, day: 1 }, { zone: timeZone }).startOf('day');
  const end = start.endOf('month');

  const firstMonday = getMonday(start.startOf('month').minus({ days: start.day - 1 }));
  const lastSunday = getSunday(end);

  const days = [];
  let current = firstMonday;

  while (current <= lastSunday) {
    days.push(current.toJSDate());
    current = current.plus({ days: 1 });
  }

  return days;
}

export function max(a: Date, b: Date): Date {
  return a > b ? a : b;
}

export function makeTimeIrrelevantDate(date: Date, timeZone = 'utc'): Date {
  return DateTime.fromJSDate(date)
    .setZone(timeZone)
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
    .toJSDate();
}

export function isSameDay(a: Date, b: Date): boolean {
  return +makeTimeIrrelevantDate(a).getTime() === +makeTimeIrrelevantDate(b).getTime();
}

export function isSameMonth(a: Date, b: Date): boolean {
  return a.getMonth() === b.getMonth() && a.getFullYear() === b.getFullYear();
}

export function formatString(date: string, template: string, timeZone: string, language: string): string {
  return DateTime.fromISO(date).setZone(timeZone).toFormat(template, { locale: language === Language.NL ? 'nl' : 'en-us' });
}

export function format(date: Date, template: string, timeZone: string, language: string): string {
  return DateTime.fromJSDate(date).setZone(timeZone).toFormat(template, { locale: language === Language.NL ? 'nl' : 'en-us' });
}
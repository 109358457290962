import httpService from './httpService';
import { store } from '../redux/store';
import { CapacityGroup } from '../types/tickets';
import {
  CapacityGroupCreate,
  CapacityGroupFilter,
  CapacityGroupUpdate,
  CapacityGroupWithDayGroups, CapacityGroupYears,
} from '../types/capacityGroups';
import { Filter, Status, Validity } from '../types/filter';
import { parseFromYYYYMMDDtoDate } from '../utils/dateUtil';
import { getSelectedLocation } from './locationService';
import { Day } from '../types/slots';

export async function getCapacityGroupsForCurrentLocation(offset: number | undefined = undefined, limit: number | undefined = undefined, filter: CapacityGroupFilter | undefined = undefined): Promise<CapacityGroup[]> {
  const locationId = store.getState().locations.selectedLocation;
  const timeZone = getSelectedLocation()?.timezone ?? 'UTC';
  console.log(encodeURI(JSON.stringify(constructFilter(filter, timeZone))));
  const url = `/api/locations/${locationId}/capacity-groups?filter=${encodeURI(JSON.stringify(constructFilter(filter, timeZone)))}&${(offset || offset === 0) && limit ? `offset=${offset}&limit=${limit}` : ''}`;
  return httpService
    .get<CapacityGroup[]>(url)
    .then((response): CapacityGroup[] => response.data);
}

export async function getCapacityGroupYears(): Promise<CapacityGroupYears[]> {
  const locationId = store.getState().locations.selectedLocation;
  return httpService
    .get<number[]>(`/api/locations/${locationId}/capacity-groups/years`)
    .then(response => response.data.map(year => ({ id: year, name: year.toString() })));
}

export async function createCapacityGroup(data: CapacityGroupCreate): Promise<CapacityGroup> {
  const locationId = store.getState().locations.selectedLocation;

  return httpService
    .post<CapacityGroup>(`/api/locations/${locationId}/capacity-groups`, data)
    .then((response): CapacityGroup => response.data);
}

export async function deleteCapacityGroup(capacityGroupId: number): Promise<CapacityGroup> {
  const locationId = store.getState().locations.selectedLocation;

  return httpService
    .delete<CapacityGroup>(`/api/locations/${locationId}/capacity-groups/${capacityGroupId}`)
    .then((response): CapacityGroup => response.data);
}

export async function getCapacityGroup(capacityGroupId: number): Promise<CapacityGroupWithDayGroups> {
  const locationId = store.getState().locations.selectedLocation;

  return httpService
    .get<CapacityGroupWithDayGroups>(`/api/locations/${locationId}/capacity-groups/${capacityGroupId}`)
    .then((response): CapacityGroupWithDayGroups => {
      console.log(response.data);
      return response.data;
    })
}

export async function getDayGroupsForCapacityGroup(capacityGroupId: number, fromDate: Date, toDate: Date): Promise<Day[]> {
  const locationId = store.getState().locations.selectedLocation;
  const from = fromDate.toISOString(); // new Date().toISOString();
  const to = toDate.toISOString(); // new Date(Date.now() + 30 * DAY_IN_MS).toISOString();

  return httpService
    .get<Day[]>(`/api/locations/${locationId}/capacity-groups/${capacityGroupId}/days?from=${from}&to=${to}`)
    .then((response): Day[] => {
      return response.data;
    });
}

export async function updateCapacityGroup(data: CapacityGroupUpdate, capacityGroupId: number): Promise<CapacityGroup> {
  const locationId = store.getState().locations.selectedLocation;

  return httpService
    .patch<CapacityGroup>(`/api/locations/${locationId}/capacity-groups/${capacityGroupId}`, data)
    .then((response): CapacityGroup => {
      console.log(response.data);
      return response.data;
    })
}

function constructFilter(capacityGroupFilter: CapacityGroupFilter | undefined, timeZone: string): Filter<CapacityGroupCreate & { archived: boolean }> {
  if (!capacityGroupFilter) {
    return {};
  }
  const validityFilter: Filter<CapacityGroupCreate> =
    capacityGroupFilter.validity === Validity.ALL ? {} :
    capacityGroupFilter.validity === Validity.CURRENT ? { startDate: { lte: new Date() }, endDate: { gte: new Date() } } :
    capacityGroupFilter.validity === Validity.EXPIRED ? { endDate: { lte: new Date() } } : {};
  const activeFilter: Filter<CapacityGroupCreate & { archived: boolean }> =
    capacityGroupFilter.status === Status.ALL ? {} :
    capacityGroupFilter.status === Status.ACTIVE ? { archived: { equals: false } } :
    capacityGroupFilter.status === Status.ARCHIVED ? { archived: { equals: true} } : {};
  const yearsFilterFilter: Filter<CapacityGroupCreate & { archived: boolean }>[] = capacityGroupFilter.years.map(year => ({
    OR: [
      { startDate: { gte: parseFromYYYYMMDDtoDate(`${year}-1-1`, timeZone), lte: parseFromYYYYMMDDtoDate(`${year}-12-31`, timeZone) } },
      { endDate: { gte: parseFromYYYYMMDDtoDate(`${year}-1-1`, timeZone), lte: parseFromYYYYMMDDtoDate(`${year}-12-31`, timeZone) } }
    ]
  }));
  return {
    AND: [
      validityFilter,
      activeFilter,
      { OR: yearsFilterFilter }
    ]
  }
}

/* eslint-disable react/destructuring-assignment */
import React, { ButtonHTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;

export default function Button(props: ButtonProps): JSX.Element {
  return (
    <button
      type="button"
      {...props}
      className={twMerge(
        'rounded-[20px] justify-center text-white bg-[#3C50E0] disabled:opacity-20 transition-all whitespace-nowrap hover:bg-opacity-80 disabled:hover:bg-opacity-100 w-fit h-fit flex items-center font-[600] px-[16px] py-[8px] gap-1',
        props.className,
      )}
    />
  );
}

import React from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, Field, Label } from '@headlessui/react';
import { CheckboxOption } from '../../types/misc';

interface CheckboxListProps {
  options: CheckboxOption[];
  selectedOptions: string[];
  onChange: (value: string) => void;
  onSelectAll: () => void;
  onUnselectAll: () => void;
}

export default function CheckboxList({
    options,
    selectedOptions,
    onChange,
    onSelectAll,
    onUnselectAll,
}: CheckboxListProps): JSX.Element {
  const { t } = useTranslation("refund_payment_form");
  return (
    <>
      <div>
        <button
          type="button"
          onClick={onSelectAll}
          className="text-blue-600 underline hover:text-blue-800 mr-4">
          {t("select_all")}
        </button>
        |
        <button
          type="button"
          onClick={onUnselectAll}
          className="text-blue-600 underline hover:text-blue-800 ml-4">
          {t("unselect_all")}
        </button>
      </div>
      {options.map((option) => (
        <div key={option.value}>
          <Field className="flex items-center gap-2">
            <Checkbox
              value={option.value}
              checked={selectedOptions.includes(option.value)}
              onChange={() => onChange(option.value)}
              className="group block size-6 rounded border-2 bg-white data-[checked]:bg-blue-500"
            >
              <svg className="stroke-white opacity-0 group-data-[checked]:opacity-100" viewBox="0 0 14 14" fill="none">
                <path d="M3 8L6 11L11 3.5" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" />
              </svg>
            </Checkbox>
            <Label>{option.label} <br/> <span className='text-xs'>{option.description}</span></Label>
          </Field> 
        </div>
      ))}
    </>
  );
};

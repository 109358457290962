import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { createColumnHelper, PaginationState, Row, RowSelectionState } from '@tanstack/react-table';
import { useNavigate, useParams } from 'react-router-dom';
import {
  TbCirclePlus,
  TbTrash,
} from 'react-icons/tb';
import { MdBlock } from 'react-icons/md';
import { CgUnblock } from "react-icons/cg";
import SelectionTable from '../../components/tables/SelectionTable';
import IconButton from '../../components/buttons/IconButton';
import DeleteModal from '../../components/modals/DeleteModal';
import { store } from '../../redux/store';
import CheckboxDropdown from '../../components/forms/CheckboxDropdown';
import useLanguage from '../../hooks/selectors/useLanguage';
import { blockDiscountCodesForDiscountOffer, deleteDiscountCodesForDiscountOffer, getDiscountCodesForDiscountOffer, getDiscountOffer, unblockDiscountCodesForDiscountOffer } from '../../services/discountService';
import { setDiscountOffer } from '../../redux/slices/discountOffersSlice';
import { BlockStatus, DiscountCode, DiscountCodeFilter } from '../../types/discounts';
import { OptionItem } from '../../components/forms/SelectWithData';
import GenerateDiscountCodesModal from '../../components/modals/GenerateDiscountCodesModal';
import useDiscountOffer from '../../hooks/selectors/useDiscountOffer';
import { parseToYYYYMMDD } from '../../utils/dateUtil';
import useSelectedLocation from '../../hooks/selectors/useSelectedLocation';
import usePermissions from '../../hooks/selectors/usePermissions';
import { Permissions } from '../../types/misc';

const columnHelper = createColumnHelper<DiscountCode>();

function RenderActionsCell({ row }: { row: Row<DiscountCode> }, resetPagination: () => void, isDiscountOfferArchrived: boolean): JSX.Element {
  const [deleteModalOpen, setDeleteModalOpen] = React.useState<boolean>(false);
  const { t } = useTranslation("discount_code");

  const onDelete = (): void => {
    deleteDiscountCodesForDiscountOffer(row.original.discountOfferId, [row.original.code])
      .then((): void => {
        toast.success(t("deleted"));
        resetPagination();
        setDeleteModalOpen(false);
      })
      .catch((error): void => {
        toast.error(error.message);
      });
  };

  const onBlock = (): void => {
    blockDiscountCodesForDiscountOffer(row.original.discountOfferId, [row.original.code])
      .then((): void => {
        toast.success(t("blocked"));
        resetPagination();
      })
      .catch((error): void => {
        toast.error(error.message);
      });
  }

  const onUnblock = (): void => {
    unblockDiscountCodesForDiscountOffer(row.original.discountOfferId, [row.original.code])
      .then((): void => {
        toast.success(t("unblocked"));
        resetPagination();
      })
      .catch((error): void => {
        toast.error(error.message);
      });
  }

  return (
    <>
      <div className="flex justify-end items-center gap-1">
        <div hidden={row.original.frozen}>
          <IconButton
            onClick={onBlock}
            icon={MdBlock} label="" variant="accent"
            className="py-1 px-1 text-lg"
            disabled={!usePermissions([Permissions.ManageDiscounts])}
          />
        </div>
        <div hidden={!row.original.frozen}>
          <IconButton
            onClick={onUnblock}
            hidden={!row.original.frozen}
            disabled={isDiscountOfferArchrived && !usePermissions([Permissions.ManageDiscounts])}
            icon={CgUnblock} label="" variant="duplicate"
            className="py-1 px-1 text-lg"
          />
        </div>
        <IconButton
          onClick={(): void => setDeleteModalOpen(true)}
          icon={TbTrash} label="" variant="danger"
          disabled={row.original.uses > 0 && !usePermissions([Permissions.ManageDiscounts])}
          className="py-1 px-1 text-lg"
        />
      </div>
      <DeleteModal open={deleteModalOpen} setOpen={setDeleteModalOpen} onActionButtonClicked={onDelete}
                   message={`${t("delete_confirmation")} "${row.original.code}"?`}
      />
    </>
  );
}

function RenderCode({ row: { original: { code, frozen} } }: {
  row: Row<DiscountCode>
}): JSX.Element {
  if (!frozen) return <>{code}</>;

  return (
    <div className="flex items-center gap-1 text-gray-600"><MdBlock size={16} />{code}</div>
  );
}

type DiscountCodeWithId = DiscountCode & { id: string };

function addIdToDiscountCode(discountCode: DiscountCode): DiscountCodeWithId {
  return { ...discountCode, id: discountCode.code };
}

export default function DiscountCodesListPage(): JSX.Element {
  if (!usePermissions([Permissions.ManageDiscounts])) {
    return (<></>);
  }
  const [rowSelection, setRowSelection] = React.useState<RowSelectionState>({});
  const [pagination, setPagination] = React.useState<PaginationState>({ pageIndex: 0, pageSize: 25 });
  const [filter, setFilter] = React.useState<DiscountCodeFilter>({ block: BlockStatus.NOT_BLOCKED });
  const [discountCodes, setDiscountCodes] = React.useState<DiscountCodeWithId[]>([]);

  const [generateModalOpen, setGenerateModalOpen] = React.useState<boolean>(false);
  const [deleteBulkModalOpen, setDeleteBulkModalOpen] = React.useState<boolean>(false);

  const { id } = useParams();
  const location = useSelectedLocation();
  const language = useLanguage();
  const navigate = useNavigate();
  const { t } = useTranslation("discount_code");

  if (!id) {
    navigate('/discount-offers');
    return <></>;
  }

  const discountOffer = useDiscountOffer(Number(id));

  const resetPagination = (): void => {
    setPagination(prev => ({ pageSize: prev.pageSize, pageIndex: prev.pageIndex }));
  }

  React.useEffect((): void => {
    getDiscountOffer(Number(id))
      .then((data): void => {
        store.dispatch(setDiscountOffer(data));
        resetPagination(); // used to force a reload of the page
      })
      .catch((error): void => {
        toast.error(error.message);
      });
  }, []);

  React.useEffect((): void => {
    getDiscountCodesForDiscountOffer(Number(id), pagination.pageIndex * pagination.pageSize, pagination.pageSize, filter)
      .then((data): void => {
        setDiscountCodes(data.map(addIdToDiscountCode))
      })
      .catch((error): void => {
        toast.error(error.message);
      });
  }, [pagination]);

  const columns = useMemo((): any[] => [
    columnHelper.accessor((row) => row.code, {
      id: 'code',
      header: t("code"),
      cell: RenderCode,
    }),
    columnHelper.accessor((row) => row.uses, {
      id: 'uses',
      header: t("uses"),
      cell: ({ row: { original: { uses, usageCap } } }): string => `${uses}/${usageCap}`,
    }),
    columnHelper.accessor((row) => row.from, {
      id: 'from',
      header: t("from"),
      cell : ({ row: { original: { from } } }): string => parseToYYYYMMDD(new Date(from), location?.timezone ?? 'UTC'),
    }),
    columnHelper.accessor((row) => row.to, {
      id: 'to',
      header: t("to"),
      cell : ({ row: { original: { to } } }): string => parseToYYYYMMDD(new Date(to), location?.timezone ?? 'UTC'),
    }),
    {
      id: 'actions',
      header: t("actions"),
      cell: (row: { row: Row<DiscountCode> }) => RenderActionsCell(row, resetPagination, discountOffer?.archived ?? false),
      meta: {
        align: 'text-right',
      },
    },
  ], [language]);

  const discountBlockStatus: Record<BlockStatus, OptionItem> = {
    [BlockStatus.ALL]: { id: BlockStatus.ALL, name: t("all") },
    [BlockStatus.BLOCKED]: { id: BlockStatus.BLOCKED, name: t("blocked_status") },
    [BlockStatus.NOT_BLOCKED]: { id: BlockStatus.NOT_BLOCKED, name: t("not_blocked_status") }
  };

  const onChangeBlockStatus = (selected: { id: BlockStatus, name: string }[]): void => {
    if (selected.length === 0) return;
    setFilter(prevFilter => ({ ...prevFilter, block: selected[0].id }));
  }

  const onBulkDelete = (): void => {
    deleteDiscountCodesForDiscountOffer(Number(id), Object.entries(rowSelection).filter(e => e[1]).map(e => e[0]))
      .then((): void => {
        toast.success(t("deleted"));
        resetPagination();
        setDeleteBulkModalOpen(false);
      })
      .catch((error): void => {
        toast.error(error.message);
      });
  };

  const onBulkBlock = (): void => {
    blockDiscountCodesForDiscountOffer(Number(id), Object.entries(rowSelection).filter(e => e[1]).map(e => e[0]))
      .then((): void => {
        toast.success(t("blocked"));
        resetPagination();
      })
      .catch((error): void => {
        toast.error(error.message);
      });
  };

  const onBulkUnblock = (): void => {
    unblockDiscountCodesForDiscountOffer(Number(id), Object.entries(rowSelection).filter(e => e[1]).map(e => e[0]))
      .then((): void => {
        toast.success(t("unblocked"));
        resetPagination();
      })
      .catch((error): void => {
        toast.error(error.message);
      });
  };

  return (
    <>
      <div className="flex justify-between items-center">
        <h2 className="font-gintobold font-bold text-2xl">{t("title", { discountOfferId: id, discountOfferName: discountOffer?.name })}</h2>
        <div className="flex gap-1">
          {
            usePermissions([Permissions.ManageDiscounts]) &&
            <IconButton onClick={(): void => setGenerateModalOpen(true)} icon={TbCirclePlus} label={t("generate")}
                        className="bg-gradient-to-r from-[#E700F9] hover:shadow-purple-300 hover:shadow-md
                      to-pink-500 duration-300 bg-[position:_0%_0%] hover:bg-[position:_100%_100%]
                      bg-[size:_200%] transition-all font-bold text-white rounded-full cursor-pointer" />
          }
        </div>
      </div>
      <div className="flex gap-1 items-center py-2">
        <p className="font-semibold text-md">{t("bulk_actions")}: </p>
        <IconButton
          disabled={Object.values(rowSelection).filter((v): boolean => v).length === 0}
          icon={TbTrash} label={t("delete_selected")} onClick={(): void => setDeleteBulkModalOpen(true)}
          className="px-2 py-0.5 bg-red-500 hover:bg-red-600 text-white disabled:bg-red-200 rounded-full"
        />
        <IconButton
          disabled={Object.values(rowSelection).filter((v): boolean => v).length === 0}
          icon={MdBlock} label={t("block_selected")} onClick={onBulkBlock}
          className="px-2 py-0.5 rounded-full" variant="accent"
        />
        <IconButton
          disabled={(Object.values(rowSelection).filter((v): boolean => v).length === 0) || discountOffer?.archived}
          icon={CgUnblock} label={t("unblock_selected")} onClick={onBulkUnblock}
          className="px-2 py-0.5 rounded-full" variant="duplicate"
        />
      </div>
      <div className="flex gap-1 items-center py-2">
        <p className="font-semibold text-md">{t("filters")}: </p>
        <CheckboxDropdown label={t("block_status")} values={Object.values(discountBlockStatus)} onChange={onChangeBlockStatus} type="radio" initialValues={[discountBlockStatus[filter.block]]} />
      </div>
      <div>
        <SelectionTable
          columns={columns}
          data={discountCodes}
          pagination={pagination}
          setPagination={setPagination}
          enableRowSelection
          rowSelection={rowSelection}
          setRowSelection={setRowSelection}
        />
      </div>
      <GenerateDiscountCodesModal open={generateModalOpen} setOpen={setGenerateModalOpen} onSuccess={() => { resetPagination(); setGenerateModalOpen(false) }} discountOfferId={Number(id)} />
      <DeleteModal open={deleteBulkModalOpen} setOpen={setDeleteBulkModalOpen} onActionButtonClicked={onBulkDelete}
                   message={`${t("delete_confirmation_bulk")}"?`}
      />
    </>
  );
}
import React from 'react';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import ModalLayout from '../layouts/ModalLayout';
import { locationService } from '../../services';
import { setSelected } from '../../redux/slices/locationsSlice';
import LocationForm from '../forms/LocationForm';
import { LocationCreate, LocationUpdate } from '../../types/location';

interface CreateLocationModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
}

export default function CreateLocationModal({
  open,
  setOpen,
}: CreateLocationModalProps): JSX.Element {
  const { t } = useTranslation("location_button");
  const dispatch = useDispatch();

  const onSubmit = (location: LocationCreate | LocationUpdate): void => {
      locationService.createLocation(location as LocationCreate).then(({ id }): void => {
        toast.success(t("location_created"));
        setOpen(false);
        dispatch(setSelected(id));
      }).catch((error): void => {
        if (error instanceof AxiosError && error.response?.data.errors) {
          toast.error(
            error.response?.data.errors
              .flatMap((e: { constraints: { [k: string]: string }[] }): {
                [k: string]: string
              }[] => Object.values(e.constraints))
              .map((x: string): string => x.charAt(0).toUpperCase() + x.slice(1))
              .join('. '),
          );
        } else {
          toast.error(error.message);
        }
      });
  };

  return (
    <ModalLayout
      open={open}
      setOpen={setOpen}
      closeButton
      className="bg-white max-w-3xl rounded-3xl p-16">
      <LocationForm onSubmit={onSubmit} onCancel={() => setOpen(false)} formType="create" />
    </ModalLayout>
  );
}

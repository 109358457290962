import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { TicketTypeDTO, TicketTypeGetEntity } from '../../types/tickets';
import { getTicketType, updateTicketType } from '../../services/ticketTypeService';
import TicketTypeForm from '../../components/forms/TicketTypeForm';

export default function EditTicketTypePage(): JSX.Element | null {
  const navigate = useNavigate();
  const [ticketType, setTicketType] = useState<TicketTypeGetEntity>();
  const { id } = useParams();
  const { t } = useTranslation("ticket_type");

  if (!id) {
    navigate('/ticket-types');
    return null;
  }

  useEffect((): void => {
    getTicketType(Number(id))
      .then((oldTicketType): void => {
        setTicketType(oldTicketType);
      })
      .catch((error): void => {
        toast.error(error.message);
        navigate('/ticket-types');
      });
  }, []);

  const onSubmit = (updatedTicketType: TicketTypeDTO): void => {
    console.log(updatedTicketType);
    updateTicketType(Number(id), updatedTicketType).then((): void => {
      toast.success(t("updated"));
      navigate('/ticket-types');
    }).catch((error): void => {
      toast.error(error.message);
    });
  };

  if (!ticketType) return null;

  return (
    <TicketTypeForm initialState={ticketType} onSubmit={onSubmit} formType="update" />
  );
}

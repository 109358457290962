import React from 'react';
import ModalLayout from '../layouts/ModalLayout';
import GenerateDiscountCodesForm from '../forms/GenerateDiscountCodesForm';

interface GenerateDiscountCodesModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  onSuccess: () => void;
  discountOfferId: number;
}

export default function GenerateDiscountCodesModal(
  {
    open,
    setOpen,
    onSuccess,
    discountOfferId
  }: GenerateDiscountCodesModalProps,
): JSX.Element | null {
  if (!open) return null;

  return (
    <ModalLayout
      open={open}
      setOpen={setOpen}
      closeButton
      className="bg-white max-w-3xl rounded-3xl p-12">
        <GenerateDiscountCodesForm onCancel={() => setOpen(false)} onSuccess={onSuccess} discountOfferId={discountOfferId} />
    </ModalLayout>
  );
}

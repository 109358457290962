import { AnyAction, CombinedState, Reducer } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
import applicationReducer from './slices/applicationSlice';
import { RootState } from '../types/redux';
import locationsReducer from './slices/locationsSlice';
import ticketTypesReducer from './slices/ticketTypesSlice';
import ticketShopsReducer from './slices/ticketShopsSlice';
import capacityGroupReducer from './slices/capacityGroupSlice';
import scanGroupsReducer from './slices/scanGroupsSlice';
import scannersReducer from './slices/scannersSlice';
import dayGroupsReducer from './slices/dayGroupSlice';
import scanDefinitionsReducer from './slices/scanDefinitionsSlice';
import pricingPoliciesReducer from './slices/pricingPoliciesSlice';
import subscriptionShopsReducer from './slices/subscriptionShopsSlice';
import subscriptionTypesReducer from './slices/subscriptionTypesSlice';
import accountsReducer from './slices/accountsSlice';
import layoutsReducer from './slices/layoutsSlice';
import paymentsReducer from './slices/paymentsSlice';
import emailTemplatesReducer from './slices/emailTemplatesSlice';
import discountOffersReducer from './slices/discountOffersSlice';
import rolesReducer from './slices/rolesSlice';
import ticketCategoriesReducer from './slices/ticketCategoriesSlice';

const combinedReducer = combineReducers({
  application: applicationReducer,
  locations: locationsReducer,
  ticketTypes: ticketTypesReducer,
  ticketShops: ticketShopsReducer,
  ticketCategories: ticketCategoriesReducer,
  capacityGroups: capacityGroupReducer,
  scanGroups: scanGroupsReducer,
  scanners: scannersReducer,
  dayGroups: dayGroupsReducer,
  scanDefinitions: scanDefinitionsReducer,
  pricingPolicies: pricingPoliciesReducer,
  subscriptionTypes: subscriptionTypesReducer,
  subscriptionShops: subscriptionShopsReducer,
  accounts: accountsReducer,
  layouts: layoutsReducer,
  payments: paymentsReducer,
  emailTemplates: emailTemplatesReducer,
  discountOffers: discountOffersReducer,
  roles: rolesReducer,
});

const initialStateForLocation = {
  ticketTypes: { ticketTypes: [] },
  ticketShops: { ticketShops: [] },
  ticketCategories: { ticketCategories: [] },
  capacityGroups: { capacityGroups: [] },
  scanGroups: { scanGroups: [] },
  scanners: { scanners: [] },
  dayGroups: { dayGroups: [] },
  scanDefinitions: { scanDefinitions: [] },
  pricingPolicies: { pricingPolicies: [] },
  subscriptionTypes: { subscriptionTypes: [] },
  subscriptionShops: { subscriptionShops: [] },
  accounts: { accounts: [] },
  layouts: { layouts: [] },
  payments: { payments: [] },
  emailTemplates: { emailTemplates: [] },
  discountOffers: { discountOffers: [] },
  roles: { roles: [] },
}

const rootReducer = (state: RootState, action: AnyAction): CombinedState<RootState> => {
  if (action.type === 'application/setAuthenticated' && action.payload === false) {
    localStorage.removeItem('persist:root');
    state = {
      application: { language: state.application.language, isAuthenticated: false },
      locations: { locations: [] },
      ...initialStateForLocation
    };
  }

  if (action.type === 'location/setSelected') {
    state = {
      ...state,
      ...initialStateForLocation
    };
  }

  return combinedReducer(state, action);
};

export default rootReducer as Reducer<RootState, AnyAction>;

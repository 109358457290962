import httpService from './httpService';
import { Slot, SlotsUpdateDto, TicketTypeWithSlotsAndPricing } from '../types/slots';
import { store } from '../redux/store';

export async function updateSlots(capacityGroupId: number, dto: SlotsUpdateDto): Promise<Slot[]> {
  const locationId = store.getState().locations.selectedLocation;

  return httpService
    .patch<Slot[]>(`/api/locations/${locationId}/capacity-groups/${capacityGroupId}/slots`, dto)
    .then(({ data }) => data);
}

export async function getTicketTypesOnSlots(capacityGroupId: number, slotIds: number[]): Promise<TicketTypeWithSlotsAndPricing[]> {
  const locationId = store.getState().locations.selectedLocation;

  return httpService.post(`/api/locations/${locationId}/capacity-groups/${capacityGroupId}/slots/bulk-edit`, {
    slotIds,
  }).then(({ data }) => data);
}

export async function updateTicketTypesOnSlots(capacityGroupId: number, pricingData: TicketTypeWithSlotsAndPricing[]): Promise<void> {
  const locationId = store.getState().locations.selectedLocation;

  return httpService.put(`/api/locations/${locationId}/capacity-groups/${capacityGroupId}/slots/bulk-edit`, pricingData);
}

export async function downloadExcelReportForSlots(slotIds: number[]): Promise<Buffer> {
  return httpService
    .post<Buffer>(`/api/report/slots`, { slotIds }, {headers: {'Content-Type': 'application/json'}, responseType: 'arraybuffer'})
    .then(({ data }) => data)
}

import React, { useEffect, useState } from 'react';
import { FaRegTrashAlt } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import TextInput from '../forms/TextField';
import Button from '../buttons/Button';
import IconButton from '../buttons/IconButton';

interface TimeBasedPricingPolicyTableProps<T> {
  items: T[];
  onChangeTimeItems: (updatedItems: T[]) => void;
}

export default function TimeBasedPricingPolicyTable<T extends { id?: number, from: string, to: string, increment: number}> ({ items, onChangeTimeItems }: TimeBasedPricingPolicyTableProps<T>): JSX.Element {
  const [rows, setRows] = useState<T[]>([]);
  const { t } = useTranslation("pricing_policy_form");

  useEffect(() => {
    setRows(items.map((item, index) => {
      return {
        ...item,
        id: index,
      }
    }));
  }, [items])

  const handleInputChange = (index: number, column: keyof T, value: string | number) : void => {
    const newRows = rows.map((row, i) => (
      i === index ? { ...row, [column]: value } : row
    ));
    setRows(newRows);
    onChangeTimeItems(newRows);
  };

  const handleDelete = (id: number) : void => {
    const updatedRows = rows.filter(d => d.id !== id);
    setRows(updatedRows);
    onChangeTimeItems(updatedRows);
  };

  const addRow = () : void => {
    const addRowItem : T = {
      id: items.length ? Math.max(...items.map(item => item.id ?? 0)) + 1 : 1,
      from: '',
      to: '',
      increment: 0,
    } as unknown as T;

    setRows((prevRows) => [...prevRows, addRowItem]);
  };

  return (
    <div>
      <table className='min-w-full border-collapse mt-2'>
        <thead>
          <tr>
            <td className="p-1 text-left">{t("from")}</td>
            <td className="p-1 text-left">{t("to")}</td>
            <td className="p-1 text-left">{t("increment")}</td>
          </tr>
        </thead>
        <tbody>
          {rows.map((row, index) => (
            <tr key={index}>
              <td className='w-1/3'>
                <TextInput
                  type="time"
                  defaultValue={row.from}
                  onChange={(e) => handleInputChange(index, 'from', e.target.value)}
                  className="bg-white mb"
                />
              </td>
              <td className='w-1/3'>
                <TextInput
                  type="time"
                  defaultValue={row.to}
                  onChange={(e) => handleInputChange(index, 'to', e.target.value)}
                  className="bg-white mb"
                />
              </td>
              <td className='w-1/3'>
                <TextInput
                  type="number"
                  step="0.01"
                  defaultValue={row.increment}
                  onChange={(e) => handleInputChange(index, 'increment', Number(e.target.value))}
                  className="bg-white mb"
                />
              </td>
              <td>
                <IconButton icon={FaRegTrashAlt} label='' variant='danger' className='px-3 py-3 mb ml-4' onClick={() => handleDelete(row.id ?? 0)} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Button className='bg-sb-pink mt-4' type='button' onClick={addRow}>{t("add_row")}</Button>
    </div>
  );
};

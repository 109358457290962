import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ScanDefinition } from '../../types/tickets';
import { ScanDefinitionsState } from '../../types/redux';

const initialState: ScanDefinitionsState = {
  scanDefinitions: [],
};

const scanDefinitionsSlice = createSlice({
  name: 'scanDefinitions',
  initialState,
  reducers: {
    setScanDefinitions(state, action: PayloadAction<ScanDefinition[]>): void {
      state.scanDefinitions = action.payload;
    },
  },
});

const scanDefinitionsReducer = scanDefinitionsSlice.reducer;

export const { setScanDefinitions } = scanDefinitionsSlice.actions;
export default scanDefinitionsReducer;

import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { ScanGroup } from '../../types/tickets';
import { getScanGroupsForCurrentLocation } from '../../services/scanGroupService';

type ScanGroupsFormData = {
  scanGroups: ScanGroup[];
  loading: boolean;
  setScanGroups: (scanGroups: ScanGroup[]) => void;
 }

export default function useScanGroupsFormData(): ScanGroupsFormData {
  const { t } = useTranslation("service_errors");
  const [scanGroups, setScanGroups] = useState<ScanGroup[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  useEffect(() => {
    getScanGroupsForCurrentLocation(0, Number.MAX_SAFE_INTEGER)
      .then(result => setScanGroups(result))
      .catch(() => {
        toast.error(t("failed_fetch_scan_groups"));
        navigate('/tickets');
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  return { scanGroups, loading, setScanGroups };
}

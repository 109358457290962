import React from 'react';
import PrefixedTextInput, { PrefixedTextInputProps } from './PrefixedTextInput';

type WithLabelProps = {
  label: string;
  containerClassName?: string;
  id: string;
} & Omit<PrefixedTextInputProps, 'id'>;

export default function PrefixedTextInputWithLabel(
  { id, containerClassName, label, ...props }: WithLabelProps,
): JSX.Element {
  return (
    <div className={containerClassName}>
      <label htmlFor={id}>{label}</label>
      <PrefixedTextInput id={id} {...props} />
    </div>
  );
}

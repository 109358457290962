import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { TbCurrencyEuro } from 'react-icons/tb';
import { TicketShop, TicketTypeDTO, TicketTypeGetEntity } from '../../types/tickets';
import TextInputWithLabel from './TextInputWithLabel';
import Button from '../buttons/Button';
import SelectWithData from './SelectWithData';
import MultipleDropdown from './MultipleDropdown';
import useTicketTypeFormData from '../../hooks/selectors/useTicketTypeFormData';
import CheckboxWithLabel from './CheckboxWithLabel';
import { parseToYYYYMMDD, setTimezoneToStringDate } from '../../utils/dateUtil';
import useSelectedLocation from '../../hooks/selectors/useSelectedLocation';
import PrefixedTextInputWithLabel from './PrefixedTextInputWithLabel';
import usePermissions from '../../hooks/selectors/usePermissions';
import { Permissions } from '../../types/misc';

type TicketTypeFormProps = {
  onSubmit: (ticketType: TicketTypeDTO) => void;
  formType?: 'create' | 'update';
  initialState?: TicketTypeGetEntity;
}

type TicketTypeFormState = {
  name: string,
  price: string,
  description: string,
  lowBoundTicketCount?: string,
  highBoundTicketCount?: string,
  requireQRCode: boolean,
  rotterdampas: boolean,
  discountable: boolean,
  alwaysAvailable: boolean,
  vatPercentage: string,
  validFrom?: string,
  validTo?: string,
  occasionallySellingFrom: boolean,
  sellingFrom?: string,
  occasionallySellingTo: boolean,
  sellingTo?: string,
  ticketCategoryId: string,
  capacityGroupId?: string,
  scanDefinitionId?: string,
  layoutId?: string,
  ticketShops?: TicketShop[],
  orderPriority: string,
}

function TicketTypeForm({
                          onSubmit,
                          formType = 'create',
                          initialState = {} as TicketTypeGetEntity,
                        }: TicketTypeFormProps): JSX.Element {
  if (!usePermissions([Permissions.ManageConfigurations])) {
    return (<></>);
  }
  const location = useSelectedLocation();

  const [ticketType, setTicketType] = useState<TicketTypeFormState>({
    name: initialState.name || '',
    price: String(initialState?.price?.toFixed(2) || ''),
    description: initialState.description || '',
    lowBoundTicketCount: String(initialState.lowBoundTicketCount || ''),
    highBoundTicketCount: String(initialState.highBoundTicketCount || ''),
    requireQRCode: initialState.requireQRCode || false,
    rotterdampas: initialState.rotterdampas || false,
    discountable: initialState.discountable || false,
    alwaysAvailable: initialState.alwaysAvailable || false,
    vatPercentage: String(initialState.vatPercentage || 9),
    validFrom: initialState.validFrom && parseToYYYYMMDD(new Date(initialState.validFrom), location?.timezone ?? 'UTC'),
    validTo: initialState.validTo && parseToYYYYMMDD(new Date(initialState.validTo), location?.timezone ?? 'UTC'),
    occasionallySellingFrom: !!initialState?.sellingFrom,
    sellingFrom: initialState?.sellingFrom && parseToYYYYMMDD(new Date(initialState.sellingFrom), location?.timezone ?? 'UTC'),
    occasionallySellingTo: !!initialState?.sellingTo,
    sellingTo: initialState?.sellingTo && parseToYYYYMMDD(new Date(initialState.sellingTo), location?.timezone ?? 'UTC'),
    ticketCategoryId: String(initialState.ticketCategoryId || ''),
    capacityGroupId: String(initialState.capacityGroupId || ''),
    scanDefinitionId: String(initialState.scanDefinitionId || ''),
    layoutId: String(initialState?.layoutId || ''),
    ticketShops: [],
    orderPriority: String(initialState.orderPriority || ''),
  });

  const navigate = useNavigate();
  const { t } = useTranslation("ticket_type_form");
  const { loading, ticketShops, ticketCategories, capacityGroups, scanDefinitions, layouts } = useTicketTypeFormData();

  useEffect(() => {
    if (!loading && formType === 'update') {
      setTicketType((prev): TicketTypeFormState => ({
        ...prev,
        ticketShops: initialState?.ticketShopsAvailableAt
          ?.map(d => ticketShops.find(shop => shop.id === d.id))
          .filter(x => x) as TicketShop[],
      }));
    }
  }, [loading]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {
    setTicketType((prev): TicketTypeFormState => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setTicketType((prev): TicketTypeFormState => ({
      ...prev,
      [event.target.name]: event.target.checked,
    }));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    onSubmit({
      name: ticketType.name,
      description: ticketType.description,
      price: Number(ticketType.price),
      ticketCategoryId: Number(ticketType.ticketCategoryId),
      ...(ticketType.lowBoundTicketCount && { lowBoundTicketCount: Number(ticketType.lowBoundTicketCount) }),
      ...(ticketType.highBoundTicketCount && { highBoundTicketCount: Number(ticketType.highBoundTicketCount) }),
      requireQRCode: ticketType.requireQRCode,
      rotterdampas: ticketType.rotterdampas,
      discountable: ticketType.discountable,
      alwaysAvailable: ticketType.alwaysAvailable,
      sellingFrom: ticketType.occasionallySellingFrom && ticketType.sellingFrom ? setTimezoneToStringDate(ticketType.sellingFrom, location?.timezone ?? 'UTC') : undefined,
      sellingTo: ticketType.occasionallySellingTo && ticketType.sellingTo ? setTimezoneToStringDate(ticketType.sellingTo, location?.timezone ?? 'UTC') : undefined,
      vatPercentage: Number(ticketType.vatPercentage),
      ...(ticketType.alwaysAvailable && ticketType.validFrom && { validFrom: setTimezoneToStringDate(ticketType.validFrom, location?.timezone ?? 'UTC') }),
      ...(ticketType.alwaysAvailable && ticketType.validTo && { validTo: setTimezoneToStringDate(ticketType.validTo, location?.timezone ?? 'UTC') }),
      ...(!ticketType.alwaysAvailable && ticketType.capacityGroupId && { capacityGroupId: Number(ticketType.capacityGroupId) }),
      ...(ticketType.scanDefinitionId && { scanDefinitionId: Number(ticketType.scanDefinitionId) }),
      ...(ticketType.layoutId && { layoutId: Number(ticketType.layoutId) }),
      ...(ticketType.ticketShops && {
        ticketShopsIds: ticketType.ticketShops?.map(d => d.id),
      }),
      orderPriority: Number(ticketType.orderPriority),
    });
  };

  const formatPrice = (): void => {
    setTicketType((prev): TicketTypeFormState => ({
      ...prev,
      price: Number(prev.price).toFixed(2),
    }));
  };

  if (loading) return <h2 className="text-2xl font-bold text-center mb-4">{t("loading")}</h2>;

  return (
    <>
      <h2 className="text-2xl font-bold text-center mb-4">{formType === 'create' ? t("create_title") : t("edit_title")}</h2>
      <form className="flex flex-col gap-4 max-w-3xl m-auto" onSubmit={handleSubmit}>
        <TextInputWithLabel label={t("name")} id="name" placeholder={t("name_placeholder")} name="name" onChange={handleChange}
                            value={ticketType.name} className="bg-white" required />
        <PrefixedTextInputWithLabel label={t("price")}
                                  prefix={<TbCurrencyEuro size={20} strokeWidth={2.25} color="#303030" />}
                                  id="price" name="price" value={ticketType.price} type="number"
                                  onChange={handleChange} placeholder={t("price_placeholder")}
                                  className="bg-white" required onBlur={formatPrice} />
        <TextInputWithLabel label={t("description")} id="description" placeholder={t("description_placeholder")} name="description"
                            onChange={handleChange} value={ticketType.description} className="bg-white" required />
        <TextInputWithLabel label={t("order_priority")} id="orderPriority" placeholder={t("order_priority_placeholder")} name="orderPriority"
                            onChange={handleChange} value={ticketType.orderPriority} className="bg-white" required type="number" />
        <div className="flex gap-4">
          <TextInputWithLabel label={t("low_bound_ticket_count")} id="lowBoundTicketCount" type="number" min="0"
                              placeholder={t("low_bound_ticket_count_placeholder")} name="lowBoundTicketCount" onChange={handleChange}
                              value={ticketType.lowBoundTicketCount} containerClassName="flex-1" className="bg-white" />
          <TextInputWithLabel label={t("high_bound_ticket_count")} id="highBoundTicketCount" type="number" min={ticketType.lowBoundTicketCount ?? 0}
                              placeholder={t("high_bound_ticket_count_placeholder")} name="highBoundTicketCount" onChange={handleChange}
                              value={ticketType.highBoundTicketCount} containerClassName="flex-1" className="bg-white" />
          <SelectWithData label={t("vat_percentage")} placeholder={t("vat_percentage_placeholder")} data={[{ id: 9, name: '9%' }, { id: 21, name: '21%' }]}
                          name="vatPercentage" id="vatPercentage" containerClassName="flex-1"
                          value={ticketType.vatPercentage}
                          selectedValueId={initialState?.vatPercentage} onChange={handleChange} required />
        </div>
        <div className="flex gap-2 w-full">
          <SelectWithData label={t("ticket_category")} placeholder={t("ticket_category_placeholder")} data={ticketCategories}
                          name="ticketCategoryId" id="ticketCategoryId" containerClassName="flex-1"
                          value={ticketType.ticketCategoryId}
                          selectedValueId={initialState?.ticketCategoryId} onChange={handleChange} required />
          <SelectWithData label={t("capacity_group")} placeholder={t("capacity_group_placeholder")} data={capacityGroups}
                          name="capacityGroupId" id="capacityGroupId" containerClassName={`flex-1 ${ ticketType.alwaysAvailable ? 'hidden' : '' }`}
                          value={ticketType.capacityGroupId}
                          selectedValueId={initialState?.capacityGroupId} onChange={handleChange} />
        </div>
        <div className="flex gap-2 w-full">
          <SelectWithData label={t("scan_definition")} placeholder={t("scan_definition_placeholder")} data={scanDefinitions}
                          name="scanDefinitionId" id="scanDefinitionId" containerClassName="flex-1"
                          value={ticketType.scanDefinitionId}
                          selectedValueId={initialState?.scanDefinitionId} onChange={handleChange} />
          <SelectWithData label={t("layouts")} placeholder={t("layouts_placeholder")} data={layouts}
                          name="layoutId" id="layoutId" containerClassName="flex-1"
                          value={ticketType.layoutId}
                          selectedValueId={initialState?.layoutId} onChange={handleChange} />
        </div>
        <MultipleDropdown placeholder={t("shop_availability_placeholder")} label={t("shop_availability")}
                          values={ticketType.ticketShops || []} onChange={(newTicketShops) => {
          setTicketType((prev): TicketTypeFormState => {
            return {
              ...prev,
              ticketShops: newTicketShops,
            };
          });
        }} allValues={ticketShops} />
        <CheckboxWithLabel containerClassName="flex" label={t("require_qr_code")} id="requireQRCode" name="requireQRCode"
                           onChange={handleCheckboxChange} checked={ticketType.requireQRCode}
                           className="w-4 h-4 border-2 rounded-sm bg-white mt-1 shrink-0
                          checked:bg-[#3C50E0] checked:border-0 cursor-pointer"
                           labelClassName="cursor-pointer pl-2" />

        <CheckboxWithLabel containerClassName="flex" label={t("require_rotterdampas")} id="rotterdampas" name="rotterdampas"
                           onChange={handleCheckboxChange} checked={ticketType.rotterdampas}
                           className="w-4 h-4 border-2 rounded-sm bg-white mt-1 shrink-0
                          checked:bg-[#3C50E0] checked:border-0 cursor-pointer"
                           labelClassName="cursor-pointer pl-2" />

        <CheckboxWithLabel containerClassName="flex" label={t("discountable")} id="discountable" name="discountable"
                           onChange={handleCheckboxChange} checked={ticketType.discountable}
                           className="w-4 h-4 border-2 rounded-sm bg-white mt-1 shrink-0
                          checked:bg-[#3C50E0] checked:border-0 cursor-pointer"
                           labelClassName="cursor-pointer pl-2" />
        <CheckboxWithLabel containerClassName="flex" label={t("occasional_selling_from")} id="occasionallySellingFrom" name="occasionallySellingFrom"
                           onChange={handleCheckboxChange} checked={ticketType.occasionallySellingFrom}
                           className="w-4 h-4 border-2 rounded-sm bg-white mt-1 shrink-0
                          checked:bg-[#3C50E0] checked:border-0 cursor-pointer"
                           labelClassName="cursor-pointer pl-2" />

        <div className={`flex gap-2 w-full ${ !ticketType.occasionallySellingFrom ? 'hidden' : '' }`}>
          <TextInputWithLabel label={t("selling_from")} id="validFrom" placeholder={t("occasional_selling_from_placeholder")} name="sellingFrom" type="date" onChange={handleChange}
                              value={ticketType.sellingFrom} className="bg-white" required={ticketType.occasionallySellingFrom} />
        </div>


        <CheckboxWithLabel containerClassName="flex" label={t("occasional_selling_to")} id="occasionallySellingTo" name="occasionallySellingTo"
                           onChange={handleCheckboxChange} checked={ticketType.occasionallySellingTo}
                           className="w-4 h-4 border-2 rounded-sm bg-white mt-1 shrink-0
                          checked:bg-[#3C50E0] checked:border-0 cursor-pointer"
                           labelClassName="cursor-pointer pl-2" />

        <div className={`flex gap-2 w-full ${ !ticketType.occasionallySellingTo ? 'hidden' : '' }`}>
          <TextInputWithLabel label={t("selling_to")} id="validFrom" placeholder={t("occasional_selling_to_placeholder")} name="sellingTo" type="date" onChange={handleChange}
                              value={ticketType.sellingTo} className="bg-white" required={ticketType.occasionallySellingTo} />
        </div>

        <CheckboxWithLabel containerClassName={`flex ${ formType !== 'create' ? 'invisible' : '' }`} label={t("always_available")} id="alwaysAvailable" name="alwaysAvailable"
                           onChange={handleCheckboxChange} checked={ticketType.alwaysAvailable}
                           className="w-4 h-4 border-2 rounded-sm bg-white mt-1 shrink-0
                          checked:bg-[#3C50E0] checked:border-0 cursor-pointer"
                           hidden={formType !== 'create'}
                           labelClassName="cursor-pointer pl-2" />

        <div className={`flex gap-2 w-full ${ !ticketType.alwaysAvailable ? 'hidden' : '' }`}>
          <TextInputWithLabel label={t("valid_from")} id="validFrom" placeholder={t("valid_from_placeholder")} name="validFrom" type="date" onChange={handleChange}
                            value={ticketType.validFrom} className="bg-white" required={ticketType.alwaysAvailable} />
          <TextInputWithLabel label={t("valid_to")} id="validTo" placeholder={t("valid_to_placeholder")} name="validTo" type="date" onChange={handleChange}
                            value={ticketType.validTo} className="bg-white" min={ticketType.validFrom ?? parseToYYYYMMDD(new Date(), location?.timezone ?? 'UTC')} required={ticketType.alwaysAvailable} />
        </div>

        <div className="flex justify-end gap-2 my-4">
          <Button type="button" className="bg-gray-500" onClick={(): void => navigate('/ticket-types')}>
            {t("cancel_button")}
          </Button>
          <Button type="submit">{formType === 'create' ? t("create_button") : t("save_button")}</Button>
        </div>
      </form>
    </>
  );
}

export default TicketTypeForm;

import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PricingPolicy, PricingPolicyDTO } from '../../types/pricingPolicies';
import { getPricingPolicy, updatePricingPolicy } from '../../services/pricingPoliciesService';
import PricingPolicyForm from '../../components/forms/PricingPolicyForm';

export default function PricingPoliciesEditPage(): JSX.Element | null {
  const navigate = useNavigate();
  const { t } = useTranslation("pricing_policy");
  const { id } = useParams();
  const [pricingPolicy, setPricingPolicy] = useState<PricingPolicy>();

  useEffect((): void => {
    if (!id) {
      return;
    }

  getPricingPolicy(Number(id))
    .then((oldPricingPolicy): void => {
      setPricingPolicy(oldPricingPolicy);
    })
    .catch((error): void => {
      toast.error(error.message);
      navigate('/pricing-policies');
    });
}, []);

  const onSubmit = (updatedPricingPolicy: PricingPolicyDTO): void => {
    updatePricingPolicy(Number(id), updatedPricingPolicy)
      .then((): void => {
        toast.success(t("updated_pricing_policy"));
        navigate('/pricing-policies');
      })
      .catch((error): void => {
        if (error.response?.data?.message) {
          toast.error(`${t("failed_edit_pricing_policy")}: ${error.response.data.message}`);
        }
        else {
          toast.error(`${t("failed_edit_pricing_policy")}: ${error}`);
        }
      });
  };

  if (!id) {
    toast.error('No pricing policy ID provided');
    navigate('/pricing-policies');
  }

  if (!pricingPolicy) return null;

  return (
    <PricingPolicyForm onSubmit={onSubmit} formType="update" initialState={pricingPolicy} />
  );
}
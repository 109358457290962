import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { CapacityGroupCreate, CapacityGroupUpdate, CapacityGroupWithDayGroups } from '../../types/capacityGroups';
import TextInputWithLabel from './TextInputWithLabel';
import Button from '../buttons/Button';
import { getSelectedLocation } from '../../services/locationService';
import { parseFromYYYYMMDDtoDate, parseToYYYYMMDD } from '../../utils/dateUtil';
import DayGroupTable from '../../pages/day-groups/DayGroupTable';
import usePermissions from '../../hooks/selectors/usePermissions';
import { Permissions } from '../../types/misc';

type CapacityGroupFormProps = {
  onSubmitCreate: (capacityGroup: CapacityGroupCreate) => void;
  onSubmitUpdate: (capacityGroup: CapacityGroupUpdate) => void;
  formType?: 'create' | 'update';
  initialState?: CapacityGroupWithDayGroups;
  capacityGroupId: number | undefined;
};

type CapacityGroupFormState = {
  name: string;
  startDate: string;
  endDate: string;
  slotDuration: string;
};

function CapacityGroupForm({
  onSubmitCreate = () => {},
  onSubmitUpdate = () => {},
  formType = 'create',
  capacityGroupId = undefined,
  initialState = {} as CapacityGroupWithDayGroups,
}: CapacityGroupFormProps): JSX.Element {
  if (!usePermissions([Permissions.ManageConfigurations])) {
    return (<></>);
  }
  const { t } = useTranslation("capacity_group_form");
  const navigate = useNavigate();

  const timeZone = getSelectedLocation()?.timezone ?? 'UTC';

  console.log(initialState)
  const [capacityGroup, setCapacityGroup] = useState<CapacityGroupFormState>({
    name: initialState?.name,
    startDate: parseToYYYYMMDD(new Date(initialState?.startDate), timeZone) ?? undefined,
    endDate: parseToYYYYMMDD(new Date(initialState?.endDate), timeZone) ?? undefined,
    slotDuration: String(initialState?.slotDuration),
  });
  console.log(capacityGroup);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>): void => {
    setCapacityGroup((prev): CapacityGroupFormState => ({
      ...prev,
      [event.target.name]: event.target.value,
    }));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>): void => {
    event.preventDefault();

    if (formType === 'create') {
      onSubmitCreate({
        name: capacityGroup.name,
        startDate: new Date(capacityGroup.startDate),
        endDate: new Date(capacityGroup.endDate),
        slotDuration: Number(capacityGroup.slotDuration),
      });
    } else {
      onSubmitUpdate({
        name: capacityGroup.name === initialState?.name ? undefined : capacityGroup.name,
        startDate: capacityGroup.startDate === parseToYYYYMMDD(new Date(initialState?.startDate), timeZone) ? undefined : parseFromYYYYMMDDtoDate(capacityGroup.startDate, timeZone),
        endDate: capacityGroup.endDate === parseToYYYYMMDD(new Date(initialState?.endDate), timeZone) ? undefined : parseFromYYYYMMDDtoDate(capacityGroup.endDate, timeZone)
      })
    }

  };


  return (
    <>
      <h2 className="text-2xl font-bold text-center mb-4">{formType === 'create' ? t("create_title") : t("edit_title")}</h2>
      <form className="flex flex-col gap-4 max-w-3xl m-auto" onSubmit={handleSubmit}>
        <TextInputWithLabel label={t("capacity_group_name")} id="name" placeholder={t("capacity_group_name_placeholder")} name="name" onChange={handleChange}
                            value={capacityGroup.name} className="bg-white" required />
        <TextInputWithLabel label={t("slot_duration")} id="slotDuration" placeholder={t("slot_duration_placeholder")} name="slotDuration" type="number" min="0" onChange={handleChange}
                            value={capacityGroup.slotDuration} className="bg-white" required disabled={formType === 'update'} />
        <TextInputWithLabel label={t("start_date")} id="startDate" placeholder={t("start_date_placeholder")} name="startDate" type="date" onChange={handleChange}
                            value={capacityGroup.startDate} className="bg-white" required />
        <TextInputWithLabel label={t("end_date")} id="endDate" placeholder={t("end_date_placeholder")} name="endDate" type="date" onChange={handleChange}
                            value={capacityGroup.endDate} className="bg-white" required />
        <div className="flex justify-center	gap-8">
          <Button type="button" className="self-center px-12" onClick={(): void => navigate(`/capacity-groups`)}>{t("cancel_button")}</Button>
          <Button type="submit" className="self-center px-12">{formType === 'create' ? t("create_button") : t("save_button")}</Button>
        </div>
      </form>
      <div className="mt-10 mb-10">
        <DayGroupTable initialState={initialState?.dayGroups} capacityGroupId={capacityGroupId}/>
      </div>
    </>
  );
}

export default CapacityGroupForm;
import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { LayoutDto, LayoutWithTemplate } from '../../types/layout';
import { editLayoutForLocation, getLayoutForLocation } from '../../services/layoutService';
import LayoutForm from '../../components/forms/LayoutForm';

export default function LayoutsEditPage(): JSX.Element {
  const navigate = useNavigate();
  const { id } = useParams();
  const [oldLayout, setOldLayout] = React.useState<LayoutWithTemplate | undefined>(undefined);
  const { t } = useTranslation("layouts");

  
  useEffect(() => {
    if (!id) {
      return;
    }
    
    getLayoutForLocation(Number(id))
      .then((oldLayoutState) => {
        setOldLayout(oldLayoutState);
      })
      .catch(error => {
        toast.error(error.message);
      });
  }, []);
  
  if (!oldLayout) {
    return <></>
  }
  
  const onSubmit = (layout: LayoutDto): void => {
    editLayoutForLocation(layout, Number(id))
      .then((): void => {
        toast.success(t("updated"));
        navigate('/layouts');
      })
      .catch((error): void => {
        toast.error(`${t("failed_edit")}: ${error}`);
      });
  }
  
  return (
    <LayoutForm onSubmit={onSubmit} formType='update' initialState={oldLayout}/>
  )
}
import React from 'react';
import { Checkbox, CheckboxProps, Field, Label } from '@headlessui/react';
import { TbCheck } from 'react-icons/tb';
import { twMerge } from 'tailwind-merge';

type StyledCheckboxWithLabelProps = {
  label: string;
  containerClassName?: string;
  labelClassName?: string;
  className?: string;
} & Omit<CheckboxProps, 'className'>;

export default function StyledCheckboxWithLabel({ label, containerClassName, labelClassName, className, ...props }: StyledCheckboxWithLabelProps): JSX.Element {
  return (
    <Field className={twMerge('flex items-center gap-2', containerClassName)}>
      <Checkbox
        className={twMerge(
          'group size-4 cursor-pointer rounded border bg-white data-[checked]:bg-sky-500',
          'flex items-center justify-center transition-none border-gray-600 border',
           className,
        )}
        {...props}
      >
        {({ checked }): JSX.Element => {
          return <TbCheck className={`size-4 stroke-[3] text-white ${checked ? 'visible' : 'invisible'}`} />
        }}
      </Checkbox>
      <Label className={twMerge(labelClassName)}>{label}</Label>
    </Field>
  );
}

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { TicketCategory } from '../../types/tickets';
import { TicketCategoryState } from '../../types/redux';

const initialState: TicketCategoryState = {
  ticketCategories: [],
};

const ticketCategoriesSlice = createSlice({
  name: 'ticketCategories',
  initialState,
  reducers: {
    setTicketCategories(state, action: PayloadAction<TicketCategory[]>): void {
      state.ticketCategories = action.payload;
    },
  },
});

const ticketCategoriesReducer = ticketCategoriesSlice.reducer;

export const { setTicketCategories } = ticketCategoriesSlice.actions;
export default ticketCategoriesReducer;

import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { TicketTypesState } from '../../types/redux';
import { TicketType } from '../../types/tickets';

const initialState: TicketTypesState = {
  ticketTypes: [],
};

const ticketTypesSlice = createSlice({
  name: 'ticketTypes',
  initialState,
  reducers: {
    setTicketTypes(state, action: PayloadAction<TicketType[]>): void {
      state.ticketTypes = action.payload;
    },
  },
});

const ticketTypesReducer = ticketTypesSlice.reducer;

export const { setTicketTypes } = ticketTypesSlice.actions;
export default ticketTypesReducer;

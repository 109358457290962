import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { TbCirclePlus } from 'react-icons/tb';
import { DateTime } from 'luxon';
import { toast } from 'react-toastify';
import { Purchase } from '../../types/payment';
import { getSelectedLocation } from '../../services/locationService';
import useLanguage from '../../hooks/selectors/useLanguage';
import { Reservation } from '../../types/reservation';
import IconButton from '../../components/buttons/IconButton';
import Button from '../../components/buttons/Button';
import { getDayGroupsForCapacityGroup } from '../../services/capacityGroupService';
import { Slot } from '../../types/slots';
import SelectWithData from '../../components/forms/SelectWithData';
import { formatString } from '../../utils/dateUtil';
import { rebookPurchase } from '../../services/rebookService';

interface PaymentsRebookSelectSlotStepProps {
  paymentId: number,
  purchases: Purchase[],
  reservations: Reservation[],
}

export default function PaymentsRebookSelectSlotStep({ paymentId, purchases, reservations }: PaymentsRebookSelectSlotStepProps): JSX.Element | null {
  const language = useLanguage();
  const navigate = useNavigate();
  const timeZone = getSelectedLocation()?.timezone ?? 'UTC';

  const [queryParams, setQueryParams] = useSearchParams();

  const products = useMemo(() => queryParams.get('products')?.split(',').map(Number), [queryParams.get('products')]);
  const date = useMemo(() => new Date(queryParams.get('date') ?? ''), [queryParams.get('date')]);

  const [slots, setSlots] = useState<Slot[]>([]); 
  const [submitted, setSubmitted] = useState<boolean>(false);

  const capacityGroupId = useMemo(() => {
    const firstProductId = products?.at(0);
    const firstProduct = purchases.find(p => p.id === firstProductId);

    const reservation = reservations.find(r => r.id === firstProduct?.extendedPurchaseData.reservation?.id);
    return reservation?.ticketTypeOnSlot.ticketType.capacityGroupId;
  }, [products, reservations, purchases]);

  const [selectedSlotId, setSelectedSlotId] = useState<number | null>(null);

  useEffect(() => {  
    if (!capacityGroupId) {
      navigate(`/payments/${paymentId}/details`);
      return;
    }

    getDayGroupsForCapacityGroup(capacityGroupId, date, DateTime.fromJSDate(date).plus({ days: 1 }).toJSDate())
      .then((data): void => {
        setSlots(data[0].slots.filter(slot => slot.availableCapacity >= (products?.length ?? 1) 
          && slot.active 
          && !slot.archived));
      })
  }, [products, date]);

  const { t } = useTranslation("payments_rebook");

  function onSubmit(): void {
    if (!products || products.length === 0 || !purchases || !selectedSlotId) {
      return;
    }

    const selectedPurchases = purchases.filter(p => products.includes(p.id));
    if (selectedPurchases.length !== products.length || selectedPurchases.some(p => !p.extendedPurchaseData.reservation)) {
      return;
    }

    const ticketTypeId = selectedPurchases[0].extendedPurchaseData.reservation!.ticketTypeOnSlot.ticketType.id;
    setSubmitted(true);
    rebookPurchase(paymentId, { ticketTypeId, purchases: selectedPurchases.map(p => ({ price: 0.0, slotId: selectedSlotId, reservationId: p.extendedPurchaseData.reservation!.id })) })
      .then(() => {
        navigate(`/payments/${paymentId}/details`);
      }).catch((error): void => {
        toast.error(error.message);
        navigate('/payments');
      });
  }
  
  const possibleSlots = useMemo(() => 
    slots.map(slot => ({ 
      id: slot.id, 
      name: `${formatString(slot.startTime, 'HH:mm', timeZone, language)}-${formatString(slot.endTime, 'HH:mm', timeZone, language)} ${slot.capacity-slot.availableCapacity}/${slot.capacity}` 
    })
  ), [slots]);

  return (
    <>
      <div className="flex justify-between items-center mb-4">
        <h2 className="font-gintobold font-bold text-2xl">{t("title_select_slot", { paymentId })}</h2>
        <div className="flex gap-1">
          <Button type="button" className="bg-gray-500" onClick={(): void => setQueryParams(current => ({ products: current.get('products') ?? '',  selected: current.get('date') ?? '' }))}>
                  {t("back")}
          </Button>
          <IconButton onClick={() => onSubmit()} icon={TbCirclePlus} label={t("rebook")}
                      className="bg-gradient-to-r from-[#E700F9] hover:shadow-purple-300 hover:shadow-md
                    to-pink-500 duration-300 bg-[position:_0%_0%] hover:bg-[position:_100%_100%]
                      bg-[size:_200%] transition-all font-bold text-white rounded-full cursor-pointer"
                      disabled={selectedSlotId === null || submitted} />
        </div>
      </div>

      <div className="flex flex-col gap-4">
        <SelectWithData label={t("slot")} placeholder={t("select_slot")} 
                          data={possibleSlots}
                          name="scanDefinitionId" id="scanDefinitionId" containerClassName="flex-1"
                          onChange={(v) => setSelectedSlotId(Number(v.target.value))} />
      </div>
    </>
  );
}
import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { TicketCategory, TicketCategoryDto } from '../../types/tickets';
import { getTicketCategoryForCurrentLocation, editTicketCategory } from '../../services/ticketCategoryService';
import TicketCategoryForm from '../../components/forms/TicketCategoryForm';

export default function TicketCategoryEditPage(): JSX.Element {
  const navigate = useNavigate();
  const { id } = useParams();
  const [oldTicketCategory, setOldTicketCategory] = React.useState<TicketCategory | undefined>(undefined);
  const { t } = useTranslation("ticket_category");


  useEffect(() => {
    if (!id) {
      return;
    }

    getTicketCategoryForCurrentLocation(Number(id))
      .then((oldTicketCategoryState) => {
        setOldTicketCategory(oldTicketCategoryState);
      })
      .catch(error => {
        toast.error(error.message);
      });
  }, []);

  if (!oldTicketCategory) {
    return <></>
  }

  const onSubmit = (ticketCategoryDto: TicketCategoryDto): void => {
    editTicketCategory(Number(id), ticketCategoryDto)
      .then((): void => {
        toast.success(t("updated"));
        navigate('/ticket-categories');
      })
      .catch((error): void => {
        toast.error(`${t("failed_edit")}: ${error}`);
      });
  }

  return (
    <TicketCategoryForm onSubmit={onSubmit} formType='update' initialState={oldTicketCategory}/>
  )
}

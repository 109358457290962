import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Payment, ShopType } from '../../types/payment';
import { getPayment } from '../../services/paymentService';
import PaymentsTicketDetailsPage from './PaymentsTicketDetailsPage';
import PaymentsSubscriptionDetailsPage from './PaymentsSubscriptionDetailsPage';

export default function PaymentsDetailsPage(): JSX.Element | null {
  const navigate = useNavigate();
  const { id } = useParams();
  const [payment, setPayment] = useState<Payment>();

  const getPaymentData = (paymentId: number): void => {
    getPayment(paymentId)
        .then((oldPayment): void => {
            setPayment(oldPayment);
        })
        .catch((error): void => {
            toast.error(error.message);
            navigate('/payments');
        });
  }

  useEffect((): void => {
    if (!id) {
        toast.error('No payment ID provided');
        navigate('/payments');
    }

    getPaymentData(Number(id));
  }, [id]);

  if (!payment) return null;

  return (
    <>
        { payment.extendedPaymentData.shop.type === ShopType.TICKET &&
            <PaymentsTicketDetailsPage payment={payment} fetchPaymentData={getPaymentData} />
        }
        { payment.extendedPaymentData.shop.type === ShopType.SUBSCRIPTION &&
            <PaymentsSubscriptionDetailsPage payment={payment} fetchPaymentData={getPaymentData} />
        }
    </>
  );
}

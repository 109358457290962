import React, { DetailedHTMLProps, FormHTMLAttributes } from 'react';
import { twMerge } from 'tailwind-merge';

type AnonymousUserFormProps = DetailedHTMLProps<FormHTMLAttributes<HTMLFormElement>, HTMLFormElement>

export default function AnonymousUserForm({ className, children, ...props }: AnonymousUserFormProps): JSX.Element {
  return (
    <form className={twMerge('flex flex-col max-w-3xl w-full gap-4 m-auto bg-[#1C2434] rounded-xl px-12 py-8 text-white', className)}
          {...props}>
      <img src="/logo.png" className="w-24 self-center my-4" alt="Schaatsbaan logo" />
      {children}
    </form>
  );
}

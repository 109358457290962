import React from 'react';
import { FaCheck, FaTimes } from 'react-icons/fa';

type BooleanIconProps = {
    value: boolean;
    label?: string;
};

function BooleanIcon({ value, label }: BooleanIconProps): JSX.Element {
    return (
        <div className="flex items-center">
            {value ? (
                <>
                    <FaTimes className="text-red-500 mr-2" />
                    {label && <span className='text-red-500'>{label}</span>}
                </>
            ) : (
                <>
                    <FaCheck className="text-green-500 mr-2" />
                    {label && <span className='text-green-500'>{label}</span>}
                </>
            )}
        </div>
    );
}

export default BooleanIcon;
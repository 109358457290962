import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { EmailTemplateDto, EmailTemplateWithData } from '../../types/emailTemplates';
import { getEmailTemplateForLocation, updateEmailTemplateForLocation } from '../../services/emailTemplatesService';
import EmailTemplateForm from '../../components/forms/EmailTemplateForm';

export default function EmailTemplatesEditPage(): JSX.Element {
  const navigate = useNavigate();
  const { id } = useParams();
  const [oldEmailTemplate, setOldEmailTemplate] = useState<EmailTemplateWithData | undefined>(undefined);
  const { t } = useTranslation("email_templates");

  useEffect(() => {
    if (!id) {
      toast.error(t("no_param"));
      return;
    }
    
    getEmailTemplateForLocation(Number(id))
      .then((oldEmailTemplateState) => {
        setOldEmailTemplate(oldEmailTemplateState);
      })
      .catch(error => {
        toast.error(error.message);
      })
  }, []);
  
  if (!oldEmailTemplate) {
    return <></>
  }
  
  const onSubmit = (emailTemplateDto: EmailTemplateDto): void => {
    updateEmailTemplateForLocation(emailTemplateDto, Number(id))
      .then((): void => {
        toast.success(t("updated"));
        navigate('/templates')
      })
      .catch((error): void => {
        toast.error(`${t("failed_edit")}: ${error}`);
      })
  }

  return (
    <EmailTemplateForm onSubmit={onSubmit} formType='update' initialState={oldEmailTemplate} />
  )
}

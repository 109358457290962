import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { createColumnHelper, Row } from '@tanstack/react-table';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { MdQrCodeScanner } from 'react-icons/md';
import { TbArchive, TbCirclePlus, TbEdit, TbTrash } from 'react-icons/tb';
import SelectionTable from '../../components/tables/SelectionTable';
import { Scanner } from '../../types/tickets';
import { store } from '../../redux/store';
import IconButton from '../../components/buttons/IconButton';
import DeleteModal from '../../components/modals/DeleteModal';
import { deleteScanner, getScannersForCurrentLocation } from '../../services/scannerService';
import { setScanners } from '../../redux/slices/scannersSlice';
import { getSelectedLocation } from '../../services/locationService';
import useScanners from '../../hooks/selectors/useScanners';
import useLanguage from '../../hooks/selectors/useLanguage';
import usePermissions from '../../hooks/selectors/usePermissions';
import { Permissions } from '../../types/misc';

function constructDayRenderer(dateName: "lastConnectedDate") {
  return function({ row: { original } }: {
    row: Row<Scanner>
  }): JSX.Element {
    const { t } = useTranslation("scanners");
    const timeZone: string = getSelectedLocation()?.timezone ?? 'UTC';
    return <>{original.lastConnectedDate ? new Date(original[dateName]).toLocaleDateString('nl-NL', {day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', minute: '2-digit', timeZone}) : t("not_connected_yet")}</>;
  }
}

function RenderActionsCell({ row }: { row: Row<Scanner> }, resetPagination: () => void): JSX.Element {
  const [deleteModalOpen, setDeleteModalOpen] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const { t } = useTranslation("scanners");

  const onDelete = (): void => {
    deleteScanner(row.original.id)
      .then((): void => {
        toast.success(t("deleted"));
        resetPagination();
        setDeleteModalOpen(false);
      })
      .catch((error): void => {
        toast.error(error.message);
      });
  };

  return (
    <>
      <div className="flex justify-end items-center gap-1">
        
        { row.original.status === 'NEW' && <IconButton
          onClick={(): void => navigate(`/scanners/register/${row.original.id}`)}
          icon={MdQrCodeScanner} label="" variant="success"
          className="py-1 px-1 text-lg"
          disabled={!usePermissions([Permissions.ManageConfigurations])}
        /> }
        <IconButton
          onClick={(): void => navigate(`/scanners/edit/${row.original.id}`)}
          icon={TbEdit} label="" variant="accent"
          className="py-1 px-1 text-lg"
          disabled={!usePermissions([Permissions.ManageConfigurations])}
        />
        { (row.original.archived || row.original.status === 'NEW') && <IconButton
          onClick={(): void => setDeleteModalOpen(true)}
          icon={TbTrash} label="" variant="danger"
          className="py-1 px-1 text-lg"
          disabled={!usePermissions([Permissions.ManageConfigurations])}
        /> }
      </div>
      <DeleteModal open={deleteModalOpen} setOpen={setDeleteModalOpen} onActionButtonClicked={onDelete}
                   message={`${t("delete_confirmation")} "${row.original.name}" ?`}
      />
    </>
  );
}

function RenderName({ row: { original: { name, archived } } }: {
  row: Row<Scanner>
}): JSX.Element {
  if (!archived) return <>{name}</>;

  return (
    <div className="flex items-center gap-1 text-gray-600"><TbArchive size={16} />{name}</div>
  );
}

export default function ScannersListPage(): JSX.Element {
  if (!usePermissions([Permissions.ViewConfigurations])) {
    return (<></>);
  }
  const [pagination, setPagination] = React.useState({ pageSize: 25, pageIndex: 0 });
  const scanners = useScanners();
  const language = useLanguage();
  const navigate = useNavigate();
  const { t } = useTranslation("scanners");

  const resetPagination = (): void => {
    setPagination(prev => ({ pageSize: prev.pageSize, pageIndex: prev.pageIndex }));
  }

  useEffect((): void => {
    getScannersForCurrentLocation(pagination.pageIndex * pagination.pageSize, pagination.pageSize)
      .then((data): void => {
        store.dispatch(setScanners(data));
      })
      .catch((error): void => {
        toast.error(error.message);
      });
  }, [pagination]);

  const columns = useMemo((): any[] => {
    const columnHelper = createColumnHelper<Scanner>();
    return [
      columnHelper.accessor((row) => row.id, {
        id: 'id',
        header: t("id"),
      }),
      columnHelper.accessor((row) => row.name, {
        id: 'name',
        header: t("name"),
        cell: RenderName
      }),
      columnHelper.accessor((row): string => row.lastConnectedDate, {
        id: 'lastConnectedDate',
        header: t("last_connected_date"),
        cell: constructDayRenderer('lastConnectedDate'),
      }),
      columnHelper.accessor((row) => row.status, {
        id: 'status',
        header: t("status"),
      }),
      {
        id: 'actions',
        header: t("actions"),
        cell: (row: { row: Row<Scanner> }) => RenderActionsCell(row, resetPagination),
        meta: {
          align: 'text-right',
        },
      },
    ];
  }, [language]);

  return (
    <>
      <div className="flex justify-between items-center mb-2">
        <h2 className="font-gintobold font-bold text-2xl">{t("title")}</h2>
        <div className="flex gap-1">
          {
            usePermissions([Permissions.ManageConfigurations]) &&
            <IconButton onClick={(): void => navigate('/scanners/create')} icon={TbCirclePlus} label={t("new")}
                        className="bg-gradient-to-r from-[#E700F9] hover:shadow-purple-300 hover:shadow-md
                      to-pink-500 duration-300 bg-[position:_0%_0%] hover:bg-[position:_100%_100%]
                      bg-[size:_200%] transition-all font-bold text-white rounded-full cursor-pointer" />
          }
        </div>
      </div>
      <SelectionTable
        columns={columns}
        data={scanners}
        pagination={pagination}
        setPagination={setPagination}
        enableRowSelection={false}
      />
    </>
  );
}

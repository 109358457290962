import { useNavigate, useParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import CapacityGroupEditor from '../../components/tables/CapacityGroupEditor';
import usePermissions from '../../hooks/selectors/usePermissions';
import { Permissions } from '../../types/misc';

export default function CapacityGroupEditorPage(): JSX.Element {
  if (!usePermissions([Permissions.ManageConfigurations])) {
    return (<></>);
  }
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect((): void => {
    if (!id) {
      navigate('/capacity-groups');
    }
  }, []);

  if (!id) {
    return <></>;
  }

  return (
    <CapacityGroupEditor />
  );
}

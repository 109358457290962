import React from 'react';

type CheckboxWithLabelProps = {
  label: string;
  containerClassName?: string;
  labelClassName?: string;
  id: string;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'id' | 'type'>;

export default function CheckboxWithLabel({ label, containerClassName, labelClassName, id, ...props }: CheckboxWithLabelProps): JSX.Element {
  return (
    <div className={containerClassName}>
      <input type="checkbox" id={id} {...props} />
      <label htmlFor={id} className={labelClassName}>{label}</label>
    </div>
  );
}

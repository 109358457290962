import React, { JSX, useEffect, useMemo } from 'react';
import { TbCirclePlus, TbEdit, TbTrash } from 'react-icons/tb';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { createColumnHelper, Row } from '@tanstack/react-table';
import { LuFileJson } from 'react-icons/lu';
import { FaRegFilePdf } from 'react-icons/fa';
import useLayouts from '../../hooks/selectors/useLayouts';
import useLanguage from '../../hooks/selectors/useLanguage';
import {
  deleteLayoutForLocation,
  getDummyPdfForLayout, getLayoutForLocation,
  getLayoutsForCurrentLocation,
} from '../../services/layoutService';
import { store } from '../../redux/store';
import { setLayouts } from '../../redux/slices/layoutsSlice';
import { Layout } from '../../types/layout';
import IconButton from '../../components/buttons/IconButton';
import SelectionTable from '../../components/tables/SelectionTable';
import DeleteModal from '../../components/modals/DeleteModal';
import usePermissions from '../../hooks/selectors/usePermissions';
import { Permissions } from '../../types/misc';

function RenderActionsCell({ row }: { row: Row<Layout> }, resetPagination: () => void): JSX.Element {
  const [deleteModalOpen, setDeleteModalOpen] = React.useState<boolean>(false);
  const navigate = useNavigate();
  const { t } = useTranslation("layouts");

  const onDelete = (): void => {
    deleteLayoutForLocation(row.original.id)
      .then((): void => {
        toast.success(t("delete_layout_success"));
        resetPagination();
        setDeleteModalOpen(false);
      })
      .catch((error): void => {
        toast.error(error.message);
      });
  };

  const onDownloadPdf = (): void => {
    getDummyPdfForLayout(row.original.id)
      .then((data): void => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'layout.pdf');
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      })
  }

  const onDownloadJson = (): void => {
    getLayoutForLocation(row.original.id)
      .then(({ template }): void => {
        const jsonData = JSON.stringify(template, null, 2);  // Convert the template object to a JSON string

        const url = window.URL.createObjectURL(new Blob([jsonData], { type: 'application/json' }));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'template.json');
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      })
  }
  
  return (
    <>
      <div className="flex justify-end items-center gap-1">
        <IconButton
          onClick={onDownloadPdf}
          icon={FaRegFilePdf} label="" variant="download"
          className="py-1 px-1 text-lg"
        />
        <IconButton
          onClick={onDownloadJson}
          icon={LuFileJson} label="" variant="download"
          className="py-1 px-1 text-lg"
        />
        <IconButton
          onClick={(): void => navigate(`/layouts/edit/${row.original.id}`)}
          icon={TbEdit} label="" variant="accent"
          className="py-1 px-1 text-lg"
          disabled={!usePermissions([Permissions.ManageConfigurations])}
        />
        <IconButton
          onClick={(): void => setDeleteModalOpen(true)}
          icon={TbTrash} label="" variant="danger"
          className="py-1 px-1 text-lg"
          disabled={!usePermissions([Permissions.ManageConfigurations])}
        />
      </div>
      <DeleteModal open={deleteModalOpen} setOpen={setDeleteModalOpen} onActionButtonClicked={onDelete}
                   message={`${t("delete_confirmation")} "${row.original.name}" ?`}
      />
    </>
  )
}

export default function LayoutsListPage(): JSX.Element {
  if (!usePermissions([Permissions.ViewConfigurations])) {
    return (<></>);
  }
  const [pagination, setPagination] = React.useState({ pageSize: 25, pageIndex: 0 });
  const layouts = useLayouts();
  const language = useLanguage();
  const navigate = useNavigate();
  const { t } = useTranslation("layouts");

  const resetPagination = (): void => {
    setPagination(prev => ({ pageSize: prev.pageSize, pageIndex: prev.pageIndex }));
  }

  useEffect((): void => {
    getLayoutsForCurrentLocation(pagination.pageIndex * pagination.pageSize, pagination.pageSize)
      .then((data): void => {
        store.dispatch(setLayouts(data));
      })
      .catch((error): void => {
        toast.error(error.message);
      });
  }, [pagination]);
  
  const columns = useMemo((): any[] => {
    const columnHelper = createColumnHelper<Layout>();
    return [
      columnHelper.accessor((row) => row.id, {
        id: 'id',
        header: t("id"),
      }),
      columnHelper.accessor((row) => row.name, {
        id: 'name',
        header: t("name")
      }),
      {
        id: 'actions',
        header: t("actions"),
        cell: (row: { row: Row<Layout> }) => RenderActionsCell(row, resetPagination),
        meta: {
          align: 'text-right'
        },
      },
    ];
  }, [language])
  
  return (
    <>
      <div className="flex justify-between items-center mb-2">
        <h2 className="font-gintobold font-bold text-2xl">{t("title")}</h2>
        <div className="flex gap-1">
          {
            usePermissions([Permissions.ManageConfigurations]) &&
            <IconButton onClick={(): void => navigate('/layouts/create')} icon={TbCirclePlus} label={t("new")}
                        className="bg-gradient-to-r from-[#E700F9] hover:shadow-purple-300 hover:shadow-md
                      to-pink-500 duration-300 bg-[position:_0%_0%] hover:bg-[position:_100%_100%]
                      bg-[size:_200%] transition-all font-bold text-white rounded-full cursor-pointer" />
          }
        </div>
      </div>
      <SelectionTable
        columns={columns}
        data={layouts}
        pagination={pagination}
        setPagination={setPagination}
        enableRowSelection={false}
      />
    </>
  )
}